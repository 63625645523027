/**
 * Sorts an array of objects based on a specified order of a property.
 *
 * @param {Array} Ions - The array of objects to be sorted.
 * @param {Array} order - The array specifying the order of the property values.
 * @returns {Array} - The sorted array.
 */
export const sortByOrder = (Ions, order) => {
  const ReplicatedIons = [...Ions];
  const sortedIons = ReplicatedIons.sort((item1, item2) => order[item1.name] - order[item2.name]);
  // Create a new array with formatted mgL values
  const formattedIons = sortedIons.map(ion => ({
    ...ion,
    mgL: parseAndFormatFloat(ion.mgL, 3),
  }));
  return formattedIons;
};

/**
 * Converts to a floating-point number and formats it to a specified number of decimal places.
 *
 * @param {string} value - The string to be converted to a floating-point number.
 * @param {number} num - The number of decimal places to format the number to.
 * @returns {string} - The formatted number as a string.
 */
export function parseAndFormatFloat(value, num) {
  return parseFloat(value).toFixed(num);
}

/**
 * Function to calculate the total sum of a specified sub-property (e.g., mgL, meqL, ppm)
 * for a given property (e.g., cations, anions, neutrals) in an array of feed water details.
 *
 * @param {Array} array - The array of feed water details.
 * @param {string} property - The property to sum (e.g., "cations", "anions", "neutrals").
 * @param {string} subProperty - The sub-property to sum (e.g., "mgL", "meqL", "ppm").
 * @returns {number} - The total sum of the specified sub-property for the given property.
 */
export const sumFeedWaterProperty = (array, property, subProperty) =>
  array?.map(
    item => item[property]?.reduce((total, currentValue) => total + Number(currentValue[subProperty]), 0) || 0,
  );
