/* eslint-disable max-len */
import React from "react";

const NotificationTealIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24' fill='none'>
      <path
        d='M4.28943 15.3085V9.72423C4.28943 9.64702 4.29104 9.58787 4.29685 9.53043C4.33711 9.13249 4.55189 7.64518 5.56505 6.18101C6.56394 4.73748 8.36557 3.27529 11.6682 2.93171C11.8985 2.90775 12.122 2.90028 12.3364 2.91731C13.068 2.97545 14.7961 3.22204 16.3953 4.24485C17.9661 5.24955 19.446 7.02199 19.7105 10.2203V15.1277C19.7105 17.0847 20.7503 18.8933 22.4409 19.8786L22.6492 20H1.66579L1.81828 19.8994C3.36106 18.8815 4.28943 17.1568 4.28943 15.3085Z'
        stroke='#007672'
      />
      <rect x='10.1055' width='3.78947' height='2.4' rx='1' fill='#007672' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 21.6001C10 22.9256 11.1311 24.0001 12.5263 24.0001C13.9216 24.0001 15.0526 22.9256 15.0526 21.6001H10Z'
        fill='#007672'
      />
    </svg>
  </>
);

export default NotificationTealIcon;
