import { reportStrings } from "@constants/report.strings";

import { WPUfParametersDataMapping } from "../../WPUfParametersTables.constants";
import { generateStepRowData } from "../../WPUfParametersTablesUtils";

export const WPUfIngeBwParametersTableColumns = [
  {
    field: "col1",
    rowHeader: true,
    headerName: reportStrings.pumpAndValveConditions,
    rowSpan: 3,
  },
  {
    field: "col2",
    headerName: reportStrings.operation,
    colSpan: 2,
  },
  {
    field: "col3",
    colSpan: 0,
  },
  {
    field: "col4",
    headerName: reportStrings.backwashBW,
    colSpan: 4,
  },
  {
    field: "col5",
    colSpan: 0,
  },
  {
    field: "col6",
    colSpan: 0,
  },
  {
    field: "col7",
    colSpan: 0,
  },
  {
    field: "col8",
    headerName: reportStrings.returnToOpertaion,
  },
  {
    field: "col9",
    headerName: reportStrings.stop + "<sup>c</sup>",
  },
];

export const WPUfIngeBwParametersTableRows = [
  {
    rowKey: "stepNumber",
    ...generateStepRowData(2, 6),
    col8: 2,
    col9: "-",
  },
  {
    rowKey: "stepLabel",
    col2: reportStrings.fillingAtStartup + "<sup>d</sup>",
    col3: reportStrings.filtrationMode,
    col4: reportStrings.forwardFlush + "<sup>b</sup>",
    col5: reportStrings.backwashDrainBottom,
    col6: reportStrings.backwashDrainTop,
    col7: reportStrings.forwardFlush + "<sup>b</sup>",
    col8: reportStrings.filtrationMode,
    col9: reportStrings.stop + "<sup>c</sup>",
  },
  {
    rowKey: "feedPump",
    col1: reportStrings.feedPump,
    col2: true,
    col3: true,
    col4: true,
    col7: true,
    col8: true,
  },
  {
    rowKey: "backwashPump",
    col1: reportStrings.backwashPump,
    col5: true,
    col6: true,
  },
  //NOTE: Chemical dosing pump values are ON by default but are hidden based on conditions in useUfParametersTableData.js
  {
    rowKey: "chemDosingPump",
    col1: reportStrings.chemDosingPump + "<sup>a</sup>",
    col3: true,
    col7: true,
  },
  {
    rowKey: "cebDosingPump",
    col1: reportStrings.cebDosingPump,
  },
  {
    rowKey: "cipRecyclePump",
    col1: reportStrings.cipRecyclePump,
  },
  {
    rowKey: "feedValve",
    col1: reportStrings.feedValve,
    col2: true,
    col3: true,
    col4: true,
    col7: true,
    col8: true,
  },
  {
    rowKey: "filtrateValve",
    col1: reportStrings.filtrateValve,
    col3: true,
    col8: true,
  },
  {
    rowKey: "drainBottomValve",
    col1: reportStrings.drainBottomValve,
    col5: true,
  },
  {
    rowKey: "drainTopValve",
    col1: reportStrings.drainTopValve,
    col4: true,
    col6: true,
    col7: true,
  },
  {
    rowKey: "backwashInletValve",
    col1: reportStrings.backwashInletValve,
    col5: true,
    col6: true,
  },
  {
    rowKey: "duration",
    col1: reportStrings.duration,
    col2: WPUfParametersDataMapping.minutesZero,
    col3: WPUfParametersDataMapping.t_filtration_cycle,
    col4: WPUfParametersDataMapping.t_FF,
    col5: WPUfParametersDataMapping.t_BW1,
    col6: WPUfParametersDataMapping.t_BW2,
    col7: WPUfParametersDataMapping.t_FF,
    col8: WPUfParametersDataMapping.t_filtration_cycle,
  },
  {
    rowKey: "flowRate",
    col1: reportStrings.flowRate,
    col2: WPUfParametersDataMapping.Flow_FF3,
    col3: WPUfParametersDataMapping.Flux_Filter_actual,
    col4: WPUfParametersDataMapping.Flow_FF,
    col5: WPUfParametersDataMapping.Flux_BW,
    col6: WPUfParametersDataMapping.Flux_BW,
    col7: WPUfParametersDataMapping.Flow_FF,
    col8: WPUfParametersDataMapping.Flux_Filter_actual,
  },
];
