import { WPUfChemicalsTable } from "../WPUfChemicalsTable";
import { WPUfElectricityTable } from "../WPUfElectricityTable";
import { WPUfServiceWaterTable } from "../WPUfServiceWaterTable";
import { WPUfUtilityCostTable } from "../WPUfUtilityCostTable";

export const WPUfUtilityAndChemicalCost = () => (
  <>
    <WPUfServiceWaterTable />
    <WPUfElectricityTable />
    <WPUfChemicalsTable />
    <WPUfUtilityCostTable />
  </>
);
