import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { loadExternalScript } from "@utils/appUtils";

import { WPFullScreenLoader } from "@components/WPFullScreenLoader";

import Footer from "./common/footer/Footer";
import Routing from "./Routing";

const App = () => {
  const locationCheck = useLocation();
  const exactLocation = locationCheck.pathname;

  const feedCheck = useSelector(state => state.tabData.tab);
  const [showSideMenu, setShowSideMenu] = useState(false);

  const handleResize = () => {
    setShowSideMenu(window.innerWidth <= 1200); //show side menu for width >=1300
  };

  useEffect(() => {
    handleResize(); // set initial state based on window size
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [showSideMenu]);

  const trustArcCookieURL = process.env.REACT_APP_TRUSTARC_CONSENT_URL;
  if (trustArcCookieURL) {
    loadExternalScript(trustArcCookieURL);
  }

  return (
    <>
      <Routing />
      <Footer locationCheck={exactLocation} tabCheck={feedCheck} />
      <WPFullScreenLoader />
    </>
  );
};

export default App;
