/**
 * Generates an object with column keys and sequential values.
 * @param {number} colIndex - The starting column index.
 * @param {number} count - The number of rows to generate.
 * @returns {Object} The generated row data as an object.
 */
export const generateStepRowData = (colIndex, count) =>
  Array.from({ length: count }).reduce((acc, _, i) => {
    acc[`col${colIndex + i}`] = i + 1;
    return acc;
  }, {});
