import { CaseManagementStrings } from "@utils/StringConstants";

import DragIcon from "@components/SVGs/DragIcon";

import CaseManagementActionRender from "./CaseManagementActionRender";

const CustomDragHandle = () => (
  <div className='custom-drag-handle'>
    <DragIcon />
  </div>
);

const CaseNoHandle = params => <div>{params.node.rowIndex + 1}</div>;

export const CASE_TABLE_CONFIG = [
  {
    headerName: "",
    field: "",
    width: 40,
    cellRenderer: CustomDragHandle,
    animateRows: true,
  },
  {
    headerName: CaseManagementStrings.caseNo,
    field: "caseNo",
    width: 80,
    cellRenderer: CaseNoHandle,
  },
  {
    headerName: CaseManagementStrings.caseName,
    field: "caseName",
    flex: 1,
    tooltip: true,
  },
  {
    headerName: CaseManagementStrings.caseNotes,
    field: "caseNote",
    flex: 2,
    tooltip: true,
  },
  {
    headerName: CaseManagementStrings.actions,
    field: "actions",
    width: 150,
    cellRenderer: CaseManagementActionRender,
  },
];
