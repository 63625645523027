import React from "react";

import WPHeading from "@components/WPHeading";

const ChemicalDisplayNameRenderer = ({ value }) => (
  <div className='vertical-center'>
    <WPHeading type='default' size='small' className='text-overflow-ellipsis'>
      <span>{value}</span>
    </WPHeading>
  </div>
);

export default ChemicalDisplayNameRenderer;
