import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { CustomModalButtons } from "@constants/global.constants";
import { APP_TOAST_NOTIFICATION } from "@constants/message.constants";

import useProjectDetails from "@hooks/useProjectDetails";
import useUpdateCase from "@hooks/useUpdateCase";

import { CaseManagementStrings, CommonButtonStrings } from "@utils/StringConstants";

import ConfirmationPopup, { DIALOG_TYPES } from "@components/ConfirmationPopup";
import { useToastNotification } from "@components/WPToastNotification";

import { updateActiveCase, updateCaseConfig, updateCaseName } from "@common/ProjectInfoSlice";

import AddEditCasePopup from "./AddEditCasePopup";
import CaseManagementDropdown from "./CaseManagementDropdown";
import CaseManagementPopup from "./CaseManagementPopup";

import "./CaseManager.scss";

const CaseManager = ({ show }) => {
  const [cases, setCases] = useState(null);
  const [deletedCaseId, setDeletedCaseId] = useState(null);
  const [selectedCase, setSelectedCase] = useState(null);
  const [openManageCases, setOpenManageCases] = useState(false);

  const [editCase, setEditCase] = useState(null);
  const [isAddCase, setIsAddCase] = useState(false);

  const dispatch = useDispatch();
  const { projectDetails, navigatToProject, activeCaseId } = useProjectDetails();
  const { projectName, projectID } = projectDetails;
  const { updateCase, loading, caseUpdateError, fetchCases, caseList } = useUpdateCase();

  const { showSuccessNotification, showErrorNotification } = useToastNotification();

  const showErrorToast = description => showErrorNotification(description);

  const [isLoading, setIsLoading] = useState(false);

  const getMaxDisplayOrder = cases =>
    cases.reduce((max, caseItem) => (caseItem.displayOrder > max ? caseItem.displayOrder : max), 0);

  useEffect(() => {
    if (projectID && projectID !== 0) fetchCases(projectID);
  }, [projectID]);

  useEffect(() => {
    if (caseList) {
      setCases(caseList);
    }
  }, [caseList]);

  const deleteCaseId = async caseId => {
    setDeletedCaseId(caseId);
  };

  const handleDeleteWarningClose = async id => {
    if (id === CustomModalButtons.CONFIRM) {
      const updatedCases = cases.map(caseItem =>
        caseItem.caseID === deletedCaseId ? { ...caseItem, isDeletedCase: true } : caseItem,
      );
      setDeletedCaseId(null);
      const res = await updateCase(updatedCases);
      if (!loading) {
        if (caseUpdateError) {
          showErrorToast(APP_TOAST_NOTIFICATION.CASE_DELETE_FAILED);
        } else {
          const filteredCase = updatedCases.filter(caseItem => !caseItem.isDeletedCase);
          showSuccessNotification(APP_TOAST_NOTIFICATION.CASE_DELETED);
          dispatch(updateCaseConfig(filteredCase));
          setCases(filteredCase);
        }
      }
    } else {
      setDeletedCaseId(null);
    }
  };

  const onModalClose = ({ target }) => {
    if (target.id === CustomModalButtons.CONFIRM) {
      handleOpenCase(selectedCase);
    } else if (target.id === CustomModalButtons.CLOSE) {
      setIsAddCase(false);
      setEditCase(null);
      setOpenManageCases(false);
    } else if (target.id === CustomModalButtons.CANCEL) {
      setIsAddCase(true);
      const newDisplayOrder = getMaxDisplayOrder(cases) + 1;
      const newCaseName = `Case ${newDisplayOrder}`;
      setEditCase({ caseName: newCaseName, caseNote: "" });
    } else {
      setIsAddCase(false);
      setEditCase(null);
      setOpenManageCases(false);
    }
  };

  const onMenuOptionclose = () => {
    setOpenManageCases(false);
  };

  const onEditCaseClose = () => {
    setEditCase(false);
    setIsAddCase(false);
  };

  const handleManageCases = () => {
    setIsAddCase(false);
    setEditCase(false);
    setOpenManageCases(true);
  };

  const handleOpenCase = openCase => {
    onMenuOptionclose(false);
    dispatch(updateCaseName(openCase.caseName));
    dispatch(updateActiveCase(openCase.caseID));
    dispatch(updateCaseConfig(cases));
    navigatToProject({ projectId: projectID }, openCase);
  };

  const handleEditCase = editCase => {
    setEditCase(editCase);
  };

  return (
    <div className='case-management-container'>
      {
        <CaseManagementDropdown
          cases={cases}
          setCases={setCases}
          handleManageCases={handleManageCases}
          deleteCaseId={deleteCaseId}
          handleOpenCase={handleOpenCase}
          show={show && !openManageCases}
          isLoading={isLoading}
        />
      }

      {deletedCaseId && (
        <ConfirmationPopup
          header={CaseManagementStrings.deleteCaseTitle}
          description={CaseManagementStrings.deleteCaseWarning}
          type={DIALOG_TYPES.ERROR}
          onClose={handleDeleteWarningClose}
          confirmBtn={CommonButtonStrings.delete}
          cancelBtn={CommonButtonStrings.cancel}
        />
      )}
      {openManageCases && !deletedCaseId && !editCase && (
        <CaseManagementPopup
          cases={cases}
          setCases={setCases}
          width='400px'
          onModalClose={onModalClose}
          handleDelete={deleteCaseId}
          onEditCase={handleEditCase}
          setSelectedCase={setSelectedCase}
          selectedCase={selectedCase}
          currentActiveCase={activeCaseId}
          handleOpenCase={handleOpenCase}
        />
      )}
      {editCase && (
        <AddEditCasePopup
          onClose={onEditCaseClose}
          currentCase={editCase}
          cases={cases}
          setCases={setCases}
          isAdd={isAddCase}
        />
      )}
    </div>
  );
};

export default CaseManager;
