import React, { useEffect, useMemo, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import useUFConfig from "@hooks/useUFConfig";

import {
  UFDiagramDuPontCIPBackWash,
  UFDiagramDuPontCIPFiltrate,
  UFDiagramIngeCIPBackwash,
  UFDiagramIngeCIPFiltrate,
  UFDiagramIngeWithoutCIPBackwash,
  UFDiagramIngeWithoutCIPFiltrate,
} from "@components/SVGs";

import { setIsSysDiagramVisible } from "../UFSlice";

import StyledUFSystemDesignDiagram from "./StyledUFSystemDesignDiagram";

const UFSystemDesignDiagram = () => {
  const dispatch = useDispatch();
  const { fetchUFConfig } = useUFConfig();

  const [accordionOpen, setAccordionOpen] = useState(false);

  const handleAccordionOpen = () => setAccordionOpen(prev => !prev);

  useEffect(() => {
    if (accordionOpen) {
      fetchUFConfig();
    }
    dispatch(setIsSysDiagramVisible(accordionOpen));
  }, [accordionOpen]);

  return (
    <>
      <StyledUFSystemDesignDiagram lg={12} md={12} sm={12} className='system-diagram-column'>
        <Accordion>
          <Accordion.Item eventKey='0'>
            <Accordion.Header onClick={handleAccordionOpen} as={Card.Header}>
              UF System Diagram
              {accordionOpen ? <span>Hide</span> : <span>Show</span>}
            </Accordion.Header>
            <Accordion.Body className='image-container'>{accordionOpen && <UFDesignDiagram />}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </StyledUFSystemDesignDiagram>
    </>
  );
};

export const UFDesignDiagram = () => {
  const { data, ufCompany } = useSelector(state => state.UFStore);

  const isTechInge = ufCompany?.toLowerCase() === "inge";
  const isBWOnlyDiagram = useMemo(() => data.uFBWCEBStandbyOptionID === 2, [data.uFBWCEBStandbyOptionID]);

  let DiagramComponent;

  if (isTechInge) {
    DiagramComponent =
      data.cIP > 0
        ? isBWOnlyDiagram
          ? UFDiagramIngeCIPBackwash
          : UFDiagramIngeCIPFiltrate
        : isBWOnlyDiagram
          ? UFDiagramIngeWithoutCIPBackwash
          : UFDiagramIngeWithoutCIPFiltrate;
  } else {
    DiagramComponent = isBWOnlyDiagram ? UFDiagramDuPontCIPBackWash : UFDiagramDuPontCIPFiltrate;
  }

  return <DiagramComponent />;
};

export default UFSystemDesignDiagram;
