import React from "react";
import { Col } from "react-bootstrap";

import ModuleSelection from "./UFConfiguration/ModuleSelection";
import SelectedConfiguration from "./UFConfiguration/SelectedConfiguration";
import SystemConf from "./UFConfiguration/SystemConfiguration";
import UFRecommendedConfiguration from "./UFConfiguration/UFRecommendedConfiguration";
import ConfigurationStyled from "./ConfigurationStyled.jsx";
import UFSystemDesignDiagram from "./UFSystemDesignDiagram";

const Configuration = React.memo(() => (
  <>
    <ConfigurationStyled className='g-0'>
      <UFSystemDesignDiagram />
      <Col lg={12} md={12} sm={12} className='configuration'>
        <div className='system-module-wrapper'>
          {/* System Configuration Card */}
          <SystemConf />
          {/* Module Selection Card */}
          <ModuleSelection />
        </div>
        {/* Selected Configuration Card */}
        <SelectedConfiguration />
        {/* Recommended Configurations */}
        <UFRecommendedConfiguration />
      </Col>
    </ConfigurationStyled>
  </>
));

Configuration.displayName = "UF Configuration";
export default Configuration;
