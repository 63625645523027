import { reportStrings } from "@constants/report.strings";

import { WPUfParametersDataMapping } from "../../WPUfParametersTables.constants";
import { generateStepRowData } from "../../WPUfParametersTablesUtils";

export const WPUfIngeCipParametersTableColumns = [
  {
    field: "col1",
    rowHeader: true,
    headerName: reportStrings.pumpAndValveConditions,
  },
  {
    field: "col2",
    headerName: reportStrings.operation,
  },
  {
    field: "col3",
    headerName: reportStrings.backwashBW + "<sup>b</sup>",
    colSpan: 2,
  },
  {
    field: "col4",
    colSpan: 0,
  },
  {
    field: "col5",
    headerName: reportStrings.cip + "<sup>c</sup>",
    colSpan: 4,
  },
  {
    field: "col6",
    colSpan: 0,
  },
  {
    field: "col7",
    colSpan: 0,
  },
  {
    field: "col8",
    colSpan: 0,
  },
  {
    field: "col9",
    headerName: reportStrings.backwashBW + "<sup>b</sup>",
    colSpan: 3,
  },
  {
    field: "col10",
    colSpan: 0,
  },
  {
    field: "col11",
    colSpan: 0,
  },
  {
    field: "col12",
    headerName: reportStrings.returnToOpertaion,
  },
];

export const WPUfIngeCipParametersTableRows = [
  {
    rowKey: "stepNumber",
    ...generateStepRowData(2, 10),
    col12: 1,
  },
  {
    rowKey: "stepLabel",
    col2: reportStrings.filtrationMode,
    col3: reportStrings.backwash1,
    col4: reportStrings.backwash2,
    col5: reportStrings.draining + "<sup>a</sup>",
    col6: reportStrings.cipRecycle1 + "<sup>c,d</sup>",
    col7: reportStrings.cipRecycle2 + "<sup>c,d</sup>",
    col8: reportStrings.soak + "<sup>e</sup>",
    col9: reportStrings.rinse1,
    col10: reportStrings.rinse2,
    col11: reportStrings.forwardFlush + "<sup>f</sup>",
    col12: reportStrings.filtrationMode,
  },
  {
    rowKey: "feedPump",
    col1: reportStrings.feedPump,
    col2: true,
    col11: true,
    col12: true,
  },
  {
    rowKey: "backwashPump",
    col1: reportStrings.backwashPump,
    col3: true,
    col4: true,
    col9: true,
    col10: true,
  },
  {
    rowKey: "chemDosingPump",
    col1: reportStrings.chemDosingPump,
  },
  {
    rowKey: "cebDosingPump",
    col1: reportStrings.cebDosingPump,
  },
  {
    rowKey: "cipRecyclePump",
    col1: reportStrings.cipRecyclePump,
    col6: true,
    col7: true,
  },
  {
    rowKey: "feedValve",
    col1: reportStrings.feedValve,
    col2: true,
    col11: true,
    col12: true,
  },
  {
    rowKey: "filtrateValve",
    col1: reportStrings.filtrateValve,
    col2: true,
    col12: true,
  },
  {
    rowKey: "concentrateValve",
    col1: reportStrings.concentrateValve,
    col3: true,
    col4: true,
    col9: true,
    col10: true,
    col11: true,
  },
  {
    rowKey: "backwashInletValve",
    col1: reportStrings.backwashInletValve,
    col3: true,
    col4: true,
    col9: true,
    col10: true,
  },
  {
    rowKey: "drainValve",
    col1: reportStrings.drainValve,
    col5: true,
  },
  {
    rowKey: "duration",
    col1: reportStrings.duration,
    col2: WPUfParametersDataMapping.t_filtration_cycle,
    col3: WPUfParametersDataMapping.t_BW1,
    col4: WPUfParametersDataMapping.t_BW2,
    col5: WPUfParametersDataMapping.t_Drain,
    col6: WPUfParametersDataMapping.t_CIP_recycle,
    col7: WPUfParametersDataMapping.t_CIP_recycle,
    col8: WPUfParametersDataMapping.t_CIP_soak,
    col9: WPUfParametersDataMapping.t_BW1,
    col10: WPUfParametersDataMapping.t_BW2,
    col11: WPUfParametersDataMapping.t_FF,
    col12: WPUfParametersDataMapping.t_filtration_cycle,
  },
  {
    rowKey: "flowRate",
    col1: reportStrings.flowRate,
    col2: WPUfParametersDataMapping.Flux_Filter_actual,
    col3: WPUfParametersDataMapping.Flux_BW,
    col4: WPUfParametersDataMapping.Flux_BW,
    col5: WPUfParametersDataMapping.drainFlow,
    col6: WPUfParametersDataMapping.Flow_CIP_recycle,
    col7: WPUfParametersDataMapping.Flow_CIP_recycle,
    col8: WPUfParametersDataMapping.flowZero,
    col9: WPUfParametersDataMapping.Flux_BW,
    col10: WPUfParametersDataMapping.Flux_BW,
    col11: WPUfParametersDataMapping.Flow_FF,
    col12: WPUfParametersDataMapping.Flux_Filter_actual,
  },
];
