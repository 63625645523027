import styled from "styled-components";

const StyledSVG = styled.svg`
  fill: var(--du-color-icon);
  ${({ disabled }) => disabled && `
    fill: var(--du-color-icon-disabled)
  `}
`;

const Container = styled.div`
  &:hover ${StyledSVG} path {
    ${({ disabled }) => !disabled && `
      fill: var(--du-color-hover
);
      stroke: var(--du-color-hover
);
      stroke-width: 1;
    `}
  }
`;

const SVGContainer = props => (
  <Container disabled={props.disabled}>
    <StyledSVG {...props} />
  </Container>
);

export default SVGContainer;