import { useReportUtils } from "@features/report-new/useReportUtils";

import { WPUfUtilityCostRowsData as rowsData } from "./WPUfUtilityCostTable.constants";

export const useWPUfUtilityCostTableData = () => {
  const { ufReportRespData, convertReportDataValue, getUnitLabelValue } = useReportUtils();
  const { Utility_Chem_specific_water_cost } = ufReportRespData || {};

  const convertToUfUtilityCostTableData = () => {
    const data = rowsData.map(row => {
      const { label, apiKey, unitData } = row;

      const value = convertReportDataValue({
        value: Utility_Chem_specific_water_cost?.[apiKey],
        ...unitData,
      });

      return {
        label: label,
        unit: getUnitLabelValue(unitData),
        value,
      };
    });

    return data;
  };

  return { convertToUfUtilityCostTableData };
};
