import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loader from "@common/utils/Loader";

import ProjectErrorPopup from "../../modals/ProjectErrorPopup";
import { setIsFinalDataUpdated, updateCalcEngData } from "../ix/IXDSlice";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "./ViewReport.css";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
const ViewReport = () => {
  const dispatch = useDispatch();
  const [pdfData, setPdfData] = useState("");
  const { reportData, error, loader } = useSelector(state => state.scrollData);
  const [hasError, setHasError] = useState(error);

  const ToolbarButton = ({ children }) => <div style={{ padding: "0px 2px" }}>{children}</div>;

  const renderToolbar = Toolbar => (
    <Toolbar>
      {({
        CurrentPageInput,
        Download,
        GoToNextPage,
        GoToPreviousPage,
        NumberOfPages,
        Print,
        ShowSearchPopover,
        Zoom,
        ZoomIn,
        ZoomOut,
      }) => (
        <div className='custom-toolbar'>
          <div className='toolbar-button'>
            <ToolbarButton>
              <ShowSearchPopover />
            </ToolbarButton>
            <ToolbarButton>
              <GoToPreviousPage />
            </ToolbarButton>
            <ToolbarButton>
              <div className='box-toolbar'>
                <CurrentPageInput />
              </div>
            </ToolbarButton>
            <ToolbarButton>
              / <NumberOfPages />
            </ToolbarButton>
            <ToolbarButton>
              <GoToNextPage />
            </ToolbarButton>
          </div>
          <div className='toolbar-button'>
            <ToolbarButton>
              <ZoomOut />
            </ToolbarButton>
            <ToolbarButton>
              <Zoom />
            </ToolbarButton>
            <ToolbarButton>
              <ZoomIn />
            </ToolbarButton>
          </div>
          <div className='toolbar-button'>
            <ToolbarButton>
              <Download />
            </ToolbarButton>
            <ToolbarButton>
              <Print />
            </ToolbarButton>
          </div>
        </div>
      )}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: defaultTabs => [
      // Remove the attachments tab (`defaultTabs[2]`)
      defaultTabs[0], // Bookmarks tab
      defaultTabs[1], // Thumbnails tab
    ],
  });

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const handleKeyDown = event => {
    if (event.key === "Enter") {
      setHasError(false);
    }
  };
  useEffect(() => {
    if (reportData) {
      convertBase64ToPDF(reportData);
      dispatch(updateCalcEngData("true"));
      dispatch(setIsFinalDataUpdated(false));
    }
  }, [reportData]);
  const convertBase64ToPDF = base64String => {
    // const byteArrayEx= JSON.parse(base64String);
    const byteCharacters = atob(base64String);
    const byteArray = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }
    const blob = new Blob([byteArray], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(blob);
    setPdfData(pdfUrl);
  };
  useEffect(() => {
    if (error) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [error]);
  if (error) {
    return (
      <div className='error-popup'>
        <ProjectErrorPopup
          show={hasError}
          close={() => {
            setHasError(false);
          }}
          message={
            "An error occurred due to an inappropriate value entered. Please ensure that you have provided valid inputs."
          }
        />
      </div>
    );
  }
  if (loader) {
    return (
      <div className='report-loader'>
        <Loader />;
      </div>
    );
  }
  return (
    <>
      <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js'>
        <div className='main-pdf'>
          <Viewer fileUrl={pdfData} plugins={[defaultLayoutPluginInstance]} defaultScale={1.5} />
        </div>
      </Worker>
    </>
  );
};

export default ViewReport;
