import React from "react";

const ErrorFileIcon = () => (
  <>
    <svg width='30' height='36' viewBox='0 0 30 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M28.7643 9.02411L20.1178 0.377679C19.8768 0.136607 19.5513 0 19.2098 0H2.14195C1.43079 0 0.856232 0.574554 0.856232 1.28571V34.7143C0.856232 35.4254 1.43079 36 2.14195 36H27.8562C28.5674 36 29.1419 35.4254 29.1419 34.7143V9.93616C29.1419 9.59464 29.0053 9.26518 28.7643 9.02411ZM26.1768 10.5268H18.6152V2.96518L26.1768 10.5268ZM26.2491 33.1071H3.74909V2.89286H15.883V11.5714C15.883 12.019 16.0608 12.4482 16.3773 12.7647C16.6937 13.0811 17.123 13.2589 17.5705 13.2589H26.2491V33.1071ZM13.3919 27.3214C13.3919 27.7477 13.5613 28.1565 13.8627 28.4579C14.1641 28.7592 14.5728 28.9286 14.9991 28.9286C15.4253 28.9286 15.8341 28.7592 16.1355 28.4579C16.4369 28.1565 16.6062 27.7477 16.6062 27.3214C16.6062 26.8952 16.4369 26.4864 16.1355 26.185C15.8341 25.8836 15.4253 25.7143 14.9991 25.7143C14.5728 25.7143 14.1641 25.8836 13.8627 26.185C13.5613 26.4864 13.3919 26.8952 13.3919 27.3214ZM14.0348 23.1429H15.9634C16.1402 23.1429 16.2848 22.9982 16.2848 22.8214V15.4286C16.2848 15.2518 16.1402 15.1071 15.9634 15.1071H14.0348C13.858 15.1071 13.7134 15.2518 13.7134 15.4286V22.8214C13.7134 22.9982 13.858 23.1429 14.0348 23.1429Z'
        fill='var(--du-color-red)'
      />
    </svg>
  </>
);
export default ErrorFileIcon;
