import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { WPUfIngeCebParametersTableColumns as columns } from "./WPUfIngeCebParametersTable.constants";

export const WPUfIngeCebParametersTable = () => {
  const { uf_ceb_parameters: rows } = useSelector(state => state.report.ufReportTableData);

  return (
    <WPTable
      sectionHeader={reportStrings.cebParameters}
      columns={columns}
      rows={rows}
      remarksList={[reportStrings.ingeCebRemark1, reportStrings.ingeCebRemark2, reportStrings.oValueRemark]}
      footNotesList={[
        reportStrings.cebFootNote1,
        reportStrings.ingeCebFootNote2,
        reportStrings.ingeCebFootNote3,
        reportStrings.ingeCebFootNote4,
      ]}
    />
  );
};
