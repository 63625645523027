import React from "react";

const CalcEngineInputBoxTriangle = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13' fill='none'>
      <path d='M12 1.75L2 12.25H10C11.1046 12.25 12 11.3546 12 10.25V1.75Z' fill='#E2F7F5' stroke='#00A9E0' />
    </svg>
  </>
);

export default CalcEngineInputBoxTriangle;
