import React from "react";
import { Modal } from "react-bootstrap";

import CloseCircleIcon from "../icons/CloseCircleIcon";
import StandardPrimaryButton from "../styles/components/buttons/standard/StandardPrimaryButton";
import CustomHeading from "../styles/components/headings/CustomHeading";
const CustomeMessagePopup = ({ operator, close }) => {
  const getType = type => {
    switch (type) {
      case "error":
        return <CloseCircleIcon />;
      case "success":
        return <SuccessIcon />;
      case "warning":
        return <WarningIcon />;

      default:
        break;
    }
  };
  return (
    <>
      <StyledModal
        show={operator.show}
        onHide={close}
        centered
        backdrop='static'
        keyboard={false}
        isWarningPopUp={true}
        maxWidth='416px'
      >
        <Modal.Body>
          <div className='warning-pop-up'>
            <div>{getType(operator.type)}</div>
            <div>
              <CustomHeading fontFamily='DiodrumSemiBold' fontSize='16px' fontWeight='600' label={operator.message} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <StandardPrimaryButton label='Ok' className='ok-btn' onClick={close} />
        </Modal.Footer>
      </StyledModal>
    </>
  );
};

export default CustomeMessagePopup;

import styled from "styled-components";

import SuccessIcon from "@common/icons/SuccessIcon";

import WarningIcon from "../icons/WarningIcon";
import StyledModal from "../styles/components/modals/CustomModal";
import { colors, modalStyles } from "../styles/Theme";

const ErrorPopupStyled = styled(Modal)`
  background-color: ${colors.blackTransparency045};
  .modal-content {
    max-width: 416px;
    ${modalStyles.normalModalStyle};
    .modal-body {
      display: flex;
      gap: 16px;
    }
    .modal-footer {
      padding: 0px 16px 10px 16px;
      border: none !important;
    }
  }
`;
