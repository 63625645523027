import React, { createContext, useContext, useState } from "react";

const WPToastContext = createContext();

export const useToastNotification = () => useContext(WPToastContext);

export const TOAST_NOTIFICATION_TYPES = {
    SUCCESS: "success",
    ERROR: "error",
    WARNING: "warning",
};
/**
 * WPToastNotificationProvider Component
 *
 * This component provides a context for managing toast notifications in a React application.
 * It uses React's Context API to allow any component within its tree to access and manipulate
 * toast notifications.
 *
 * - `WPToastContext`: The context object created using React's createContext.
 * - `useToastNotification`: A custom hook that allows components to access the context.
 * - `WPToastNotificationProvider`: The provider component that holds the state and functions
 *   for showing and removing toast notifications.
 *
 * The provider component maintains the state of the current toast notification (`toastData`)
 * and provides two functions:
 * - `showToastNotification(data)`: Sets the toast data to display a new toast notification.
 * - `removeToastNotification()`: Clears the toast data to remove the current toast notification.
 *
 * The `WPToastNotificationProvider` wraps its children with the context provider, making the
 * toast notification state and functions available to any nested components.
 */
const WPToastNotificationProvider = ({ children }) => {
    const [toastData, setToastData] = useState(null);

    /**
     * Show a toast notification.
     * @param {Object} data - The data for the toast notification.
     * @param {string} data.message - The message to display in the toast.
     * @param {string} data.type - The type of the toast (e.g., 'success', 'error').
     * @param {string} data.duration - The type of the toast (e.g., 'success', 'error').
     */
    const showToastNotification = data => data && setToastData(data);
    /**
    * Show a success toast notification with the given message.
    * @param {string} message - The message to display in the toast notification.
    */
    const showSuccessNotification = message => setToastData({ message, type: TOAST_NOTIFICATION_TYPES.SUCCESS });

    /**
     * Show a failure (error) toast notification with the given message.
     * @param {string} message - The message to display in the toast notification.
     */
    const showErrorNotification = message => setToastData({ message, type: TOAST_NOTIFICATION_TYPES.ERROR });

    /**
     * Show a warning toast notification with the given message.
     * @param {string} message - The message to display in the toast notification.
     */
    const showWarningNotification = message => setToastData({ message, type: TOAST_NOTIFICATION_TYPES.WARNING });

    /**
     * Remove the current toast notification.
     */
    const removeToastNotification = () => setToastData(null);

    return (
        <WPToastContext.Provider
            value={{
                toastData,
                setToastData,
                showToastNotification,
                removeToastNotification,
                showSuccessNotification,
                showErrorNotification,
                showWarningNotification
            }}
        >
            {children}
        </WPToastContext.Provider>
    );
};

export default WPToastNotificationProvider;
