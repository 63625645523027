import { CHEMICAL_USAGE_STRINGS } from "../chemicalLibrary.strings";

const { formatDate } = require("@utils/appUtils");
const { default: ChemicalLibraryChemicalUsageActions } = require("./ChemicalLibraryChemicalUsageActions");

const valueFormatter = params => formatDate(params.value);

export const CHEMICAL_USAGE_TABLE_COLUMNS = [
  {
    headerName: CHEMICAL_USAGE_STRINGS.projectName,
    field: "projectName",
    width: 200,
    suppressSizeToFit: true,
    tooltip: true,
  },
  {
    headerName: CHEMICAL_USAGE_STRINGS.customer,
    field: "customer",
    width: 120,
    tooltip: true,
  },
  {
    headerName: CHEMICAL_USAGE_STRINGS.lastModified,
    field: "createdDate",
    width: 210,
    valueFormatter,
  },
  {
    headerName: CHEMICAL_USAGE_STRINGS.createdDate,
    field: "createdDate",
    width: 210,
    valueFormatter,
  },
  {
    headerName: CHEMICAL_USAGE_STRINGS.actions,
    field: "",
    maxWidth: 115,
    cellRenderer: ChemicalLibraryChemicalUsageActions,
  },
];

export const CHEMICAL_USAGE_FOOTER_BTNS = [
  {
    id: "cancel",
    variant: "outlined",
    label: "Cancel",
  },
  {
    id: "deleteProjects",
    variant: "contained",
    label: "Delete Project(s)",
  },
];
