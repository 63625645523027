import { useDispatch, useSelector } from "react-redux";

import { SELECTED_UNIT, UNIT_TYPES, UNITS } from "@constants/units.constant";

import { convertUptoDigits } from "@utils/appUtils";
import DupontLogger from "@utils/DupontLogger";

import { useCreateDataMutation, useLazyGetAllDataQuery } from "@services/apiConfig";

import {
  CONVERSION_FACTORS,
  FINAL_PARAMETER_LIST,
  IX_REGEN_DOSE_ID,
  OVERRUN_COMPUTATION,
  PROPERTIES_TO_CONVERT_FPA_DATA,
  PROPERTIES_TO_CONVERT_SAVING_DATA,
  RESIN_ID,
  REVERSE_CONVERSION_FACTORS,
} from "@features/feedwater/ix/constants/IXDConstants";
import { convertAndFormatProperties, formatToFourDecimals } from "@features/feedwater/ix/IXConfiguration/IXhelper";
import {
  updateFlagIsSaveIXDReportJson,
  updatelistFinalParamAdj,
  updateShowFinalParameterTab,
  updateVesselCylindricalHeight,
  updatevesselwallThickness,
} from "@features/feedwater/ix/IXDSlice";
import { getDefaultOnVessel1Selected } from "@features/feedwater/ix/IXDUtilityFunction";

import useUnitConversion from "./useUnitConversion";

const useFPAValidation = () => {
  const dispatch = useDispatch();
  const Logger = DupontLogger("useFPAValidation");
  const { convertUnit, convertUnitNumber } = useUnitConversion();
  const ixStoreObj = useSelector(state => state.IXStore);
  const units = useSelector(state => state.projectInfo?.projectConfig?.unitConfig?.selectedUnits);
  const { listFinalParamAdj, saveVesselCylindricalHeight, data: ixStore } = ixStoreObj || {};
  const { cationResin, anionResin, selectedResinList } = ixStore;
  const [resinItem1, resinItem2] = selectedResinList || [];

  const { projectID, caseId } = useSelector(state => state.projectInfo.data);
  const { IXD } = useSelector(state => state.ReportIXD.tabAvailable);
  const { UserId } = useSelector(state => state.userInfo.data);
  const [getIXDetails] = useLazyGetAllDataQuery();
  const [IXData_PostData] = useCreateDataMutation();
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const resinVal = useSelector(state => state.IXStore.data?.listRegenConds);
  const ixStoreAdvance = useSelector(state => state.IXStore?.data?.listAdvRegen);
  const ixRegenreteDose = useSelector(state => state.IXStore?.data?.listProductQualityandregeneration);
  const { unitConversionByName, convertToMetric } = useUnitConversion();

  const getEquipmentDetails = () => ({
    pdExtPiping: unitConversionByName(ixStore.pdExtPiping, UNITS.bar, unit.selectedUnits[3]),
    pdIntDistributor: unitConversionByName(ixStore.pdIntDistributor, UNITS.bar, unit.selectedUnits[3]),
    effluentPressure: unitConversionByName(ixStore.effluentPressure, UNITS.bar, unit.selectedUnits[3]),
    tankTemperature: unitConversionByName(ixStore.tankTemperature, UNITS.celsius, unit.selectedUnits[2]),
    backwashTowerDiameter: unitConversionByName(
      ixStore.backwashTowerDiameter || getDefaultOnVessel1Selected(ixStore.ixTreatment, ixStore?.vessel1),
      UNITS.millimeter,
      unit.selectedUnits[8],
    ),
    sacRegenVesselDia: unitConversionByName(
      ixStore.sacRegenVesselDia || getDefaultOnVessel1Selected(ixStore.ixTreatment, ixStore?.vessel1),
      UNITS.millimeter,
      unit.selectedUnits[8],
    ),
    sbaRegenVesselDia: unitConversionByName(
      ixStore.sbaRegenVesselDia || getDefaultOnVessel1Selected(ixStore.ixTreatment, ixStore?.vessel1),
      UNITS.millimeter,
      unit.selectedUnits[8],
    ),
  });

  const getConvertedData = (calcChemDose, calcOverrun) => {
    /*----Unit conversion for regenenConditionPage start-----*/
    const [cationRegenCond, anionRegenCond] = resinVal;
    let cationTemp = resinVal[0]?.temperature;
    let anionTemp = resinVal[1]?.temperature;
    if (cationRegenCond) {
      cationTemp = unitConversionByName(resinVal[0]?.temperature, UNITS.celsius, unit.selectedUnits[2]);
    }
    if (anionRegenCond) {
      anionTemp = unitConversionByName(resinVal[1]?.temperature, UNITS.celsius, unit.selectedUnits[2]);
    }

    // Below is for conversion and saving data for Product quality and regeneration
    const [cationAverageVal, anionAverageVal] = ixRegenreteDose;
    let cationRegenreteDoseVel = cationAverageVal?.regenerantDoseVal4;
    let anionRegenreteDoseVel = anionAverageVal?.regenerantDoseVal4;
    let cationAverageConduc = cationAverageVal?.averageConductivityVal;
    let anionAverageConduc = anionAverageVal?.averageConductivityVal;
    let cationendpointConduc = cationAverageVal?.endpointConductivityVal;
    let anionendpointConduc = anionAverageVal?.endpointConductivityVal;
    const isOverRunAutomatic = cationAverageVal.overAllComputation === OVERRUN_COMPUTATION.AUTOMATIC;
    if (cationAverageVal) {
      cationRegenreteDoseVel = isOverRunAutomatic
        ? calcChemDose[0]
        : convertToMetric(cationRegenreteDoseVel, UNIT_TYPES.REGENERANT_DOSE);
      cationAverageConduc = unitConversionByName(cationAverageConduc, UNITS.uscm, unit.selectedUnits[17]);
      cationendpointConduc = unitConversionByName(cationendpointConduc, UNITS.uscm, unit.selectedUnits[17]);
    }
    if (anionAverageVal) {
      anionRegenreteDoseVel = isOverRunAutomatic
        ? calcChemDose[1]
        : convertToMetric(anionRegenreteDoseVel, UNIT_TYPES.REGENERANT_DOSE);
      anionAverageConduc = unitConversionByName(anionAverageConduc, UNITS.uscm, unit.selectedUnits[17]);
      anionendpointConduc = unitConversionByName(anionendpointConduc, UNITS.uscm, unit.selectedUnits[17]);
    }
    const [cationRegenVelocity, anionRegenVelocity] = ixStoreAdvance;
    let cationregenVel = ixStoreAdvance[0]?.regenerationVelocity;
    let anionregeneVel = ixStoreAdvance[1]?.regenerationVelocity;
    let cationDisVol = ixStoreAdvance[0]?.displacementVolume;
    let anionDisVol = ixStoreAdvance[1]?.displacementVolume;
    let cationFasVol = ixStoreAdvance[0]?.fatRinseVolume;
    let anionFasVol = ixStoreAdvance[1]?.fatRinseVolume;
    if (cationRegenVelocity) {
      cationregenVel = unitConversionByName(
        ixStoreAdvance[0]?.regenerationVelocity,
        UNITS.BV_PER_HR,
        unit.selectedUnits[10],
      );
      cationDisVol = unitConversionByName(ixStoreAdvance[0]?.displacementVolume, UNITS.BV, unit.selectedUnits[13]);
      cationFasVol = unitConversionByName(ixStoreAdvance[0]?.fatRinseVolume, UNITS.BV, unit.selectedUnits[13]);
    }
    if (anionRegenVelocity) {
      anionregeneVel = unitConversionByName(
        ixStoreAdvance[1]?.regenerationVelocity,
        UNITS.BV_PER_HR,
        unit.selectedUnits[10],
      );
      anionDisVol = unitConversionByName(ixStoreAdvance[1]?.displacementVolume, UNITS.BV, unit.selectedUnits[13]);
      anionFasVol = unitConversionByName(ixStoreAdvance[1]?.fatRinseVolume, UNITS.BV, unit.selectedUnits[13]);
    }

    return {
      space_velocity_txt: unitConversionByName(ixStore.space_velocity_txt, UNITS.BV_PER_HR, unit.selectedUnits[10]),
      ...getEquipmentDetails(),
      listRegenConds: [
        { ...ixStore.listRegenConds[0], temperature: cationTemp },
        { ...ixStore.listRegenConds[1], temperature: anionTemp },
      ],
      listAdvRegen: [
        {
          ...ixStore.listAdvRegen[0],
          regenerationVelocity: cationregenVel,
          displacementVolume: cationDisVol,
          fatRinseVolume: cationFasVol,
        },
        {
          ...ixStore.listAdvRegen[1],
          regenerationVelocity: anionregeneVel,
          displacementVolume: anionDisVol,
          fatRinseVolume: anionFasVol,
        },
      ],
      listProductQualityandregeneration: [
        {
          ...ixStore.listProductQualityandregeneration[0],
          regenerantDoseVal4: convertUptoDigits(cationRegenreteDoseVel),
          averageConductivityVal: cationAverageConduc,
          endpointConductivityVal: cationendpointConduc,
          regenerantDoseVal2:
            cationAverageVal.overAllComputation === OVERRUN_COMPUTATION.AUTOMATIC
              ? convertUptoDigits(calcOverrun[0])
              : cationAverageVal.regenerantDoseVal2,
          regenerantDoseLbl2ID: IX_REGEN_DOSE_ID.wacOverRun,
        },
        {
          ...ixStore.listProductQualityandregeneration[1],
          regenerantDoseVal4: convertUptoDigits(anionRegenreteDoseVel),
          averageConductivityVal: anionAverageConduc,
          endpointConductivityVal: anionendpointConduc,
          regenerantDoseVal2:
            cationAverageVal.overAllComputation === OVERRUN_COMPUTATION.AUTOMATIC
              ? convertUptoDigits(calcOverrun[1])
              : anionAverageVal.regenerantDoseVal2,
          regenerantDoseLbl2ID: IX_REGEN_DOSE_ID.wbaOverRun,
        },
      ],
    };
  };

  // The function fetchIXFPADetails is used to call the Final Parameter Adjustment API and fetch data
  const fetchIXFPADetails = async (calcChemDose, calcOverrun) => {
    try {
      const queryParams = {
        userID: UserId,
        projectID,
        caseID: caseId,
      };
      const params = new URLSearchParams(queryParams).toString();
      const response = await getIXDetails(`${"ix/api/v1/FinalParameter"}?${params}`);
      const { data } = response;
      if (response.isSuccess) {
        if (data?.length > 0) {
          const finalList = data.reduce((acc, item, index) => {
            if (Object.values(item).some(value => value !== null)) {
              const resinNameKey = `resinName${index + 1}`;
              const resinName = ixStoreObj[resinNameKey];
              acc.push({
                ...item,
                resinName: resinName,
                resinType: ixStoreObj.resinData[resinName],
              });
            }
            return acc;
          }, []);
          const vesselWallThickness = finalList.map(item => item.vesselWallThickness).filter(thickness => thickness);
          let counter = 0;
          const layout = [];
          const vesselArraySave = [];
          const addVesselLayout = (layoutType, count) => {
            for (let vesselIndex = 0; vesselIndex < count; vesselIndex++) {
              vesselArraySave.push(vesselWallThickness[counter]);
              counter++;
              layout.push(layoutType);
            }
          };
          const addDoubleVesselLayout = layoutType => {
            vesselArraySave.push(vesselWallThickness[counter]);
            layout.push(layoutType);
            vesselArraySave.push(vesselWallThickness[counter]);
            layout.push(layoutType);
            counter++;
          };
          if ([RESIN_ID.WAC_RESIN_ID, RESIN_ID.SAC_RESIN_ID].includes(cationResin)) addVesselLayout("C", 1);
          if (cationResin == RESIN_ID.WAC_SAC_TWO_VESSEL_RESIN_ID) addVesselLayout("C", 2);
          if ([RESIN_ID.WAC_SAC_SINGLE_CHAMBER_RESIN_ID, RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID].includes(cationResin))
            addDoubleVesselLayout("C");
          if ([RESIN_ID.WBA_RESIN_ID, RESIN_ID.SBA_RESIN_ID].includes(anionResin)) addVesselLayout("A", 1);
          if (anionResin == RESIN_ID.WBA_SBA_TWO_VESSEL_RESIN_ID) addVesselLayout("A", 2);
          if ([RESIN_ID.WBA_SBA_SINGLE_CHAMBER_RESIN_ID, RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID].includes(anionResin))
            addDoubleVesselLayout("A");
          if ([RESIN_ID.SBA_SAC_SINGLE_CHAMBER_RESIN_ID, RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID].includes(anionResin))
            addDoubleVesselLayout("C");
          finalList[0] = { ...finalList[0], ixfpaRadioButtonID: ixStore.listFinalParamAdj[0]?.ixfpaRadioButtonID || 1 };
          const newList = finalList.map((item, index) => {
            const updatedItem = convertAndFormatProperties(item, PROPERTIES_TO_CONVERT_FPA_DATA, convertUnit);
            const vesselWallThickness = convertUnit(vesselArraySave[index], SELECTED_UNIT.LENGTH, UNITS.METER, 0);
            dispatch(
              updateVesselCylindricalHeight({
                isInput: false,
                value: updatedItem.vesselCylindricalHeight,
                index: index,
                freeBoard: updatedItem.freeBoard,
              }),
            );
            return {
              ...updatedItem,
              minVesselCylindricalHeight: updatedItem.vesselCylindricalHeight,
              vesselWallThickness: formatToFourDecimals(vesselWallThickness),
              minFreeBoard: updatedItem.freeBoard,
              isCation: layout[index] == "C",
            };
          });
          dispatch(
            updatevesselwallThickness({
              vesselWallThickness0: newList[0].vesselWallThickness,
              vesselWallThickness2: newList[1].vesselWallThickness,
            }),
          );
          dispatch(updatelistFinalParamAdj(newList));
          // The below code is used for saving the FPA data
          if (IXD) {
            dispatch(updateFlagIsSaveIXDReportJson(true));
            const finalParameterList = newList.map(item =>
              convertAndFormatProperties(item, PROPERTIES_TO_CONVERT_SAVING_DATA, convertUnitNumber),
            );
            const MethodName = { Method: "ix/api/v1/IXData" };
            const IXData_Method_Body = {
              ...MethodName,
              ...ixStore,
              listFinalParamAdj: finalParameterList,
              selectedResinList: [
                { ...resinItem1, inert: resinItem1?.inert || 0 },
                { ...resinItem2, inert: resinItem2?.inert || 0 },
              ],
              ...getConvertedData(calcChemDose, calcOverrun),
            };
            await IXData_PostData(IXData_Method_Body);
          }
        }
      }
    } catch (error) {
      Logger.log("fetchIXFPADetails", error);
    }
  };

  const validateFPA = () => {
    const invalidVessels =
      listFinalParamAdj?.reduce((acc, item, index) => {
        const key = `vesselCylindricalHeight${index}`;
        if (key in saveVesselCylindricalHeight) {
          const vesselHeight = saveVesselCylindricalHeight[`vesselCylindricalHeight${index}`];
          const isValid = vesselHeight.isUserInput ? item.vesselCylindricalHeight > vesselHeight.value : true;
          if (!isValid && !acc.includes(vesselHeight.vesselName)) {
            acc.push(vesselHeight.vesselName);
          }
        }
        return acc;
      }, []) || [];

    let errorMsg = false;
    if (invalidVessels.length) {
      errorMsg = `Vessel height is inconsistent with Resin Volume and Vessel Diameter for ${invalidVessels.join(", ")}`;
    }
    return errorMsg;
  };
  const resetVesselCylindricalHeightData = () => {
    const newData = listFinalParamAdj.map((item, index) => {
      const key = `vesselCylindricalHeight${index}`;
      if (key in saveVesselCylindricalHeight) {
        const tempVCH = saveVesselCylindricalHeight[`vesselCylindricalHeight${index}`];
        if (tempVCH.isUserInput) {
          dispatch(updateVesselCylindricalHeight({ isInput: false, index: index }));
          return {
            ...item,
            vesselCylindricalHeight: formatToFourDecimals(tempVCH.value),
            freeBoard: formatToFourDecimals(tempVCH.freeBoard),
          };
        } else {
          return item;
        }
      }
    });
    dispatch(updatelistFinalParamAdj(newData));
  };

  const updateParameterSettings = () => {
    dispatch(updateShowFinalParameterTab(false));
    dispatch(updatelistFinalParamAdj(Array(4).fill(FINAL_PARAMETER_LIST)));
  };

  const finalParameterDataCalcEngine = (controller, listFinalParamAdjListCalcEngine) => {
    const MAXM_VESSEL_NUMBER = 4;
    let counter = 0;
    const parameterMapping = {
      Custom_Inert_Height: "inertBedHeight",
      Custom_Height: "vesselCylindricalHeight",
      Custom_Diameter: "vesselDiameter",
      Custom_Wall: "vesselWallThickness",
    };
    const selectedParameter = parameterMapping[controller];
    const vesselMeasurmentsData = {};
    const compartMentData = {};
    for (let vesselNumber = 1; vesselNumber <= MAXM_VESSEL_NUMBER; vesselNumber++) {
      vesselMeasurmentsData[`Vessel_${vesselNumber}_${controller}`] = 0;
      compartMentData[`Vessel_${vesselNumber}_Comp_1_${controller}`] = 0;
      compartMentData[`Vessel_${vesselNumber}_Comp_2_${controller}`] = 0;
    }
    const resinMeasurmentsData = [];

    // Function to handle data processing for both cation and anion resins

    const processResinData = (resinTypes, isCation) => {
      const selectedResin = isCation ? cationResin : anionResin;

      if (listFinalParamAdjListCalcEngine.length >= counter && resinTypes.includes(selectedResin)) {
        let newData;
        if (
          [RESIN_ID.SAC_RESIN_ID, RESIN_ID.WAC_RESIN_ID, RESIN_ID.WBA_RESIN_ID, RESIN_ID.SBA_RESIN_ID].includes(
            selectedResin,
          )
        ) {
          newData = listFinalParamAdjListCalcEngine[counter][selectedParameter];
          resinMeasurmentsData.push({ value: newData });
          counter++;
        }

        if ([RESIN_ID.WAC_SAC_TWO_VESSEL_RESIN_ID, RESIN_ID.WBA_SBA_TWO_VESSEL_RESIN_ID].includes(selectedResin)) {
          newData = listFinalParamAdjListCalcEngine[counter][selectedParameter];
          resinMeasurmentsData.push({ value: newData });
          counter++;
          newData = listFinalParamAdjListCalcEngine[counter][selectedParameter];
          resinMeasurmentsData.push({ value: newData });
          counter++;
        }

        if (
          [
            RESIN_ID.WAC_SAC_SINGLE_CHAMBER_RESIN_ID,
            RESIN_ID.SBA_SAC_SINGLE_CHAMBER_RESIN_ID,
            RESIN_ID.WBA_SBA_SINGLE_CHAMBER_RESIN_ID,
          ].includes(selectedResin)
        ) {
          newData = listFinalParamAdjListCalcEngine[counter][selectedParameter];
          resinMeasurmentsData.push({ value: newData });
          counter += 2;
        }

        if (
          [
            RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID,
            RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID,
            RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID,
          ].includes(selectedResin)
        ) {
          if (controller === "Custom_Diameter" || controller === "Custom_Wall") {
            newData = listFinalParamAdjListCalcEngine[counter][selectedParameter];
          } else {
            newData =
              Number(listFinalParamAdjListCalcEngine[counter][selectedParameter]) +
              Number(listFinalParamAdjListCalcEngine[counter + 1][selectedParameter]);
          }

          const extraVessel = [
            listFinalParamAdjListCalcEngine[counter][selectedParameter],
            listFinalParamAdjListCalcEngine[counter + 1][selectedParameter],
          ];
          counter += 2;

          resinMeasurmentsData.push({ value: newData, extraVessel });
        }
      }
    };
    // Process cation resins
    if (listFinalParamAdjListCalcEngine && listFinalParamAdjListCalcEngine.length > 0 && IXD) {
      processResinData(
        [
          RESIN_ID.WAC_RESIN_ID,
          RESIN_ID.SAC_RESIN_ID,
          RESIN_ID.WAC_SAC_TWO_VESSEL_RESIN_ID,
          RESIN_ID.WAC_SAC_SINGLE_CHAMBER_RESIN_ID,
          RESIN_ID.SBA_SAC_SINGLE_CHAMBER_RESIN_ID,
          RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID,
          RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID,
        ],
        true,
      );
      // Process anion resins
      processResinData(
        [
          RESIN_ID.WBA_RESIN_ID,
          RESIN_ID.SBA_RESIN_ID,
          RESIN_ID.WBA_SBA_TWO_VESSEL_RESIN_ID,
          RESIN_ID.WBA_SBA_SINGLE_CHAMBER_RESIN_ID,
          RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID,
        ],
        false,
      );
    }

    resinMeasurmentsData?.forEach((item, index) => {
      // Assign the main value to the vesselMeasurmentsData object
      vesselMeasurmentsData[`Vessel_${index + 1}_${controller}`] = item.value;
      // If extraVessel exists, assign each of its values to the compartMentData object
      if (item.extraVessel) {
        item.extraVessel.forEach((extra, i) => {
          compartMentData[`Vessel_${index + 1}_Comp_${i + 1}_${controller}`] = extra;
        });
      }
    });
    return { compartMentData, vesselMeasurmentsData };
  };

  // The below function is used as a wrapper for conversion calculations in the FPA screen and formatting up to 4 decimals without rounding off
  const convertAndFormatLength = (value, unitConversionFlag = false) => {
    const unit = units[SELECTED_UNIT.LENGTH];
    const factor = unitConversionFlag ? CONVERSION_FACTORS[unit] : REVERSE_CONVERSION_FACTORS[unit];
    const convertedValue = value * (factor || 1);
    return formatToFourDecimals(convertedValue);
  };

  return {
    validateFPA,
    resetVesselCylindricalHeightData,
    finalParameterDataCalcEngine,
    updateParameterSettings,
    fetchIXFPADetails,
    convertAndFormatLength,
  };
};

export default useFPAValidation;
