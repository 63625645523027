import React from "react";

const DragIcon = () => (
  <svg width='11' height='23' viewBox='0 0 11 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='Group 141'>
      <ellipse id='Ellipse 14' cx='2.16697' cy='1.60696' rx='1.66648' ry='1.60696' fill='black' fillOpacity='0.85' />
      <ellipse id='Ellipse 15' cx='8.83347' cy='1.60696' rx='1.66648' ry='1.60696' fill='black' fillOpacity='0.85' />
      <ellipse id='Ellipse 16' cx='2.16697' cy='11.2495' rx='1.66648' ry='1.60696' fill='black' fillOpacity='0.85' />
      <ellipse id='Ellipse 18' cx='2.16697' cy='20.8892' rx='1.66648' ry='1.60696' fill='black' fillOpacity='0.85' />
      <ellipse id='Ellipse 17' cx='8.83347' cy='11.2495' rx='1.66648' ry='1.60696' fill='black' fillOpacity='0.85' />
      <ellipse id='Ellipse 19' cx='8.83347' cy='20.8892' rx='1.66648' ry='1.60696' fill='black' fillOpacity='0.85' />
    </g>
  </svg>
);

export default DragIcon;
