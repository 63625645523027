import { reportStrings } from "@constants/report.strings";
import { UNIT_TYPES } from "@constants/units.constant";

// all col fields are custom fields, not present in the API response
export const WPUfSystemSizeModuleDetailsTableColumns = [
  {
    field: "unitLabel",
    rowHeader: true,
    headerName: reportStrings.units,
    width: "250px",
  },
  {
    field: "unitValue",
    width: "75px",
  },
  {
    field: "moduleLabel",
    headerName: reportStrings.moduleDetails,
    rowHeader: true,
  },
  {
    field: "moduleUnit",
    rowHeader: true,
    colSpan: 2,
  },
  {
    field: "moduleValue",
    colSpan: 0,
  },
];

// need to create custom rows due to custom UX design for the table
export const WPUfSystemSizeModuleDetailsTableRowsData = [
  {
    unitLabel: reportStrings.onlineUnits,
    unitValueKey: "online_units",
    moduleLabel: reportStrings.moduleType,
    moduleValueKey: "module_name",
  },
  {
    unitLabel: reportStrings.standbyUnits,
    unitValueKey: "standby_units",
    moduleLabel: reportStrings.area,
    moduleValueKey: "area",
    moduleUnitType: UNIT_TYPES.AREA,
  },
  {
    unitLabel: reportStrings.redundantUnits,
    unitValueKey: "redundant_units",
    moduleLabel: reportStrings.length,
    moduleValueKey: "length",
    moduleUnitType: UNIT_TYPES.LENGTH,
  },
  {
    unitLabel: reportStrings.totalUnits,
    unitValueKey: "total_units",
    moduleLabel: reportStrings.diameter,
    moduleValueKey: "diam",
    moduleUnitType: UNIT_TYPES.LENGTH,
  },
  {
    unitLabel: reportStrings.maxOfflineUnits,
    unitValueKey: "max_offline_units",
    moduleLabel: reportStrings.weightEmpty,
    moduleValueKey: "weight_empty",
    moduleUnitType: UNIT_TYPES.WEIGHT,
  },
  {
    unitLabel: reportStrings.modulePerUnit,
    unitValueKey: "modules_per_unit",
    moduleLabel: reportStrings.weightFilled,
    moduleValueKey: "weight_water_filled",
    moduleUnitType: UNIT_TYPES.WEIGHT,
  },
  {
    unitLabel: reportStrings.totalModules,
    unitValueKey: "total_modules",
    moduleLabel: reportStrings.waterVolume,
    moduleValueKey: "water_volume",
    moduleUnitType: UNIT_TYPES.VOLUME_SOLUTION,
  },
];

export const WPUfModuleDesignTableColumns = [
  {
    field: "unitLabel",
    rowHeader: true,
    // Headner name is dynamic and will be set in the component, this is an exception for this particular table
    headerName: "",
    width: "250px",
  },
  {
    field: "unitValue",
    width: "75px",
  },
];

export const WPUfModuleDesignTableRowsData = ["racks", "modules", "total"];
