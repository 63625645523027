import API_URLS from "@constants/api.urls";

import { getRequest } from "../baseApi";

//All apis that are related to project will be here

export const getProjectinfo = parmas => getRequest(API_URLS.projectInfo, parmas);

export const getUnitOfMeasure = parmas => getRequest(API_URLS.unitOfMeasure, parmas);

export const getPumps = parmas => getRequest(API_URLS.pumps, parmas);

export const getCases = parmas => getRequest(API_URLS.cases, parmas);

export const getDefaultCurrency = parmas => getRequest(API_URLS.defaultCurrency, parmas);
