import { TECHNOLOGIES } from "@constants/global.constants";

import { RESIN_ID } from "../constants/IXDConstants";

const PRODUCT_QUALITY_DEFAULT = [
  {
    technologyName: TECHNOLOGIES.IXD,
    method: RESIN_ID.SBA_SAC_SINGLE_CHAMBER_RESIN_ID,
    Na: { avg: 0.0929, endPt: 0.186 },
    cond: { avg: 1, endPt: 2 },
    SiO2: { avg: 0.02, endPt: 0.04 },
    organicRemoval: 50,
  },
  {
    technologyName: TECHNOLOGIES.IXMB,
    method: RESIN_ID.SBA_SAC_SINGLE_CHAMBER_RESIN_ID,
    Na: { avg: 0.0025, endPt: 0.013 },
    cond: { avg: 0.06, endPt: 0.15 },
    SiO2: { avg: 0.01, endPt: 0.02 },
    organicRemoval: 10,
  },
  {
    technologyName: TECHNOLOGIES.IXD,
    method: RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID,
    Na: { avg: 0.0929, endPt: 0.186 },
    cond: { avg: 1, endPt: 2 },
    SiO2: { avg: 0.02, endPt: 0.04 },
    organicRemoval: 70,
  },
  {
    technologyName: TECHNOLOGIES.IXMB,
    method: RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID,
    Na: { avg: 0.0079, endPt: 0.0179 },
    cond: { avg: 0.1, endPt: 0.2 },
    SiO2: { avg: 0.01, endPt: 0.02 },
    organicRemoval: 25,
  },
];

export const getProductQualityDefaultValues = (technologyName, ionsResin) =>
  PRODUCT_QUALITY_DEFAULT.find(item => item.technologyName === technologyName && item.method === ionsResin);

export const IXD_DEFAULT = {
  hardnessCocurrentAvg: 1,
  cocurrentAvg: 100,
  defaultAvg: 20,
  hardnessCocurrentEndpt: 2,
  cocurrentEndpt: 200,
  defaultEndpt: 40,
  cocurrentSiO2Avg: 100,
  defaultSiO2Avg: 20,
  cocurrentSiO2Endpt: 200,
  defaultSiO2Endpt: 40,
  defaultOrganicRemoval: 70,
};
