import { useState } from "react";
import { getProjectNameStatus } from "src/api/importApi/importApi";

import { STATUS_CODE } from "@constants/global.constants";

export const useCheckProjectExistence = () => {
  const [isCheckingProjectName, setCheckingProjectName] = useState(false);
  const [isEntityFound, setIsEntityfound] = useState(false);
  const [entityData, setEntityData] = useState(null);
  const checkProjectExistence = async (name, entityType = "Project") => {
    if (name === "") {
      return false;
    }
    try {
      setCheckingProjectName(true);
      const params = new URLSearchParams();
      params.append("entityType", entityType);
      params.append("entityValue", name);

      const response = await getProjectNameStatus(params);
      const { status, data } = response;
      const isFound = status === STATUS_CODE.SUCCESS;
      setIsEntityfound(isFound ? data.isFound : false);
      setEntityData(isFound ? data.data : null);
      return response?.data?.isFound;
    } catch (error) {
      return false;
    } finally {
      setCheckingProjectName(false);
    }
  };

  return [checkProjectExistence, isCheckingProjectName, entityData, isEntityFound];
};
