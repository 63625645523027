import { reportStrings } from "@constants/report.strings";
import { UNIT_TYPES } from "@constants/units.constant";

export const WPUfPressureRatingsTableColumns = [
  {
    field: "label", // custom field not present in the API response
    rowHeader: true,
    headerName: reportStrings.process,
    width: "175px",
  },
  {
    field: "T",
    headerName: reportStrings.t,
    minWidth: "60px",
  },
  {
    field: "TMPa",
    headerName: (
      <>
        {reportStrings.tmp}
        <sup>a</sup>
      </>
    ),
  },
  {
    field: "FoulingMaxDP",
    headerName: reportStrings.foulingMaxDp,
  },
  {
    field: "PipingDP",
    headerName: reportStrings.pipingDp,
  },
  {
    field: "FiltratePressure",
    headerName: reportStrings.filtratePressure,
  },
  {
    field: "FeedPressureb",
    headerName: (
      <>
        {reportStrings.feedPressure}
        <sup>b</sup>
      </>
    ),
  },
  {
    field: "PressureRating",
    headerName: reportStrings.pressureRating,
  },
  {
    field: "OKc",
    headerName: (
      <>
        {reportStrings.ok}?<sup>c</sup>
      </>
    ),
  },
];

export const WPUfPressureRatingsTableRowsData = {
  // custom row not present in the API response
  filtration: {
    label: reportStrings.filtration,
  },
  filtration_Min_Temp: {
    label: reportStrings.minTemp,
    indentLevel: 1,
  },
  filtration_Design_Temp: {
    label: reportStrings.designTemp,
    indentLevel: 1,
  },
  filtration_Max_Temp: {
    label: reportStrings.maxTemp,
    indentLevel: 1,
  },
  BW: {
    label: reportStrings.bw,
  },
  CEB: {
    label: reportStrings.ceb,
    specialFeature: "CEB",
  },
  Mini_CIP: {
    label: reportStrings.miniCip,
    specialFeature: "mCIP",
  },
  CIP: {
    label: reportStrings.cip,
  },
};

export const WPUfPressureRatingsTableUnitMapping = {
  T: UNIT_TYPES.TEMPERATURE,
  TMPa: UNIT_TYPES.PRESSURE,
  FoulingMaxDP: UNIT_TYPES.PRESSURE,
  PipingDP: UNIT_TYPES.PRESSURE,
  FiltratePressure: UNIT_TYPES.PRESSURE,
  FeedPressureb: UNIT_TYPES.PRESSURE,
  PressureRating: UNIT_TYPES.PRESSURE,
  OKc: "",
};
