import { useEffect, useState } from "react";

import { UNIT_TYPES, UNITS } from "@constants/units.constant";

import { convertUptoDigits } from "@utils/appUtils";

import WPHeading from "@components/WPHeading";

import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";
import { ChemicalLibInputBox } from "../ChemicalLibraryAddEditDialog/ChemicalLibFormControls";
import useChemicalUnitConversion from "../ChemicalLibraryHooks/useChemicalUnitConversion";

const initialControls = [
    { id: "rawWater", label: "", value: "", unit: UNIT_TYPES.VOLUME_SOLUTION },
    { id: "wasteWaterDisposal", label: "", value: "", unit: UNIT_TYPES.VOLUME_SOLUTION },
    { id: "electricity", label: "", value: "", unit: UNIT_TYPES.POWER },
];

const ProjectOperationCost = ({ operationCost, onCostChange }) => {
    const { activeUnits, activeCurrency, convertOperationCost } = useChemicalUnitConversion();
    const [controls, setControls] = useState(initialControls);
    const [costData, setCostData] = useState(operationCost);

    const getControlUnit = ({ unit }, activeUnits) => {
        const selectedUnit = activeUnits[unit];
        const formattedUnit = unit === UNIT_TYPES.POWER
            ? UNITS.kiloWattHour
            : (selectedUnit === UNITS.gallon ? UNITS.killoGallon : selectedUnit);
        return `${activeCurrency}/${formattedUnit}`;
    };

    const formattedData = control => {
        const isElectricity = control.id === "electricity";
        const metricValue = operationCost[control.id] || control.value;
        const unit = getControlUnit(control, activeUnits);
        const value = isElectricity ? metricValue : convertOperationCost(metricValue, UNIT_TYPES.VOLUME_SOLUTION);
        return {
            unit,
            value: convertUptoDigits(value, 4),
            label: CHEMICALS_LIBRARY_STRINGS[control.id],
        };
    };

    useEffect(() => {
        setControls(prevControls => prevControls.map(control => ({ ...control, ...formattedData(control) })));
    }, [operationCost, activeUnits]);

    const getOperationCostInMetric = data => Object.entries(data).reduce((acc, [key, value]) => {
        const convertedValue = key !== "electricity" ? convertOperationCost(value, UNIT_TYPES.VOLUME_SOLUTION, true) : value;
        return { ...acc, [key]: convertedValue };
    }, {});

    const handleControlChange = ({ id, data }) => {
        setControls(prev => prev.map(control => (control.id === id ? { ...control, value: data.value } : control)));
        const updatedCost = { ...costData, [id]: data.value };
        setCostData(updatedCost);
        const opCostInMetric = getOperationCostInMetric(updatedCost);
        onCostChange(opCostInMetric);
    };

    const handleBlur = () => {
        // Add any onBlur logic here
    };

    return (
        <div>
            <div className='operation-cost--container'>
                <WPHeading secondary size='small' className='operation-cost--label'>
                    Operation Cost
                </WPHeading>
                {controls.map(control => (
                    <div key={control.id} className='column'>
                        <ChemicalLibInputBox control={control} onControlChange={handleControlChange} onBlur={handleBlur} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProjectOperationCost;
