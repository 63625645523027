export const reportProjectInfoColumns = [
  {
    field: "col1",
    width: "22.5%",
  },
  {
    field: "col2",
    width: "22.5%",
  },
  {
    field: "col3",
    width: "27.5%",
  },
  {
    field: "col4",
    width: "27.5%",
  },
];

export const reportProjectInfoTableLabels = {
  projectNumber: "Project No.",
  projectName: "Project Name",
  createdDate: "Date Created",
  updatedDate: "Last Modified",
  activeCaseName: "Case Name",
  designer: "Prepared By",
  designerCompany: "Company",
  customer: "Customer",
  countryName: "Country",
  marketsegmentName: "Project Market Segment",
  projectNotes: "Project Notes",
  appVersion: "Application Version",
};

export const reportProjectInfoRows = [
  ["projectNumber", "projectName", "createdDate", "updatedDate"],
  ["activeCaseName", "designer", "designerCompany", "customer"],
  ["countryName", "marketsegmentName", "projectNotes", "appVersion"],
];
