import { useDispatch } from "react-redux";

import { setUfReportTableData } from "@common/ReportSlice";

import { useReportUtils } from "../useReportUtils";

import { useWPUfOperatingConditionsTableData } from "./WPUfOperatingConditionsTable/useWPUfOperatingConditionsTableData";
import { useWPUfServiceWaterTableData } from "./WPUfServiceWaterTable/useWPUfServiceWaterTableData";
import { useWPUfStorageTanksTableData } from "./WPUfStorageTanksTable/useWPUfStorageTanksTableData";
import { useWPUfAdditionalFeedWaterInfoTableData } from "./WPUfAdditionalFeedWaterInfoTable";
import { useWPUfChemicalsTableData } from "./WPUfChemicalsTable";
import { useWPUfConfigurationOptionsTableData } from "./WPUfConfiguringationOptionsTable";
import { useWPUfDesignWarningsTableData } from "./WPUfDesignWarningsTable";
import { useWPUfElectricityTableData } from "./WPUfElectricityTable";
import { useWPUfFlowDetailsTableData } from "./WPUfFlowDetailsTable";
import { useWPUfParametersTableData } from "./WPUfParametersTables";
import { useWPUfPressureRatingsTableData } from "./WPUfPressureRatingsTable";
import { useWPUfPumpHydraulicsTableData } from "./WPUfPumpHydraulicsTable";
import { useWPUfSystemOverViewTableData } from "./WPUfSystemOverviewTable";
import { useWPUfSystemSizeModuleDetailsTableData } from "./WPUfSystemSizeModuleDetailsTable";
import { useWPUfUtilityCostTableData } from "./WPUfUtilityCostTable";
import { useWPUfWaterQualityTableData } from "./WPUfWaterQualityTable";

export const useUfReport = () => {
  const dispatch = useDispatch();

  const { ufReportRespData } = useReportUtils();

  const { convertToSystemOverviewTableData } = useWPUfSystemOverViewTableData();
  const { convertToUfConfigurationOptionsTableData } = useWPUfConfigurationOptionsTableData();
  const { convertToUfDesignWarningsTableData } = useWPUfDesignWarningsTableData();
  const { convertToUfFlowDetailsTableData } = useWPUfFlowDetailsTableData();
  const { convertToUfOperatingConditionsTableData } = useWPUfOperatingConditionsTableData();
  const { convertToUfWaterQualityTableData } = useWPUfWaterQualityTableData();
  const { convertToUfPressureRatingsTableData } = useWPUfPressureRatingsTableData();
  const { convertToUfPumpHydraulicsTableData } = useWPUfPumpHydraulicsTableData();
  const { convertToUfStorageTanksTableData } = useWPUfStorageTanksTableData();
  const { convertToUfSystemSizeModuleDetailsTableData, convertToUfModuleDesignDetailsTableData } =
    useWPUfSystemSizeModuleDetailsTableData();
  const { convertToUfChemicalsTableData } = useWPUfChemicalsTableData();
  const { convertToUfElectricityTableData } = useWPUfElectricityTableData();
  const { convertToUfServiceWaterTableData } = useWPUfServiceWaterTableData();
  const { convertToUfUtilityCostTableData } = useWPUfUtilityCostTableData();
  const { convertToUfParametersTableData } = useWPUfParametersTableData();
  const { convertToUfAdditionalWaterInfoData } = useWPUfAdditionalFeedWaterInfoTableData();

  const convertUfCalcReportToTableData = () => {
    if (!ufReportRespData) return;
    dispatch(
      setUfReportTableData({
        uf_system_overview: convertToSystemOverviewTableData(),
        uf_configuration_options: convertToUfConfigurationOptionsTableData(),
        uf_design_quality: convertToUfDesignWarningsTableData(),
        flow_details: convertToUfFlowDetailsTableData(),
        uf_operating_conditions: convertToUfOperatingConditionsTableData(),
        uf_water_quality: convertToUfWaterQualityTableData(),
        uf_additional_water_info: convertToUfAdditionalWaterInfoData(),
        uf_Power_ratings: convertToUfPressureRatingsTableData(),
        pump_electrical: convertToUfPumpHydraulicsTableData(),
        uf_Storage_tanks: convertToUfStorageTanksTableData(),
        // nested on purpose because we are rendering additional table only for this section
        uf_system_module: {
          module_details_and_units: convertToUfSystemSizeModuleDetailsTableData(),
          module_design: convertToUfModuleDesignDetailsTableData(),
        },
        Chemicals: convertToUfChemicalsTableData(),
        Electricity: convertToUfElectricityTableData(),
        service_Water: convertToUfServiceWaterTableData(),
        Utility_Chem_specific_water_cost: convertToUfUtilityCostTableData(),
        uf_backwash_parameters: convertToUfParametersTableData("bw"),
        uf_ceb_parameters: convertToUfParametersTableData("ceb"),
        uf_cip_parameters: convertToUfParametersTableData("cip"),
        uf_mcip_parameters: convertToUfParametersTableData("mcip"),
      }),
    );
  };

  return { convertUfCalcReportToTableData };
};
