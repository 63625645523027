export const elements = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  p: "p",
  span: "span",
  label: "label",
  div: "div",
};

export const typographyVariants = {
  body: "body",
  cardHeader: "cardHeader",
  xsText: "xsText",
  reportSectionHeader: "reportSectionHeader",
  tableData: "tableData",
  tableColumnData: "tableColumnData",
};

export const TypographyMapping = {
  [typographyVariants.body]: {
    element: elements.p,
    className: "body",
  },
  [typographyVariants.cardHeader]: {
    element: elements.h5,
    className: "cardHeader",
  },
  [typographyVariants.xsText]: {
    element: elements.p,
    className: "xs-Text",
  },
  [typographyVariants.tableData]: {
    element: elements.span,
    className: "tableData",
  },
  [typographyVariants.tableColumnData]: {
    element: elements.span,
    className: "tableColumnData",
  },
  [typographyVariants.reportSectionHeader]: {
    element: elements.h3,
    className: "reportSectionHeader",
  },
};
