import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { TEMPERARTURE_RANGES } from "@constants/global.constants";
import { UNITS } from "@constants/units.constant";

import useUFDataHandler from "@hooks/useUFDataHandler";
import { useUFInputRanges } from "@hooks/useUFInputRanges";
import useUnitConversion from "@hooks/useUnitConversion";

import { TEMPERATURE_OUT_OF_RANGE_MESSAGES, validateTemperature } from "@utils/temperatureValidation";

import ConfirmationPopup, { DIALOG_TYPES } from "@components/ConfirmationPopup";

import CustomLabel from "@common/styles/components/headings/CustomLabel";
import InputReferenceText from "@common/styles/components/headings/InputReferenceText";
import InputWithText from "@common/styles/components/inputs/InputWithText";

const temperatureInputs = ["Minimum", "Design", "Maximum"];

const FeedSetupTemperatures = ({ Stremdata, handleKeyPressfeedsetup, handleTempChange }) => {
  const { updateFiltrateFluxData } = useUFInputRanges();
  const tempUnit =
    useSelector(state => state.projectInfo?.projectConfig?.unitConfig?.selectedUnits?.[2]) || UNITS.celsius;
  const { unitConversionByName } = useUnitConversion();
  const { projectTechnology } = useUFDataHandler()?.projectDetails || {};
  const [tempInputData, setTempInputData] = useState({});
  const [isFocused, setIsFocused] = useState(null);
  const [tempDefaultValues, setTempDefaultValues] = useState({});
  const [alertData, setAlertData] = useState(null);
  const [tempErrors, setTempErrors] = useState({});

  useEffect(() => {
    if (Stremdata && Stremdata.length > 0) {
      const tempData = {
        Minimum: getFloatTemp("tempMin"),
        Design: getFloatTemp("tempDesign"),
        Maximum: getFloatTemp("tempMax"),
      };
      setTempInputData(tempData);
      setTempDefaultValues(tempData);
    }
  }, [Stremdata]);

  const convertTempToFahrenheit = temp => unitConversionByName(temp, tempUnit, UNITS.celsius, 1);
  const convertTempToCelsius = temp => unitConversionByName(temp, UNITS.celsius, tempUnit, 0);

  const getFloatTemp = type => {
    let value = (Stremdata && Stremdata.length > 0 && parseFloat(Stremdata[0][type])) || 0;
    if (tempUnit !== UNITS.celsius) {
      value = convertTempToFahrenheit(value);
    }
    return value;
  };

  /**
   * Renders temperature ranges for both soft and hard limits.
   * It formats the ranges into a string and passes it to the InputReferenceText component.
   */
  const renderTempRanges = () => {
    const { hard } = tempRanges;
    const hardRange = `${hard.min}-${hard.max}`;
    const refText = `Ranges ${hardRange}`;
    return <InputReferenceText refText={refText} />;
  };

  const handleOnWheel = ({ target }) => target.blur();
  const handleFocus = ({ target }) => setIsFocused(target?.id);
  const handleValueChange = ({ target }) => {
    const { id, value } = target;
    setTempInputData(oldValue => ({ ...oldValue, [id]: value }));
    const validationErrors = validateTemperature(tempRanges, id, value, tempDefaultValues);
    setTempErrors(validationErrors);
  };

  /**
   * This function checks for temperature errors within the `tempErrors` object.
   * If an error is found, it clears the `tempErrors` object, displays an alert with a specific message based on the error field ID,
   * and returns the ID of the error field. If no errors are found, it returns an empty string.
   */
  const handleErrorAlert = () => {
    const errorFieldId = Object.keys(tempErrors).find(key => tempErrors[key]?.isError) || "";
    if (errorFieldId) {
      setTempErrors({});
      handleShowAlert(TEMPERATURE_OUT_OF_RANGE_MESSAGES[errorFieldId]);
    }
    return errorFieldId;
  };

  const getTempInCelsius = (temperatureData, tempUnit) => {
    if (tempUnit === UNITS.celsius) return temperatureData;
    return Object.keys(temperatureData).reduce((acc, key) => {
      acc[key] = convertTempToCelsius(temperatureData[key]);
      return acc;
    }, {});
  };

  /*
   * Handles the onBlur event for temperature input fields.
   * It checks for errors using `handleErrorAlert` and resets the value to default if the blurred field has an error.
   * Then, it formats the value to one decimal place, updates the `tempInputData` state with the new value,
   * and triggers `handleTempChange` if there's no error and the changed field is "Design".
   * Finally, it updates the `tempInputData` state with the new temperature data.
   */
  const handleBlur = ({ target }) => {
    let { id, value } = target;
    const errorFieldId = handleErrorAlert();
    if (errorFieldId === id) value = tempDefaultValues[id];
    value = parseFloat(value).toFixed(1);
    const temperatureData = { ...tempInputData, [id]: value };
    if (!errorFieldId) {
      handleTempChange({ temperatureData: getTempInCelsius(temperatureData), isDesignTempUpdated: id === "Design" });
    }
    setTempInputData(temperatureData);
    if (id === "Design") {
      updateFiltrateFluxData(temperatureData[id]);
    }
  };

  const handleShowAlert = message => setAlertData({ type: DIALOG_TYPES.ERROR, message });
  const handleHideAlert = () => setAlertData(null);

  /**
   * useMemo hook to calculate temperature ranges based on the current temperature unit and
   * project technology. It returns an object with `soft` and `hard` properties,
   * each containing `min` and `max` temperature values.
   */
  const tempRanges = useMemo(() => {
    if (!tempUnit) return;
    const soft = {
      min: TEMPERARTURE_RANGES[tempUnit].soft.min,
      max: TEMPERARTURE_RANGES[tempUnit].soft.max[projectTechnology],
    };

    const hard = {
      min: TEMPERARTURE_RANGES[tempUnit].hard.min,
      max: TEMPERARTURE_RANGES[tempUnit].hard.max[projectTechnology],
    };
    return { soft, hard };
  }, [tempUnit, projectTechnology]);

  const getTempValue = tempType => {
    const tempValue = tempInputData[tempType];
    return isFocused === tempType ? tempValue : (+tempValue).toFixed(1);
  };

  const renderTempInputBox = tempType => {
    const { isError, isWarning } = tempErrors[tempType] || {};
    return (
      <div key={`temp_input${tempType}`}>
        <CustomLabel label={tempType} />
        <InputWithText
          onKeyPress={e => handleKeyPressfeedsetup(e, tempType)}
          id={tempType}
          isError={isError}
          isWarning={isWarning}
          type='number'
          inputText={tempUnit}
          onWheel={handleOnWheel}
          value={getTempValue(tempType)}
          onChange={handleValueChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          isFocused={isFocused === tempType}
        />
        {renderTempRanges()}
      </div>
    );
  };

  return (
    <>
      {temperatureInputs.map(tempType => renderTempInputBox(tempType))}{" "}
      {alertData ? (
        <ConfirmationPopup
          type={alertData.type}
          header='Temperature not in range'
          description={alertData.message}
          confirmBtn='Okay'
          onClose={handleHideAlert}
        />
      ) : null}
    </>
  );
};

export default FeedSetupTemperatures;
