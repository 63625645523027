import { useSelector } from "react-redux";

import { WPTable } from "@components/WPTable";

import { WPUfUtilityCostTableColumns as columns } from "./WPUfUtilityCostTable.constants";

export const WPUfUtilityCostTable = () => {
  const { Utility_Chem_specific_water_cost: rows } = useSelector(state => state.report.ufReportTableData);

  return <WPTable showHeader={false} columns={columns} className='marginTop24' rows={rows} />;
};
