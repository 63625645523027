import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { WPUfIngeBwParametersTableColumns as columns } from "./WPUfIngeBwParametersTable.constants";

export const WPUfIngeBwParametersTable = () => {
  const { uf_backwash_parameters: rows = [] } = useSelector(state => state.report.ufReportTableData);

  return (
    <WPTable
      sectionHeader={reportStrings.normalModeBwParameters}
      columns={columns}
      rows={rows}
      remarksList={[reportStrings.ingeBwRemark1, reportStrings.valveOpeningClosingRemark, reportStrings.oValueRemark]}
      footNotesList={[
        reportStrings.ingeBwFootNote1,
        reportStrings.forwardFlushOptional,
        reportStrings.ingeBwFootNote3,
        reportStrings.ingeBwFootNote4,
        reportStrings.ingeBwFootNote5,
      ]}
    />
  );
};
