import React from "react";
import styled from "styled-components";

import WPHeading from "@components/WPHeading";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  height: inherit;
  .symbol {
    padding: 4px 8px 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    border: 1px solid var(--du-color-green);
    background: var(--du-color-green-pale);
    color:var(--du-color-green);
  }
`;

const ChemicalConentrationRenderer = props => (
  <StyledDiv>
    <WPHeading size='xSmall' className="symbol">
      {props.value}%
    </WPHeading>
  </StyledDiv>
);

export default ChemicalConentrationRenderer;
