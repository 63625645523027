/* eslint-disable max-len */
import React from "react";

const CheckCircleIcon = () => (
  <>
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_181567_32119)'>
        <path
          d='M15.5917 7.09528H14.4401C14.1897 7.09528 13.9515 7.21559 13.8042 7.42184L9.94437 12.7745L8.19615 10.3486C8.04883 10.1448 7.81312 10.0221 7.56022 10.0221H6.40865C6.24906 10.0221 6.15575 10.2038 6.24906 10.3339L9.30843 14.5767C9.3807 14.6776 9.47598 14.7598 9.58636 14.8165C9.69674 14.8732 9.81905 14.9028 9.94314 14.9028C10.0672 14.9028 10.1895 14.8732 10.2999 14.8165C10.4103 14.7598 10.5056 14.6776 10.5778 14.5767L15.7488 7.40711C15.8446 7.27697 15.7513 7.09528 15.5917 7.09528Z'
          fill='#52C41A'
        />
        <path
          d='M11 0C4.92545 0 0 4.92545 0 11C0 17.0746 4.92545 22 11 22C17.0746 22 22 17.0746 22 11C22 4.92545 17.0746 0 11 0ZM11 20.1339C5.9567 20.1339 1.86607 16.0433 1.86607 11C1.86607 5.9567 5.9567 1.86607 11 1.86607C16.0433 1.86607 20.1339 5.9567 20.1339 11C20.1339 16.0433 16.0433 20.1339 11 20.1339Z'
          fill='#52C41A'
        />
      </g>
      <defs>
        <clipPath id='clip0_181567_32119'>
          <rect width='22' height='22' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default CheckCircleIcon;
