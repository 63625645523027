import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { DesignStrings, DupontString, IngeString } from "@utils/StringConstants";

import { isIngeSelected } from "@features/feedwater/uf/ufUtils";

export const useUFTechnologyStrings = () => {
  const pufTechnology = useSelector(state => state.UFStore?.data?.pUFTechnologyID);

  const [techStrings, setTechStrings] = useState({ ...DesignStrings });

  useEffect(() => {
    if (isIngeSelected(pufTechnology)) {
      setTechStrings({ ...techStrings, ...IngeString });
    } else {
      setTechStrings({ ...techStrings, ...DupontString });
    }
  }, [pufTechnology]);
  return techStrings;
};

export default useUFTechnologyStrings;
