export const colors = {
  DupontBrandRed: "#E4001C",
  DupontDarkRed: "#AA0014",
  PrimaryDarkAquaMarine: "#007672",
  SecondaryElfGreen: "#128370",
  SecondaryDarkBlue: "#004C97",
  SecondaryRadicalPink: "#FC2D72",
  SecondaryYellowishOrange: "#FF9E19",
  SecondaryLeafyGreen: "#58b947",
  SecondaryPictonBlue: "#00A9E0",
  SecondaryPurple: "#5F259F",
  WaveproBlueCyanBlue: "#004C97",
  LightPinkishRed: "#FF566B",
  LightRadicalPink: "#FFA3CA",
  LightYellowishOrange: "#ffcd00",
  LightLeafyGreen: "#a4d65e",
  LightLightTeal: "#6dd8cd",
  LightLightBlue: "#88DCF9",
  LightPurple: "#ad87f7",
  Pale20PalePink: "#F9E7EF",
  Pale20PaleYellow: "#FFFBCC",
  Pale20PaleGreen: "#EEF7D9",
  Pale20PaleBlue: "#EAF4FB",
  Pale20PaleTeal: "#E2F7F5",
  Pale20PaleTealOp50: "rgba(226, 247, 245, 0.5)",
  Pale20PalePurple: "#EFE7FD",
  Black: "#000000",
  Grey96: "#969696",
  GreyE1: "#E1E1E1",
  GreyF0: "#F0F0F0",
  GreyF8: "#F8F8F8",
  GreyF5: "#F5F5F5",
  GreyD9: "#D9D9D9",
  GreyDB: "#DBDBDB",
  White: "#FFFFFF",
  accountPreferenceSubHeading: "rgba(255, 255, 255, 0.80)",
  carouselPointerColor: "rgba(0, 118, 114, 0.60);",
  cationsHeaderFooterColor: "rgba(109, 216, 205, 0.30)",
  anionsHeaderFooterColor: "rgba(255, 163, 202, 0.30);",
  neutralHeaderFooterColor: "rgba(255, 251, 204, 0.40)",
  BrandTagLineColor: "#377098",
  blackTransparency006: "rgba(0, 0, 0, 0.06);",
  blackTransparency025: "rgba(0, 0, 0, 0.25)",
  blackTransparency012: "rgba(0, 0, 0, 0.12)",
  blackTransparency016: "rgba(0, 0, 0, 0.016)",
  blackTransparency020: "rgba(0, 0, 0, 0.20)",
  blackTransparency008: "rgba(0, 0, 0, 0.08)",
  blackTransparency005: "rgba(0, 0, 0, 0.05)",
  blackTransparency045: "rgba(0, 0, 0, 0.45)",
  blackTransparency085: "rgba(0, 0, 0, 0.85)",
  d98680d: "#D9680D",
  f8b57e: "#F8B57E",
  c71bfd2: "#71BFD2",
  c287082: "#287082",
  LightGrayishCyan: "#DCF2EF",
  LightGrayishCyan030: "rgba(220, 242, 239, 0.3)",
  LightPink010: "rgba(255, 163, 202, 0.10)",
  whiteOpacity: "rgba(255, 255, 255)",
};

export const tagsStyle = {
  normalBlueTags: {
    borderRadius: "4px",
    border: "1px solid",
    borderColor: colors.SecondaryPictonBlue,
    backgroundColor: colors.Light20PictonBlue,
    color: colors.SecondaryPictonBlue,
    display: "inline-flex",
    padding: "1px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    height: "17px",
    fontFamily: "Diodrum",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
  },
  normalGreenTags: {
    borderRadius: "4px",
    border: "1px solid",
    borderColor: colors.SecondaryLeafyGreen,
    backgroundColor: colors.Light20LeafyGreen,
    color: colors.SecondaryLeafyGreen,
    display: "inline-flex",
    padding: "1px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    height: "17px",
    fontFamily: "Diodrum",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
  },
  normalYellowTags: {
    borderRadius: "4px",
    border: "1px solid",
    borderColor: colors.SecondaryYellowishOrange,
    backgroundColor: colors.Light20YellowishOrange,
    color: colors.SecondaryYellowishOrange,
    display: "inline-flex",
    padding: "1px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    height: "17px",
    fontFamily: "Diodrum",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
  },
  normalRedTags: {
    borderRadius: "4px",
    border: "1px solid",
    borderColor: colors.SecondaryRadicalRed,
    backgroundColor: colors.Light20PinkishRed,
    color: colors.SecondaryRadicalRed,
    display: "inline-flex",
    padding: "1px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    height: "17px",
    fontFamily: "Diodrum",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
  },
};

export const standardButtonStyles = {
  normalPrimaryButton: {
    display: "inline-flex",
    padding: "14px 24px 14px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "1px solid",
    borderColor: colors.PrimaryDarkAquaMarine,
    backgroundColor: colors.PrimaryDarkAquaMarine,
    color: colors.White,
    textAlign: "center",
    height: "32px",
  },
  normalSecondaryButton: {
    display: "inline-flex",
    padding: "14px 24px 10px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "1px solid",
    borderColor: colors.Black,
    backgroundColor: colors.White,
    color: colors.Black,
    textAlign: "center",
    height: "32px",
  },
  hoverPrimaryButton: {
    display: "inline-flex",
    padding: "14px 24px 10px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "1px solid",
    borderColor: colors.SecondaryElfGreen,
    background: colors.SecondaryElfGreen,
    color: colors.White,
    textAlign: "center",
    height: "32px",
  },
  hoverSecondaryButton: {
    display: "inline-flex",
    padding: "14px 24px 10px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "1px solid",
    borderColor: colors.SecondaryElfGreen,
    backgroundColor: colors.White,
    color: colors.SecondaryElfGreen,
    textAlign: "center",
    height: "32px",
  },
  disablePrimaryButton: {
    display: "inline-flex",
    padding: "14px 24px 10px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "1px solid",
    borderColor: "#D9D9D9",
    backgroundColor: colors.GreyF8,
    color: "rgba(0, 0, 0, 0.25)",
    textAlign: "center",
    height: "32px",
  },
  linkWithIconPrimaryButton: {
    display: "inline-flex",
    padding: "14px 24px 10px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "none",
    borderColor: "none",
    backgroundColor: "transparent",
    color: colors.Black,
    textAlign: "center",
    height: "32px",
  },
  dashedNormalPrimaryButton: {
    display: "inline-flex",
    padding: "14px 24px 10px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "1px dashed",
    borderColor: colors.Black,
    backgroundColor: colors.White,
    color: colors.Black,
    textAlign: "center",
    height: "32px",
  },
  dashedActivePrimaryButton: {
    display: "inline-flex",
    padding: "14px 24px 10px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "1px dashed",
    borderColor: colors.PrimaryDarkAquaMarine,
    backgroundColor: colors.White,
    color: colors.PrimaryDarkAquaMarine,
    textAlign: "center",
    height: "32px",
  },
  dashedDisablePrimaryButton: {
    display: "inline-flex",
    padding: "14px 24px 10px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "1px dashed",
    borderColor: "#D9D9D9",
    backgroundColor: "#F5F5F5",
    color: colors.PrimaryDarkAquaMarine,
    textAlign: "center",
    height: "32px",
  },
};

export const largeStandardButtonStyles = {
  normalPrimaryButton: {
    display: "inline-flex",
    padding: "16px 48px",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    borderRadius: "100px",
    border: "1px solid",
    borderColor: colors.PrimaryDarkAquaMarine,
    backgroundColor: colors.PrimaryDarkAquaMarine,
    boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
    color: colors.White,
    textAlign: "center",
    height: "53px",
  },
  normalSecondaryButton: {
    display: "inline-flex",
    padding: "16px 48px",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    borderRadius: "100px",
    border: "1px solid",
    borderColor: colors.Black,
    backgroundColor: colors.White,
    boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
    color: colors.Black,
    textAlign: "center",
    height: "53px",
  },
  hoverPrimaryButton: {
    display: "inline-flex",
    padding: "16px 48px",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    borderRadius: "100px",
    border: "1px solid",
    borderColor: colors.PrimaryDarkAquaMarine,
    backgroundColor: colors.SecondaryElfGreen,
    boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
    color: colors.White,
    textAlign: "center",
    height: "53px",
  },
  hoverSecondaryButton: {
    display: "inline-flex",
    padding: "16px 48px",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    borderRadius: "100px",
    border: "1px solid",
    borderColor: colors.SecondaryElfGreen,
    backgroundColor: colors.White,
    boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
    color: colors.SecondaryElfGreen,
    textAlign: "center",
    height: "53px",
  },
  disablePrimaryButton: {
    display: "inline-flex",
    padding: "16px 48px",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    borderRadius: "25px",
    border: "1px solid",
    borderColor: colors.Grey96,
    backgroundColor: colors.GreyF8,
    boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
    color: "rgba(0, 0, 0, 0.25)",
    textAlign: "center",
    height: "53px",
  },
};

export const dangerStandardButtonStyles = {
  normalPrimaryButton: {
    display: "inline-flex",
    padding: "14px 24px 10px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "1px solid",
    borderColor: colors.DupontBrandRed,
    backgroundColor: colors.DupontBrandRed,
    color: colors.White,
    textAlign: "center",
    height: "32px",
  },
  normalSecondaryButton: {
    display: "inline-flex",
    padding: "14px 24px 10px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "1px solid",
    borderColor: colors.DupontBrandRed,
    backgroundColor: colors.White,
    color: colors.DupontBrandRed,
    textAlign: "center",
    height: "32px",
  },
  hoverPrimaryButton: {
    display: "inline-flex",
    padding: "14px 24px 10px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "1px solid",
    borderColor: colors.DupontDarkRed,
    backgroundColor: colors.DupontDarkRed,
    color: colors.White,
    textAlign: "center",
    height: "32px",
  },
  hoverSecondaryButton: {
    display: "inline-flex",
    padding: "14px 24px 10px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "1px solid",
    borderColor: colors.DupontDarkRed,
    backgroundColor: colors.DupontDarkRed,
    color: colors.White,
    textAlign: "center",
    height: "32px",
  },
  disablePrimaryButton: {
    display: "inline-flex",
    padding: "14px 24px 10px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "1px solid",
    borderColor: "#D9D9D9",
    backgroundColor: colors.GreyF8,
    color: "rgba(0, 0, 0, 0.25)",
    textAlign: "center",
    height: "32px",
  },
};

export const dangerLargeButtonStyles = {
  normalPrimaryButton: {
    display: "inline-flex",
    padding: "16px 48px",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    borderRadius: "100px",
    border: "1px solid",
    borderColor: colors.DupontBrandRed,
    backgroundColor: colors.DupontBrandRed,
    color: colors.White,
    textAlign: "center",
    height: "53px",
  },
  normalSecondaryButton: {
    display: "inline-flex",
    padding: "16px 48px",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    borderRadius: "100px",
    border: "1px solid",
    borderColor: colors.DupontBrandRed,
    backgroundColor: colors.White,
    color: colors.DupontBrandRed,
    textAlign: "center",
    height: "53px",
  },
  hoverPrimaryButton: {
    display: "inline-flex",
    padding: "16px 48px",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    borderRadius: "100px",
    border: "1px solid",
    borderColor: colors.DupontDarkRed,
    backgroundColor: colors.DupontDarkRed,
    color: colors.White,
    textAlign: "center",
    height: "53px",
  },
  hoverSecondaryButton: {
    display: "inline-flex",
    padding: "16px 48px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "100px",
    border: "1px solid",
    borderColor: colors.DupontDarkRed,
    backgroundColor: colors.DupontDarkRed,
    color: colors.White,
    textAlign: "center",
    height: "53px",
  },
  disablePrimaryButton: {
    display: "inline-flex",
    padding: "16px 48px",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    borderRadius: "25px",
    border: "1px solid",
    borderColor: colors.Grey96,
    backgroundColor: colors.GreyF8,
    boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
    color: "rgba(0, 0, 0, 0.25)",
    textAlign: "center",
    height: "53px",
  },
};

export const techButtonStyles = {
  normalTechButton: {
    border: "0.5px solid",
    borderColor: colors.GreyE1,
    borderRadius: "0",
    backgroundColor: colors.GreyE1,
    boxShadow: "1px 1px 3px 0px rgba(0, 0, 0, 0.20)",
    display: "inline-flex",
    padding: " 4px 10px",
    alignItems: "flex-start",
    color: colors.Grey96,
    fontFamily: "NotoSans",
    fontSize: "14px",
    fontStyle: "normal",
    // fontWeight: "700",
    lineHeight: "22px",
    height: "30px",
  },
  activeTechButton: {
    border: "0.5px solid red",
    borderColor: colors.GreyE1,
    borderRadius: "0",
    backgroundColor: "#E2F7F5",
    boxShadow: "1px 1px 3px 0px rgba(0, 0, 0, 0.20)",
    display: "inline-flex",
    padding: " 4px 10px",
    alignItems: "flex-start",
    color: colors.Black,
    fontFamily: "NotoSans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    height: "30px",
  },
};

export const techSmallButtonStyles = {
  normalTechButton: {
    border: "0.5px solid",
    borderColor: colors.GreyE1,
    borderRadius: "0",
    backgroundColor: colors.GreyE1,
    boxShadow: "1px 1px 3px 0px rgba(0, 0, 0, 0.20)",
    display: "inline-flex",
    padding: " 0px 10px",
    alignItems: "flex-start",
    color: colors.Grey96,
    fontFamily: "NotoSans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    height: "24px",
  },
  activeTechButton: {
    border: "0.5px solid",
    borderRadius: "0",
    borderColor: colors.GreyE1,
    backgroundColor: "#E2F7F5",
    boxShadow: "1px 1px 3px 0px rgba(0, 0, 0, 0.20)",
    display: "inline-flex",
    padding: " 0px 10px",
    alignItems: "flex-start",
    color: colors.Black,
    fontFamily: "NotoSans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    height: "24px",
  },
};

export const smallStandardButtonStyles = {
  normalPrimaryButton: {
    display: "inline-flex",
    padding: "10px 10px 7px 10px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "1px solid",
    borderColor: colors.PrimaryDarkAquaMarine,
    backgroundColor: colors.PrimaryDarkAquaMarine,
    color: colors.White,
    textAlign: "center",
    height: "24px",
  },
  normalSecondaryButton: {
    display: "inline-flex",
    padding: "10px 10px 7px 10px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "1px solid",
    borderColor: colors.Black,
    backgroundColor: colors.White,
    color: colors.Black,
    textAlign: "center",
    height: "24px",
  },
  hoverPrimaryButton: {
    display: "inline-flex",
    padding: "10px 10px 7px 10px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "1px solid",
    borderColor: colors.SecondaryElfGreen,
    backgroundColor: colors.SecondaryElfGreen,
    color: colors.White,
    textAlign: "center",
    height: "24px",
  },
  hoverSecondaryButton: {
    display: "inline-flex",
    padding: "10px 10px 7px 10px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "1px solid",
    borderColor: colors.SecondaryElfGreen,
    backgroundColor: colors.White,
    color: colors.SecondaryElfGreen,
    textAlign: "center",
    height: "24px",
  },
  disablePrimaryButton: {
    display: "inline-flex",
    padding: "11px 10px 7px 10px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    background: colors.GreyF8,
    color: colors.Grey96,
    textAlign: "center",
    height: "24px",
  },
};

export const draggableButtonStyles = {
  defaultDraggableButton: {
    border: "0.5px solid",
    borderColor: colors.GreyE1,
    backgroundColor: "#E2F7F5",
    boxShadow: "1px 1px 3px 0px rgba(0, 0, 0, 0.20)",
    display: "inline-flex",
    padding: "4px 8px",
    alignItems: "center",
    gap: "6px",
    height: "32px",
  },
  activeDraggableButton: {
    border: "0.5px solid",
    borderColor: colors.GreyE1,
    backgroundColor: "#6DD8CD",
    boxShadow: "1px 1px 3px 0px rgba(0, 0, 0, 0.20)",
    display: "inline-flex",
    padding: "4px 8px",
    alignItems: "center",
    gap: "6px",
    height: "32px",
  },
};

export const fontStyles = {
  diodrum8SemiBold: {
    color: colors.Black,
    textAlign: "center",
    fontFamily: "Diodrum",
    fontSize: "8px",
    fontStyle: "normal",
    fontWeight: "600",
  },
  diodrum11Light: {
    fontFamily: "Diodrum",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "0.055px",
  },
  diodrum10: {
    fontFamily: "Diodrum",
    fontSize: "10px",
    fontWeight: "400",
    fontStyle: "Normal",
  },
  diodrum10Light: {
    fontFamily: "Diodrum",
    fontSize: "10px",
    fontWeight: "400",
    fontStyle: "Normal",
    letterSpacing: "0.05px",
  },
  diodrum10SemiBold: {
    fontFamily: "Diodrum",
    fontSize: "10px",
    fontWeight: "600",
    fontStyle: "Normal",
  },
  diodrum11: {
    fontFamily: "Diodrum",
    fontSize: "11px",
    fontWeight: "400",
    fontStyle: "Normal",
  },
  diodrum12: {
    fontFamily: "Diodrum",
    fontSize: "12px",
    fontWeight: "400",
    fontStyle: "Normal",
  },
  diodrum12SemiBold: {
    fontFamily: "Diodrum",
    fontSize: "12px",
    fontWeight: "600",
    fontStyle: "Normal",
  },
  diodrum14: {
    fontFamily: "Diodrum",
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "Normal",
    lineHeight: "14px",
  },
  diodrum14SemiBold: {
    fontFamily: "Diodrum",
    fontSize: "14px",
    fontWeight: "600",
    fontStyle: "Normal",
  },
  diodrum14Bold: {
    fontFamily: "Diodrum",
    fontSize: "14px",
    fontWeight: "600",
    fontStyle: "Normal",
    lineHeight: "18px",
  },
  diodrum16: {
    fontFamily: "Diodrum",
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "Normal",
  },
  diodrum18: {
    fontFamily: "Diodrum",
    fontSize: "18px",
    fontWeight: "400",
    fontStyle: "Normal",
  },
  diodrum18SemiBold: {
    fontFamily: "Diodrum",
    fontSize: "18px",
    fontWeight: "600",
    fontStyle: "Normal",
  },
  diodrum16SemiBold: {
    fontFamily: "Diodrum",
    fontSize: "16px",
    fontWeight: "600",
    fontStyle: "Normal",
  },
  diodrum32: {
    fontFamily: "Diodrum",
    fontSize: "32px",
    fontWeight: "400",
    fontStyle: "Normal",
  },
  diodrum32SemiBold: {
    fontFamily: "Diodrum",
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "35px",
    fontStyle: "Normal",
  },
  notoSans12: {
    fontFamily: "NotoSans",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    fontStyle: "Normal",
  },
  notoSans12SemiBold: {
    fontFamily: "NotoSans",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    fontStyle: "Normal",
  },
  notoSans14: {
    fontFamily: "NotoSans",
    fontSize: "14px",
    fontWeight: "400",
    // lineHeight:"18px",
    fontStyle: "Normal",
  },
  notoSans14W500: {
    fontFamily: "NotoSans",
    fontSize: "14px",
    fontWeight: "500",
    // lineHeight:"18px",
    fontStyle: "Normal",
  },
  notoSans14SemiBold: {
    fontFamily: "NotoSans",
    fontSize: "14px",
    fontWeight: "700",
    // lineHeight:"18px",
    fontStyle: "Normal",
  },
  notoSans16: {
    fontFamily: "NotoSans",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "22px",
    fontStyle: "Normal",
  },
  notosans16SemiBold: {
    fontFamily: "NotoSans",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "18px",
    fontStyle: "Normal",
  },
};
export const headingStyles = {
  h1Diodrum60: {
    color: colors.Black,
    fontFamily: "Diodrum",
    fontSize: "60px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "64px",
  },
  h2DiodrumMedium45: {
    color: colors.Black,
    fontFamily: "Diodrum",
    fontSize: "45px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "48px",
    letterSpacing: "0.225px",
  },
  h3Diodrum32: {
    color: colors.Black,
    fontFamily: "Diodrum",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "35px",
    letterSpacing: "0.16px",
  },
  h4DiodrumMedium26: {
    color: colors.Black,
    fontFamily: "Diodrum",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "32px",
  },
  h5DiodrumMedium16: {
    color: colors.Black,
    fontFamily: "Diodrum",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "18px",
  },
  bodyNotoSansBold14: {
    color: colors.Black,
    fontFamily: "NotoSans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "22px",
  },
  bodyNotoSans14: {
    color: colors.Black,
    fontFamily: "NotoSans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "18px",
  },
  footNoteDiodrum12: {
    color: colors.Black,
    fontFamily: "Diodrum",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "15px",
  },
  footNoteDiodrum10: {
    color: colors.Black,
    fontFamily: "Diodrum",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "12px",
    letterSpacing: "0.05px",
  },
};

export const normalInputBoxStyles = {
  defaultInputBoxStyle: {
    borderRadius: "2px",
    border: "1px solid",
    padding: "5px 10px",
    borderColor: colors.GreyE1,
    backgroundColor: colors.White,
    boxShadow: "none",
    height: "32px",
  },
  defaultInputBoxStyleW126: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    padding: "5px 10px",
    backgroundColor: colors.White,
    boxShadow: "none",
    height: "32px",
    width: "126px",
  },
  defaultExtraSmallInputBoxStyle: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    backgroundColor: colors.White,
    boxShadow: "none",
    height: "32px",
    width: "108px",
  },
  disabledInputBoxStyle: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    backgroundColor: colors.GreyE1,
    height: "32px",
    boxShadow: "none",
    pointerEvents: "none",
  },
};

export const formTextStyles = {
  smallFormTextStyle: {
    fontFamily: "Diodrum",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "12px",
    letterSpacing: "0.05px",
    color: colors.Grey96,
    display: "inherit",
  },
};
export const inputGroupStyles = {
  calcEngineSmallInputBoxWholeStyle: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.SecondaryPictonBlue,
    backgroundColor: colors.GreyE1,
    width: "126px",
  },
  calcEngineInputGroupTextStyle: {
    alignItems: "end",
    padding: "0",
    border: "none",
    backgroundColor: "transparent",
  },
  calcEngineFormControlStyle: {
    borderRadius: "0",
    border: "none",
    padding: "5px 2px 5px 12px",
    borderColor: colors.GreyE1,
    backgroundColor: "transparent",
    height: "30px",
    boxShadow: "none",
  },
  formControlStyles: {
    borderRadius: "0",
    border: "none",
    padding: "5px 2px 5px 12px",
    borderColor: colors.GreyE1,
    backgroundColor: colors.White,
    height: "30px",
    boxShadow: "none",
  },
  normalInputBoxWholeStyle: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    backgroundColor: colors.White,
    height: "32px",
  },
  normalSmallInputBoxWholeStyle: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    backgroundColor: colors.White,
    width: "190px",
    height: "32px",
  },
  normalSmallInputBoxWholeStyle1366: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    backgroundColor: colors.White,
    width: "170px",
    height: "32px",
  },
  normalExtraSmallInputBoxWholeStyle: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    backgroundColor: colors.White,
    width: "126px",
    height: "32px",
  },
  normalExtraSmallInputBoxWholeStyle1366: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    backgroundColor: colors.White,
    width: "110px",
    height: "32px",
  },
  extraSmallInputBoxWholeStyle: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    backgroundColor: colors.White,
    width: "101px",
    height: "32px",
  },
  extraSmallFormControlStyles: {
    borderRadius: "0",
    border: "none",
    padding: "5px 2px 5px 12px",
    borderColor: colors.GreyE1,
    backgroundColor: colors.White,
    height: "30px",
    width: "70px",
    boxShadow: "none",
  },
  extraSmallFormControlStylesW95: {
    borderRadius: "0",
    border: "none",
    padding: "5px 2px 5px 12px",
    borderColor: colors.GreyE1,
    backgroundColor: colors.White,
    height: "30px",
    width: "95px",
    boxShadow: "none",
  },
  extraSmallInputBoxWholeStyle1366: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    backgroundColor: colors.White,
    width: "85px",
    height: "32px",
  },
  normalMediumInputBoxWholeStyle: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    backgroundColor: colors.White,
    width: "410px",
  },
  normalMediumInputBoxWholeStyle1440: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    backgroundColor: colors.White,
    width: "380px",
  },
  defaultInputGroupTextStyles: {
    borderLeft: "1px solid",
    borderColor: colors.GreyE1,
    borderTop: "none",
    borderRight: "none",
    borderBottom: "none",
    backgroundColor: colors.GreyF8,
    borderRadius: "0",
    height: "30px",
  },
  defaultInputGroupTextWithoutPaddingStyles: {
    borderLeft: "none",
    borderColor: colors.GreyE1,
    borderTop: "none",
    borderRight: "none",
    borderBottom: "none",
    backgroundColor: "transparent",
    borderRadius: "0",
    height: "30.5px",
    padding: "5px",
  },
  defaultInputGroupTextWithPaddingStyles: {
    borderLeft: "none",
    borderColor: colors.GreyE1,
    borderTop: "none",
    borderRight: "none",
    borderBottom: "none",
    backgroundColor: colors.GreyE1,
    borderRadius: "0px 2px 2px 0px",
    height: "30.5px",
    padding: "10px",
    fontFamily: "Diodrum",
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "Normal",
  },
  defaultInputGroupInputBoxStyle: {
    borderRadius: "0",
    border: "none",
    borderColor: colors.GreyE1,
    backgroundColor: colors.White,
    boxShadow: "none",
  },
  disabledInputGroupInputBoxStyle: {
    borderRadius: "0",
    border: "none",
    borderColor: colors.GreyE1,
    backgroundColor: colors.GreyE1,
    boxShadow: "none",
    pointerEvents: "none",
  },
  inputGroupTextStyleFromSelect: {
    // borderLeft: "none",
    borderLeft: "1px solid",
    borderColor: colors.GreyE1,
    borderTop: "none",
    borderRight: "none",
    borderBottom: "none",
    backgroundColor: colors.GreyF8,
    borderRadius: "0",
    height: "30px",
    padding: "5px",
  },
  normalFormSelectInputGroupTextStyle: {
    backgroundColor: "transparent",
    border: "none",
    padding: "2px 30px 2px 2px",
    boxShadow: "none",
    height: "30px",
  },
};

export const InputBoxStyles = {
  normalSmallInputBox: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    backgroundColor: colors.White,
    color: colors.Black,
    display: "flex",
    padding: "5px 12px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "10px",
    flex: "1 0 0",
    alignSelf: "stretch",
    height: "32px",
    width: "194px",
    boxShadow: "none",
  },
  normalMediumInputBox: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    backgroundColor: colors.White,
    color: colors.Black,
    display: "flex",
    padding: "5px 12px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "10px",
    flex: "1 0 0",
    alignSelf: "stretch",
    height: "32px",
    width: "294px",
    boxShadow: "none",
  },
  normalLargeInputBox: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    backgroundColor: colors.White,
    color: colors.Black,
    display: "flex",
    padding: "5px 12px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "10px",
    flex: "1 0 0",
    alignSelf: "stretch",
    height: "32px",
    width: "377px",
    boxShadow: "none",
  },
  disableSmallInputBox: {
    display: "flex",
    padding: "5px 12px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "4px",
    flex: "1 0 0",
    alignSelf: "stretch",
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    backgroundColor: colors.GreyE1,
    height: "32px",
    width: "194px",
    boxShadow: "none",
  },
  disableMediumInputBox: {
    display: "flex",
    padding: "5px 12px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "4px",
    flex: "1 0 0",
    alignSelf: "stretch",
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    backgroundColor: colors.GreyE1,
    height: "32px",
    width: "290px",
    boxShadow: "none",
  },
  disableLargeInputBox: {
    display: "flex",
    padding: "5px 12px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "4px",
    flex: "1 0 0",
    alignSelf: "stretch",
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    backgroundColor: colors.GreyE1,
    height: "32px",
    width: "377px",
    boxShadow: "none",
  },
  errorInputBox: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.DupontBrandRed,
    backgroundColor: colors.White,
    color: colors.Black,
    display: "flex",
    padding: "5px 12px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "10px",
    flex: "1 0 0",
    alignSelf: "stretch",
    height: "32px",
    width: "194px",
    boxShadow: "none",
  },
  calcEngineNormalInputBox: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.SecondaryLeafyGreen,
    backgroundColor: colors.White,
    color: colors.Black,
    display: "flex",
    padding: "5px 12px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "10px",
    flex: "1 0 0",
    alignSelf: "stretch",
    height: "32px",
    width: "194px",
    boxShadow: "none",
  },
  calcEngineDisableInputBox: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.SecondaryLeafyGreen,
    backgroundColor: colors.GreyE1,
    color: colors.Black,
    display: "flex",
    padding: "5px 12px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "10px",
    flex: "1 0 0",
    alignSelf: "stretch",
    height: "32px",
    width: "194px",
    boxShadow: "none",
  },
};

export const selectStyles = {
  normalSelect: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    backgroundColor: colors.White,
    display: "flex",
    height: "32px",
    padding: "4px 10px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
    flex: "1 0 0",
    boxShadow: "none",
  },
};

export const textAreaStyles = {
  normalTextArea: {
    borderRadius: "2px",
    border: "1px solid",
    borderColor: colors.GreyE1,
    background: colors.White,
    boxShadow: "none",
  },
};

export const labels = {
  normalLabel: {
    color: colors.Black,
    fontFamily: "Diodrum",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "18px",
  },
};

export const checkBoxStyles = {
  hoverCheckBox: {
    borderRadius: "2px",
    borderColor: colors.PrimaryDarkAquaMarine,
    backgroundColor: colors.White,
    boxShadow: "none",
  },
  defaultCheckBox: {
    borderRadius: "2px",
    borderColor: "#D9D9D9",
    backgroundColor: colors.White,
    boxShadow: "none",
  },
  activeCheckBox: {
    borderRadius: "2px",
    borderColor: colors.PrimaryDarkAquaMarine,
    backgroundColor: colors.PrimaryDarkAquaMarine,
    boxShadow: "none",
  },
  errorCheckBox: {
    borderRadius: "2px",
    borderColor: colors.DupontBrandRed,
    backgroundColor: colors.White,
    boxShadow: "none",
  },
};

export const radioStyles = {
  defaultRadio: {
    borderColor: "#D9D9D9",
    backgroundColor: colors.White,
    boxShadow: "none",
  },
  hoverRadio: {
    borderColor: colors.PrimaryDarkAquaMarine,
    backgroundColor: colors.White,
    boxShadow: "none",
  },
  activeRadio: {
    borderColor: colors.PrimaryDarkAquaMarine,
    backgroundColor: colors.PrimaryDarkAquaMarine,
    boxShadow: "none",
  },
};

export const unitPillStyles = {
  activeRightPills: {
    width: "52px",
    height: "18px",
    padding: "2px 10px 2px 10px",
    alignItems: "center",
    borderRadius: "0px 5px 5px 0px",
    border: "none",
    backgroundColor: colors.PrimaryDarkAquaMarine,
    color: colors.White,
  },
  defaultRightPills: {
    width: "52px",
    height: "18px",
    padding: "2px 10px 2px 10px",
    alignItems: "center",
    borderRadius: "0px 5px 5px 0px",
    border: "none",
    backgroundColor: colors.GreyE1,
    color: colors.Grey96,
  },
  activeLeftPills: {
    width: "52px",
    height: "18px",
    padding: "2px 10px 2px 10px",
    alignItems: "center",
    borderRadius: "5px 0px 0px 5px",
    border: "none",
    backgroundColor: colors.PrimaryDarkAquaMarine,
    color: colors.White,
  },
  defaultLeftPills: {
    width: "52px",
    height: "18px",
    padding: "2px 10px 2px 10px",
    alignItems: "center",
    borderRadius: "5px 0px 0px 5px",
    border: "none",
    backgroundColor: colors.GreyE1,
    color: colors.Grey96,
  },
};

export const dataInputField = {
  borderRadius: "2px",
  border: "1px solid",
  borderColor: colors.GreyE1,
  backgroundColor: colors.White,
  boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.02)",
  display: "flex",
  width: "96px",
  height: "32px",
  padding: "8px 8px 10px 9px",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "32px",
};

export const normalCardStyle = {
  defaultCardStyle: {
    backgroundColor: colors.White,
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.20)",
    borderRadius: "2px",
    border: "none",
  },
  defaultCardStyleWithoutBackground: {
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.20)",
    borderRadius: "2px",
    border: "none",
  },
};

export const cardStylesListView = {
  normalCardStyle: {
    backgroundColor: colors.White,
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.20)",
    borderRadius: "4px",
    border: "none",
  },
  hoverCardStyle: {
    boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.20)",
  },
};
export const cardStylesGridView = {
  normalCardStyle: {
    width: "264.922px",
    height: "218px",
    backgroundColor: colors.White,
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.20)",
    borderRadius: "4px",
    border: "none",
  },
  hoverCardStyle: {
    boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.20)",
  },
};

export const cardStyles1366GridView = {
  normalCardStyle: {
    width: "250px",
    height: "210px",
    backgroundColor: colors.White,
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.20)",
    borderRadius: "4px",
    border: "none",
  },
  hoverCardStyle: {
    boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.20)",
  },
};
export const cardStyles1600GridView = {
  normalCardStyle: {
    width: "100%",
    height: "90%",
    backgroundColor: colors.White,
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.20)",
    borderRadius: "4px",
    border: "none",
  },
  hoverCardStyle: {
    boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.20)",
  },
};

export const dropdownStyles = {
  dropdownMenuStyles: {
    backgroundColor: colors.White,
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "2px",
    border: "none",
  },
};

export const modalStyles = {
  normalModalStyle: {
    backgroundColor: colors.White,
    borderRadius: "2px",
    boxShadow:
      "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
  },
};

export const circleStyles = {
  normalCircleStyles: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  disableCircleStyle: {
    border: "1px solid",
    borderColor: colors.Grey96,
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    color: colors.Grey96,
    backgroundColor: colors.GreyF8,
  },
  activeCircleStyle: {
    backgroundColor: colors.PrimaryDarkAquaMarine,
    border: "1px solid",
    borderColor: colors.PrimaryDarkAquaMarine,
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    color: colors.White,
  },
  doneCircleStyle: {
    backgroundColor: "transparent",
    border: "1px solid",
    borderColor: colors.PrimaryDarkAquaMarine,
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    color: colors.PrimaryDarkAquaMarine,
    paddingTop: "2px",
  },
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const accordionStyles = {
  normalAccordionStyle: {
    borderRadius: "4px",
    background: colors.White,
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.20)",
  },
  accordionHeaderStyle: {
    backgroundColor: "transparent",
    boxShadow: "none",
    borderRadius: "0%",
  },
};

export const switchStyles = {
  normalSwitchStyle: {
    height: "18px",
    display: "flex",
    width: "20px",
    padding: "2px 5px 3px 0px",
    alignItems: "center",
    flexShrink: "0",
    boxShadow: "none",
  },
};
