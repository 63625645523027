import React, { useEffect, useState } from "react";

import { isValueInRange } from "@utils/appUtils";

import InputRangeWithErrorAndWaring from "@components/InputRefWithErrorAndWaring";

import InputWithText from "@common/styles/components/inputs/InputWithText";

const InputBoxWithValidation = ({
  id,
  value,
  disabled,
  inputText,
  onBlur,
  onChange,
  ranges,
  handleFocus,
  errors,
  onError,
  isEnabled,
  tabIndex,
}) => {
  const [validationState, setValidationState] = useState({ isError: false, isWarning: false, message: "" });

  useEffect(() => {
    if (errors) {
      setValidationState(errors);
    }
  }, [errors]);

  useEffect(() => {
    if (isEnabled) {
      setValidationState({ isError: false, isWarning: false, message: "" });
      onError(id, false);
    }
  }, [isEnabled]);

  const handleValueChange = ({ target }) => {
    const { id, value } = target;
    const { softLimit, hardLimit } = ranges;
    let newState = { isError: false, isWarning: false, message: "" };
    let isError = false;

    if (!isValueInRange(value, hardLimit)) {
      newState = { isError: true, message: `Ranges must be between ${hardLimit.minValue} to ${hardLimit.maxValue}` };
      isError = true;
    } else if (!isValueInRange(value, softLimit) && value <= hardLimit.maxValue) {
      newState = {
        isWarning: true,
        message: `Ranges should be between ${softLimit.minValue} to ${softLimit.maxValue}`,
      };
    }
    onError(id, isError);
    setValidationState(newState);
    onChange(event);
  };

  const handleKeyDown = evt => ["e", "E", "+", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault();

  return (
    <>
      <InputWithText
        id={id}
        value={value}
        inputText={inputText}
        onBlur={onBlur}
        onChange={handleValueChange}
        disabled={disabled}
        type='number'
        isWarning={validationState.isWarning}
        isError={validationState.isError}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        tabIndex={tabIndex}
      />
      {isEnabled && <InputRangeWithErrorAndWaring ranges={ranges?.hardLimit} {...validationState} />}
    </>
  );
};

export default InputBoxWithValidation;
