import React, { useState } from "react";
import { useSelector } from "react-redux";

import { CustomModalButtons } from "@constants/global.constants";

import useProjectDetails from "@hooks/useProjectDetails";
import useUpdateCase from "@hooks/useUpdateCase";

import { CaseManagementStrings } from "@utils/StringConstants";

import CustomModal from "@components/CustomModal";
import WPHeading from "@components/WPHeading";
import { useToastNotification } from "@components/WPToastNotification";

import ErrorMessage from "@common/styles/components/headings/ErrorMessage";
import CustomTextArea from "@common/styles/components/inputs/CustomTextArea";
import InputWithText from "@common/styles/components/inputs/InputWithText";

const AddEditCasePopup = ({ isAdd, onClose, currentCase, cases, setCases }) => {
  const originalCase = currentCase;
  const [newCase, setNewCase] = useState(currentCase);
  const ProjectInfoStore = useSelector(state => state.projectInfo.data);
  const { loading, addNewCases, editCase } = useUpdateCase();
  const { showSuccessNotification, showErrorNotification } = useToastNotification();
  const [errorMessage, setErrorMessage] = useState(null);
  const { activeCaseId } = useProjectDetails();

  const getNewCasePayload = () => {
    const highestDisplayOrder = Math.max(...cases.map(caseItem => caseItem.displayOrder), 0);
    const payload = {
      projectID: ProjectInfoStore?.projectID,
      caseName: newCase.caseName,
      caseNote: newCase.caseNote,
      currentCaseID: activeCaseId,
      displayOrder: highestDisplayOrder + 1,
    };
    return payload;
  };
  const getUpdateCasePayload = () => {
    const payload = {
      caseName: newCase.caseName,
      caseNote: newCase.caseNote,
      caseID: newCase.caseID,
    };
    return payload;
  };

  const handleConfirm = async ({ target }) => {
    if (target.id === CustomModalButtons.CONFIRM && newCase) {
      if (isAdd) {
        const payload = getNewCasePayload();
        const { isSuccess, message } = await addNewCases(payload);
        if (isSuccess) {
          showSuccessNotification(message);
          onClose();
        } else {
          showErrorNotification(message);
        }
      } else {
        const payload = getUpdateCasePayload();
        const { isSuccess, message } = await editCase(payload);
        if (isSuccess) {
          showSuccessNotification(message);
          onClose();
        } else {
          showErrorNotification(message);
        }
      }
    }
    onClose();
  };

  const handleChange = ({ target: { id, value } }) => {
    if (id === "caseName") {
      const caseExists = cases.some(caseItem => caseItem.caseName === value);
      if (caseExists) {
        setErrorMessage(CaseManagementStrings.caseNameExists);
        return;
      } else {
        setErrorMessage("");
      }
    }
    setNewCase(prevCase => ({ ...prevCase, [id]: value }));
  };

  const isConfirmBtnDisabled = (newCase, errorMessage, isAdd, originalCase) =>
    !newCase?.caseName?.trim() ||
    errorMessage ||
    (!isAdd && newCase?.caseName === originalCase?.caseName && newCase?.caseNote === originalCase?.caseNote);

  const handleKeyDown = evt => ["+", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault();

  const handleCancelButtonClick = () => setNewCase(prevCase => ({ ...prevCase, ["caseName"]: "" }));

  return (
    <CustomModal
      header={isAdd ? CaseManagementStrings.addNewCase : CaseManagementStrings.editCase}
      confirmBtn={isAdd ? CaseManagementStrings.save : CaseManagementStrings.update}
      cancelBtn={CaseManagementStrings.cancel}
      onModalClose={handleConfirm}
      confirmBtnDisabled={isConfirmBtnDisabled(newCase, errorMessage, isAdd, originalCase)}
      width='400px'
      customclass='popup-body-container'
      isLoading={Boolean(loading)}
      loadingText={loading}
    >
      <div>
        <div className='margin-top-10'>
          <WPHeading color={"var(--du-color-grey-700)"} secondary>
            {CaseManagementStrings.caseName}
          </WPHeading>
        </div>
        <InputWithText
          value={newCase.caseName}
          onChange={handleChange}
          id='caseName'
          isError={errorMessage}
          max={100}
          onKeyDown={handleKeyDown}
          showCancelIcon
          onCancelIconClick={handleCancelButtonClick}
        />
        {errorMessage && <ErrorMessage texMsg={errorMessage} className='error-message' />}
        <div className='margin-top-16'>
          <WPHeading color={"var(--du-color-grey-700)"} secondary>
            {CaseManagementStrings.caseNotes}
          </WPHeading>
          <div className='margin-top-10'>
            <CustomTextArea
              className='form-control'
              rows='2'
              id='caseNote'
              name='text'
              value={newCase.caseNote}
              onChange={handleChange}
              max={500}
            ></CustomTextArea>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default AddEditCasePopup;
