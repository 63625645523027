import { useReportUtils } from "@features/report-new/useReportUtils";

import { WPUfElectricityRowsData as rowsData } from "./WPUfElectricityTable.constants";

export const useWPUfElectricityTableData = () => {
  const { ufReportRespData, convertReportDataValue, getUnitLabelValue } = useReportUtils();
  const { Electricity } = ufReportRespData || {};

  const convertToUfElectricityTableData = () => {
    const data = rowsData.map(row => {
      const { label, apiKey, unitData } = row;
      const { unit, isCost, unitDenominator, precision = 2 } = unitData;

      return {
        label,
        unit: getUnitLabelValue({ unit, unitDenominator, isCost }),
        value: convertReportDataValue({ value: Electricity?.[apiKey], unit, isCost, unitDenominator, precision }),
      };
    });

    return data;
  };

  return { convertToUfElectricityTableData };
};
