import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { combineClassNames } from "@utils/appUtils";

import { WPTypogrpahy } from "@components/WPTypography";

import AccordionUpArrowIcon from "@common/icons/AccordionUpArrowIcon";

import "./WPAccordion.scss";

const WPAccordion = ({ title, children, defaultOpen }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const contentRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
    } else {
      contentRef.current.style.height = "0px";
    }
  }, [isOpen]);

  return (
    <div className='wp-accordion'>
      <div className='wp-accordion-header-wrapper' onClick={handleToggle}>
        <WPTypogrpahy fontFamily='Diodrum' text={title} className='wp-accordion-header' asElement='div' />
        <div className={combineClassNames("wp-accordion-icon", isOpen && "rotate")}>
          <AccordionUpArrowIcon />
        </div>
      </div>
      <div ref={contentRef} className={combineClassNames("wp-accordion-content-wrapper", isOpen && "open")}>
        {children}
      </div>
    </div>
  );
};

WPAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  defaultOpen: PropTypes.bool,
};

WPAccordion.defaultProps = {
  defaultOpen: false,
};

export default WPAccordion;
