/* eslint-disable max-len */
import React from "react";

const CreateNewProjectIcon = () => (
  <>
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='32' cy='32' r='32' fill='#128370' />
      <path
        d='M33.0712 19.1446H30.9283C30.7378 19.1446 30.6426 19.2398 30.6426 19.4303V30.6446H20.0006C19.8101 30.6446 19.7148 30.7398 19.7148 30.9303V33.0732C19.7148 33.2636 19.8101 33.3589 20.0006 33.3589H30.6426V44.5732C30.6426 44.7636 30.7378 44.8589 30.9283 44.8589H33.0712C33.2616 44.8589 33.3569 44.7636 33.3569 44.5732V33.3589H44.0006C44.191 33.3589 44.2863 33.2636 44.2863 33.0732V30.9303C44.2863 30.7398 44.191 30.6446 44.0006 30.6446H33.3569V19.4303C33.3569 19.2398 33.2616 19.1446 33.0712 19.1446Z'
        fill='white'
      />
    </svg>
  </>
);

export default CreateNewProjectIcon;
