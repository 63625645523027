import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { WPUfPumpHydraulicsTableColumns as columns } from "./WPUfPumpHydraulicsTable.constants";

export const WPUfPumpHydraulicsTable = () => {
  const { pump_electrical: rows } = useSelector(state => state.report.ufReportTableData);

  return (
    <WPTable sectionHeader={reportStrings.ufPumpHydraulicElectricalCost} columns={columns} rows={rows} hasUnitsRow />
  );
};
