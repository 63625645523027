import InputRanges from "@components/InputRanges";

import ErrorMessage from "@common/styles/components/headings/ErrorMessage";
import WarningMessage from "@common/styles/components/headings/WarningMessage";

const InputRangeWithErrorAndWaring = ({ ranges, isError, message, isWarning }) => {
  if (!ranges) return null;

  return isError ? (
    <ErrorMessage texMsg={message} />
  ) : isWarning ? (
    <WarningMessage txtMsg={message} />
  ) : (
    <InputRanges ranges={ranges} />
  );
};

export default InputRangeWithErrorAndWaring;
