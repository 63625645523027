import { useDispatch, useSelector } from "react-redux";

import { setFeedFlowRate } from "@features/feedwater/systemdesign/processDiagramSlice";

import useUnitConversion from "./useUnitConversion";

export const useSelectedUnitChanged = () => {
  const { feedFlowRate } = useSelector(state => state.processDiagramSlice);
  const { unitTypeFlow } = useSelector(state => state.GUnitConversion);
  const { unitConversionByName } = useUnitConversion();

  const dispatch = useDispatch();

  const updateFeedFlow = unitData => {
    const incomingUnit = unitData;
    const isUnitNew = incomingUnit[0].unitKey.filter(item => item.isSelected);
    const selectedUnits = [];
    if (isUnitNew.length > 0) {
      incomingUnit.map(item => {
        const unitTag = item.unitKey.filter(item => item.isSelected);
        if (unitTag.length > 0) {
          selectedUnits[item.uomTypeID] = unitTag[0].uomName;
        } else {
          selectedUnits[item.uomTypeID] = item.unitKey[0].uomName;
        }
      });
    }
    const convertedValue = unitConversionByName(feedFlowRate, selectedUnits[1], unitTypeFlow, 2);
    dispatch(
      setFeedFlowRate({
        value: convertedValue,
        name: "feedFlowRate",
        flag: false,
      }),
    );
  };

  return { updateFeedFlow };
};
