import styled from "styled-components";

import { colors } from "@common/styles/Theme";

const StyledChemicalAdjustment = styled("div")`
  display: flex;
  gap: 3px;

  .header_label--container {
    display: flex;
  }

  .empty-option {
    display: none;
  }

  .header_icon {
    height: 18px;
    width: 14px;
    margin-right: 4px;
  }

  .main-content {
    padding: 5px;
  }

  .measurement-table {
    /* width: 500px; */
    margin-top: 16px;
  }

  .bordered-div {
    border: 1px solid #969696;
    height: 100%;
    width: 10px;
  }

  .border-div-left {
    border-right: none;
  }

  .border-div-right {
    border-left: none;
  }

  .product,
  .uf {
    display: flex;
    .product-icon,
    .uf-btn {
      display: flex;
      margin-top: 29px;
      svg {
        margin-top: 12px;
      }
    }
    .uf-btn {
      padding-left: 10px;
    }
  }
  .card-wrapper {
    display: flex;
    gap: 10px;
    .card-container,
    .oxidant-card,
    .coagulant-card,
    .temp-card {
      flex-basis: 243px;
      max-width: 243px;
      padding: 10px;
      .card-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
      }
      .input-select {
        > div {
          margin-bottom: 8px;
        }
        .arrow-down {
          margin-top: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  /* .measurement {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    width: 49.5%;
    .heading {
      background-color: ${colors.GreyE1};
      border: 1px solid ${colors.GreyE1};
    }
    .measurement-data {
      border-bottom: 1px solid ${colors.GreyE1};
    }
    .heading,
    .measurement-data {
      display: flex;
      > div {
        padding: 8px 10px;
        width: 186px;
      }
    }
  } */
`;
export default StyledChemicalAdjustment;
