import { reportStrings } from "@constants/report.strings";
import { UNIT_TYPES, UNITS } from "@constants/units.constant";

export const WPUfUtilityCostTableColumns = [
  {
    field: "label",
    rowHeader: true,
    width: "250px",
  },
  {
    field: "unit",
    rowHeader: true,
    width: "150px",
  },
  {
    field: "value",
  },
];

export const WPUfUtilityCostRowsData = [
  {
    apiKey: "Utility_and_Chemical_Cost",
    label: reportStrings.utilityAndChemicalCost,
    unitData: {
      unit: UNITS.day,
      isCost: true,
      precision: 2,
    },
  },
  {
    apiKey: "Specific_water_cost",
    label: reportStrings.specificWaterCost,
    unitData: {
      unit: UNIT_TYPES.VOLUME_SOLUTION,
      isCost: true,
      precision: 3,
    },
  },
];
