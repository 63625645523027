/* eslint-disable max-len */
import React from "react";

const RecentProjectTealIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
      <g clipPath='url(#clip0_2414_42218)'>
        <path
          d='M7.37593 3.26468H6.62437C6.55562 3.26468 6.49937 3.32093 6.49937 3.38968V7.69124C6.49937 7.73187 6.51812 7.76937 6.55093 7.79281L9.13375 9.67874C9.19 9.71937 9.26812 9.70843 9.30875 9.65218L9.75562 9.04281C9.79781 8.98499 9.78531 8.90687 9.72906 8.86781L7.50093 7.25687V3.38968C7.50093 3.32093 7.44468 3.26468 7.37593 3.26468ZM10.8103 4.43968L13.2603 5.03812C13.3384 5.05687 13.415 4.99749 13.415 4.91781L13.4275 2.39437C13.4275 2.28968 13.3072 2.23031 13.2259 2.29593L10.7634 4.21937C10.7448 4.23377 10.7307 4.25312 10.7226 4.27522C10.7145 4.29731 10.7128 4.32124 10.7177 4.34425C10.7226 4.36727 10.7338 4.38843 10.7502 4.40531C10.7666 4.42219 10.7874 4.43411 10.8103 4.43968ZM13.4306 9.14437L12.5447 8.83968C12.5138 8.82909 12.48 8.83101 12.4505 8.84503C12.421 8.85905 12.3982 8.88404 12.3869 8.91468C12.3572 8.99437 12.3259 9.07249 12.2931 9.15062C12.015 9.80843 11.6166 10.4006 11.1072 10.9084C10.6035 11.4137 10.0065 11.8165 9.34937 12.0944C8.66863 12.3822 7.93689 12.5299 7.19781 12.5287C6.45093 12.5287 5.7275 12.3834 5.04625 12.0944C4.38912 11.8165 3.79215 11.4137 3.28843 10.9084C2.78062 10.4006 2.38218 9.80843 2.1025 9.15062C1.81627 8.4695 1.66963 7.73787 1.67125 6.99906C1.67125 6.25218 1.81656 5.52718 2.10562 4.84593C2.38375 4.18812 2.78218 3.59593 3.29156 3.08812C3.79528 2.58284 4.39225 2.18008 5.04937 1.90218C5.7275 1.61312 6.4525 1.46781 7.19937 1.46781C7.94625 1.46781 8.66968 1.61312 9.35093 1.90218C10.0081 2.18008 10.605 2.58284 11.1087 3.08812C11.2681 3.24906 11.4181 3.41624 11.5556 3.59281L12.49 2.86156C11.2603 1.28968 9.34625 0.278743 7.19625 0.280306C3.4525 0.281868 0.446245 3.32249 0.483745 7.06781C0.521245 10.7475 3.51343 13.7178 7.19937 13.7178C10.0978 13.7178 12.5666 11.8803 13.5072 9.30687C13.5306 9.24124 13.4962 9.16781 13.4306 9.14437Z'
          fill='#007672'
        />
      </g>
      <defs>
        <clipPath id='clip0_2414_42218'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default RecentProjectTealIcon;
