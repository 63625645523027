import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import CustomSelect from "@common/styles/components/selects/CustomSelect";

import CardWrapper from "./CardWrapper";
import InputBoxWithValidation from "./InputBoxWithValidation";

const ChemicalCard = ({
  id,
  label,
  onToggleChange,
  isToggleChecked,
  chemicalOptions,
  inputText,
  onBlur,
  onChange,
  phUpDown,
  data,
  disabled,
  ranges,
  lSIValidations,
  onFocus,
  onError,
  tabIndex,
}) => {
  const [phUpDownData, setphUpDownData] = useState(phUpDown);
  const [firstInputValue, setFirstInputValue] = useState("");
  const [dropdownVal, setDropdownVal] = useState(null);
  const [inputErrors, setInputErrors] = useState(null);

  const handlePhUpDownChange = event => {
    setphUpDownData({ ...phUpDown, value: event.target.value });
    onChange(event);
  };

  const handleError = (id, value) => onError(id, value);

  useEffect(() => setphUpDownData(phUpDown), [phUpDown]);

  useEffect(() => {
    if (data) {
      setDropdownVal(JSON.stringify(data.dropdownVal));
      setFirstInputValue(data.inputVal);
      if (data?.lsiSdi !== undefined || data?.lsiSdi !== null) {
        setphUpDownData(phUpDownData => ({ ...phUpDownData, value: data.lsiSdi }));
      }
    }
  }, [data]);

  const handleFocus = ({ target }) => {
    const errors = target.id === "lsiSdi_input" || target.id === "pH_input" ? {} : null;
    if (errors && onFocus) {
      onFocus(target.id);
    }
    setInputErrors(errors);
  };

  return (
    <CardWrapper
      label={label}
      labelIcon={phUpDown?.icon}
      onToggleChange={onToggleChange}
      isToggleChecked={isToggleChecked}
      disabled={disabled}
    >
      <div className='input-select'>
        <div>
          <CustomSelect
            id={`${id || label}_dropdown`}
            disabled={!isToggleChecked}
            value={dropdownVal}
            onChange={onChange}
          >
            {isToggleChecked && chemicalOptions}
          </CustomSelect>
        </div>
        <div>
          <InputBoxWithValidation
            id={`${id || label}_input`}
            value={isToggleChecked ? firstInputValue : ""}
            inputText={inputText}
            onBlur={onBlur}
            onChange={onChange}
            disabled={!isToggleChecked}
            ranges={ranges}
            errors={inputErrors}
            handleFocus={handleFocus}
            onError={handleError}
            isEnabled={isToggleChecked}
            tabIndex={tabIndex}
          />
        </div>
        {phUpDown && (
          <div>
            <InputBoxWithValidation
              id={`${phUpDownData.id || label}_input`}
              value={isToggleChecked ? phUpDownData.value : ""}
              inputText={phUpDownData.label}
              onBlur={onBlur}
              onChange={handlePhUpDownChange}
              disabled={!isToggleChecked || phUpDownData.disabled}
              ranges={lSIValidations}
              errors={inputErrors}
              handleFocus={handleFocus}
              onError={handleError}
              isEnabled={isToggleChecked}
              tabIndex={tabIndex}
            />
          </div>
        )}
      </div>
    </CardWrapper>
  );
};

ChemicalCard.propTypes = {
  label: PropTypes.string.isRequired,
  onToggleChange: PropTypes.func.isRequired,
  isToggleChecked: PropTypes.bool.isRequired,
  chemicalOptions: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  inputText: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  phUpDown: PropTypes.string,
};

export default ChemicalCard;
