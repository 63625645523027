/* eslint-disable max-len */
import React from "react";

const MoreVertIcon = () => (
  <>
    <svg width='5' height='24' viewBox='0 0 5 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.07812 24C2.34077 24 2.60084 23.9483 2.84349 23.8478C3.08614 23.7472 3.30662 23.5999 3.49234 23.4142C3.67806 23.2285 3.82537 23.008 3.92588 22.7654C4.02639 22.5227 4.07812 22.2626 4.07812 22C4.07812 21.7374 4.02639 21.4773 3.92588 21.2346C3.82537 20.992 3.67805 20.7715 3.49234 20.5858C3.30662 20.4001 3.08614 20.2528 2.84349 20.1522C2.60084 20.0517 2.34077 20 2.07812 20C1.81548 20 1.55541 20.0517 1.31276 20.1522C1.07011 20.2528 0.849628 20.4001 0.663911 20.5858C0.478194 20.7715 0.330876 20.992 0.230366 21.2346C0.129857 21.4773 0.0781249 21.7374 0.0781249 22C0.0781249 22.2626 0.129857 22.5227 0.230366 22.7654C0.330876 23.008 0.478194 23.2285 0.663911 23.4142C0.849628 23.5999 1.07011 23.7472 1.31276 23.8478C1.55541 23.9483 1.81548 24 2.07812 24ZM2.07812 14C2.60856 14 3.11726 13.7893 3.49234 13.4142C3.86741 13.0391 4.07812 12.5304 4.07812 12C4.07812 11.4696 3.86741 10.9609 3.49234 10.5858C3.11726 10.2107 2.60856 10 2.07812 10C1.54769 10 1.03898 10.2107 0.66391 10.5858C0.288838 10.9609 0.0781245 11.4696 0.0781245 12C0.0781245 12.5304 0.288838 13.0391 0.663911 13.4142C1.03898 13.7893 1.54769 14 2.07812 14ZM2.07812 4C2.60856 4 3.11726 3.78928 3.49234 3.41421C3.86741 3.03914 4.07812 2.53043 4.07812 2C4.07812 1.46957 3.86741 0.960859 3.49234 0.585787C3.11726 0.210714 2.60856 -1.10609e-07 2.07812 -8.74228e-08C1.54769 -6.42368e-08 1.03898 0.210714 0.66391 0.585787C0.288837 0.960859 0.078124 1.46957 0.078124 2C0.0781241 2.53043 0.288837 3.03914 0.66391 3.41421C1.03898 3.78928 1.54769 4 2.07812 4Z'
        fill='black'
      />
    </svg>
  </>
);

export default MoreVertIcon;
