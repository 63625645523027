import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { UNITS } from "@constants/units.constant";

import useFPAValidation from "@hooks/useFPAValidation";
import useUnitConversion from "@hooks/useUnitConversion";

import InfoIcon from "@common/icons/InfoIcon";
import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import IconWithTooltip from "@common/styles/components/headings/IconWithTooltip";
import InputReferenceText from "@common/styles/components/headings/InputReferenceText";
import InputWithText from "@common/styles/components/inputs/InputWithText";
import { colors } from "@common/styles/Theme";

import ProjectErrorPopup from "../../modals/ProjectErrorPopup";

import EquipmentStyled from "./EquipmentStyled";
import { updateIXStore } from "./IXDSlice";
import SystemDiagram from "./SystemDiagram";
const Equipment = () => {
  const [isFocused, setIsFocused] = useState(null);
  const { updateParameterSettings } = useFPAValidation();
  const ixStore = useSelector(state => state.IXStore.data);
  const [error, setError] = useState();
  const {
    tankTemperature,
    pdIntDistributor,
    pdExtPiping,
    effluentPressure,
    backwashTowerDiameter,
    sbaRegenVesselDia,
    sacRegenVesselDia,
  } = useSelector(state => state.IXStore.data);
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const { unitConversionByName } = useUnitConversion();

  const dispatch = useDispatch();

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      setError({ message: null });
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleFocus = id => {
    setIsFocused(id);
  };

  const getTempratureConverted = value => unitConversionByName(value, unit.selectedUnits[2], UNITS.celsius);

  const getConvertedValueInMM = value => {
    const val = unitConversionByName(value, unit.selectedUnits[8], UNITS.millimeter);
    return val;
  };

  const getConversionInBar = value => unitConversionByName(value, unit.selectedUnits[3], UNITS.bar);

  const EquipmentRanges = {
    pdIntDistributor: { min: 0, max: getConversionInBar(1) },
    pdExtPiping: { min: 0, max: getConversionInBar(50) },
    effluentPressure: { min: 0, max: getConversionInBar(50) },
    tankTemperature: { min: 0, max: getTempratureConverted(100) },
    backwashTowerDiameter: { min: 0, max: getConvertedValueInMM(2000) },
    sacRegenVesselDia: { min: 0, max: getConvertedValueInMM(2000) },
    sbaRegenVesselDia: { min: 0, max: getConvertedValueInMM(2000) },
  };

  const isError = (value, min, max) => {
    if (!value && value !== 0) return true; // Check for empty, undefined, or null values
    const parsedValue = parseFloat(value);
    return parsedValue < parseFloat(min) || parsedValue > parseFloat(max);
  };

  const checkError = (id, value) => {
    if (value === "" || value === undefined || value === null) return true;
    const ranges = {
      pdExtPiping: EquipmentRanges.pdExtPiping,
      pdIntDistributor: EquipmentRanges.pdIntDistributor,
      effluentPressure: EquipmentRanges.effluentPressure,
      tankTemperature: EquipmentRanges.tankTemperature,
      backwashTowerDiameter: EquipmentRanges.backwashTowerDiameter,
      sacRegenVesselDia: EquipmentRanges.sacRegenVesselDia,
      sbaRegenVesselDia: EquipmentRanges.sbaRegenVesselDia,
    };

    const { min = 0, max = 0 } = ranges[id] || {};
    return isError(parseFloat(value), min, max);
  };

  const handleBlur = event => {
    if (checkError(event.target.name, event.target.value)) {
      setError({ message: "Please enter value within valid Range!" });
      setTimeout(() => {
        event.target.focus();
      }, 0);
    } else {
      setError({ message: null });
    }
  };

  const handleValueChange = e => {
    const value = isNaN(e.target.value) ? 0 : e.target.value;
    dispatch(updateIXStore({ ...ixStore, [e.target.name]: value }));
    updateParameterSettings();
  };

  const closeErrorMessag = () => {
    setError({ message: null });
  };

  return (
    <>
      <EquipmentStyled className='g-0'>
        <SystemDiagram />
        <div style={{ display: "flex", gap: "10px" }} className='card-container '>
          <StyledCard className='equipment-settings-card'>
            <Card.Header>
              <CustomHeading
                fontFamily='DiodrumRegular'
                fontSize='14px'
                fontWeight='400'
                color={colors.PrimaryDarkAquaMarine}
                label='Equipment Settings'
              />
              <Card.Title className='info-icon'>
                <IconWithTooltip
                  label='Provide typical values for piping and distributor pressure drop, and the required outlet pressure.'
                  icon={<InfoIcon />}
                />
              </Card.Title>
            </Card.Header>
            <Card.Body className='equipment-body'>
              <div>
                <CustomLabel label='Typical Pressure Drop of Internal Distributors' />
                <div className='input-field'>
                  <InputWithText
                    isError={isError(pdIntDistributor, 0, EquipmentRanges.pdIntDistributor.max)}
                    type='number'
                    name='pdIntDistributor'
                    id='pdIntDistributor'
                    inputText={`${unit.selectedUnits[3]}/distributor`}
                    onBlur={handleBlur}
                    onFocus={() => handleFocus(1)}
                    isFocused={isFocused === 1}
                    value={pdIntDistributor}
                    onChange={handleValueChange}
                  />
                  <InputReferenceText
                    refText={`Ranges ${EquipmentRanges.pdIntDistributor.min} - ${EquipmentRanges.pdIntDistributor.max}`}
                  />
                </div>
              </div>
              <div>
                <CustomLabel label='Typical Pressure Drop of External Piping' disabled={false} />
                <div className='input-field'>
                  <InputWithText
                    isError={isError(pdExtPiping, 0, EquipmentRanges.pdExtPiping.max)}
                    type='number'
                    name='pdExtPiping'
                    id='pdExtPiping'
                    inputText={`${unit.selectedUnits[3]}/vessel`}
                    onBlur={handleBlur}
                    onFocus={() => handleFocus(2)}
                    isFocused={isFocused === 2}
                    value={pdExtPiping}
                    onChange={handleValueChange}
                  />
                  <InputReferenceText refText={`Range 0 - ${EquipmentRanges.pdExtPiping.max}`} />
                </div>
              </div>
              <div>
                <CustomLabel label='Effluent Pressure' disabled={false} />
                <div className='input-field'>
                  <InputWithText
                    isError={isError(effluentPressure, 0, EquipmentRanges.effluentPressure.max)}
                    type='text'
                    name='effluentPressure'
                    id='effluentPressure'
                    inputText={unit.selectedUnits[3]}
                    onBlur={handleBlur}
                    onFocus={() => handleFocus(3)}
                    isFocused={isFocused === 3}
                    value={effluentPressure}
                    onChange={handleValueChange}
                  />
                  <InputReferenceText refText={`Ranges 0 - ${EquipmentRanges.effluentPressure.max}`} />
                </div>
              </div>
              <div>
                <CustomLabel label='Bulk Chemical Storage Tank Temperature' />
                <div className='input-field'>
                  <InputWithText
                    isError={isError(tankTemperature, 0, EquipmentRanges.tankTemperature.max)}
                    type='number'
                    name='tankTemperature'
                    id='tankTemperature'
                    inputText={unit.selectedUnits[2]}
                    onBlur={handleBlur}
                    onFocus={() => handleFocus(4)}
                    isFocused={isFocused === 4}
                    value={tankTemperature}
                    onChange={handleValueChange}
                  />
                  <InputReferenceText refText={`Ranges 0 - ${EquipmentRanges.tankTemperature.max}`} />
                </div>
              </div>
            </Card.Body>
          </StyledCard>
          {ixStore.vessel1 === 9 && (
            <div>
              <StyledCard className='equipment-settings-card'>
                <Card.Header>
                  <CustomHeading
                    fontFamily='DiodrumRegular'
                    fontSize='14px'
                    fontWeight='400'
                    color={colors.PrimaryDarkAquaMarine}
                    label='External Regenration Equipment Settings'
                  />
                  <Card.Title className='info-icon'>
                    <IconWithTooltip
                      label='Provide typical values for piping and distributor pressure drop, and the required outlet pressure.'
                      icon={<InfoIcon />}
                    />
                  </Card.Title>
                </Card.Header>
                <Card.Body className='equipment-body'>
                  <div>
                    <CustomLabel label='Backwash Tower Diameter' />
                    <div className='input-field'>
                      <InputWithText
                        isError={isError(backwashTowerDiameter, 0, EquipmentRanges.backwashTowerDiameter.max)}
                        type='number'
                        name='backwashTowerDiameter'
                        id='backwashTowerDiameter'
                        inputText={unit.selectedUnits[8]}
                        value={backwashTowerDiameter}
                        onBlur={handleBlur}
                        onChange={handleValueChange}
                      />
                      <InputReferenceText
                        refText={`Ranges ${EquipmentRanges.backwashTowerDiameter.min} -
                         ${EquipmentRanges.backwashTowerDiameter.max}`}
                      />
                    </div>
                  </div>
                  <div>
                    <CustomLabel label='SAC Regenration Vessel Diameter' />
                    <div className='input-field'>
                      <InputWithText
                        isError={isError(sacRegenVesselDia, 0, EquipmentRanges.sacRegenVesselDia.max)}
                        type='text'
                        name='sacRegenVesselDia'
                        id='sacRegenVesselDia'
                        inputText={unit.selectedUnits[8]}
                        onBlur={handleBlur}
                        onFocus={() => handleFocus(2)}
                        isFocused={isFocused === 2}
                        value={sacRegenVesselDia}
                        onChange={handleValueChange}
                      />
                      <InputReferenceText
                        refText={`Ranges ${EquipmentRanges.sacRegenVesselDia.min} -
                         ${EquipmentRanges.sacRegenVesselDia.max}`}
                      />
                    </div>
                  </div>
                  <div>
                    <CustomLabel label='SBA Regeneration Vessel Diameter' disabled={false} />
                    <div className='input-field'>
                      <InputWithText
                        isError={isError(sbaRegenVesselDia, 0, EquipmentRanges.sbaRegenVesselDia.max)}
                        type='text'
                        name='sbaRegenVesselDia'
                        id='sbaRegenVesselDia'
                        inputText={unit.selectedUnits[8]}
                        onBlur={handleBlur}
                        onFocus={() => handleFocus(3)}
                        isFocused={isFocused === 3}
                        value={sbaRegenVesselDia}
                        onChange={handleValueChange}
                      />
                      <InputReferenceText
                        refText={`Ranges ${EquipmentRanges.sbaRegenVesselDia.min} -
                         ${EquipmentRanges.sbaRegenVesselDia.max}`}
                      />
                    </div>
                  </div>
                </Card.Body>
              </StyledCard>
            </div>
          )}
          {error?.message && (
            <ProjectErrorPopup show={error.message} close={closeErrorMessag} message={error.message} />
          )}
        </div>
      </EquipmentStyled>
    </>
  );
};

export default Equipment;
