import styled from "styled-components";

import { colors, fontStyles } from "@common/styles/Theme";

import "@common/styles/notoSansFont.css";
import "@common/styles/diodrumFont.css";
const DismissStyled = styled.button`
  position: absolute;
  margin-top: -30px;
  z-index: 2;
  right: 8px;
  padding: 5px 10px;
  text-decoration: none;
  ${fontStyles.notoSans14}
  letter-spacing: 0.005em;
  color: ${colors.White};
  background: transparent;
  border: none;

  svg {
    margin-left: 2px;
    width: 20px;
    margin-top: -2px;
  }
`;

export default DismissStyled;
