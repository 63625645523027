import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactFlow, { ReactFlowProvider } from "reactflow";

import { useFullScreenLoader } from "@components/WPFullScreenLoader";

import { setUpdateCanvas } from "@features/feedwater/systemdesign/processDiagramSlice";

import CustomEdge from "./customnodes/CustomeEdge";

// TODO: Reuse the same component for feed technology component too
export const WPProcessFlowDiagram = ({ reactFlowProps, height }) => {
  const { nodes, edges } = useSelector(state => state.processDiagramSlice);
  const [wrapperDimensions, setWrapperDimensions] = useState({ width: "100%", height });
  const dispatch = useDispatch();
  const { isFullScreenLoaderVisible } = useFullScreenLoader();
  const divRef = useRef(null);

  useEffect(() => {
    const myObserver = new ResizeObserver(entries => {
      if (entries[0]) {
        const { width, height } = entries[0].contentRect;
        dispatch(setUpdateCanvas({ width, height }));
      }
    });

    if (divRef.current) {
      myObserver.observe(divRef.current);
    }
    return () => {
      if (divRef.current) {
        myObserver.unobserve(divRef.current);
      }
      myObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isFullScreenLoaderVisible) {
      setWrapperDimensions({ width: "750px", height });
    } else {
      setWrapperDimensions({ width: "100%", height });
    }
  }, [isFullScreenLoaderVisible]);

  CustomEdge;
  return (
    <>
      <div className='flex-center'>
        <div id='process-flow-diagram' style={{ ...wrapperDimensions }} ref={divRef}>
          <ReactFlowProvider>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              edgeTypes={{ customeEdge: CustomEdge }}
              preventScrolling={false}
              panOnDrag={false}
              panOnScroll={false}
              zoomOnPinch={false}
              zoomOnScroll={false}
              zoomOnDoubleClick={false}
              {...reactFlowProps}
            ></ReactFlow>
          </ReactFlowProvider>
        </div>
      </div>
    </>
  );
};
