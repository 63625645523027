import SVGContainerPath from "../SVGContainerPath";

const PrintIcon = ({ disabled }) => (
  <SVGContainerPath
    width='24'
    height='24'
    viewBox='0 0 36 37'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    disabled={disabled}
  >
    <path
      d='M30.375 15.5418H28.7679C28.5911 15.5418 28.4464 15.6864 28.4464 15.8632V17.4704C28.4464 17.6471 28.5911 17.7918 28.7679 17.7918H30.375C30.5518 17.7918 30.6964 17.6471 30.6964 17.4704V15.8632C30.6964 15.6864 30.5518 15.5418 30.375 15.5418ZM31.6607 11.3632H26.8393V2.84535C26.8393 2.66857 26.6946 2.52393 26.5179 2.52393H9.48214C9.30536 2.52393 9.16072 2.66857 9.16072 2.84535V11.3632H4.33929C2.56339 11.3632 1.125 12.8016 1.125 14.5775V27.7561C1.125 28.4672 1.69955 29.0418 2.41071 29.0418H9.16072V34.3454C9.16072 34.5221 9.30536 34.6668 9.48214 34.6668H26.5179C26.6946 34.6668 26.8393 34.5221 26.8393 34.3454V29.0418H33.5893C34.3004 29.0418 34.875 28.4672 34.875 27.7561V14.5775C34.875 12.8016 33.4366 11.3632 31.6607 11.3632ZM11.8929 5.25607H24.1071V11.3632H11.8929V5.25607ZM24.1071 31.9346H11.8929V20.8454H24.1071V31.9346ZM32.1429 26.3096H26.8393V18.1132H9.16072V26.3096H3.85714V14.5775C3.85714 14.3123 4.07411 14.0954 4.33929 14.0954H31.6607C31.9259 14.0954 32.1429 14.3123 32.1429 14.5775V26.3096Z'
      fill='black'
      fillOpacity='0.85'
    />
  </SVGContainerPath>
);

export default PrintIcon;
