import IconButton from "@components/IconButton";
import FolderOpenIcon from "@components/SVGs/FolderOpenIcon";
import ProjectIcon from "@components/SVGs/ProjectIcon";
import ProjectTickIcon from "@components/SVGs/ProjectTickIcon";

import CircularLoader from "@common/styles/components/circularLoader";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import { colors } from "@common/styles/Theme";

import "./index.css";

const ImportProjectLoader = ({ isLoading, projectName, fileSize, onFolderIconClick }) => (
  <div className='import-project-container'>
    <div className='import-Project-margin'>{isLoading ? <ProjectIcon /> : <ProjectTickIcon />}</div>
    <div>
      <CustomHeading label={projectName} fontSize='14px' />
      <CustomHeading label={`${fileSize} KB`} fontSize='12px' color={colors.Grey96} lineHeight='15px' />
    </div>
    <div className='import-loader-container'>
      {isLoading ? (
        <CircularLoader isLoading={isLoading} size='20px' />
      ) : (
        <IconButton className='open-import-project' tooltip='View All Imported' onClick={onFolderIconClick}>
          <FolderOpenIcon />
        </IconButton>
      )}
    </div>
  </div>
);

export default ImportProjectLoader;
