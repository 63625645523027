import React from "react";

const ErrorSmallIcon = ({ className, style }) => (
  <>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='11'
      viewBox='0 0 11 11'
      fill='none'
      style={style}
      className={className}
    >
      <g clipPath='url(#clip0_1757_166722)'>
        <path
          d='M4.91103 8.05357C4.91103 8.20986 4.97311 8.35975 5.08362 8.47026C5.19414 8.58077 5.34402 8.64286 5.50031 8.64286C5.6566 8.64286 5.80649 8.58077 5.917 8.47026C6.02751 8.35975 6.0896 8.20986 6.0896 8.05357C6.0896 7.89728 6.02751 7.7474 5.917 7.63688C5.80649 7.52637 5.6566 7.46429 5.50031 7.46429C5.34402 7.46429 5.19414 7.52637 5.08362 7.63688C4.97311 7.7474 4.91103 7.89728 4.91103 8.05357ZM5.10745 4.32143V6.58036C5.10745 6.63438 5.15165 6.67857 5.20567 6.67857H5.79495C5.84897 6.67857 5.89317 6.63438 5.89317 6.58036V4.32143C5.89317 4.26741 5.84897 4.22321 5.79495 4.22321H5.20567C5.15165 4.22321 5.10745 4.26741 5.10745 4.32143ZM10.9475 9.72322L5.84038 0.883929C5.76426 0.752567 5.6329 0.6875 5.50031 0.6875C5.36772 0.6875 5.23513 0.752567 5.16024 0.883929L0.0531013 9.72322C-0.0979032 9.98594 0.0911593 10.3125 0.393168 10.3125H10.6075C10.9095 10.3125 11.0985 9.98594 10.9475 9.72322ZM1.32866 9.38069L5.50031 2.15949L9.67196 9.38069H1.32866Z'
          fill='#E4001C'
        />
      </g>
      <defs>
        <clipPath id='clip0_1757_166722'>
          <rect width='11' height='11' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default ErrorSmallIcon;
