/* eslint-disable max-len */
import React from "react";

const MandatoryFieldIcon = () => (
  <>
    <svg width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.508 0.359999L4.228 3.048L6.916 2.292L7.112 3.58L4.536 3.79L6.202 6.002L4.998 6.66L3.808 4.21L2.73 6.66L1.484 6.002L3.122 3.79L0.574 3.58L0.77 2.292L3.43 3.048L3.136 0.359999H4.508Z'
        fill='#E4001C'
      />
    </svg>
  </>
);

export default MandatoryFieldIcon;
