import React from "react";

const PlusIcon = ({ disabled, changeIcon }) => (
  <>
    <svg
      width='32'
      className='create-folder-icon'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <line
        x1='16.0293'
        y1='8'
        x2='16.0293'
        y2='24'
        stroke={disabled ? "rgba(0,0,0,0.45)" : changeIcon ? "#007672" : "#000"}
      />
      <line
        x1='24'
        y1='15.9667'
        x2='8'
        y2='15.9667'
        stroke={disabled ? "rgba(0,0,0,0.45)" : changeIcon ? "#007672" : "#000"}
      />
    </svg>
  </>
);

export default PlusIcon;
