import { Modal } from "react-bootstrap";
import styled from "styled-components";

import "@common/styles/notoSansFont.css";
import "@common/styles/diodrumFont.css";

const AddToFolderStyled = styled(Modal)`
  background-color: rgba(0, 0, 0, 0.45);
  .modal-dialog {
    max-width: 900px;
  }
  .modal-content {
    border-radius: 2px;
    .modal-header {
      padding: 0;
      .header-create-project {
        display: flex;
        justify-content: space-between;
        background: #f8f8f8;
        width: 100%;
        margin: 0;
        border-radius: 4px 4px 0px 0px;
        padding: 17px 32px 14px 32px;
        .heading {
          .add-folder-sub-heading {
            margin-top: 6px;
          }
        }
        .close-icon {
          #btnClose {
            background-color: transparent;
            border: none;
            outline: none;
          }
        }
      }
    }
    .modal-body {
      padding: 24px 32px;
      .add-folder-container {
        display: block;
      }
      .create-folder {
        max-width: 50%;
        margin-left: auto;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 32px;
        cursor: pointer;

        :hover {
          color: #007672;

          line {
            stroke: #007672;
            stroke-width: 1.5;
          }
        }
      }

      .folders-container {
        max-height: 50vh;
        overflow-y: scroll;
        .no-folder-text {
          font-size: 12px;
        }
        .create-folder {
          cursor: pointer;
          max-width: 50%;
          margin-left: auto;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: 32px;
        }

        .folders-container {
          max-height: 50vh;
          overflow-y: scroll;
        }
      }
      .folder-radio {
        padding-bottom: 10px;
      }
      .folder-radio {
        padding-bottom: 10px;
      }
      .displayCenter {
        display: flex;
        justify-content: center;
      }

      .search-input-container {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
`;

export default AddToFolderStyled;
