import { reportStrings } from "@constants/report.strings";

//Note: key is mapped with the API response data key & the order of the rows is as per the below labels order
export const WPUfConfiguringationOptionsTableLabels = {
  standby_opton: reportStrings.standbyOption,
  standby_tank_option: reportStrings.standbyTankOption,
  FF_water_source: reportStrings.forwardFlushWaterSource,
  BW_water_source: reportStrings.backwashWaterSource,
  CEB_water_source: reportStrings.cebWaterSource,
  mini_CIP_water_source: reportStrings.miniCipWaterSource,
  CIP_water_source: reportStrings.cipWaterSource,
};

export const WPUfConfiguringationOptionsTableColumns = [
  {
    field: "col1",
    rowHeader: true,
  },
  {
    field: "col2",
  },
];
