import { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import useProjectDetails from "@hooks/useProjectDetails";

import { capitalize } from "@utils/appUtils";

import ConfirmationPopup, { DIALOG_TYPES } from "@components/ConfirmationPopup";
import CustomModal from "@components/CustomModal";
import WPAgGrid from "@components/WPAgGrid/WPAgGrid";
import { useToastNotification } from "@components/WPToastNotification";

import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";
import useChemicalLibrary from "../ChemicalLibraryHooks/useChemicalLibrary";

import { CHEMICAL_USAGE_TABLE_COLUMNS } from "./ChemicalLibraryChemicalUsage.const";
import { ChemicalUsageDlgFooter, ChemicalUsageDlgHeader, ChemicalUsageErrorBanner } from "./ChemicalUsageHeaderFooter";

import "./ChemicalLibraryChemicalUsage.scss";

const ChemicalLibraryChemicalUsage = ({ chemical, actionType, projectList, onClose }) => {
    const { navigatToProject, projectDetails } = useProjectDetails();
    const { loaderText, deleteProjects } = useChemicalLibrary();
    const { showSuccessNotification, showErrorNotification } = useToastNotification();
    const [rowData, setRowData] = useState([]);

    const [gridApi, setGridApi] = useState(null);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [deleteProjectData, setDeleteProjectData] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    const isCurrentProj = projectId => projectDetails?.projectID?.toString() === projectId?.toString();

    useEffect(() => {
        const projects = projectList.map(item => {
            if (isCurrentProj(item.projectId)) {
                item.projectName = `${item.projectName} ${CHEMICALS_LIBRARY_STRINGS.currentProj}`;
            }
            return item;
        });
        setRowData(projects);
    }, [projectList]);

    const onActionBtnClick = useCallback(
        ({ type, data }) => {
            if (type === "open-project-btn") {
                navigatToProject(data);
                onClose({ isCloseChemDlg: true });
            } else {
                const selectedRow = [data];
                setDeleteProjectData(selectedRow);
                const dialogData = getDeleteConfirmMessage(selectedRow);
                setDeleteConfirmation(dialogData);
            }
        },
        [navigatToProject, onClose],
    );

    const getUpdateProjects = deletedProjects => rowData.filter(({ projectId }) => !deletedProjects.includes(projectId));

    const onDeleteProjects = (message, projectIds) => {
        showSuccessNotification(message);
        const updatedProjects = getUpdateProjects(projectIds);
        if (updatedProjects.length === 0) {
            onClose({ isAllProjectsDeleted: true, actionType, chemical });
        } else {
            setRowData(updatedProjects);
        }
    };

    const handleDeleteConfirmClose = async action => {
        setDeleteConfirmation(null);
        if (action === "modal-confirm-btn") {
            const projects = deleteProjectData || selectedProjects;
            const projectIds = projects.map(({ projectId }) => projectId);
            const payload = { isPermanent: true, projectIds };
            const { isSuccess, message } = await deleteProjects(payload);
            if (isSuccess) {
                onDeleteProjects(message, projectIds);
            } else {
                showErrorNotification(message);
            }
        }
    };

    const getDeleteConfirmMessage = items => () => {
        const itemCount = items.length;
        const itemLabel = itemCount > 1 ? "Projects" : "Project";
        const { delete: Delete, deleteProjDlgTitle, deleteProjDlgDescription } = CHEMICALS_LIBRARY_STRINGS;
        return {
            header: deleteProjDlgTitle.replace("{%}", itemLabel),
            description: deleteProjDlgDescription,
            confirmBtn: `${Delete} ${capitalize(itemLabel)}`,
        };
    };

    const handleModalClose = useCallback(
        ({ target: { id } }) => {
            const dialogData = id === "deleteProjects" ? getDeleteConfirmMessage(selectedProjects) : null;
            dialogData ? setDeleteConfirmation(dialogData) : onClose();
        },
        [onClose, selectedProjects],
    );

    const onSelectionChanged = useCallback(() => {
        const selectedData = gridApi.getSelectedRows();
        setSelectedProjects(selectedData);
    }, [gridApi]);

    const onGridReady = useCallback(params => setGridApi(params.api), []);

    const isRowSelectable = rowNode =>
        rowNode.data ? !isCurrentProj(rowNode.data.projectId) : false;

    const rowSelection = useMemo(
        () => ({
            mode: "multiRow",
            enableSelectionWithoutKeys: true,
            enableClickSelection: false,
            isRowSelectable: isRowSelectable,
            //hideDisabledCheckboxes: true
        }),
        [projectDetails],
    );
    const defaultColDef = useMemo(
        () => ({
            deltaRowDataMode: true,
            getRowNodeId: data => data.id,
            suppressRowClickSelection: true,
        }),
        [],
    );

    const handleBackBtnClick = () => onClose();

    return (
        <>
            <CustomModal
                header={<ChemicalUsageDlgHeader onClose={handleBackBtnClick} actionType={actionType} />}
                onModalClose={handleModalClose}
                width='960px'
                isLoading={Boolean(loaderText)}
                loadingText={loaderText}
                modalFooter={
                    <ChemicalUsageDlgFooter onDialogClose={handleModalClose} isConfirmDisable={!selectedProjects.length} />
                }
            >
                <div className='chemical-usage-container'>
                    <ChemicalUsageErrorBanner chemical={chemical?.displayName} />
                    <WPAgGrid
                        context={{ onActionBtnClick }}
                        tableHeight='200px'
                        rowData={rowData}
                        rowSelection={rowSelection}
                        onGridReady={onGridReady}
                        columnDefs={CHEMICAL_USAGE_TABLE_COLUMNS}
                        defaultColDef={defaultColDef}
                        onSelectionChanged={onSelectionChanged}
                    />
                </div>
            </CustomModal>

            {deleteConfirmation && (
                <ConfirmationPopup
                    {...deleteConfirmation}
                    type={DIALOG_TYPES.ERROR}
                    onClose={handleDeleteConfirmClose}
                    cancelBtn={"Cancel"}
                />
            )}
        </>
    );
};

ChemicalLibraryChemicalUsage.propTypes = {
    chemical: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    projectList: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ChemicalLibraryChemicalUsage;
