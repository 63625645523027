import { Card } from "react-bootstrap";
import styled from "styled-components";

import { colors } from "../../Theme";

/**
 * The shouldForwardProp function is used to control which props are forwarded to the underlying DOM element.
 * This is particularly useful when you want to prevent certain props from being passed down to the DOM element,
 * which can cause unwanted behavior or warnings.
 *
 * In this specific case, the function is used to exclude the following props from being forwarded to the DOM element:
 * - backgroundColor
 * - borderRadius
 * - cardBorder
 * - paddingHeader
 *
 * These props are likely used for styling purposes within the styled component but are not valid HTML attributes.
 * By excluding them, you ensure that they do not end up on the DOM element, preventing potential issues.
 *
 * @param {string} prop - The prop name to check.
 * @param {function} defaultValidatorFn - The default validator function provided by styled-components.
 * @returns {boolean} - Returns true if the prop should be forwarded, false otherwise.
 */
const StyledCard = styled(Card).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !["backgroundColor", "borderRadius", "cardBorder", "paddingHeader"].includes(prop) && defaultValidatorFn(prop),
})`
  background-color: ${({ backgroundColor }) => backgroundColor || colors.White};
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: ${({ borderRadius }) => borderRadius || "4px"};
  border: ${({ cardBorder }) => cardBorder || "none"};

  .card-header {
    padding: ${({ paddingHeader }) => paddingHeader || "0px"};
    background-color: ${({ backgroundColor }) => backgroundColor || colors.White};
    border-bottom: none;
  }

  .dropdown-item-disabled {
    color: ${colors.Gray};
  }
`;

export default StyledCard;
