import styled from "styled-components";

import "@common/styles/notoSansFont.css";
import "@common/styles/diodrumFont.css";
const SmallLoaderStyled = styled.div`
  /* position: absolute; */
  margin-left: 50px;
  margin-top: 5px;
  z-index: 20;
  p {
    font-family: DiodrumRegular;
    font-size: 10px;
    font-weight: bold;
    color: #007672;
  }
`;
export default SmallLoaderStyled;
