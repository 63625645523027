/* eslint-disable max-len */
import React from "react";

const AccordionDownArrowIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.41427 4.55575C4.02374 4.16523 3.46972 4.08608 3.17683 4.37898C2.88394 4.67187 2.96308 5.22589 3.35361 5.61641L6.18203 8.44484C6.42536 8.68817 6.73216 8.81061 6.99971 8.79749C7.26737 8.81076 7.57436 8.68831 7.81782 8.44486L10.6462 5.61643C11.0368 5.22591 11.1159 4.67189 10.823 4.379C10.5301 4.0861 9.97611 4.16525 9.58558 4.55577L6.99993 7.14142L4.41427 4.55575Z'
        fill='black'
      />
    </svg>
  </>
);

export default AccordionDownArrowIcon;
