import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { WPUfPressureRatingsTableColumns as columns } from "./WPUfPressureRatingsTable.constants";

export const WPUfPressureRatingsTable = () => {
  const { uf_Power_ratings: rows } = useSelector(state => state.report.ufReportTableData);

  return (
    <WPTable
      sectionHeader={reportStrings.ufPressureRatings}
      columns={columns}
      rows={rows}
      hasUnitsRow
      footNotesList={[
        reportStrings.pressureRatingsTableFootNotes1,
        reportStrings.pressureRatingsTableFootNotes2,
        reportStrings.pressureRatingsTableFootNotes3,
      ]}
    />
  );
};
