import React from "react";

const TabletCloseMenuIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
      <path d='M4.72461 5.22498L25.86 26.3604' stroke='black' strokeWidth='2.5' strokeLinecap='round' />
      <path d='M3.72461 26.7749L24.86 5.63948' stroke='black' strokeWidth='2.5' strokeLinecap='round' />
    </svg>
  </>
);

export default TabletCloseMenuIcon;
