import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { WPUfChemicalsTableColumns as columns } from "./WPUfChemicalsTable.constants";

export const WPUfChemicalsTable = () => {
  const { Chemicals: rows } = useSelector(state => state.report.ufReportTableData);

  return <WPTable sectionHeader={reportStrings.chemicals} columns={columns} rows={rows} hasUnitsRow />;
};
