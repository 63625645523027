import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";

import { updateProjectInfo } from "./common/ProjectInfoSlice";
import { updateUserInfo } from "./common/UserInfoSlice";
import ErrorBoundary from "./common/utils/ErrorBoundary";
import FeedWaterHome from "./features/feedwater/FeedWaterHome";
import Home from "./features/home/Home";
import { clearAuthData, setAccessTokens, setUserAttributes } from "./features/login/AuthSlice";
import SalesforceCallback from "./features/login/SalesforceCallback";
import SalesforceLogin from "./features/login/SalesforceLogin";
import PrivateRoute from "./PrivateRoute";

function Routing() {
  const dispatch = useDispatch();

  if (JSON.parse(localStorage.getItem("accessTokens")) !== null) {
    dispatch(setAccessTokens(JSON.parse(localStorage.getItem("accessTokens"))));
    dispatch(setUserAttributes(JSON.parse(localStorage.getItem("loggedInData"))));
  }

  useEffect(() => {
    const ProjectInfo = JSON.parse(localStorage.getItem("ProjectInfo"));
    const UserInfo = JSON.parse(localStorage.getItem("UserInfo"));

    if (ProjectInfo) {
      dispatch(updateProjectInfo(ProjectInfo));
    }
    if (UserInfo) {
      dispatch(updateUserInfo(UserInfo));
    }
  }, []);

  const clearUserData = () => {
    dispatch(clearAuthData());
    window.localStorage.clear();
  };

  useEffect(() => clearUserData(), []);

  /* Ensures that only authenticated users can access private routes. */
  return (
    <Fragment>
      <div style={{ minHeight: "100vh" }}>
        <ErrorBoundary>
          <Routes>
            <Route path='/' element={<SalesforceLogin />}></Route>
            <Route exact path='/oauth2/callback/' element={<SalesforceCallback />} />

            <Route exact path='/Home' element={<PrivateRoute />}>
              <Route exact path='/Home' element={<Home />} />
            </Route>

            <Route exact path='/FeedWaterHome' element={<PrivateRoute />}>
              <Route exact path='/FeedWaterHome' element={<FeedWaterHome />} />
            </Route>
          </Routes>
        </ErrorBoundary>
      </div>
    </Fragment>
  );
}

export default Routing;
