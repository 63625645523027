import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { convertUptoDigits } from "@utils/appUtils";

import { WPTable } from "@components/WPTable";

import { WPUfStorageTanksTableColumns as columns } from "./WPUfStorageTanksTable.constants";

export const WPUfStorageTanksTable = () => {
  const { uf_Storage_tanks: rows } = useSelector(state => state.report.ufReportTableData);
  const { chemicalStorageTime, uFBWCEBStandbyOptionID } = useSelector(state => state.UFStore.data);
  return (
    <WPTable
      sectionHeader={reportStrings.ufStorageTanks}
      columns={columns}
      rows={rows}
      hasUnitsRow
      footNotesList={[
        uFBWCEBStandbyOptionID == 1
          ? reportStrings.storageTanksTableFootNotesNetFiltrate
          : reportStrings.storageTanksTableFootNotesBwDemand,
        reportStrings.storageTanksTableFootNotes2.replace("${count}", convertUptoDigits(chemicalStorageTime, 0)),
      ]}
    />
  );
};
