import { reportStrings } from "@constants/report.strings";

import { WPUfParametersDataMapping } from "../../WPUfParametersTables.constants";
import { generateStepRowData } from "../../WPUfParametersTablesUtils";

export const WPUfDupontCebParametersTableColumns = [
  {
    field: "col1",
    rowHeader: true,
    headerName: reportStrings.pumpAndValveConditions,
    width: "130px",
  },
  {
    field: "col2",
    headerName: reportStrings.operation,
    width: "75px",
  },
  {
    field: "col3",
    headerName: reportStrings.cebFullForm,
    colSpan: 10,
  },
  {
    field: "col4",
    colSpan: 0,
  },
  {
    field: "col5",
    colSpan: 0,
  },
  {
    field: "col6",
    colSpan: 0,
  },
  {
    field: "col7",
    colSpan: 0,
  },
  {
    field: "col8",
    colSpan: 0,
  },
  {
    field: "col9",
    colSpan: 0,
  },
  {
    field: "col10",
    colSpan: 0,
  },
  {
    field: "col11",
    colSpan: 0,
  },
  {
    field: "col12",
    colSpan: 0,
  },
  {
    field: "col13",
    headerName: reportStrings.returnToOpertaion + "<sup>d</sup>",
    width: "75px",
  },
];

export const WPUfDupontCebParametersTableRows = [
  {
    rowKey: "stepNumber",
    ...generateStepRowData(2, 11),
    col13: 1,
  },
  {
    rowKey: "stepLabel",
    col2: reportStrings.filtrationMode,
    col3: reportStrings.airInlet + "<sup>b</sup>",
    col4: reportStrings.draining,
    col5: reportStrings.backwash1,
    col6: reportStrings.backwash2,
    col7: reportStrings.soak,
    col8: reportStrings.airInlet + "<sup>b</sup>",
    col9: reportStrings.draining,
    col10: reportStrings.backwash1,
    col11: reportStrings.backwash2,
    col12: reportStrings.forwardFlush + "<sup>c</sup>",
    col13: reportStrings.filtrationMode,
  },
  {
    rowKey: "feedPump",
    col1: reportStrings.feedPump,
    col2: true,
    col12: true,
    col13: true,
  },
  {
    rowKey: "backwashPump",
    col1: reportStrings.backwashPump,
    col5: true,
    col6: true,
    col10: true,
    col11: true,
  },
  {
    rowKey: "chemDosingPump",
    col1: reportStrings.chemDosingPump,
  },
  {
    rowKey: "cebDosingPump",
    col1: reportStrings.cebDosingPump + "<sup>a</sup>",
    col5: true,
    col6: true,
  },
  {
    rowKey: "cipRecyclePump",
    col1: reportStrings.cipRecyclePump,
  },
  {
    rowKey: "feedValve",
    col1: reportStrings.feedValve,
    col2: true,
    col12: true,
    col13: true,
  },
  {
    rowKey: "filtrateValve",
    col1: reportStrings.filtrateValve,
    col2: true,
    col13: true,
  },
  {
    rowKey: "concentrateValve",
    col1: reportStrings.concentrateValve,
    col3: true,
    col4: true,
    col5: true,
    col8: true,
    col9: true,
    col10: true,
    col12: true,
  },
  {
    rowKey: "backwashInletValve",
    col1: reportStrings.backwashInletValve,
    col5: true,
    col6: true,
    col10: true,
    col11: true,
  },
  {
    rowKey: "drainValve",
    col1: reportStrings.drainValve,
    col4: true,
    col6: true,
    col9: true,
    col11: true,
  },
  {
    rowKey: "airInletValve",
    col1: reportStrings.airInletValve,
    col3: true,
    col8: true,
  },
  {
    rowKey: "duration",
    col1: reportStrings.duration,
    col2: WPUfParametersDataMapping.t_filtration_cycle,
    col3: WPUfParametersDataMapping.t_AS_CEB,
    col4: WPUfParametersDataMapping.t_Drain_CEB,
    col5: WPUfParametersDataMapping.t_BW1_CEB,
    col6: WPUfParametersDataMapping.t_BW2_CEB,
    col7: WPUfParametersDataMapping.t_CEB_soak,
    col8: WPUfParametersDataMapping.t_AS,
    col9: WPUfParametersDataMapping.t_Drain,
    col10: WPUfParametersDataMapping.t_BW1_CEB,
    col11: WPUfParametersDataMapping.t_BW2_CEB,
    col12: WPUfParametersDataMapping.t_FF,
    col13: WPUfParametersDataMapping.t_filtration_cycle,
  },
  {
    rowKey: "flowRate",
    col1: reportStrings.flowRate,
    col2: WPUfParametersDataMapping.Flux_Filter_actual,
    col3: WPUfParametersDataMapping.Flow_AS,
    col4: WPUfParametersDataMapping.drainFlow,
    col5: WPUfParametersDataMapping.Flux_CEB,
    col6: WPUfParametersDataMapping.Flux_CEB,
    col7: WPUfParametersDataMapping.flowZero,
    col8: WPUfParametersDataMapping.Flow_AS,
    col9: WPUfParametersDataMapping.drainFlow,
    col10: WPUfParametersDataMapping.Flux_BW,
    col11: WPUfParametersDataMapping.Flux_BW,
    col12: WPUfParametersDataMapping.Flow_FF,
    col13: WPUfParametersDataMapping.Flux_Filter_actual,
  },
];
