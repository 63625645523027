import React from "react";

import SVGContainerPath from "../SVGContainerPath";

const DeleteIcon = ({ disabled, width = 20, height = 20 }) => (
  <SVGContainerPath
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    disabled={disabled}
  >
    <g id='DeleteOutlined'>
      <path
        id='Vector'
        d='M6.60704 2.6755H6.42847C6.52668 2.6755 6.60704 2.59515 6.60704 2.49693V2.6755H13.3928V2.49693C13.3928 2.59515 13.4731 2.6755 13.5713 2.6755H13.3928V4.28264H14.9999V2.49693C14.9999 1.70898 14.3593 1.06836 13.5713 1.06836H6.42847C5.64052 1.06836 4.9999 1.70898 4.9999 2.49693V4.28264H6.60704V2.6755ZM17.857 4.28264H2.14275C1.74766 4.28264 1.42847 4.60184 1.42847 4.99693V5.71122C1.42847 5.80943 1.50882 5.88979 1.60704 5.88979H2.95525L3.50659 17.5639C3.54231 18.3251 4.17177 18.9255 4.93293 18.9255H15.0669C15.8303 18.9255 16.4575 18.3273 16.4932 17.5639L17.0445 5.88979H18.3928C18.491 5.88979 18.5713 5.80943 18.5713 5.71122V4.99693C18.5713 4.60184 18.2521 4.28264 17.857 4.28264ZM14.895 17.3184H5.10481L4.56463 5.88979H15.4352L14.895 17.3184Z'
      />
    </g>
  </SVGContainerPath>
);

export default DeleteIcon;
