import React from "react";

import TextElement from "./TextElement";

const BoldTextElement = React.memo(({ fill, x, y, children }) => (
  <TextElement fill={fill} fontSize='120' fontWeight='bold'>
    <tspan x={x} y={y}>
      {children}
    </tspan>
  </TextElement>
));
BoldTextElement.displayName = "BoldTextElement";

export default BoldTextElement;
