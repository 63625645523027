import { useMemo } from "react";

import { reportStrings } from "@constants/report.strings";

import EndPointNode from "@features/feedwater/systemdesign/customnodes/EndPointNode";
import TechnologyNode from "@features/feedwater/systemdesign/customnodes/TechnologyNode";
import { WPProcessFlowDiagram } from "@features/feedwater/systemdesign/FlowDiagram";

import { WPReportSectionHeader } from "../WPReportSectionHeader";

import "./WPReportSystemDiagram.scss";

// overriding default delete
function DeleteNode() {
  return <></>;
}

export const WPReportSystemDiagram = () => {
  const nodeTypes = useMemo(
    () => ({
      endPointNode: EndPointNode,
      techNode: TechnologyNode,
      deleteType: DeleteNode,
    }),
    [],
  );

  return (
    <div className='report-flow-diagram'>
      <WPReportSectionHeader text={reportStrings.processFlowDiagram} />
      <WPProcessFlowDiagram
        reactFlowProps={{
          nodeTypes,
          nodesDraggable: false,
          nodesConnectable: false,
          elementsSelectable: false,
          nodesFocusable: false,
          edgesFocusable: false,
          autoPanOnConnect: false,
          autoPanOnNodeDrag: false,
        }}
        height='100px'
      />
    </div>
  );
};
