import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import {
  CURRENCY_UNIT,
  DEFAULT_CURRENCY_SYMBOL,
  DEFAULT_EXCHANGE_RATE,
  METRIC_UNITS,
  UNITS,
} from "@constants/units.constant";

import GlobalUnitConversion from "@common/utils/GlobalUnitConversion";

const useUnitConversion = () => {
  const { GUnitConversionData, selectedUnits } = useSelector(state => ({
    GUnitConversionData: state.GUnitConversion.data,
    selectedUnits: state.projectInfo?.projectConfig?.unitConfig?.selectedUnits,
  }));

  const { currencyConfig } = useSelector(state => state.projectInfo.projectConfig);

  const activeUnits = useMemo(() => selectedUnits, [selectedUnits]);

  const { exchangeRate, activeCurrency } = useMemo(() => {
    const selectedCurrency = currencyConfig.selectedCurrency || {};
    return {
      activeCurrency: selectedCurrency.currencyUnit || DEFAULT_CURRENCY_SYMBOL,
      exchangeRate: Number(selectedCurrency.currencyValue) || DEFAULT_EXCHANGE_RATE,
    };
  }, [currencyConfig.selectedCurrency]);

  const convertUnit = useCallback(
    (value, targetUnitIdx, sourceUnit, fixedDigit = 2) => {
      let convertedVal = value;
      const targetUnit = selectedUnits[targetUnitIdx];
      if (value !== null && value !== undefined && targetUnit && targetUnit !== sourceUnit) {
        convertedVal = GlobalUnitConversion(GUnitConversionData, value, targetUnit, sourceUnit);
        convertedVal = fixedDigit ? convertedVal.toFixed(fixedDigit) : convertedVal;
      }
      return convertedVal;
    },
    [GUnitConversionData, selectedUnits],
  );

  const convertUnitInt = useCallback(
    (value, targetUnitIdx, sourceUnit) => convertUnit(value, targetUnitIdx, sourceUnit, 0),
    [convertUnit],
  );

  const unitConversionByName = useCallback((value, targetUnit, sourceUnit, fixedDigit = 2) => {
    let convertedVal = value;
    if (value !== null && value !== undefined && targetUnit !== sourceUnit) {
      convertedVal = GlobalUnitConversion(GUnitConversionData, value, targetUnit, sourceUnit);
      convertedVal = fixedDigit ? convertedVal.toFixed(fixedDigit) : convertedVal;
    }
    return convertedVal;
  }, []);

  const convertUnitNumber = useCallback(
    (value, targetUnit, sourceUnitIdx, fixedDigit = 2) => {
      const sourceUnit = selectedUnits[sourceUnitIdx];
      if (value === null || value === undefined || targetUnit === sourceUnit) return value;
      const convertedVal = GlobalUnitConversion(GUnitConversionData, value, targetUnit, sourceUnit);
      return fixedDigit ? +convertedVal.toFixed(fixedDigit) : convertedVal;
    },
    [GUnitConversionData, selectedUnits],
  );

  // function converts value from metric to user selected unit
  const convertFromMetric = useCallback(
    (value, unitType, fixedDigit = 2) => {
      const targetUnit = selectedUnits[unitType];
      const sourceUnit = METRIC_UNITS[unitType];
      return unitConversionByName(value, targetUnit, sourceUnit, fixedDigit);
    },
    [selectedUnits],
  );

  // from usd/unit to selectedCurrency/selectedUnit
  // ex: $/kg to €/lb
  const convertCurrencyPerUnitFromMetric = useCallback(
    (value, unit, precision = 2) => convertUnitPerUnitFromMetric(value, CURRENCY_UNIT, unit, precision),
    [selectedUnits, exchangeRate],
  );

  const convertUnitPerUnitToMetric = useCallback(
    (value, unitNumerator, unitDenominator, precision = 2) => {
      const targetUnitNumerator = METRIC_UNITS[unitNumerator];
      const targetUnitDenominator = METRIC_UNITS[unitDenominator];
      const sourceUnitNumerator = selectedUnits[unitNumerator];
      const sourceUnitDenominator = selectedUnits[unitDenominator];

      let valueNumerator;
      if (unitNumerator === CURRENCY_UNIT) {
        valueNumerator = parseFloat(value) / exchangeRate;
      } else {
        valueNumerator = Number(
          GlobalUnitConversion(GUnitConversionData, value, targetUnitNumerator, sourceUnitNumerator),
        );
      }

      // value is always 1
      const valueDenominator = Number(
        GlobalUnitConversion(GUnitConversionData, 1, targetUnitDenominator, sourceUnitDenominator),
      );

      const result = valueNumerator / valueDenominator;

      return result.toFixed(precision);
    },
    [selectedUnits, exchangeRate],
  );

  const convertCurrencyPerUnitToMetric = useCallback(
    (value, unit, precision = 2) => convertUnitPerUnitToMetric(value, CURRENCY_UNIT, unit, precision),
    [selectedUnits, exchangeRate],
  );

  // sometimes we need to convert unit per unit , ex: energy/volume, where volume can be in m3 or gal or L
  // ex: kWh/m3 to kWh/gal
  const convertUnitPerUnitFromMetric = useCallback(
    (value, unitNumerator, unitDenominator, precision = 2) => {
      const targetUnitNumerator = selectedUnits[unitNumerator];
      const targetUnitDenominator = selectedUnits[unitDenominator];
      const sourceUnitNumerator = METRIC_UNITS[unitNumerator];
      const sourceUnitDenominator = METRIC_UNITS[unitDenominator];

      let valueNumerator;
      if (unitNumerator === CURRENCY_UNIT) {
        valueNumerator = parseFloat(value) * exchangeRate;
      } else {
        valueNumerator = Number(
          GlobalUnitConversion(GUnitConversionData, value, targetUnitNumerator, sourceUnitNumerator),
        );
      }

      // value is always 1
      const valueDenominator = Number(
        GlobalUnitConversion(GUnitConversionData, 1, targetUnitDenominator, sourceUnitDenominator),
      );

      const result = valueNumerator / valueDenominator;

      return result.toFixed(precision);
    },
    [selectedUnits, exchangeRate],
  );

  // function converts value from user selected unit to metric
  const convertToMetric = useCallback(
    (value, unitType, fixedDigit = 2) => {
      const sourceUnit = selectedUnits[unitType];
      const targetUnit = METRIC_UNITS[unitType];
      return unitConversionByName(value, targetUnit, sourceUnit, fixedDigit);
    },
    [selectedUnits],
  );

  return {
    activeUnits,
    exchangeRate,
    activeCurrency,
    convertUnit,
    convertUnitInt,
    convertToMetric,
    convertFromMetric,
    convertUnitNumber,
    unitConversionByName,
    convertUnitPerUnitFromMetric,
    convertCurrencyPerUnitToMetric,
    convertCurrencyPerUnitFromMetric,
  };
};

export default useUnitConversion;
