import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

import CheckCircleIcon from "@common/icons/CheckCircleIcon";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import StyledModal from "@common/styles/components/modals/CustomModal";
import { colors } from "@common/styles/Theme";

const DefaultValueSaved = ({ show, close, parentModal }) => {
  // const[openModal, setOpenModal] =useState(true);

  const handleClose = () => {
    // setOpenModal(false);
    close(false);
  };

  useEffect(() => {
    // if(show===true){
    //   setOpenModal(true);
    //   close(false);
    // }

    if (show) {
      const timer = setTimeout(() => {
        handleClose();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [show]);

  return (
    <>
      <StyledModal show={show} onHide={handleClose} centered isSuccessPopUp={true} maxWidth='416px'>
        <Modal.Body>
          <div className='success-pop-up'>
            <div>
              <CheckCircleIcon />
            </div>
            <div>
              <CustomHeading
                fontFamily='DiodrumSemiBold'
                fontSize='16px'
                fontWeight='600'
                color={colors.Black}
                label='Default values updated successfully!'
              />
            </div>
          </div>
        </Modal.Body>
      </StyledModal>
    </>
  );
};

export default DefaultValueSaved;
