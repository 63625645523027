import React, { useMemo } from "react";

import InputWithText from "@common/styles/components/inputs/InputWithText";

import useChemicalUnitConversion from "../ChemicalLibraryHooks/useChemicalUnitConversion";

const ChemicalProjectCostRenderer = ({ data, context }) => {
  const { activeCurrency, convertChemicalCurrency } = useChemicalUnitConversion();

  const handleInputBlur = ({ target }) => {
    const { value } = target;
    if (context.onDataUpdate) {
      const bulkPrice = convertChemicalCurrency(value, true);
      context.onDataUpdate({ ...data, bulkPrice });
    }
  };

  const value = useMemo(() => convertChemicalCurrency(data.bulkPrice), [data.bulkPrice]);

  return (
    <div className='cost-input-container'>
      <InputWithText
        id={`checmical_cost_input_${data.id}`}
        inputText={activeCurrency}
        value={value}
        onBlur={handleInputBlur}
      />
    </div>
  );
};

export default ChemicalProjectCostRenderer;
