import React from "react";
import { Tooltip } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import styled from "styled-components";

import { colors, fontStyles } from "../../Theme";

const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    text-align: left;
    ${fontStyles.diodrum12}
    color: ${colors.White};
  }
`;

const StyledOverlayTrigger = styled(OverlayTrigger)`
  border-radius: 2px;
  text-align: left;
`;

const CustomTooltip = ({ text, children, placement = "top" }) => {
  const renderTooltip = () => <StyledTooltip id='tooltip'>{text}</StyledTooltip>;

  return text ? (
    <StyledOverlayTrigger placement={placement} overlay={renderTooltip()}>
      {children}
    </StyledOverlayTrigger>
  ) : (
    children
  );
};

export default CustomTooltip;
