import CHEMICALS_LIBRARY_STRINGS from "./chemicalLibrary.strings";

const { UNITS } = require("@constants/units.constant");

export const CHEMICAL_VALUE_MAX_FRACTIONS = 4;
export const CHEMICAL_PRICE_MAX_FRACTIONS = 3;

export const CHEMICAL_UNIT_TYPES = [UNITS.gallon, UNITS.pound, UNITS.kiloGram, UNITS.liter];
export const PROJECT_COST_FOOTER_BUTTONS = [
  //ToDo: This we need to enable later
  // {
  //   id: "makeDefaultPriceBtn",
  //   variant: "text",
  //   label: CHEMICALS_LIBRARY_STRINGS.makePriceNewDefault,
  // },
  {
    id: "addEditChemicalBtn",
    variant: "text",
    label: CHEMICALS_LIBRARY_STRINGS.addEditChemicals,
  },
  {
    id: "saveUpdatedChemicals",
    variant: "contained",
    label: CHEMICALS_LIBRARY_STRINGS.saveChanges,
  },
];

export const CHEMICAL_FIELDS = {
  id: "id",
  name: "name",
  symbol: "symbol",
  chemical: "chemical",
  costType: "costType",
  category: "category",
  isSystem: "isSystem",
  bulkPrice: "bulkPrice",
  categoryId: "categoryId",
  bulkDensity: "bulkDensity",
  displayName: "displayName",
  viewInProjects: "viewInProjects",
  bulkConcentration: "bulkConcentration",
};
