import React from "react";

import TextElement from "./TextElement";

const TextElementWithArrow = React.memo(({ x, y, d, text }) => (
  <>
    <path d={d} fill='#E61611' />
    <TextElement fill='#454545'>
      <tspan x={x} y={y}>
        {text}
      </tspan>
    </TextElement>
  </>
));
TextElementWithArrow.displayName = "TextElementWithArrow";

export default TextElementWithArrow;
