import React from "react";

import CustomLabel from "@common/styles/components/headings/CustomLabel";
import InputWithIcon from "@common/styles/components/inputs/InputWithIcon";
import InputWithText from "@common/styles/components/inputs/InputWithText";

const InputFieldWrapper = ({ label, type, id, name, defaultValue, inputText, disabled, value }) => (
  <div className='input-field-container'>
    <CustomLabel disabled={true} label={label} />
    {inputText ? (
      <InputWithText
        type={type}
        id={id}
        name={name}
        defaultValue={defaultValue}
        inputText={inputText}
        disabled={disabled}
        value={value}
      />
    ) : (
      <InputWithIcon type={type} id={id} name={name} defaultValue={defaultValue} value={value} disabled={disabled} />
    )}
  </div>
);

export default InputFieldWrapper;
