import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { UNIT_TYPES } from "@constants/units.constant";

import WPAgGrid from "@components/WPAgGrid/WPAgGrid";

import { CHEMICAL_FIELDS } from "../chemicalLibrary.constants";
import useChemicalUnitConversion from "../ChemicalLibraryHooks/useChemicalUnitConversion";

import { PROJECT_COST_TABLE_CONFIG } from "./ProjectCostTable.config";

const ProjectCostTable = ({ chemicals, onChemicalUpdate }) => {
    const gridRef = useRef();
    const { activeUnits, convertChemicalDensity } = useChemicalUnitConversion();
    const [columnDefs, setColumnDefs] = useState(PROJECT_COST_TABLE_CONFIG);

    const getRowId = params => params.data.id;

    const onDataUpdate = useCallback(
        newData => {
            if (gridRef.current) {
                const rowNode = gridRef.current.api.getRowNode(newData.id);
                rowNode.updateData(newData);
                onChemicalUpdate(newData);
            }
        },
        [gridRef, onChemicalUpdate],
    );

    const colDefs = useMemo(() => {
        const { DENSITY } = UNIT_TYPES;
        const { bulkDensity } = CHEMICAL_FIELDS;
        const densityUnit = activeUnits[DENSITY];

        return PROJECT_COST_TABLE_CONFIG.map(item => {
            const isBulkDensity = item.field === bulkDensity;
            return {
                ...item,
                headerName: isBulkDensity ? `${item.headerName} (${densityUnit})` : item.headerName,
                valueFormatter: isBulkDensity ? ({ value }) => convertChemicalDensity(value) : null
            };
        });
    }, [activeUnits]);

    useEffect(() => {
        setColumnDefs(colDefs);
    }, [colDefs]);

    return (
        <WPAgGrid
            gridRef={gridRef}
            columnDefs={columnDefs}
            rowData={chemicals}
            tableHeight='50vh'
            getRowId={getRowId}
            context={{ onDataUpdate }}
        />
    );
};

export default ProjectCostTable;
