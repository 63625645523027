import { useReportUtils } from "@features/report-new/useReportUtils";

import {
  WPUfPressureRatingsTableColumns as columns,
  WPUfPressureRatingsTableRowsData as rowsData,
  WPUfPressureRatingsTableUnitMapping as unitMapping,
} from "./WPUfPressureRatingsTable.constants";

export const useWPUfPressureRatingsTableData = () => {
  const { ufReportRespData, formatLabel, formatUnitLabel, convertReportDataValue, isSpecialFeatureVisible } =
    useReportUtils();
  const { uf_Power_ratings } = ufReportRespData || {};

  const convertToUfPressureRatingsTableData = () => {
    const firstRow = columns.reduce((acc, curr) => {
      acc[curr.field] = formatUnitLabel(unitMapping[curr.field]);
      return acc;
    }, {});

    const data = Object.entries(rowsData).reduce((acc, curr) => {
      const [rowKey, row] = curr;
      const { label, indentLevel, specialFeature } = row;

      if (specialFeature && !isSpecialFeatureVisible(specialFeature)) {
        return acc;
      }

      const singleRow = columns.reduce((acc, curr) => {
        const colKey = curr.field;
        if (colKey === "label") {
          acc.label = formatLabel(label, indentLevel);
        } else {
          const value = convertReportDataValue({
            value: uf_Power_ratings?.[rowKey]?.[colKey],
            unit: unitMapping[curr.field],
            returnCheckMark: colKey === "OKc",
          });
          acc[colKey] = value;
        }
        return acc;
      }, {});
      //TODO: Update oKc column to check mark need to check logic with middleware
      acc.push(singleRow);
      return acc;
    }, []);

    // adding unit row at the top
    data.unshift(firstRow);

    return data;
  };

  return { convertToUfPressureRatingsTableData };
};
