import React from "react";
import PropTypes from "prop-types";

import CustomCard from "@components/CustomCard";

import CustomHeading from "@common/styles/components/headings/CustomHeading";
import ToggleSwitch from "@common/styles/components/switches/CustomSwitch";
import { colors } from "@common/styles/Theme";

const CardWrapper = ({
  label,
  labelIcon,
  onToggleChange,
  isToggleChecked,
  children,
  tooltipLabel,
  disabled,
  toggleHidden,
}) => {
  const header = tooltipLabel ? label : null;
  const customHeader = tooltipLabel ? null : (
    <>
      <span className='header_label--container'>
        {labelIcon && labelIcon}
        <CustomHeading label={label} color={colors.PrimaryDarkAquaMarine} />
      </span>
      {!tooltipLabel && !toggleHidden && (
        <ToggleSwitch
          small
          id={label}
          name={label}
          onChange={onToggleChange}
          checked={isToggleChecked}
          disabled={disabled}
        />
      )}
    </>
  );

  return (
    <CustomCard className='card-container' customHeader={customHeader} tooltipLabel={tooltipLabel} header={header}>
      {children}
    </CustomCard>
  );
};

CardWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  labelIcon: PropTypes.node,
  onToggleChange: PropTypes.func.isRequired,
  isToggleChecked: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default CardWrapper;
