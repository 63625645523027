/* eslint-disable max-len */
import React from "react";

const ArrowRightDownIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='18' viewBox='0 0 30 18' fill='none'>
      <ellipse cx='0.666667' cy='7.14286' rx='0.666667' ry='0.642857' fill='black' />
      <ellipse cx='3.33366' cy='7.14286' rx='0.666667' ry='0.642857' fill='black' />
      <ellipse cx='0.666667' cy='11.0002' rx='0.666667' ry='0.642857' fill='black' />
      <ellipse cx='0.666667' cy='14.857' rx='0.666667' ry='0.642857' fill='black' />
      <ellipse cx='3.33366' cy='11.0002' rx='0.666667' ry='0.642857' fill='black' />
      <ellipse cx='3.33366' cy='14.857' rx='0.666667' ry='0.642857' fill='black' />
      <path
        d='M29.3536 4.85355C29.5488 4.65829 29.5488 4.34171 29.3536 4.14645L26.1716 0.964465C25.9763 0.769203 25.6597 0.769203 25.4645 0.964465C25.2692 1.15973 25.2692 1.47631 25.4645 1.67157L28.2929 4.5L25.4645 7.32843C25.2692 7.52369 25.2692 7.84027 25.4645 8.03553C25.6597 8.2308 25.9763 8.2308 26.1716 8.03553L29.3536 4.85355ZM14 5L29 5L29 4L14 4L14 5Z'
        fill='black'
      />
      <path
        d='M13.6464 17.8536C13.8417 18.0488 14.1583 18.0488 14.3536 17.8536L17.5355 14.6716C17.7308 14.4763 17.7308 14.1597 17.5355 13.9645C17.3403 13.7692 17.0237 13.7692 16.8284 13.9645L14 16.7929L11.1716 13.9645C10.9763 13.7692 10.6597 13.7692 10.4645 13.9645C10.2692 14.1597 10.2692 14.4763 10.4645 14.6716L13.6464 17.8536ZM13.5 4.5L13.5 17.5L14.5 17.5L14.5 4.5L13.5 4.5Z'
        fill='black'
      />
    </svg>
  </>
);

export default ArrowRightDownIcon;
