import React from "react";

const DissolvedSolutesSection = ({ label, value }) => (
  <div className='solutes'>
    <label htmlFor='dissolved-solutes'>{label}: </label>
    <span id='dissolved-solutes'> {value}</span>
  </div>
);

export default DissolvedSolutesSection;
