import html2canvas from "html2canvas";
import moment from "moment";

import { CHEMICALS_SYMBOL_MAPPING, DATE_FORMAT } from "@constants/global.constants";

import DupontLogger from "./DupontLogger";

const featureIdToField = {
  1: "mCIP",
  2: "CEB",
};

export const getDisabledUFTech = ufSpecialFeatureID => featureIdToField[ufSpecialFeatureID] || "";

export const convertUpto2Digits = value => (isNaN(parseFloat(value)) ? "0" : parseFloat(value).toFixed(2));

export const convertUptoDigits = (value, digits = 2) => {
  const tempValue = isNaN(value) ? 0 : parseFloat(value);
  return (tempValue && tempValue.toFixed(digits)) || 0;
};

/**
 * Changes the format of a chemical symbol based on a predefined mapping.
 * Replace each occurrence of the 'from' symbol with the 'to' symbol
 *
 * @param {string} chemicalSymbol - The chemical symbol to be formatted.
 * @return {string} - The formatted chemical symbol.
 */
export const changeChemicalFormat = chemicalSymbol => {
  if (!chemicalSymbol) return "0";

  for (const [from, to] of Object.entries(CHEMICALS_SYMBOL_MAPPING)) {
    chemicalSymbol = `${chemicalSymbol}`.replaceAll(from, to);
  }

  return chemicalSymbol;
};

/**
 * Converts a source object into a new object with keys appended by a type.
 * @param {Object} source - The source object.
 * @param {string} type - The type to append to each key.
 * @return {Object} - The new object with keys appended by the type.
 */
export const getChemicalObject = (source, type, disabledField) =>
  Object.entries(source).reduce((result, [key, value]) => {
    const paramValue = disabledField && key.includes(disabledField) ? "0" : value;
    result[`${key}_${type}`] = paramValue;
    return result;
  }, {});

export const getFolderName = foldername =>
  foldername?.length > 20 ? `${foldername?.substring(0, 20)}...` : foldername;

export const debounce = (callback, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback(...args);
    }, timeout);
  };
};

const validData = (data, label, flag) => {
  const den = flag ? "0" : 0;
  return data ? data[label] : den;
};

export const formatChemicalSymbol = (chemicalSymbol, flag, field = "symbol") =>
  changeChemicalFormat(validData(chemicalSymbol, field, flag)).toString();

export const calculateSum = arr => {
  const sum = arr.reduce((acc, val) => {
    const value = parseFloat(val) || 0;
    return acc + value;
  }, 0);
  return sum;
};

// function returns true if all doses are disabled or total dose value is 0
export const isDoseErr = ({ indicators, doseValues }) => {
  const isAllDosesDisabled = indicators.every(indicator => !indicator);
  const totalDoseValue = calculateSum(doseValues);
  return isAllDosesDisabled || totalDoseValue === 0;
};

export const loadExternalScript = url => {
  if (url) {
    const script = document.createElement("script");
    script.src = url;
    document.body.appendChild(script);
  }
};

export const isValueInRange = (value, ranges) => {
  if (isNaN(value) || !ranges) return false;
  const { minValue, maxValue } = ranges;

  const val = parseFloat(value);
  return val >= minValue && val <= maxValue;
};

export const groupBy = (array, key) => {
  if (!array || !key) return {};
  return array.reduce((acc, currentValue) => {
    const groupKey = currentValue[key];
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(currentValue);
    return acc;
  }, {});
};

/**
 * Converts an array of objects into an object, using a specified key from each object as the key for the new object.
 *
 * @param {Array} array - The array of objects to convert.
 * @param {string} key - The key to use for the new object.
 * @returns {Object} - The resulting object with keys derived from the specified key in each object.
 */
export const convertListToObj = (array, key) => {
  if (!array || !key) return {};
  return array.reduce((acc, item) => ({ ...acc, [item[key]]: item }), {});
};

/**
 * Clones an array and overrides specific objects within it based on provided overrides.
 *
 * @param {Array<Object>} array - The original array to be cloned and modified.
 * @param {Object} overrides - An object containing keys that match the `arrayObjkey` values in the array and values that are objects with properties to override.
 * @param {string} arrayObjkey - The key in the array objects that will be used to match against the keys in the overrides object.
 * @returns {Array<Object>} - A new array with the specified overrides applied.
 */
export const cloneArrayAndOverride = (array, overrides, arrayObjkey) => {
  const clonedArray = structuredClone(array);
  Object.entries(overrides).forEach(([overrideValueKey, OverrideValueData]) => {
    const arrayOverrideIndex = clonedArray.findIndex(obj => obj[arrayObjkey] === overrideValueKey);
    if (arrayOverrideIndex !== -1) {
      clonedArray[arrayOverrideIndex] = {
        ...clonedArray[arrayOverrideIndex],
        ...OverrideValueData,
      };
    }
  });
  return clonedArray;
};

/**
 * Capitalizes the first character of the input string.
 *
 * @param {string} value - The input string to be capitalized.
 * @return {string} - The input string with the first character capitalized.
 */
export const capitalize = value => {
  if (!value) return "";
  return value.charAt(0).toUpperCase() + value.slice(1);
};

/**
 * Formats a date using the moment library.
 *
 * @param {string|Date} date - The date to be formatted.
 * @param {string} format - The format string to use.
 * @return {string} - The formatted date string.
 */
export const formatDate = (date, format = DATE_FORMAT) => (date ? moment(date).format(format) : "-");

/**
 * Converts the HTML content of a specified selector to a Base64-encoded PNG image.
 *
 * @param {string} selector - The CSS selector of the HTML element to convert.
 * @param {Object} options - The options to pass to the html2canvas function.
 * @returns {Promise<string>} A promise that resolves to a Base64-encoded PNG image.
 * @throws Will log an error message if the conversion fails.
 */
export const convertHtmlToBase64 = async (selector, options) => {
  try {
    const generatedCanvas = await html2canvas(document.querySelector(selector), options);
    return generatedCanvas.toDataURL("image/png");
  } catch (error) {
    DupontLogger("error", "Error in converting system diagram to base64", error);
  }
};

/**
 * Combines multiple class names into a single string.
 *
 * @param {...string} classes - The class names to combine.
 * @returns {string} A single string with all the class names combined, separated by spaces.
 */
export const combineClassNames = (...classes) => classes.filter(Boolean).join(" ").trim();

/**
 * Checks if a value is null or undefined.
 *
 * @param {*} value - The value to check.
 * @returns {boolean} True if the value is null or undefined, false otherwise.
 */
export const isNullOrUndefined = value => value === null || value === undefined;

export const isProdEnv = () => process.env.REACT_APP_PROJECT_ENV?.toUpperCase() === "PROD";

/**
 * Downloads the provided data as a file with the specified file name.
 *
 * This function creates a Blob from the provided data, generates a URL for the Blob,
 * creates an anchor element, sets its href to the Blob URL, and triggers a download
 * by programmatically clicking the anchor element. After a short delay, it removes
 * the anchor element and revokes the Blob URL to free up memory.
 *
 * @param {BlobPart} data - The data to be downloaded.
 * @param {string} fileName - The name of the file to be downloaded.
 */
export const downloadBlobData = (data, fileName) => {
  const blob = new Blob([data]);
  const url = window.URL.createObjectURL(blob);
  const aTag = document.createElement("a");
  aTag.href = url;
  aTag.download = fileName;
  document.body.appendChild(aTag);
  aTag.click();
  setTimeout(() => {
    aTag.remove();
    window.URL.revokeObjectURL(url);
  }, 100);
};

/**
 * Swaps the positions of two elements in an array and updates their displayOrder properties.
 *
 * @param {Array} cases - The array of case objects.
 * @param {number} index1 - The index of the first element to swap.
 * @param {number} index2 - The index of the second element to swap.
 * @returns {Array} - A new array with the elements at index1 and index2 swapped and their displayOrder properties updated.
 */
export const swapData = (cases, index1, index2) => {
  const newCases = cases.map(caseItem => ({ ...caseItem }));
  [newCases[index1], newCases[index2]] = [newCases[index2], newCases[index1]];

  // Update displayOrder
  const tempDisplayOrder = newCases[index1].displayOrder;
  newCases[index1].displayOrder = newCases[index2].displayOrder;
  newCases[index2].displayOrder = tempDisplayOrder;

  return newCases;
};

/**
 * Memoizes a given function by caching its results.
 *
 * @param {Function} fn - The function to be memoized.
 * @returns {Function} - A new function that caches the results of the original function.
 *
 * The returned function takes any number of arguments and checks if the result for those arguments
 * is already in the cache. If it is, it returns the cached result. If not, it calls the original
 * function with the arguments, stores the result in the cache, and then returns the result.
 */
export const memoizedFun = fn => {
  const cache = {};
  return (...args) => {
    const key = args.toString();
    if (cache[key]) {
      return cache[key];
    }
    const result = fn(...args);
    cache[key] = result;
    return result;
  };
};

export const isEmptyObject = obj => Object.keys(obj).length === 0;

export const isSelectedTreatment = (treatmentName, systemDesignCaseTreatmentVM) =>
  systemDesignCaseTreatmentVM?.find(item => item.treatmentName === treatmentName);

export const truncateText = (text, length) => {
  if (text.length > length) {
    return `${text.substring(0, length)}...`;
  }
  return text;
};

export const getFileExtension = fileName => fileName.split(".").pop();
