/* eslint-disable max-len */
import React from "react";

const PencilIcon = () => (
  <>
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.1879 18.4261C5.24148 18.4261 5.29505 18.4207 5.34862 18.4127L9.85398 17.6225C9.90755 17.6118 9.95844 17.5877 9.99594 17.5475L21.3504 6.19303C21.3752 6.16824 21.3949 6.13881 21.4084 6.10641C21.4218 6.074 21.4287 6.03927 21.4287 6.00419C21.4287 5.96911 21.4218 5.93437 21.4084 5.90197C21.3949 5.86956 21.3752 5.84013 21.3504 5.81535L16.8986 1.36088C16.8477 1.30999 16.7808 1.2832 16.7084 1.2832C16.6361 1.2832 16.5692 1.30999 16.5183 1.36088L5.1638 12.7153C5.12362 12.7555 5.09951 12.8037 5.0888 12.8573L4.29862 17.3627C4.27256 17.5062 4.28187 17.6538 4.32574 17.7929C4.36962 17.932 4.44673 18.0583 4.5504 18.1609C4.72719 18.3323 4.94951 18.4261 5.1879 18.4261ZM6.99326 13.7546L16.7084 4.04213L18.6718 6.00553L8.95665 15.718L6.5754 16.1386L6.99326 13.7546Z'
        fill='white'
      />
    </svg>
  </>
);

export default PencilIcon;
