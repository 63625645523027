/* eslint-disable max-len */
import React from "react";

const DropRightIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.05575 7.58573C2.66523 7.97626 2.58608 8.53028 2.87898 8.82317C3.17187 9.11606 3.72589 9.03692 4.11641 8.64639L6.94484 5.81797C7.18817 5.57464 7.31061 5.26784 7.29749 5.00029C7.31076 4.73263 7.18831 4.42564 6.94486 4.18218L4.11643 1.35376C3.72591 0.963233 3.17189 0.884088 2.879 1.17698C2.5861 1.46987 2.66525 2.02389 3.05577 2.41442L5.64142 5.00007L3.05575 7.58573Z'
        fill='black'
        fillOpacity='0.85'
      />
    </svg>
  </>
);

export default DropRightIcon;
