import styled from "styled-components";

import waveLogo from "@common/assets/images/Wave-PRO-UF-Logo-02.svg";
const SystemDesignStyled = styled.div`
  width: 100%;
  background-image: url(${waveLogo});
  background-repeat: no-repeat;
  background-position: center 30%;
  background-size: 20%;
  background-blend-mode: soft-light;
  background-color: #f8f8f8;
`;

export default SystemDesignStyled;
