import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import useUnitConversion from "@hooks/useUnitConversion";

import { convertUptoDigits } from "@utils/appUtils";

import TextElement from "../UFDiagramsUtils/TextElement";

const VOLUMN_METRIC_UNIT = "m³";

const UFDiagramTankCIPDetails = ({ isInge }) => {
  const { unitConversionByName } = useUnitConversion();
  const { calcEngineData } = useSelector(state => state.UFStore);
  const [cipTankVolumn, setCIPTankVolumn] = useState(0);
  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig);

  useEffect(() => {
    const selectedUnit = unitConfig.selectedUnits[11];
    let value = calcEngineData.vol_CIP_Tank || 0;

    if (value && selectedUnit !== VOLUMN_METRIC_UNIT) {
      value = unitConversionByName(value, selectedUnit, VOLUMN_METRIC_UNIT, 3);
    }
    value = convertUptoDigits(value, 3);
    setCIPTankVolumn(`${value} ${selectedUnit}`);
  }, [unitConfig.selectedUnits, calcEngineData]);

  const xyPosition = useMemo(() => ({ x: isInge ? 2500 : 2450, y: isInge ? 1150 : 1125 }), [isInge]);

  return (
    <>
      <TextElement fill='white' fontSize='160'>
        <tspan {...xyPosition}>
          CIP
        </tspan>
        <tspan x={xyPosition.x - 30} y={xyPosition.y + 275}>
          Tank
        </tspan>
      </TextElement>
      <TextElement fill='white' fontSize='120'>
        <tspan x={xyPosition.x - 75} y={xyPosition.y + 550}>
          {cipTankVolumn}
        </tspan>
      </TextElement>
    </>
  );
};

export default UFDiagramTankCIPDetails;
