import WPHeading from "@components/WPHeading";
import { WPTypogrpahy } from "@components/WPTypography";

import ToggleSwitch from "@common/styles/components/switches/CustomSwitch";

import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";

import { ChemicalLibInputBox } from "./ChemicalLibFormControls";

import "./ChemicalLibraryAddEditDialog.scss";

const DisplaySection = ({ title, description }) => (
    <div className="show-in-projects">
        <WPHeading type="default" size="xSmall">{title}</WPHeading>
        <WPHeading type="default" secondary size="xxSmall" className="pt-1">{description}</WPHeading>
    </div>
);

const ChemicalLibAddEditDisplaySettings = ({ controls, onControlChange }) => {

    const handleToggleChange = ({ target }) => {
        const { id, checked } = target;
        onControlChange({ id, data: { value: checked } });
    };

    return <div className="display-settings-container fs-14">
        <WPTypogrpahy className="fs-14">{CHEMICALS_LIBRARY_STRINGS.displaySettings}</WPTypogrpahy>
        <div className="content">
            <DisplaySection
                title={CHEMICALS_LIBRARY_STRINGS.showInAllProjectsTitle}
                description={CHEMICALS_LIBRARY_STRINGS.showInAllProjectsDescription}
            />

            <div>
                <ChemicalLibInputBox control={controls.displayName} onControlChange={onControlChange} />
                <WPHeading type="default" secondary size="xxSmall" className="pt-1">{CHEMICALS_LIBRARY_STRINGS.dropdownLabelDescription}</WPHeading>
            </div>

            <ToggleSwitch
                small
                id="viewInProjects"
                name="viewInProjects"
                checked={controls.viewInProjects.value}
                onChange={handleToggleChange}
                disabled={controls.viewInProjects.disabled}
            />
        </div>
    </div>;
};

export default ChemicalLibAddEditDisplaySettings;