import React from "react";

const BigVerticalLinePhIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='10' height='150' viewBox='0 0 10 150' fill='none'>
      <line x1='9.5' y1='2.18557e-08' x2='9.49999' y2='150' stroke='#969696' />
      <line x1='10' y1='0.5' x2='-4.37112e-08' y2='0.499999' stroke='#969696' />
      <line x1='10' y1='149.5' x2='-5.50761e-08' y2='149.5' stroke='#969696' />
    </svg>
  </>
);

export default BigVerticalLinePhIcon;
