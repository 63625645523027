import React, { useState } from "react";

import { MAX_PROJECT_NAME_LENGTH, MIN_PROJECT_NAME_LENGTH } from "@constants/global.constants";

import { useCheckProjectExistence } from "@hooks/useCheckProjectExistence";

import { ImportProjectStrings } from "@utils/StringConstants";

import CustomModal from "@components/CustomModal";

import InputTextWithNameValidation from "@common/styles/components/inputs/inputTextWithNameValidation";

import "./index.css";

const EditProjectNamePopup = ({ projectName, handleClose, editedName, setEditedName, handleReplace, isNew }) => {
  const [doesProjectExist, setDoesProjectExist] = useState(false);
  const [checkProjectExistence, isCheckingProjectName] = useCheckProjectExistence();
  const isImportDisabled =
    !doesProjectExist ||
    isCheckingProjectName ||
    editedName?.trim() === "" ||
    editedName === null ||
    (editedName === projectName && !isNew) ||
    editedName.length < MIN_PROJECT_NAME_LENGTH;

  let errorMessage;

  if (editedName.trim() === "") {
    errorMessage = ImportProjectStrings.inputFieldCannotBeEmpty;
  } else if (editedName.length < MIN_PROJECT_NAME_LENGTH) {
    errorMessage = ImportProjectStrings.minLengthError(MIN_PROJECT_NAME_LENGTH);
  } else if (checkProjectExistence) {
    errorMessage = ImportProjectStrings.projectNameExistDoYouWantToReplace;
  } 

  const getReplaceBtnLabel = () =>
    editedName.length < MIN_PROJECT_NAME_LENGTH || editedName?.trim() === "" ? "" : ImportProjectStrings.replaceProject;

  return (
    <>
      <CustomModal
        header={ImportProjectStrings.renameYourProject}
        headerHelper={ImportProjectStrings.pleaseCheckTheFileName}
        confirmBtn='Import'
        onModalClose={handleClose}
        width='512px'
        confirmBtnDisabled={isImportDisabled}
      >
        <div className='edit-text'>
          <sup className='astrix'>*</sup>
          {ImportProjectStrings.editFileName}
        </div>

        <InputTextWithNameValidation
          value={projectName}
          doesProjectExist={doesProjectExist}
          onValidationChange={setDoesProjectExist}
          validateInput={checkProjectExistence}
          setEditedName={setEditedName}
          minLength={MIN_PROJECT_NAME_LENGTH}
          maxLength={MAX_PROJECT_NAME_LENGTH}
          errorMessage={errorMessage}
          handleReplace={handleReplace}
          replaceBtnLabel={getReplaceBtnLabel()}
        />
      </CustomModal>
    </>
  );
};

export default EditProjectNamePopup;
