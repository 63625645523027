import React from "react";

import ShortUpArrow from "@common/icons/ShortUpArrow";
import ShortDownArrow from "@common/icons/SortDownArrow";
import UnSortArrow from "@common/icons/SortUpandDownArrowIcon";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import { colors } from "@common/styles/Theme";

const SortOrder = ({ onChange, value }) => {
  const handleSortChange = option => {
    onChange(option);
  };

  const renderSortOption = (label, option, activeIcon) => {
    const isActive = option === value;
    return (
      <div>
        <CustomLabel label={label} className='sort-option' onClick={() => handleSortChange(option)}>
          {isActive ? activeIcon : <UnSortArrow />}
        </CustomLabel>
      </div>
    );
  };

  return (
    <div className='sort-wrapper'>
      <CustomHeading fontFamily='DiodrumSemiBold' label='Sort By' color={colors.PrimaryDarkAquaMarine} />
      {renderSortOption("Ascending", "asc", <ShortUpArrow />)}
      {renderSortOption("Descending", "desc", <ShortDownArrow />)}
    </div>
  );
};

export default React.memo(SortOrder);
