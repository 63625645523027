/* eslint-disable max-len */
import React from "react";

const CloseCircleIcon = () => (
  <>
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_181567_32359)'>
        <path
          d='M15.2574 7.14119C15.2574 7.03316 15.169 6.94476 15.0609 6.94476L13.4404 6.95213L10.9998 9.86173L8.56161 6.95459L6.93862 6.94722C6.83058 6.94722 6.74219 7.03316 6.74219 7.14365C6.74219 7.1903 6.75937 7.2345 6.78884 7.27133L9.98326 11.0771L6.78884 14.8805C6.75917 14.9165 6.74271 14.9615 6.74219 15.0082C6.74219 15.1162 6.83058 15.2046 6.93862 15.2046L8.56161 15.1972L10.9998 12.2876L13.4379 15.1948L15.0585 15.2021C15.1665 15.2021 15.2549 15.1162 15.2549 15.0057C15.2549 14.959 15.2377 14.9149 15.2083 14.878L12.0188 11.0747L15.2132 7.26887C15.2426 7.2345 15.2574 7.18784 15.2574 7.14119Z'
          fill='#FF4D4F'
        />
        <path
          d='M11 0.0241699C4.92545 0.0241699 0 4.94962 0 11.0242C0 17.0987 4.92545 22.0242 11 22.0242C17.0746 22.0242 22 17.0987 22 11.0242C22 4.94962 17.0746 0.0241699 11 0.0241699ZM11 20.1581C5.9567 20.1581 1.86607 16.0675 1.86607 11.0242C1.86607 5.98087 5.9567 1.89024 11 1.89024C16.0433 1.89024 20.1339 5.98087 20.1339 11.0242C20.1339 16.0675 16.0433 20.1581 11 20.1581Z'
          fill='#FF4D4F'
        />
      </g>
      <defs>
        <clipPath id='clip0_181567_32359'>
          <rect width='22' height='22' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default CloseCircleIcon;
