import React from "react";

const ActivityMonitorTriangle = () => (
  <>
    <svg width='36' height='10' viewBox='0 0 36 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M35.999 10L19.8588 0.502945C18.7192 -0.167647 17.2789 -0.167649 16.1392 0.502944L-0.000976562 9.99999L35.999 10Z'
        fill='white'
      />
      <circle cx='17.499' cy='7.25' r='2.5' fill='#007672' />
    </svg>
  </>
);

export default ActivityMonitorTriangle;
