import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import DupontLogger from "@utils/DupontLogger";

import ErrorMessage from "../headings/ErrorMessage";

import InputWithText from "./InputWithText";

import "./index.css";

/**
 * InputTextWithNameValidation component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.value - The current value of the input.
 * @param {Function} props.validateInput - The function used to validate the input.
 * @param {string} props.errorMessage - The error message to display when the input is invalid.
 * @param {Function} props.setEditedName - The function used to set the edited name.
 * @param {Function} props.onValidationChange - The function called when the input validation changes.
 * @param {Function} props.handleReplace - The function called when the "Replace Project" text is clicked.
 * @returns {JSX.Element} The rendered InputTextWithNameValidation component.
 */
const InputTextWithNameValidation = ({
  value,
  validateInput,
  errorMessage,
  setEditedName,
  minLength,
  maxLength,
  onValidationChange,
  handleReplace,
  replaceBtnLabel,
  ...props
}) => {
  const [timer, setTimer] = useState(null);
  const [inputValue, setInputValue] = useState(value);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const Logger = DupontLogger("InputTextWithNameValidation");

  const handleKeyDown = evt => ["+", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault();

  useEffect(() => {
    if (isValid !== null) {
      onValidationChange(isValid);
      setIsLoading(false);
    }
  }, [isValid]);

  useEffect(() => {
    if (value) {
      checkInputValidity(value);
    }
  }, [value]);

  const checkInputValidity = async name => {
    if (name === "") {
      return;
    }
    try {
      setIsLoading(true);
      const result = await validateInput(name);
      setIsValid(!result);
    } catch (error) {
      Logger.error("Error while validating input", error);
    } finally {
      setIsLoading(false);
    }
    if (name.length < minLength) {
      setIsValid(false);
    }
  };

  /** This function is called whenever the input value changes.
    It first clears any existing timer using clearTimeout(timer).
    It then sets a new timer using setTimeout to call checkInputValidity after 1 second.
    The reference to the new timer is stored using setTimer. */
  const handleInputChange = event => {
    const newValue = event.target.value;
    setInputValue(newValue);
    setEditedName(newValue);
    if (timer) {
      clearTimeout(timer);
    }

    setTimer(setTimeout(() => checkInputValidity(newValue), 1000));
  };

  const onCancelIconClick = () => {
    clearTimeout(timer);
    setInputValue("");
    setEditedName("");
    setIsValid(true);
  };

  return (
    <div>
      <InputWithText
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        isLoading={isLoading}
        isError={!isValid}
        minLength={minLength}
        maxLength={maxLength}
        showCancelIcon={!isLoading}
        onCancelIconClick={onCancelIconClick}
        {...props}
      />
      {!isValid && (
        <div className='display-flex'>
          <ErrorMessage texMsg={errorMessage} />
          {replaceBtnLabel && (
            <span className='replace-project-text' onClick={handleReplace}>
              {replaceBtnLabel}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

InputTextWithNameValidation.propTypes = {
  value: PropTypes.string.isRequired,
  validateInput: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onValidationChange: PropTypes.func.isRequired,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
};

export default InputTextWithNameValidation;
