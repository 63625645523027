import SVGContainerPath from "../SVGContainerPath";

const ArrowIconUp = () => (
  <SVGContainerPath width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      id='Icon'
      d='M9 5.16667L5 1M5 1L1 5.16667M5 1V11'
      stroke='#344054'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SVGContainerPath>
);

export default ArrowIconUp;
