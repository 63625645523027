import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { WPUfConfiguringationOptionsTableColumns as columns } from "./WPUfConfiguringationOptionsTable.constants";

export const WPUfConfiguringationOptionsTable = () => {
  const { uf_configuration_options: rows } = useSelector(state => state.report.ufReportTableData);

  return (
    <WPTable sectionHeader={reportStrings.ufConfigurationOptions} showHeader={false} columns={columns} rows={rows} />
  );
};
