import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { WPUfDupontUxaMiniCipParametersTableColumns as columns } from "./WPUfDupontUxaMiniCipParametersTable.constants";

export const WPUfDupontUxaMiniCipParametersTable = () => {
  const { uf_mcip_parameters: rows } = useSelector(state => state.report.ufReportTableData);

  return (
    <WPTable
      sectionHeader={reportStrings.mCipUxaParameters}
      columns={columns}
      rows={rows}
      remarksList={[
        reportStrings.mCipHighFrequencyRemark,
        reportStrings.mCipAuxillaryRemark,
        reportStrings.mCipDurationRemark,
        reportStrings.mCipSubstituteRemark,
      ]}
      footNotesList={[
        reportStrings.airFlowFootNote,
        reportStrings.repeatStepRemark.replace("${steps}", "3-8"),
        reportStrings.cipStepDurationFootNote.replace("${steps}", "3-6"),
        reportStrings.mCipFootNote4,
        reportStrings.cipDurationLongerFootNote,
        reportStrings.forwardFlushFlowFootNote,
        reportStrings.mayWastePermeateRemark,
      ]}
    />
  );
};
