/* eslint-disable max-len */
import { useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import useProjectDetails from "@hooks/useProjectDetails";
import { useResetProjectData } from "@hooks/useResetReduxData";

import { formatDate, truncateText } from "@utils/appUtils";

import AccordionDownArrowIcon from "@common/icons/AccordionDownArrowIcon";
import AccordionUpArrowIcon from "@common/icons/AccordionUpArrowIcon";
import CloseCircleGreenIcon from "@common/icons/CloseCircleGreenIcon";
import CloseIcon from "@common/icons/CloseIcon";
import FilterIconOpenProject from "@common/icons/FilterIconOpenProject";
import ShortUpArrow from "@common/icons/ShortUpArrow";
import ShortDownArrow from "@common/icons/SortDownArrow";
import SortUpandDownArrowIcon from "@common/icons/SortUpandDownArrowIcon";
import { updateActiveCase, updateCaseName, updateProjectInfo, updateProjectTitle } from "@common/ProjectInfoSlice";
import CustomRadioCheck from "@common/styles/components/checkboxs/CustomRadioCheck";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import DateInput from "@common/styles/components/inputs/DateInput";
import InputWithIcon from "@common/styles/components/inputs/InputWithIcon";
import CustomSelect from "@common/styles/components/selects/CustomSelect";
import { MyError } from "@common/utils/ErrorCreator";

import { useLazyGetAllDataQuery } from "@services/apiConfig";

import { updateOpenProject } from "./OpenProjectSlice";
import OpenProjectStyled from "./OpenProjectStyled";

import "react-calendar/dist/Calendar.css";
import "react-date-picker/dist/DatePicker.css";

const OpenProject = ({ show, close }) => {
  const { resetProjectData } = useResetProjectData();

  //get Data from Store
  const { countries, marketSegments } = useSelector(state => state.globalAppData);
  const openProjectInitialDataValue = useSelector(state => state.OpenProjectStore.openProjectInitialData);
  const ProjectInfoStore = useSelector(state => state.projectInfo.data);
  const UserInfoStore = useSelector(state => state.userInfo.data);
  const userId = UserInfoStore ? UserInfoStore.UserId : 1;

  // css variabels
  const [openModal, setOpenModal] = useState(true);
  const [collapse, setCollapse] = useState(false);
  const { navigatToProject } = useProjectDetails();

  //constants
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dateVariabel = new Date();
  const dropDown = [
    {
      label: "Equal",
      value: "equal",
    },

    {
      label: "Does not equal",
      value: "doesnotequal",
    },
    {
      label: "Begins with",
      value: "beginswith",
    },
    {
      label: "Ends with",
      value: "endswith",
    },
    {
      label: "Contains",
      value: "contains",
    },
    {
      label: "Does not contain",
      value: "doesnotcontains",
    },
  ];

  const filters = [
    {
      applied: false,
      comparator: "sort",
      column: "projectName",
      value: "",
    },
    {
      applied: false,
      comparator: "equal",
      column: "projectName",
      value: "",
    },
    {
      applied: false,
      comparator: "equal",
      column: "customer",
      value: "",
    },
    {
      applied: false,
      comparator: "equal",
      column: "segmentName",
      value: "",
    },
    {
      applied: false,
      comparator: "equal",
      column: "countryName",
      value: "",
    },
    {
      applied: false,
      comparator: "equal",
      column: "waveVersion",
      value: "",
    },
    {
      applied: false,
      comparator: "inbetween",
      column: "lastModified",
      minValue: dateVariabel,
      maxValue: dateVariabel,
    },
    {
      applied: false,
      comparator: "inbetween",
      column: "createdDate",
      minValue: dateVariabel,
      maxValue: dateVariabel,
    },
    {
      applied: false,
      comparator: "tecnology",
      andSelected: true,
      column: "technologyName",
      uf: false,
      ix: false,
      ro: false,
    },
  ];

  //functional variabels
  const [lstProjectData, setLstProjectData] = useState([]);
  const [lstCaseData, setLstCaseData] = useState([]);
  const [appliedFilter, setAppliedFilter] = useState(filters);

  //api variavels
  const [getAllData, response] = useLazyGetAllDataQuery();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [currentRow, setCurrentRow] = useState(null);
  const [isKeyboardScrolling, setIsKeyboardScrolling] = useState(false);

  useEffect(() => {
    if (show) {
      getAllData(`masterdata/api/v1/OpenProject?userID=${userId}`);
    }
  }, [show]);

  useEffect(() => {
    if (response.isLoading) {
      // Loading
    }
    if (response.isError) {
      throw new MyError("OpenProject Api Error", response.error.status, "ApiError");
    }
    if (response.isSuccess) {
      dispatch(updateOpenProject(response.data.lstProjectData));
      setLstProjectData(response.data.lstProjectData);
      setLstCaseData(response.data.lstCaseData);
    }
  }, [response]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (show === true) {
      setOpenModal(true);
      close(false);
      setAppliedFilter(filters);
    }
  }, [openModal]);

  const changeData = index => {
    if (selectedIndex === index) {
      setCollapse(!collapse);
      setCurrentRow(index);
    } else {
      setSelectedIndex(index);
      setCollapse(true);
      setCurrentRow(index);
    }
  };

  /**
   * Handles the click event for a project.
   * Resets project data, updates project info, navigates to the FeedWaterHome page, and closes the modal.
   *
   * @param {Object} project - The project object.
   * @param {number} caseId - The case ID.
   */
  const handleProjectClick = (project, caseId) => {
    resetProjectData();
    createUpdatedProjectInfo(project, caseId);
    navigateToFeedWaterHome(project);
    handleCloseModal();
  };

  /**
   * Creates an updated project information object.
   *
   * @param {Object} project - The project object.
   * @returns {Object} - The updated project information.
   */
  const createUpdatedProjectInfo = (project, caseId = 0) => {
    const { projectID, projectName, technologyName, isImported } = project;
    const projInfo = {
      ...ProjectInfoStore,
      projectID,
      caseId,
      projectName,
      Tchnology: technologyName,
      case: technologyName,
      isImported: isImported,
    };
    dispatch(updateProjectInfo(projInfo));
  };

  /**
   * Navigates to the FeedWaterHome page with the given project information.
   *
   * @param {Object} project - The project object.
   */
  const navigateToFeedWaterHome = project => {
    const { projectID, projectName, technologyName } = project;
    navigate("/FeedWaterHome", {
      state: {
        title: projectName,
        projectID,
        technologyName,
        caseID: 0,
      },
    });
  };
  const handleKeyDown = e => {
    if (e.key.startsWith("Arrow")) {
      setIsKeyboardScrolling(true);
    }
  };
  const handleKeyUp = () => {
    setIsKeyboardScrolling(false);
  };

  const handleCaseClick = (project, openCase) => {
    const title = `${truncateText(project.projectName)} - ${openCase.caseName}`;
    dispatch(updateCaseName(openCase.caseName));
    dispatch(updateActiveCase(openCase.caseID));
    dispatch(updateProjectTitle(title));
    navigatToProject({ projectId: project.projectID, projectName: title }, openCase);
    handleCloseModal();
  };

  //-----------------------------------------------------------
  //handle change function
  const handleChangeDropdown = e => {
    const { value, name } = e.target;
    setAppliedFilter(prev =>
      prev.map(item => {
        if (item.column == name) {
          return { ...item, comparator: value };
        } else {
          return item;
        }
      }),
    );
  };

  const handleChangeFilterInput = e => {
    const { value, name } = e.target;
    const applied = value ? true : false;
    setAppliedFilter(prev =>
      prev.map(item => {
        if (item.column == name && item.comparator != "sort") {
          return { ...item, value: value, applied: applied };
        } else {
          return item;
        }
      }),
    );
  };

  useEffect(() => {
    applyFilter();
  }, [appliedFilter]);

  const handleClearFilter = () => {
    setAppliedFilter(filters);
    setLstProjectData(openProjectInitialDataValue);
  };
  const sortTheData = flag => {
    const [column, value] = flag.split("_");
    setAppliedFilter(prev =>
      prev.map(item => {
        if (item.comparator == "sort") {
          return { ...item, value: value, column: column, applied: true };
        } else {
          return item;
        }
      }),
    );
  };

  const handleDateChange = (e, flag) => {
    const [name, value] = flag.split("_");
    setAppliedFilter(prev =>
      prev.map(item => {
        if (item.column == name) {
          return { ...item, [value]: new Date(e), applied: true };
        } else {
          return item;
        }
      }),
    );
  };

  const handleTechnologyChange = e => {
    const { name, checked } = e.target;
    setAppliedFilter(prev =>
      prev.map(item => {
        if (item.comparator == "tecnology") {
          const uf = name == "uf" ? checked : item.uf;
          const ix = name == "ix" ? checked : item.ix;
          const ro = name == "ro" ? checked : item.ro;
          return {
            ...item,
            [name]: checked,
            applied: uf || ix || ro,
          };
        } else {
          return item;
        }
      }),
    );
  };

  const applyFilter = () => {
    let filteredList = openProjectInitialDataValue;
    appliedFilter.map(filter => {
      if (filter.applied) {
        if (filter.comparator == "sort") {
          const newData = JSON.parse(JSON.stringify(filteredList));
          filteredList = newData.sort((a, b) => {
            const nameA = a[filter.column] ? a[filter.column] : "-";
            const nameB = b[filter.column] ? b[filter.column] : "-";
            return filter.value == "A" ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
          });
        }
        if (filter.comparator == "equal") {
          filteredList = filteredList.filter(item => item[filter.column].toLowerCase() == filter.value.toLowerCase());
        }

        if (filter.comparator == "doesnotequal") {
          filteredList = filteredList.filter(item => item[filter.column].toLowerCase() != filter.value.toLowerCase());
        }

        if (filter.comparator == "beginswith") {
          const beginPattern = new RegExp("^" + filter.value.toLowerCase());
          filteredList = filteredList.filter(item => beginPattern.test(item[filter.column].toLowerCase()));
        }
        if (filter.comparator == "endswith") {
          const endPattern = new RegExp(filter.value.toLowerCase() + "$");
          filteredList = filteredList.filter(item => endPattern.test(item[filter.column].toLowerCase()));
        }
        if (filter.comparator == "contains") {
          filteredList = filteredList.filter(item =>
            item[filter.column].toLowerCase().includes(filter.value.toLocaleLowerCase()),
          );
        }
        if (filter.comparator == "doesnotcontains") {
          filteredList = filteredList.filter(
            item => !item[filter.column].toLowerCase().includes(filter.value.toLocaleLowerCase()),
          );
        }
        if (filter.comparator == "tecnology") {
          const ufPattern = new RegExp("UF");
          const ixPattern = new RegExp("IX");
          const roPattern = new RegExp("RO");

          filteredList = filteredList.filter(item => {
            const ufValue = filter.uf ? ufPattern.test(item.technologyName) : filter.andSelected;
            const ixValue = filter.ix ? ixPattern.test(item.technologyName) : filter.andSelected;
            const roValue = filter.ro ? roPattern.test(item.technologyName) : filter.andSelected;

            return filter.andSelected ? ufValue && ixValue && roValue : ufValue || ixValue || roValue;
          });
        }
        if (filter.comparator == "inbetween") {
          const from = new Date(filter.minValue);
          const to = new Date(filter.maxValue).setHours(23, 59, 59, 999);

          filteredList = filteredList.filter(
            item => new Date(item[filter.column]) >= from && new Date(item[filter.column]) <= to,
          );
        }
      }
    });
    setLstProjectData(filteredList);
  };

  return (
    <>
      <OpenProjectStyled
        size='lg'
        show={show && openModal}
        onHide={handleCloseModal}
        centered
        dialogClassName='primary-modal'
        backdropClassName='dark-backdrop'
        backdrop='static'
        keyboard={false}
        aria-labelledby='contained-modal-title-vcenter'
      >
        <Row className='header-create-project bg-light d-flex'>
          <Col lg={10} md={10} sm={10} className='heading'>
            <h3>Open Project</h3>
            <p>
              Please click on the Project Name from the list below to open desired project. You can filter based on your
              preferences.
            </p>
          </Col>
          <Col lg={2} md={2} sm={2} className='close-icon'>
            <button onClick={handleCloseModal}>
              <CloseIcon />
            </button>
          </Col>
        </Row>
        <Row className='project-details-row g-0'>
          <div
            className={isKeyboardScrolling ? "project-details-scroll keyboard-scroll" : "project-details-scroll"}
            tabIndex='0'
            id='scrollBar'
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
          >
            <div className='projects-container'>
              <div className='project-name title card'>
                <h3 className='heading'>
                  Project Name
                  <span className='project-filter-icon'>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <FilterIconOpenProject />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className='ascending-wrapper'>
                          <h6 className='filter-heading'>Sort By</h6>
                          <span className='cursor' onClick={() => sortTheData("projectName_A")}>
                            {appliedFilter.find(
                              item =>
                                item.comparator == "sort" &&
                                item.column == "projectName" &&
                                item.applied &&
                                item.value == "A",
                            ) ? (
                              <ShortUpArrow />
                            ) : (
                              <SortUpandDownArrowIcon />
                            )}
                            Ascending
                          </span>
                          <span>
                            <div className='cursor' onClick={() => sortTheData("projectName_D")}>
                              {appliedFilter.find(
                                item =>
                                  item.comparator == "sort" &&
                                  item.column == "projectName" &&
                                  item.applied &&
                                  item.value == "D",
                              ) ? (
                                <ShortDownArrow />
                              ) : (
                                <SortUpandDownArrowIcon />
                              )}
                              Descending
                            </div>
                          </span>
                        </div>
                        <div className='filter-group'>
                          <h6 className='filter-heading'>Text Filters</h6>
                          <select
                            name='projectName'
                            onChange={handleChangeDropdown}
                            className='filter-sub-dropdown form-select'
                            id='colours'
                          >
                            {dropDown.map((option, index) => (
                              <option key={`project_${index}`} value={option.value} id={index}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className='filter-text-wrapper'>
                          <InputWithIcon
                            onChange={handleChangeFilterInput}
                            value={
                              appliedFilter.find(item => item.column == "projectName" && item.comparator != "sort")
                                .value
                            }
                            id='f'
                            name='projectName'
                            type='text'
                            inputText={<CloseCircleGreenIcon />}
                            unitBgColor='transparent'
                          />
                          <Button id='btnClear' onClick={handleClearFilter}>
                            Clear Filter <CloseIcon />
                          </Button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </span>
                </h3>
              </div>
              <div className='technology title card'>
                <h3 className='heading'>
                  Technologies
                  <span className='project-filter-icon'>
                    <Dropdown className='filter-dropdown'>
                      <Dropdown.Toggle variant='success' id='dropdown-basic'>
                        <FilterIconOpenProject />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className='ascending-wrapper'>
                          <h6 className='filter-heading'>Sort By</h6>
                          <span className='cursor' onClick={() => sortTheData("technologyName_A")}>
                            {appliedFilter.find(
                              item =>
                                item.comparator == "sort" &&
                                item.column == "technologyName" &&
                                item.applied &&
                                item.value == "A",
                            ) ? (
                              <ShortUpArrow />
                            ) : (
                              <SortUpandDownArrowIcon />
                            )}
                            Ascending
                          </span>
                          {/* </span> */}
                          <span>
                            <div className='cursor' onClick={() => sortTheData("technologyName_D")}>
                              {appliedFilter.find(
                                item =>
                                  item.comparator == "sort" &&
                                  item.column == "technologyName" &&
                                  item.applied &&
                                  item.value == "D",
                              ) ? (
                                <ShortDownArrow />
                              ) : (
                                <SortUpandDownArrowIcon />
                              )}
                              Descending
                            </div>
                          </span>
                        </div>

                        <div className='check-group-wrapper'>
                          <h6 className='filter-heading'>By Technologies</h6>
                          <div className='checkbox-group d-flex'>
                            <Form.Group className='mb-3' controlId='formBasicCheckbox'>
                              <CustomRadioCheck
                                type='checkbox'
                                name='uf'
                                checked={
                                  appliedFilter.find(
                                    item => item.column == "technologyName" && item.comparator != "sort",
                                  ).uf
                                }
                                value='UF'
                                label='UF'
                                onChange={handleTechnologyChange}
                              />
                            </Form.Group>
                            <Form.Group className='mb-3' controlId='formBasicCheckbox'>
                              <CustomRadioCheck
                                type='checkbox'
                                name='ix'
                                checked={
                                  appliedFilter.find(
                                    item => item.column == "technologyName" && item.comparator != "sort",
                                  ).ix
                                }
                                value='IX'
                                label='IX'
                                onChange={handleTechnologyChange}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        <div className='clear-filter'>
                          <Button id='btnClear' onClick={handleClearFilter}>
                            Clear Filter
                            <CloseIcon />
                          </Button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </span>
                </h3>
              </div>
              <div className='market-segment title card'>
                <h3 className='heading'>
                  Market Segment
                  <span className='project-filter-icon'>
                    <Dropdown className='filter-dropdown'>
                      <Dropdown.Toggle variant='success' id='dropdown-basic'>
                        <FilterIconOpenProject />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className='ascending-wrapper'>
                          <h6 className='filter-heading'>Sort By</h6>
                          <span className='cursor' onClick={() => sortTheData("segmentName_A")}>
                            {appliedFilter.find(
                              item =>
                                item.comparator == "sort" &&
                                item.column == "segmentName" &&
                                item.applied &&
                                item.value == "A",
                            ) ? (
                              <ShortUpArrow />
                            ) : (
                              <SortUpandDownArrowIcon />
                            )}
                            Ascending
                          </span>
                          {/* </span> */}
                          <span>
                            <div className='cursor' onClick={() => sortTheData("segmentName_D")}>
                              {appliedFilter.find(
                                item =>
                                  item.comparator == "sort" &&
                                  item.column == "segmentName" &&
                                  item.applied &&
                                  item.value == "D",
                              ) ? (
                                <ShortDownArrow />
                              ) : (
                                <SortUpandDownArrowIcon />
                              )}
                              Descending
                            </div>
                          </span>
                        </div>
                        <div className='filter-group'>
                          <h6 className='filter-heading'>By Project Market Segment</h6>
                          <CustomSelect
                            name='segmentName'
                            onChange={handleChangeFilterInput}
                            value={
                              appliedFilter.find(item => item.column == "segmentName" && item.comparator != "sort")
                                .value
                            }
                            required
                          >
                            <option value=''>Select Market Segment</option>
                            {marketSegments?.map((data, index) => (
                              <option key={`segment_${index}`} value={data.segmentName}>
                                {data.segmentName}
                              </option>
                            ))}
                          </CustomSelect>
                        </div>
                        <div className='clear-filter'>
                          <Button id='btnClear' onClick={handleClearFilter}>
                            Clear Filter
                            <CloseIcon />
                          </Button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </span>
                </h3>
              </div>
              <div className='customer title card'>
                <h3 className='heading'>
                  Customer
                  <span className='project-filter-icon'>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <FilterIconOpenProject />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className='ascending-wrapper'>
                          <h6 className='filter-heading'>Sort By</h6>
                          <span className='cursor' onClick={() => sortTheData("customer_A")}>
                            {appliedFilter.find(
                              item =>
                                item.comparator == "sort" &&
                                item.column == "customer" &&
                                item.applied &&
                                item.value == "A",
                            ) ? (
                              <ShortUpArrow />
                            ) : (
                              <SortUpandDownArrowIcon />
                            )}
                            Ascending
                          </span>
                          {/* </span> */}
                          <span>
                            <div className='cursor' onClick={() => sortTheData("customer_D")}>
                              {appliedFilter.find(
                                item =>
                                  item.comparator == "sort" &&
                                  item.column == "customer" &&
                                  item.applied &&
                                  item.value == "D",
                              ) ? (
                                <ShortDownArrow />
                              ) : (
                                <SortUpandDownArrowIcon />
                              )}
                              Descending
                            </div>
                          </span>
                        </div>
                        <div className='filter-group'>
                          <h6 className='filter-heading'>Text Filters</h6>
                          <select
                            name='customer'
                            onChange={handleChangeDropdown}
                            className='filter-sub-dropdown form-select'
                            id='colours'
                          >
                            {dropDown.map((option, index) => (
                              <option key={`customer_${index}`} value={option.value} id={index}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className='filter-text-wrapper'>
                          <InputWithIcon
                            onChange={handleChangeFilterInput}
                            value={
                              appliedFilter.find(item => item.column == "customer" && item.comparator != "sort").value
                            }
                            // id="filter-inside-input"
                            name='customer'
                            type='text'
                            inputText={<CloseCircleGreenIcon />}
                            unitBgColor='transparent'
                          />
                          <Button id='btnClear' onClick={handleClearFilter}>
                            Clear Filter <CloseIcon />
                          </Button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </span>
                </h3>
              </div>
              <div className='country title card'>
                <h3 className='heading'>
                  Country
                  <span className='project-filter-icon'>
                    <Dropdown className='filter-dropdown'>
                      <Dropdown.Toggle variant='success' id='dropdown-basic'>
                        <FilterIconOpenProject />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className='ascending-wrapper'>
                          <h6 className='filter-heading'>Sort By</h6>
                          <span className='cursor' onClick={() => sortTheData("countryName_A")}>
                            {appliedFilter.find(
                              item =>
                                item.comparator == "sort" &&
                                item.column == "countryName" &&
                                item.applied &&
                                item.value == "A",
                            ) ? (
                              <ShortUpArrow />
                            ) : (
                              <SortUpandDownArrowIcon />
                            )}
                            Ascending
                          </span>
                          {/* </span> */}
                          <span>
                            <div className='cursor' onClick={() => sortTheData("countryName_D")}>
                              {appliedFilter.find(
                                item =>
                                  item.comparator == "sort" &&
                                  item.column == "countryName" &&
                                  item.applied &&
                                  item.value == "D",
                              ) ? (
                                <ShortDownArrow />
                              ) : (
                                <SortUpandDownArrowIcon />
                              )}
                              Descending
                            </div>
                          </span>
                        </div>
                        <div className='filter-group'>
                          <h6 className='filter-heading'>By Country</h6>
                          <select
                            className='filter-sub-dropdown form-select'
                            id='colours'
                            name='countryName'
                            value={
                              appliedFilter.find(item => item.column == "countryName" && item.comparator != "sort")
                                .value
                            }
                            onChange={handleChangeFilterInput}
                          >
                            <option value={""}>Select Country</option>
                            {countries?.map((data, index) => (
                              <option key={`country_${index}`} value={data.countryName}>
                                {data.countryName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className='clear-filter'>
                          <Button id='btnClear' onClick={handleClearFilter}>
                            Clear Filter
                            <CloseIcon />
                          </Button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </span>
                </h3>
              </div>
              <div className='last-modified title card'>
                <h3 className='heading'>
                  Last Modified
                  <span className='project-filter-icon'>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <FilterIconOpenProject />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className='ascending-wrapper'>
                          <h6 className='filter-heading'>Sort By</h6>
                          <span className='cursor' onClick={() => sortTheData("lastModified_A")}>
                            {appliedFilter.find(
                              item =>
                                item.comparator == "sort" &&
                                item.column == "lastModified" &&
                                item.applied &&
                                item.value == "A",
                            ) ? (
                              <ShortUpArrow />
                            ) : (
                              <SortUpandDownArrowIcon />
                            )}
                            Ascending
                          </span>
                          {/* </span> */}
                          <span>
                            <div className='cursor' onClick={() => sortTheData("lastModified_D")}>
                              {appliedFilter.find(
                                item =>
                                  item.comparator == "sort" &&
                                  item.column == "lastModified" &&
                                  item.applied &&
                                  item.value == "D",
                              ) ? (
                                <ShortDownArrow />
                              ) : (
                                <SortUpandDownArrowIcon />
                              )}
                              Descending
                            </div>
                          </span>
                        </div>
                        <div className='date-wrapper'>
                          <div>
                            <CustomLabel label='From' />
                            <DateInput
                              onChange={e => handleDateChange(e, "lastModified_minValue")}
                              value={
                                appliedFilter.find(item => item.column == "lastModified" && item.comparator != "sort")
                                  .minValue
                              }
                              name='lastModified_minValue'
                            />
                          </div>
                          <div>
                            <CustomLabel label='To' />
                            <DateInput
                              onChange={e => handleDateChange(e, "lastModified_maxValue")}
                              value={
                                appliedFilter.find(item => item.column == "lastModified" && item.comparator != "sort")
                                  .maxValue
                              }
                              name='lastModified_maxValue'
                            />
                          </div>
                        </div>
                        <Button id='btnClear' onClick={handleClearFilter}>
                          Clear Filter
                          <CloseIcon />
                        </Button>
                      </Dropdown.Menu>
                    </Dropdown>
                  </span>
                </h3>
              </div>
              <div className='date-created title card'>
                <h3 className='heading'>
                  Date Created
                  <span className='project-filter-icon'>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <FilterIconOpenProject />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className='ascending-wrapper'>
                          <h6 className='filter-heading'>Sort By</h6>
                          <span className='cursor' onClick={() => sortTheData("createdDate_A")}>
                            {appliedFilter.find(
                              item =>
                                item.comparator == "sort" &&
                                item.column == "createdDate" &&
                                item.applied &&
                                item.value == "A",
                            ) ? (
                              <ShortUpArrow />
                            ) : (
                              <SortUpandDownArrowIcon />
                            )}
                            Ascending
                          </span>
                          {/* </span> */}
                          <span>
                            <div className='cursor' onClick={() => sortTheData("createdDate_D")}>
                              {appliedFilter.find(
                                item =>
                                  item.comparator == "sort" &&
                                  item.column == "createdDate" &&
                                  item.applied &&
                                  item.value == "D",
                              ) ? (
                                <ShortDownArrow />
                              ) : (
                                <SortUpandDownArrowIcon />
                              )}
                              Descending
                            </div>
                          </span>
                        </div>
                        <div className='date-wrapper'>
                          <div>
                            <CustomLabel label='From' />
                            <DateInput
                              onChange={e => handleDateChange(e, "createdDate_minValue")}
                              value={
                                appliedFilter.find(item => item.column == "createdDate" && item.comparator != "sort")
                                  .minValue
                              }
                              name='createdDate_minValue'
                            />
                          </div>
                          <div>
                            <CustomLabel label='To' />
                            <DateInput
                              onChange={e => handleDateChange(e, "createdDate_maxValue")}
                              value={
                                appliedFilter.find(item => item.column == "createdDate" && item.comparator != "sort")
                                  .maxValue
                              }
                              name='createdDate_maxValue'
                            />
                          </div>
                        </div>
                        <Button id='btnClear' onClick={handleClearFilter}>
                          Clear Filter
                          <CloseIcon />
                        </Button>
                      </Dropdown.Menu>
                    </Dropdown>
                  </span>
                </h3>
              </div>
              <div className='wave-version title card'>
                <h3 className='heading'>
                  Version
                  <span className='project-filter-icon'>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <FilterIconOpenProject />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className='ascending-wrapper'>
                          <h6 className='filter-heading'>Sort By</h6>
                          <span className='cursor' onClick={() => sortTheData("waveVersion_A")}>
                            {appliedFilter.find(
                              item =>
                                item.comparator == "sort" &&
                                item.column == "waveVersion" &&
                                item.applied &&
                                item.value == "A",
                            ) ? (
                              <ShortUpArrow />
                            ) : (
                              <SortUpandDownArrowIcon />
                            )}
                            Ascending
                          </span>
                          {/* </span> */}
                          <span>
                            <div className='cursor' onClick={() => sortTheData("waveVersion_D")}>
                              {appliedFilter.find(
                                item =>
                                  item.comparator == "sort" &&
                                  item.column == "waveVersion" &&
                                  item.applied &&
                                  item.value == "D",
                              ) ? (
                                <ShortDownArrow />
                              ) : (
                                <SortUpandDownArrowIcon />
                              )}
                              Descending
                            </div>
                          </span>
                        </div>
                        <div className='filter-group'>
                          <h6 className='filter-heading'>Text Filters</h6>
                          <select
                            name='waveVersion'
                            onChange={handleChangeDropdown}
                            className='filter-sub-dropdown form-select'
                            id='colours'
                          >
                            {dropDown.map((option, index) => (
                              <option key={`app_version_${index}`} value={option.value} id={index}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <input
                          onChange={handleChangeFilterInput}
                          type='text'
                          name='waveVersion'
                          value={
                            appliedFilter.find(item => item.column == "waveVersion" && item.comparator != "sort").value
                          }
                          id='filter-inside-input'
                          className='form-control'
                        />
                        <Button id='btnClear' onClick={handleClearFilter}>
                          Clear Filter <CloseIcon />
                        </Button>
                        {/* <input
                          type="text"
                          name=""
                          id="filter-inside-input"
                          className="form-control"
                        />
                        <Button id="btnClear" onClick={clearFilter}>
                          Clear Filter <CloseIcon />
                        </Button> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </span>
                </h3>
              </div>
            </div>
            <div className='projects-records-data'>
              {lstProjectData.map((project, index) => (
                <>
                  <div className='open-project-data'>
                    <div className='project-name-details title card'>
                      <span>
                        <span onClick={() => changeData(index)} className='accordion-icon'>
                          {collapse && currentRow === index ? <AccordionUpArrowIcon /> : <AccordionDownArrowIcon />}
                        </span>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip id='tooltip-top'>{project.projectName}</Tooltip>}
                        >
                          <h3 className='project-title-name' onClick={() => handleProjectClick(project)}>
                            {project.projectName.length > 15
                              ? `${project.projectName.substring(0, 15)}...`
                              : project.projectName}
                          </h3>
                        </OverlayTrigger>
                      </span>
                    </div>
                    <div className='technology-details title card'>
                      <span>{project.technologyName}</span>
                    </div>
                    <div className='market-segment-details title card'>
                      <span>{project.segmentName}</span>
                    </div>
                    <div className='customer-details title card'>
                      <span>{project.customer}</span>
                    </div>
                    <div className='country-details title card'>
                      <span>{project.countryName}</span>
                    </div>
                    <div className='last-modified title card'>
                      <span>{formatDate(project.lastModified)}</span>
                    </div>
                    <div className='date-created title card'>
                      <span>{formatDate(project.createdDate)}</span>
                    </div>
                    <div className='wave-Version title card'>
                      <span>{project.waveVersion ? project.waveVersion : "-"}</span>
                    </div>
                  </div>
                  {selectedIndex === index &&
                    collapse &&
                    lstCaseData.map(data => (
                      <>
                        {data.projectID === project.projectID && (
                          <div className='open-project-data'>
                            <div className='project-child-name-details title card'>
                              <span>
                                <h3
                                  className='project-title-name case-name'
                                  onClick={() => handleCaseClick(project, data)}
                                >
                                  {data.caseName ? ` - ${data.caseName}` : "-"}
                                </h3>
                              </span>
                            </div>
                            <div className='technology-details title card'>
                              <span>{data.technologyName ? data.technologyName : "-"}</span>
                            </div>
                            <div className='market-segment-details title card'>
                              <span>{data.segmentName ? data.segmentName : "-"}</span>
                            </div>
                            <div className='customer-details title card'>
                              <span>{data.customer ? data.customer : "-"}</span>
                            </div>
                            <div className='country-details title card'>
                              <span>{data.countryName ? data.countryName : "-"}</span>
                            </div>
                            <div className='last-modified title card'>
                              <span>{formatDate(data.lastModified)}</span>
                            </div>
                            <div className='date-created title card'>
                              <span>{formatDate(data.dateCreated)}</span>
                            </div>
                            <div className='wave-Version title card'>
                              <span>{data.waveVersion ? data.waveVersion : "-"}</span>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                </>
              ))}
            </div>
          </div>
        </Row>
      </OpenProjectStyled>
    </>
  );
};

export default OpenProject;
