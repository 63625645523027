import { useEffect, useState } from "react";
import { Card, Dropdown, Modal } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { Draggable } from "react-drag-and-drop";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteRequest } from "src/api";

import API_URLS from "@constants/api.urls";
import { CustomModalButtons, PROJECT_NAME_MAX_LENGTH, PROJECT_TYPE } from "@constants/global.constants";
import { APP_TOAST_NOTIFICATION } from "@constants/message.constants";

import { useExportProject } from "@hooks/useExportProject";

import { getFolderName } from "@utils/appUtils";
import { CommonButtonStrings, FolderStrings, ProjectStrings } from "@utils/StringConstants";

import ConfirmationPopup, { DIALOG_TYPES } from "@components/ConfirmationPopup";
import WPHeading from "@components/WPHeading";
import { useToastNotification } from "@components/WPToastNotification";

import Thumbnail_IX from "@common/assets/images/Thumbnail_IX.svg";
import Thumbnail_UF from "@common/assets/images/Thumbnail_UF.svg";
import CloseCircleGreenIcon from "@common/icons/CloseCircleGreenIcon";
import CloseCircleRedIcon from "@common/icons/CloseCircleRedIcon";
import CloseIcon from "@common/icons/CloseIcon";
import CreateNewProjectIcon from "@common/icons/CreateNewProjectIcon";
import FavoriteIcon from "@common/icons/FavoriteIcon";
import FolderIcon from "@common/icons/FolderIcon";
import LockProjectIcon from "@common/icons/LockProjectIcon";
import MoreVertIcon from "@common/icons/MoreVertIcon";
import SharedFileIcon from "@common/icons/SharedFileIcon";
import { updateProjectInfo } from "@common/ProjectInfoSlice";
import StandardPrimaryButton from "@common/styles/components/buttons/standard/StandardPrimaryButton";
import StandardSecondaryButton from "@common/styles/components/buttons/standard/StandardSecondaryButton";
import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import ErrorMessage from "@common/styles/components/headings/ErrorMessage";
import InputWithIcon from "@common/styles/components/inputs/InputWithIcon";
import StyledModal from "@common/styles/components/modals/CustomModal";
import { colors } from "@common/styles/Theme";
import { MyError } from "@common/utils/ErrorCreator";
import FavRemovePopup from "@common/utils/FavRemovePopup";
import Loader from "@common/utils/Loader";

import { useDeleteDataRecordMutation, useLazyGetAllDataQuery, useUpdateDataMutation } from "@services/apiConfig";

import { isIXTechnology } from "@features/feedwater/ix/IXDUtilityFunction";

import { btnlist, sortData2, Tempbtnlist, updateLoader, updateSubTechnology } from "../home/CardListSlice";
import CreateNewProjectModal from "../modals/CreateNewProjectModal";
import SendProjectModal from "../modals/SendProjectModal";

import AddToFolder from "./AddToFolder";
import CardDetailsStyled from "./CardDetailsStyled";
import DeleteProjectPopup from "./DeleteProjectPopup";

import "react-toastify/dist/ReactToastify.css";

const CardTiles = () => {
  const UserInfoStore = useSelector(state => state.userInfo.data);
  const userID = UserInfoStore ? UserInfoStore.UserId : 0;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Cardstate, setCard] = useState([]);
  const StoreData = useSelector(state => state.cardlist.data);
  const StoreSidLeftpanel = useSelector(state => state.leftpanel.data);
  const activeMenu = useSelector(state => state.leftpanel.activeMenu);
  const { pTitle } = useSelector(state => state.cardlist);
  const FolderProjectIDLeftpanel = useSelector(state => state.FolderProjectleftpanel.data);
  const [flagstate, setflagstate] = useState(true);
  const [getData, responseInfo] = useLazyGetAllDataQuery();
  const [getSubTechnology, responseSubTechnology] = useLazyGetAllDataQuery();
  const [isOpen, setIsOpen] = useState(false);
  const [sendProject, setSendProject] = useState({
    show: false,
    projectName: "",
    projectId: 0,
  });
  const [error, setError] = useState(false);
  const [inputValue, setInputValue] = useState({
    projectID: "",
    projectName: "",
    prevProjectName: "",
  });
  const [deleteSuccessModalGrid, setDeleteSuccessModalGrid] = useState(false);
  const [idDel, setIdDel] = useState(null);
  const [deletedProject, setDeletedProject] = useState(null);
  const [permanentDelProject, setPermanentDeleteProject] = useState(null);
  const [MethodName, setMethodName] = useState(null);
  const [IsDeleted, setIsDeleted] = useState(null);
  const [lblMessage, setlabelMesg] = useState(null);
  const [isFocused, setIsFocused] = useState(null);
  const [projectInfo, setProjectInfo] = useState(null);
  const { exportProject, duplicateProject } = useExportProject();
  const { showSuccessNotification, showErrorNotification } = useToastNotification();

  const handleFocus = id => {
    setIsFocused(id);
  };
  const handleBlur = () => {
    setIsFocused(null);
  };

  const deleteProject = async (id, isPermanentDelete) => {
    const payload = { isPermanent: isPermanentDelete, projectIds: [id] };
    try {
      await deleteRequest(API_URLS.project, payload);
      const newData = StoreData.filter(item => item.projectID !== id);
      dispatch(btnlist(newData));
      showSuccessNotification(APP_TOAST_NOTIFICATION.PROJECT_DELETED);
    } catch (error) {
      showErrorNotification(APP_TOAST_NOTIFICATION.PROJECT_DELETE_FAILED);
    }
  };

  const handleDeleteWarningClose = async id => {
    if (CustomModalButtons.CONFIRM === id) {
      deleteProject(permanentDelProject?.ProjectId ?? deletedProject?.ProjectId, Boolean(permanentDelProject));
    }
    setDeletedProject(null);
    setPermanentDeleteProject(null);
  };

  const handleOpenPermanentDelete = id => setPermanentDeleteProject(id);
  const handleOpenDelete = id => setDeletedProject(id);

  const handleRestoreFolder = id => {
    setIdDel(id);
    const objMethod = {
      Method: "masterdata/api/v1/RestoreFolder",
      userID: userID,
      folderId: id,
    };
    setMethodName(objMethod);
    setDeleteSuccessModalGrid(true);
    setlabelMesg("Are you sure you want to restore this folder?");
    setIsDeleted("RF");
  };

  const handleOpenRestoreProject = id => {
    setIdDel(id);
    const objMethod = {
      Method: "masterdata/api/v1/RestoreProject",
      userID: userID,
      projectID: id,
    };
    setMethodName(objMethod);
    setDeleteSuccessModalGrid(true);
    setlabelMesg("Are you sure you want to restore this project?");
    setIsDeleted("R");
  };

  const handleDeleteFolder = id => {
    setIdDel(id);
    const objMethod = {
      Method: "masterdata/api/v1/PermanentDeleteFolder",
      userID: userID,
      folderId: id,
    };
    setMethodName(objMethod);
    setDeleteSuccessModalGrid(true);
    setlabelMesg("Are you sure you want to Delete this folder?");
    setIsDeleted("F");
  };

  const [Message, setMessage] = useState("");
  const [GetRenameData, { data }] = useUpdateDataMutation("");
  const [isPreviewShown, setPreviewShown] = useState(false);
  const [selectTitle, setSelectTitle] = useState("");
  const [popupOperator, setPopupOperator] = useState({
    message: "",
    show: false,
    type: "",
    subtext: "",
  });
  const [showAddToFolder, setShowAddToFolder] = useState(false);

  const handleOpenRenameProject = item => {
    const { ProjectId, title } = item;
    setIsOpen(true);
    setInputValue({ projectID: ProjectId, projectName: title, prevProjectName: title });
    document.body.classList.add("disable");
  };
  const handleOpenSendProject = ({ ProjectId, title }) => {
    setSendProject({ show: true, projectName: title, projectId: ProjectId });
  };
  const handleCloseRenameProject = () => {
    setIsOpen(false);
    // document.body.classList.remove("disable");
  };
  const handleInputChange = e => {
    const myvalus = e.target.value;
    const Rename = e.target.value;
    const ProjectId = inputValue.projectID;
    const index = Cardstate.findIndex(item => item.title === myvalus);
    if (myvalus.trim() === "") {
      setInputValue({ ...inputValue, projectID: ProjectId, projectName: Rename });
      setMessage("This field cannot be empty, please enter a project name.");
      setError(true);
      return;
    } else if (myvalus.length < 3) {
      setInputValue({ ...inputValue, projectID: ProjectId, projectName: Rename });
      setMessage("Project Name,Three Minimum Length Required !!");
      setError(true);
      return;
    } else if (index > -1) {
      setInputValue({ ...inputValue, projectID: ProjectId, projectName: Rename });
      setMessage("Data Exist !!");
      setError(true);
      return;
    } else {
      setInputValue({ ...inputValue, projectID: ProjectId, projectName: Rename });
      setMessage("");
      setError(false);
    }
  };
  const handleSubmit = async e => {
    e.preventDefault();
    if (inputValue.projectName.trim() === "") {
      setError(true);
      return;
    }
    if (inputValue.length < 3) {
      setPopupOperator({
        type: "warning",
        show: true,
        message: "Minimum 3 characters required !!",
        subtext: "",
      });
      setError(true);
      return;
    } else {
      const newData = {
        Method: "masterdata/api/v1/RenameProject",
        userID: userID,
        projectID: inputValue.projectID,
        projectName: inputValue.projectName,
      };

      const ResponseValues = await GetRenameData(newData);
      if (ResponseValues.data.responseMessage == "Success") {
        const prevTitle =
          inputValue.prevProjectName.length > PROJECT_NAME_MAX_LENGTH
            ? `${inputValue.prevProjectName.substring(0, PROJECT_NAME_MAX_LENGTH)}...`
            : inputValue.prevProjectName;
        const newTitle =
          inputValue.projectName.length > PROJECT_NAME_MAX_LENGTH
            ? `${inputValue.projectName.substring(0, PROJECT_NAME_MAX_LENGTH)}...`
            : inputValue.projectName;
        setPopupOperator({
          type: "success",
          show: true,
          message: `Project Name ${prevTitle} has been successfully renamed to ${newTitle}.`,
          subtext: "",
        });
        setIsOpen(false);
        if (StoreSidLeftpanel == "masterdata/api/v1/FolderProject") {
          getData(`${StoreSidLeftpanel}?folderID=${FolderProjectIDLeftpanel}`);
        } else {
          getData(`${StoreSidLeftpanel}?userID=${userID}`);
        }
        LoadRecord();
      } else {
        toast.success(ResponseValues.data.responseMessage, "Record not Update !!", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-center",
        });
      }

      // setInputValue(undefined);
      setIsOpen(false);
      document.body.classList.remove("disable");
    }
  };

  const handleAddToFolder = item => {
    setProjectInfo(item);
    setShowAddToFolder(true);
  };

  const handleAddFolderClose = () => {
    setShowAddToFolder(false);
  };

  const loader = useSelector(state => state.cardlist.loader);
  useEffect(() => {
    try {
      getSubTechnology(`${"masterdata/api/v1/SubTechnology"}?userID=${userID}`);
    } catch {
      console.log("Error in get SubTechnology");
    }
  }, []);
  useEffect(() => {
    if (responseSubTechnology.isLoading) {
      // dispatch(updateLoader(true));
      // setLoading(true);
    } else {
      if (responseSubTechnology.isSuccess === true) {
        dispatch(updateSubTechnology(responseSubTechnology.data));
      }
    }
    if (responseSubTechnology.isError) {
      throw new MyError("responseSubTechnology Api Error", responseSubTechnology.error.status, "ApiError");
    }
  }, [responseSubTechnology]);
  useEffect(() => {
    if (StoreSidLeftpanel == "masterdata/api/v1/FolderProject") {
      getData(`${StoreSidLeftpanel}?folderID=${FolderProjectIDLeftpanel}`);
    } else {
      if (userID !== 0) {
        getData(`${StoreSidLeftpanel}?userID=${userID}`);
      }
    }
  }, [StoreSidLeftpanel, FolderProjectIDLeftpanel, userID]);

  useEffect(() => {
    if (responseInfo.isLoading) {
      dispatch(updateLoader(true));
      return;
    }
    if (responseInfo.isSuccess) {
      let data = responseInfo.data;

      if (data["lstprojectInfoHomePageVM"] || data["lstfolderDeleteVM"]) {
        const updatedRes = data["lstfolderDeleteVM"].map(item => ({
          ...item,
          projectID: 0,
          projectName: item.folderName,
        }));
        data = [...data["lstprojectInfoHomePageVM"], ...updatedRes];
      } else if (activeMenu === PROJECT_TYPE.IMPORTED) {
        data = data.filter(project => project.isImported);
      }

      dispatch(Tempbtnlist(data));
      dispatch(sortData2({ flag: pTitle, data }));
      dispatch(updateLoader(false));
    }
  }, [responseInfo]);

  const [FavouritePost, responseFavorite] = useDeleteDataRecordMutation();

  useEffect(() => {
    if (responseInfo.isSuccess) {
      if (StoreSidLeftpanel == "masterdata/api/v1/FolderProject") {
        getData(`${StoreSidLeftpanel}?folderID=${FolderProjectIDLeftpanel}`);
      } else {
        getData(`${StoreSidLeftpanel}?userID=${userID}`);
      }
      LoadRecord();
    }
  }, [StoreSidLeftpanel, userID, activeMenu]);

  useEffect(() => {
    try {
      if (responseFavorite.isSuccess) {
        const newTitle =
          selectTitle?.length > PROJECT_NAME_MAX_LENGTH
            ? `${selectTitle?.substring(0, PROJECT_NAME_MAX_LENGTH)}...`
            : selectTitle;
        if (flagstate === true) {
          setPopupOperator({
            message: "Project added to Favourite Projects successfully! ",
            show: true,
            type: "success",
            subtext: `${newTitle} has been added to Favourite Projects successfully.`,
          });
        } else {
          setPopupOperator({
            message: "Project removed from Favourite Projects successfully!.",
            show: true,
            type: "success",
            subtext: `${newTitle} has been removed from Favourite Projects successfully.`,
          });
        }
        if (StoreSidLeftpanel == "masterdata/api/v1/FolderProject") {
          getData(`${StoreSidLeftpanel}?folderID=${FolderProjectIDLeftpanel}`);
        } else {
          getData(`${StoreSidLeftpanel}?userID=${userID}`);
        }
        LoadRecord();
      }
    } catch {
      console.log("Error in use effect responseFavorite");
    }
  }, [responseFavorite]);

  const handleOnclick = item => {
    const obj = {};
    const _projectId = item.ProjectId ? item.ProjectId : 0;
    if (_projectId !== 0) {
      obj.projectID = _projectId;
      obj.caseId = 0;
      obj.projectName = item.title;
      obj.Tchnology = item.technologyName;
      obj.isImported = item.isImported;
      dispatch(updateProjectInfo(obj));
    }
  };
  const FavouriteRecord = item => {
    const { ProjectId, favorite, title } = item;
    dispatch(updateLoader(false));
    setSelectTitle(title);
    const flag = favorite == true ? false : true;
    setflagstate(flag);
    const data1 = {
      Method: "masterdata/api/v1/FavoriteProject",
      userID: userID,
      projectID: ProjectId,
      favorite: flag,
    };
    FavouritePost(data1);
  };

  useEffect(() => {
    LoadRecord();
  }, [StoreData]);
  const btnCreateprojct = e => {
    e.preventDefault();
    setPreviewShown(true);
  };
  const updateCreateprojc = e => {
    e.preventDefault();
    setPreviewShown(false);
  };

  const TechImages = { UF: Thumbnail_UF, IX: Thumbnail_IX };

  const getTechnologyImage = project => {
    if (!project || !project.technologyName) return "";

    const technologies = project.technologyName.split(",");
    return technologies[0];
  };

  const handleTechImageClick = item => {
    if (StoreSidLeftpanel !== "masterdata/api/v1/ProjectDeleted") {
      handleOnclick(item);
      navigate("/FeedWaterHome", {
        state: {
          ...item,
          projectID: item.ProjectId,
          caseID: 0,
        },
      });
    }
  };

  const handleExportProject = item => {
    exportProject(item.ProjectId, item.title);
  };

  const LoadRecord = () => {
    setCard([]);
    if (StoreData === 0) {
      <div>Loading....</div>;
    } else {
      setCard([]);
      StoreData.forEach(element => {
        setCard(current => [
          ...current,
          {
            favorite: element.favorite,
            ProjectId: element.projectID,
            title: element.projectName || element.folderName,
            description: element.modifiedDuration,
            createDate: element.createDateDuration,
            image: getTechnologyImage(element),
            isLocked: false,
            tagName: element.tagName,
            isDeleted: element.isDeleted,
            folderName: element.folderName,
            technologyName: element.technologyName,
            folderNameDeleted: element.folderName,
            folderDeletedDuration: element.modifiedDuration,
            folderIdDeleted: element.folderID || 0,
            isImported: element.isImported,
          },
        ]);
      });
    }
  };

  const isFolder = folderIdDeleted => folderIdDeleted !== 0;

  const handleCLoseOperator = () => {
    setPopupOperator({
      show: false,
      message: "",
      type: "",
    });
  };

  return !loader ? (
    <>
      <CardDetailsStyled>
        {StoreSidLeftpanel == "masterdata/api/v1/FolderProject" ||
        StoreSidLeftpanel == "masterdata/api/v1/ProjectDeleted" ? (
          ""
        ) : (
          <StyledCard borderRadius='4px' className='spc_card'>
            <Card.Body className='create-project'>
              <div onClick={btnCreateprojct} className='create-project-icon'>
                <CreateNewProjectIcon />
              </div>
              <div className='create-project-text'>
                <CustomHeading
                  fontFamily='DiodrumSemiBold'
                  fontSize='16px'
                  fontWeight='600'
                  label='Create New Project'
                  color={colors.Black}
                />
              </div>
            </Card.Body>
          </StyledCard>
        )}
        {showAddToFolder && (
          <AddToFolder
            show={showAddToFolder}
            handleAddFolderClose={handleAddFolderClose}
            projectInfo={projectInfo}
          ></AddToFolder>
        )}
        {isPreviewShown ? (
          <CreateNewProjectModal CPmodal={updateCreateprojc} show={isPreviewShown} close={setPreviewShown} />
        ) : (
          ""
        )}
        <DeleteProjectPopup
          show={deleteSuccessModalGrid}
          close={() => setDeleteSuccessModalGrid(false)}
          id={idDel}
          methodName={MethodName}
          IsDeleted={IsDeleted}
          lblMessage={lblMessage}
        />
        {responseInfo?.data?.length === 0 || Cardstate.length === 0
          ? null
          : Cardstate?.map((item, index) => (
              <>
                {
                  <Draggable
                    className='spc_card'
                    type='item'
                    data={[item.ProjectId, item.title, item.folderName, item.isDeleted]}
                    key={`${item.ProjectId}_${index}`}
                  >
                    <StyledCard borderRadius='4px' className={item.isLocked ? "main-card normal-card" : "normal-card"}>
                      {item.isLocked && (
                        <Card className='locked-project'>
                          <div className='lock-icon'>
                            <LockProjectIcon />
                          </div>
                        </Card>
                      )}

                      <div className='project-header-part'>
                        {StoreSidLeftpanel == "masterdata/api/v1/ProjectDeleted" ? (
                          <div>
                            <div className='project-title'>
                              <WPHeading type='default' tooltip={item.title} text={item.title} wrapContent />
                            </div>
                            <div className='project-edited-duration'>
                              <CustomHeading
                                fontFamily='NotoSansRegular'
                                color={colors.blackTransparency045}
                                label={item.createDate}
                              />
                              <div className={item.isShared ? "shared-project-icon" : ""}>
                                {item.isShared && <SharedFileIcon />}
                              </div>
                              {isFolder(item.folderIdDeleted) && (
                                <div className='folder-icon' style={{ marginTop: "50px", marginLeft: "70px" }}>
                                  <FolderIcon />
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              handleOnclick(item);
                              navigate("/FeedWaterHome", {
                                state: {
                                  ...item,
                                  projectID: item.ProjectId,
                                  caseID: 0,
                                },
                              });
                            }}
                            className='title-wrapper'
                          >
                            <div className='project-title'>
                              <WPHeading type='default' tooltip={item.title} text={item.title} wrapContent />
                            </div>
                            <div className='project-edited-duration'>
                              <CustomHeading
                                fontFamily='NotoSansRegular'
                                color={colors.blackTransparency045}
                                label={item.description}
                              />
                              <div className={item.isShared ? "shared-project-icon" : ""}>
                                {item.isShared && <SharedFileIcon />}
                              </div>
                            </div>
                            <div className='project-inside-tags'>
                              {item.tagName == "" ? "" : <a className='folder-tag'>{item.tagName}</a>}
                              {item.folderName == "" ? (
                                ""
                              ) : (
                                <a className='sample-project-tag'>{getFolderName(item.folderName)}</a>
                              )}
                            </div>
                          </div>
                        )}
                        <div>
                          <Dropdown className='project-option-dropdown'>
                            <Dropdown.Toggle variant=''>
                              <MoreVertIcon />
                            </Dropdown.Toggle>
                            {item.isDeleted || isFolder(item.folderIdDeleted) ? (
                              !isFolder(item.folderIdDeleted) ? (
                                <Dropdown.Menu>
                                  <Dropdown.Item>
                                    <a onClick={() => handleOpenRestoreProject(item.ProjectId)}>Restore Project</a>
                                  </Dropdown.Item>

                                  <Dropdown.Item>
                                    <a onClick={() => handleOpenPermanentDelete(item)}>Permanently Delete</a>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              ) : (
                                <DropdownMenu>
                                  <Dropdown.Item>
                                    <a onClick={() => handleRestoreFolder(item.folderIdDeleted)}>
                                      {FolderStrings.restoreFolder}
                                    </a>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <a onClick={() => handleDeleteFolder(item.folderIdDeleted)}>Permanently Delete</a>
                                  </Dropdown.Item>
                                </DropdownMenu>
                              )
                            ) : (
                              <Dropdown.Menu>
                                <Dropdown.Item>
                                  <a
                                    onClick={() => {
                                      handleOnclick(item);
                                      navigate("/FeedWaterHome", {
                                        state: {
                                          ...item,
                                          projectID: item.ProjectId,
                                          caseID: 0,
                                        },
                                      });
                                    }}
                                  >
                                    Open Project
                                  </a>
                                </Dropdown.Item>
                                {item.favorite ? (
                                  <Dropdown.Item>
                                    <a onClick={() => FavouriteRecord(item)}>Remove from Favourite</a>
                                  </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item href='#/action-2'>
                                    <a onClick={() => FavouriteRecord(item)}>Add to Favourite</a>
                                  </Dropdown.Item>
                                )}
                                <Dropdown.Item href='#/action-3'>
                                  <a onClick={() => handleAddToFolder(item)}>Add to Folder</a>
                                </Dropdown.Item>
                                <Dropdown.Item href='#/action-3'>
                                  <a onClick={() => duplicateProject(item)}>Duplicate this Project</a>
                                </Dropdown.Item>
                                {item.isDeleted ? (
                                  ""
                                ) : (
                                  <Dropdown.Item href='#/action-3'>
                                    <a onClick={() => handleOpenDelete(item)}>Delete Project</a>
                                  </Dropdown.Item>
                                )}
                                <Dropdown.Item href='#/action-3'>
                                  <a onClick={() => handleOpenRenameProject(item)}>Rename Project</a>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  disabled={isIXTechnology(item.technologyName)}
                                  className={isIXTechnology(item.technologyName) ? "dropdown-item-disabled" : ""}
                                >
                                  <a
                                    disabled={isIXTechnology(item.technologyName)}
                                    onClick={() => handleExportProject(item)}
                                    className={isIXTechnology(item.technologyName) ? "dropdown-item-disabled" : ""}
                                  >
                                    Export Project
                                  </a>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <a onClick={() => handleOpenSendProject(item)}>Send Project</a>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            )}
                          </Dropdown>
                        </div>
                      </div>
                      <div className='project-snippet'>
                        {TechImages[item.image] && (
                          <Card.Img
                            src={TechImages[item.image]}
                            alt='project-thumbnail'
                            onClick={() => handleTechImageClick(item)}
                          />
                        )}

                        {item.isDeleted ? (
                          ""
                        ) : (
                          <div className={item.favorite ? "favorite-icon" : ""} onClick={() => FavouriteRecord(item)}>
                            {item.favorite && <FavoriteIcon />}
                          </div>
                        )}
                      </div>
                    </StyledCard>
                  </Draggable>
                }
              </>
            ))}
      </CardDetailsStyled>
      <StyledModal
        show={isOpen}
        onHide={handleCloseRenameProject}
        backdrop='static'
        keyboard='false'
        centered
        maxWidth='572px'
        backgroundColor={colors.GreyF8}
      >
        <Modal.Header backgroundColor={colors.GreyF8}>
          <CustomHeading
            fontFamily='DiodrumSemiBold'
            fontSize='16px'
            color={colors.PrimaryDarkAquaMarine}
            label='Rename this Project'
          />
          <div className='close-icon' onClick={handleCloseRenameProject}>
            <CloseIcon />
          </div>
        </Modal.Header>
        <Modal.Body>
          <CustomLabel label='Project Name' />
          <InputWithIcon
            disabled={false}
            isError={error}
            isWarning={false}
            onClick
            type='text'
            onBlur={handleBlur}
            onFocus={() => handleFocus(1)}
            isFocused={isFocused === 1}
            minLength='3'
            maxLength='200'
            inputText={error ? <CloseCircleRedIcon /> : <CloseCircleGreenIcon />}
            unitBgColor='transparent'
            value={inputValue.projectName}
            onChange={handleInputChange}
          />
          <ErrorMessage errorIcon={true} style={{ visibility: error ? "visible" : "hidden" }} texMsg={Message} />
        </Modal.Body>
        <Modal.Footer>
          <StandardSecondaryButton variant='light' onClick={handleCloseRenameProject} id='canBtn' label='Cancel' />
          <StandardPrimaryButton
            type='submit'
            id=''
            variant='light'
            disabled={error ? "true" : ""}
            onClick={handleSubmit}
            label='Submit'
          />
        </Modal.Footer>
      </StyledModal>
      <FavRemovePopup operator={popupOperator} close={handleCLoseOperator} />
      <SendProjectModal
        show={sendProject.show}
        projectId={sendProject.projectId}
        projectName={sendProject.projectName}
        close={() => setSendProject({ show: false, projectName: "", projectId: 0 })}
      />
      {deletedProject && (
        <ConfirmationPopup
          header={ProjectStrings.deleteProject}
          description={ProjectStrings.deleteNote}
          type={DIALOG_TYPES.WARNING}
          onClose={handleDeleteWarningClose}
          confirmBtn={CommonButtonStrings.okay}
          cancelBtn={CommonButtonStrings.cancel}
        />
      )}
      {permanentDelProject && (
        <ConfirmationPopup
          header={ProjectStrings.deleteProject}
          description={ProjectStrings.deleteNotePermanent}
          type={DIALOG_TYPES.ERROR}
          onClose={handleDeleteWarningClose}
          confirmBtn={CommonButtonStrings.okay}
          cancelBtn={CommonButtonStrings.cancel}
        />
      )}
    </>
  ) : (
    <Loader />
  );
};

export default CardTiles;
