import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DynamicLoadder from "@common/utils/DynamicLoadder";

import { setScrollDirection } from "../activitymonitor/activityMonitorSlice";

import ChargeBalanceAdjustment from "./ChargeBalanceAdjustment";
import FormEntry from "./FormEntry";
import StreamDefinition from "./StreamDefinition";

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      setScrollDirection(scrollY);
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
}
const FeedSetup = () => {
  const dispatch = useDispatch();
  const scrollDirection = useScrollDirection();
  const { isDataFetched, streamData } = useSelector(state => state.Feedsetupdetailsdatapanel);
  const isStreamDataAvail = streamData.lstrequestsavefeedwater[0].streams.length > 0;

  localStorage.setItem("set scroll", scrollDirection);
  useEffect(() => {
    dispatch(setScrollDirection(scrollDirection));
  }, [scrollDirection]);

  return (
    <>
      <div style={{ minHeight: "100vh" }}>
        <DynamicLoadder isLoading={!isDataFetched || !isStreamDataAvail}>
          <ChargeBalanceAdjustment />
          <StreamDefinition scrollDirection={scrollDirection} />
          <FormEntry />
        </DynamicLoadder>
      </div>
      {/* <Footer/> */}
    </>
  );
};

export default FeedSetup;
