/* eslint-disable max-len */
import React from "react";

const SearchIcon = () => (
  <>
    <svg
      width='22'
      className='search-icon'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.6519 20.178L14.6956 13.2218C15.7751 11.8262 16.359 10.12 16.359 8.32533C16.359 6.17711 15.5206 4.16283 14.0046 2.64408C12.4885 1.12533 10.4688 0.289612 8.32331 0.289612C6.17778 0.289612 4.15813 1.128 2.64206 2.64408C1.12331 4.16015 0.287598 6.17711 0.287598 8.32533C0.287598 10.4709 1.12599 12.4905 2.64206 14.0066C4.15813 15.5253 6.1751 16.361 8.32331 16.361C10.118 16.361 11.8215 15.7771 13.2171 14.7003L20.1733 21.6539C20.1937 21.6743 20.2179 21.6905 20.2446 21.7015C20.2712 21.7126 20.2998 21.7183 20.3287 21.7183C20.3575 21.7183 20.3861 21.7126 20.4128 21.7015C20.4394 21.6905 20.4636 21.6743 20.484 21.6539L21.6519 20.4887C21.6723 20.4683 21.6885 20.4441 21.6995 20.4174C21.7106 20.3908 21.7163 20.3622 21.7163 20.3334C21.7163 20.3045 21.7106 20.2759 21.6995 20.2493C21.6885 20.2226 21.6723 20.1984 21.6519 20.178ZM12.5662 12.5682C11.4305 13.7012 9.9251 14.3253 8.32331 14.3253C6.72153 14.3253 5.21617 13.7012 4.08046 12.5682C2.94742 11.4325 2.32331 9.92711 2.32331 8.32533C2.32331 6.72354 2.94742 5.21551 4.08046 4.08247C5.21617 2.94943 6.72153 2.32533 8.32331 2.32533C9.9251 2.32533 11.4331 2.94676 12.5662 4.08247C13.6992 5.21818 14.3233 6.72354 14.3233 8.32533C14.3233 9.92711 13.6992 11.4352 12.5662 12.5682Z'
        fill='black'
        fillOpacity='0.45'
      />
    </svg>
  </>
);

export default SearchIcon;
