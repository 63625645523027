export const CHEMICAL_SYMBOLS = {
  ferricIon: "Fe³⁺",
  aluminiumIon: "Al³⁺",
  H2SO4: "H₂SO₄",
};

export const COAGULANT_CHEMICAL_MAPPING = {
  [CHEMICAL_SYMBOLS.ferricIon]: "FeCl3",
  [CHEMICAL_SYMBOLS.aluminiumIon]: "PaCl",
};
