import React from "react";

const DeleteTealIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
      <path
        d='M4.625 1.8736H4.5C4.56875 1.8736 4.625 1.81735 4.625 1.7486V1.8736H9.375V1.7486C9.375 1.81735 9.43125 1.8736 9.5 1.8736H9.375V2.9986H10.5V1.7486C10.5 1.19703 10.0516 0.748596 9.5 0.748596H4.5C3.94844 0.748596 3.5 1.19703 3.5 1.7486V2.9986H4.625V1.8736ZM12.5 2.9986H1.5C1.22344 2.9986 1 3.22203 1 3.4986V3.9986C1 4.06735 1.05625 4.1236 1.125 4.1236H2.06875L2.45469 12.2955C2.47969 12.8283 2.92031 13.2486 3.45313 13.2486H10.5469C11.0813 13.2486 11.5203 12.8298 11.5453 12.2955L11.9313 4.1236H12.875C12.9438 4.1236 13 4.06735 13 3.9986V3.4986C13 3.22203 12.7766 2.9986 12.5 2.9986ZM10.4266 12.1236H3.57344L3.19531 4.1236H10.8047L10.4266 12.1236Z'
        fill='#007672'
      />
    </svg>
  </>
);

export default DeleteTealIcon;
