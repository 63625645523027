import styled from "styled-components";

import WPHeading from "@components/WPHeading";

const MandatoryStar = styled.span`
  color: var(--du-color-red);
`;

const WPFormLabel = ({ label, isMandatory }) => (
    <WPHeading secondary type='default' size='small'>
        {isMandatory && <MandatoryStar>*</MandatoryStar>}
        {label}
    </WPHeading>
);

export default WPFormLabel;
