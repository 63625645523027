import { useReportUtils } from "@features/report-new/useReportUtils";

import {
  WPUfServiceWaterRowsData as rowsData,
  WPUfServiceWaterTableColumns as columns,
  WPUfServiceWaterUnitMapping as unitMapping,
} from "./WPUfServiceWaterTable.constants";

export const useWPUfServiceWaterTableData = () => {
  const { ufReportRespData, convertReportDataValue, getUnitLabelValue } = useReportUtils();
  const { service_Water } = ufReportRespData || {};

  const convertToUfServiceWaterTableData = () => {
    // unit row
    const firstRow = columns.reduce((acc, col) => {
      acc[col.field] = getUnitLabelValue(unitMapping[col.field] || {});
      return acc;
    }, {});

    const data = rowsData.map(row => {
      const { label, apiKey } = row;

      return columns.reduce((acc, col) => {
        const { unit, isCost, unitDenominator, precision = 2 } = unitMapping[col.field] || {};
        if (col.field === "label") {
          acc[col.field] = label;
        } else {
          const value = service_Water?.[apiKey][col.field];
          acc[col.field] = convertReportDataValue({ value, unit, isCost, unitDenominator, precision });
        }
        return acc;
      }, {});
    });

    data.unshift(firstRow);
    return data;
  };

  return { convertToUfServiceWaterTableData };
};
