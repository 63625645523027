import DupontLogger from "@utils/DupontLogger";

import { INGE_MODULE_TR_RANGES } from "../../constants/UFConstants";

import { getNModulesTrainTarget, getStandByUnits } from "./UFConfigFormulasHelper";

const Logger = DupontLogger("UFConfigIngeFormulas");

const MAXIMUM_ROWS = 10;

/**
 * CASE C (No Standby with Rack) : UF Test case 12
 *
 * This function calculates and returns an array of configuration objects for ultrafiltration (UF) inge module
 * setups with rack configurations. It takes into account various parameters such as the UF module ID, number of
 * skids per train, whether there is a standby unit, offline time per train, and calculation engine data to
 * determine the optimal configuration. The function iterates through possible online train numbers within a
 * calculated range, adjusting for standby units and calculating the number of modules per train based on the
 * target and actual filtration times. It ensures the skid size is even and computes the total number of modules
 * needed. Each iteration's configuration details are added to an array, which is returned at the end.
 *
 *   •	Compute N_modules_target
 *    •	N_IP_Skids = User Input (Can be 1, 2, 4 depending if the user chooses TR1, 2, 4)
 *    •	IP_Skid_Size_max = take from table according to module selected
 *    •	IP_Skid_Size_min = take from table according to module selected
 *    •	Compute N_Online_Trains_Minimum = INT(N_modules_target/ N_IP_Skids/ IP_Skid_Size_max) + 1
 *    •	Loop over N_Online_Trains = N_Online_Trains_Minimum to N_Online_Trains_Minimum + 10
 *       o	N_Standby_Trains = MAX(1, INT(N_Online_Trains / (N_BW_per_filter_max+1) + 0.99))
 *       o	N_Total_Trains = N_Online_Trains + N_Standby_Trains
 *       o	N_Modules_Train_Target = ROUNDUP(N_modules_target* t_filter_module_day/(1440 - t_MIT_module_day) / Online_Trains, 0), rounds up to next integer
 *       o	N_Skid_Size = EVEN(ROUNDUP((N_Modules_Train_Target / N_IP_skid, 0))
 *       o	N_Modules_Train_Adjusted = N_IP_Skids * IP_Skid_Size
 *       o	N_Online_Modules = N_Online_Trains * N_Modules_Train_Adjusted
 *       o	N_Total_Modules = N_Total_Trains * N_Modules_Train_Adjusted
 *       o	If N_Modules_Train_Adjusted <= IP_Skid_Size_min then exit loop
 *
 * @param {number} ufmoduleId - The ID of the UF module.
 * @param {number} skidsPerTrain - The number of skids per train.
 * @param {boolean} withStandBy - Indicates if standby units should be considered.
 * @param {number} offlinetimepertrain - The offline time per train.
 * @param {Object} calcEngineData - An object containing calculation engine data such as the target number of modules,
 *                                  the filtration time per module per day, and the maximum backwash per filter.
 * @returns {Array} An array of configuration objects, each containing details about the UF inge module setup with
 *                  rack configurations.
 */

export const calculateIngeUFConfigWithRack = (
  ufmoduleId,
  skidsPerTrain,
  withStandBy,
  offlinetimepertrain,
  calcEngineData,
) => {
  const configurations = [];
  if (calcEngineData) {
    let options = 0;
    const { max: IP_Skid_Size_max, min: IP_Skid_Size_min } = INGE_MODULE_TR_RANGES[ufmoduleId][`TR${skidsPerTrain}`];
    const N_IP_Skids = skidsPerTrain;
    const N_Modules_Target = calcEngineData.n_modules_target;
    const T_Filter_Module_Day = Number(calcEngineData.t_filter_module_day);
    const T_MIT_module_day = Number(offlinetimepertrain);
    const N_Online_Trains_Minimum = Math.max(1, Math.floor(N_Modules_Target / N_IP_Skids / IP_Skid_Size_max) + 1);
    const N_Online_Trains_Maximum = N_Online_Trains_Minimum + MAXIMUM_ROWS;
    const N_BW_per_filter_max = Number(calcEngineData.n_BW_per_filter_max);
    const standByUnits = withStandBy ? 1 : 0;

    Logger.log(
      `calculateIngeUFConfigWithRack 
      withStandBy: ${withStandBy}
      N_IP_Skids :${N_IP_Skids} 
      IP_Skid_Size_min: ${IP_Skid_Size_min} 
      IP_Skid_Size_max: ${IP_Skid_Size_max} 
      N_Modules_Target: ${N_Modules_Target}
      N_Online_Trains_Minimum(${N_Modules_Target}/${N_IP_Skids}/${IP_Skid_Size_max}): ${N_Online_Trains_Minimum}
      N_Online_Trains_Maximum: ${N_Online_Trains_Maximum} 
      N_BW_per_filter_max: ${N_BW_per_filter_max}
      calcEngineData: `,
      calcEngineData,
    );

    for (let N_Online_Trains = N_Online_Trains_Minimum; N_Online_Trains <= N_Online_Trains_Maximum; N_Online_Trains++) {
      options++;
      const N_Standby_Trains = getStandByUnits(withStandBy, N_Online_Trains, N_BW_per_filter_max);
      const N_Total_Trains = N_Online_Trains + N_Standby_Trains;

      const N_Modules_Train_Target = getNModulesTrainTarget(
        withStandBy,
        N_Modules_Target,
        T_Filter_Module_Day,
        T_MIT_module_day,
        N_Online_Trains,
      );

      const skidSize = Math.ceil(N_Modules_Train_Target / N_IP_Skids);
      const IP_Skid_Size = skidSize % 2 === 0 ? skidSize : skidSize + 1;
      const N_Modules_Train_Adjusted = N_IP_Skids * IP_Skid_Size;
      const N_Online_Modules = N_Online_Trains * N_Modules_Train_Adjusted;
      const N_Total_Modules = N_Total_Trains * N_Modules_Train_Adjusted;

      configurations.push({
        options,
        onlineUnits: N_Online_Trains,
        standByUnits: standByUnits,
        totalUnits: N_Online_Trains,
        maxOfflineBW_CEB: Math.floor(Math.max(1, N_Online_Trains / (N_BW_per_filter_max + 2) + 0.99)),
        modulesPerRack: IP_Skid_Size,
        racksPerUnit: N_IP_Skids,
        modulesPerUnit: N_Modules_Train_Adjusted,
        onlineModules: N_Online_Modules,
        totalModules: N_Total_Modules,
      });

      if (N_Modules_Train_Target <= IP_Skid_Size_min) break;
    }
  }
  Logger.log("calculateIngeUFConfigWithRack - calculated configurations: ", configurations);
  return configurations;
};
