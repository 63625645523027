import React from "react";

const TabletViewMenuIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
      <g transform='translate(0, 3)'>
        <path d='M4 14H20' stroke='black' strokeWidth='2.5' strokeLinecap='round' />
        <path d='M4 22H28' stroke='black' strokeWidth='2.5' strokeLinecap='round' />
        <path d='M4 6H28' stroke='black' strokeWidth='2.5' strokeLinecap='round' />
      </g>
    </svg>
  </>
);

export default TabletViewMenuIcon;
