import { BaseEdge, getSmoothStepPath } from "reactflow";

export default function CustomEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  selected,
}) {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius: 0,
  });

  return (
    <>
      <BaseEdge
        onDrop={e => console.log("drop", JSON.parse(e.dataTransfer.getData("application/reactflow")))}
        path={edgePath}
        updatable={false}
        markerEnd={markerEnd}
        style={{
          strokeWidth: 2,
          stroke: selected ? "#007672" : "#007672",
          boxShadow: "10px 5px 5px red",
        }}
      />
    </>
  );
}
