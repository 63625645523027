/* eslint-disable max-len */
import React from "react";

const FileIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 0.999999L11.1333 1V1.59987H12.1333V1C12.1333 0.447715 11.6856 0 11.1333 0H1C0.447715 0 0 0.447714 0 0.999999V12.2C0 12.7523 0.447716 13.2 1 13.2H1.86682V12.2H1V0.999999ZM13 2.99999H3.33338V13H13V2.99999ZM2.33338 1.99999V14H14V1.99999H2.33338ZM4.66662 6.00001H11.6666V5.00001H4.66662V6.00001ZM11.6666 8H4.66662V7H11.6666V8ZM4.66662 10H8.16662V9.00003H4.66662V10Z'
        fill='black'
      />
    </svg>
  </>
);

export default FileIcon;
