/* eslint-disable max-len */
import React from "react";

const LockProjectIcon = () => (
  <>
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <mask id='path-1-inside-1_181234_332208' fill='white'>
        <path d='M5.41895 3C5.41895 1.34315 6.76209 0 8.41895 0H16.3544C18.0113 0 19.3544 1.34315 19.3544 3V10.0645H5.41895V3Z' />
      </mask>
      <path
        d='M4.41895 3C4.41895 0.790861 6.20981 -1 8.41895 -1H16.3544C18.5636 -1 20.3544 0.790861 20.3544 3H18.3544C18.3544 1.89543 17.459 1 16.3544 1H8.41895C7.31438 1 6.41895 1.89543 6.41895 3H4.41895ZM19.3544 10.0645H5.41895H19.3544ZM4.41895 10.0645V3C4.41895 0.790861 6.20981 -1 8.41895 -1V1C7.31438 1 6.41895 1.89543 6.41895 3V10.0645H4.41895ZM16.3544 -1C18.5636 -1 20.3544 0.790861 20.3544 3V10.0645H18.3544V3C18.3544 1.89543 17.459 1 16.3544 1V-1Z'
        fill='black'
        mask='url(#path-1-inside-1_181234_332208)'
      />
      <rect x='0.5' y='10.5646' width='23' height='12.9355' rx='1.5' stroke='black' />
      <path
        d='M12.0002 17.5323C12.9177 17.5323 13.6614 16.7885 13.6614 15.871C13.6614 14.9535 12.9177 14.2097 12.0002 14.2097C11.0827 14.2097 10.3389 14.9535 10.3389 15.871C10.3389 16.7885 11.0827 17.5323 12.0002 17.5323Z'
        stroke='black'
      />
      <line x1='12.1133' y1='17.0323' x2='12.1133' y2='20.1291' stroke='black' />
    </svg>
  </>
);

export default LockProjectIcon;
