import SVGContainerPath from "../SVGContainerPath";

const { default: styled } = require("styled-components");

const ArrowIconDown = () => (
  <SVGContainerPath width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      id='Icon'
      d='M9 6.83333L5 11M5 11L1 6.83333M5 11V1'
      stroke='#344054'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SVGContainerPath>
);

export default ArrowIconDown;
