import { UNIT_TYPES, UNITS } from "@constants/units.constant";

import useUnitConversion from "@hooks/useUnitConversion";

import { convertUptoDigits } from "@utils/appUtils";

import { CHEMICAL_PRICE_MAX_FRACTIONS, CHEMICAL_VALUE_MAX_FRACTIONS } from "../chemicalLibrary.constants";

const useChemicalUnitConversion = () => {
  const {
    activeUnits,
    activeCurrency,
    exchangeRate,
    unitConversionByName,
    convertCurrencyPerUnitFromMetric,
    convertCurrencyPerUnitToMetric,
  } = useUnitConversion();

  const convertChemicalDensity = (value, isToMetric = false) => {
    const sourceUnit = isToMetric ? activeUnits[UNIT_TYPES.DENSITY] : UNITS.gcm3;
    const targetUnit = isToMetric ? UNITS.gcm3 : activeUnits[UNIT_TYPES.DENSITY];
    const result = unitConversionByName(value, targetUnit, sourceUnit, CHEMICAL_VALUE_MAX_FRACTIONS);
    return result;
  };

  const convertChemicalCurrency = (value, isToDollar = false, precision = CHEMICAL_PRICE_MAX_FRACTIONS) => {
    if (isToDollar) {
      return convertUptoDigits(value / exchangeRate, precision);
    } else {
      return convertUptoDigits(value * exchangeRate, precision);
    }
  };

  const convertOperationCost = (value, unit, isToMetric = false) => {
    const conversionFactor = activeUnits[unit] === UNITS.gallon ? 1000 : 1;
    if (isToMetric) {
      return convertCurrencyPerUnitToMetric(value / conversionFactor, unit, 3);
    } else {
      return convertCurrencyPerUnitFromMetric(value, unit, 10) * conversionFactor;
    }
  };

  return {
    activeUnits,
    activeCurrency,
    convertOperationCost,
    convertChemicalDensity,
    convertChemicalCurrency,
  };
};

export default useChemicalUnitConversion;
