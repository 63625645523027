import React from "react";

import SVGContainer from "../SVGContainer";

const EditIcon = ({ disabled }) => (
  <SVGContainer width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" disabled={disabled}>
    <g id="Edit">
      <path id="Vector" d="M4.32328 15.3541C4.36792 15.3541 4.41256 15.3496 4.45721 15.3429L8.21167 14.6844C8.25631 14.6755 8.29872 14.6554 8.32997 14.6219L17.792 5.15988C17.8127 5.13923 17.8291 5.1147 17.8403 5.0877C17.8515 5.06069 17.8573 5.03175 17.8573 5.00251C17.8573 4.97328 17.8515 4.94433 17.8403 4.91733C17.8291 4.89032 17.8127 4.8658 17.792 4.84515L14.0822 1.13309C14.0398 1.09068 13.984 1.06836 13.9237 1.06836C13.8635 1.06836 13.8077 1.09068 13.7652 1.13309L4.30319 10.5951C4.26971 10.6286 4.24962 10.6688 4.24069 10.7134L3.58221 14.4679C3.56049 14.5875 3.56825 14.7106 3.60481 14.8265C3.64137 14.9424 3.70563 15.0476 3.79203 15.1331C3.93935 15.2759 4.12462 15.3541 4.32328 15.3541ZM5.82774 11.4612L13.9237 3.36747L15.5599 5.00363L7.4639 13.0974L5.47953 13.4478L5.82774 11.4612ZM18.2139 17.2291H1.78533C1.39024 17.2291 1.07104 17.5483 1.07104 17.9434V18.7469C1.07104 18.8451 1.1514 18.9255 1.24962 18.9255H18.7496C18.8478 18.9255 18.9282 18.8451 18.9282 18.7469V17.9434C18.9282 17.5483 18.609 17.2291 18.2139 17.2291Z" />
    </g>
  </SVGContainer>
);

export default EditIcon;