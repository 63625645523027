import { CHEMICAL_FIELDS, CHEMICAL_UNIT_TYPES } from "../chemicalLibrary.constants";

const LABEL = "label";
const VALUE = "value";
const INPUT_TYPE = "inputType";
const TYPE = "type";
const DISABLED = "disabled";

export const EXCLUDED_CONTROLS = [
  CHEMICAL_FIELDS.id,
  CHEMICAL_FIELDS.isSystem,
  CHEMICAL_FIELDS.viewInProjects,
  CHEMICAL_FIELDS.displayName,
  CHEMICAL_FIELDS.categoryId,
];

export const ADD_EDIT_FORM_CONTROLS = {
  [CHEMICAL_FIELDS.id]: {
    id: CHEMICAL_FIELDS.id,
    [LABEL]: "ID",
    [VALUE]: "",
  },
  [CHEMICAL_FIELDS.isSystem]: {
    id: CHEMICAL_FIELDS.isSystem,
    [LABEL]: "System Chemical",
    [VALUE]: false,
  },
  [CHEMICAL_FIELDS.category]: {
    id: CHEMICAL_FIELDS.category,
    [TYPE]: "dropdown",
    [LABEL]: "Category",
    [VALUE]: "",
    [DISABLED]: false,
    placeholder: "Select Category",
    options: [],
    labelKey: "name",
  },
  [CHEMICAL_FIELDS.categoryId]: {
    id: CHEMICAL_FIELDS.categoryId,
    [LABEL]: "Category ID",
    [VALUE]: "",
  },
  [CHEMICAL_FIELDS.chemical]: {
    id: CHEMICAL_FIELDS.chemical,
    [TYPE]: "dropdown",
    [LABEL]: "Chemical",
    [VALUE]: "",
    [DISABLED]: true,
    options: [],
    placeholder: "Select Chemical",
    labelKey: "name",
  },
  [CHEMICAL_FIELDS.symbol]: {
    id: CHEMICAL_FIELDS.symbol,
    [LABEL]: "Symbol",
    [INPUT_TYPE]: "text",
    [TYPE]: "input",
    [VALUE]: "",
    [DISABLED]: true,
  },
  [CHEMICAL_FIELDS.name]: {
    id: CHEMICAL_FIELDS.name,
    [LABEL]: "Chemical Name",
    [INPUT_TYPE]: "text",
    [TYPE]: "input",
    [VALUE]: "",
    [DISABLED]: true,
  },
  [CHEMICAL_FIELDS.bulkConcentration]: {
    id: CHEMICAL_FIELDS.bulkConcentration,
    [TYPE]: "input",
    [INPUT_TYPE]: "number",
    [LABEL]: "Bulk Concentration",
    unit: "%",
    [VALUE]: "0",
    [DISABLED]: true,
  },
  [CHEMICAL_FIELDS.bulkDensity]: {
    id: CHEMICAL_FIELDS.bulkDensity,
    [LABEL]: "Bulk Density",
    [TYPE]: "input",
    [INPUT_TYPE]: "number",
    unit: "lb/gal",
    [VALUE]: "0",
    [DISABLED]: true,
  },
  [CHEMICAL_FIELDS.bulkPrice]: {
    id: CHEMICAL_FIELDS.bulkPrice,
    [LABEL]: "Bulk Price",
    [TYPE]: "input",
    [INPUT_TYPE]: "number",
    unit: "$",
    [VALUE]: "0",
    [DISABLED]: true,
  },
  [CHEMICAL_FIELDS.costType]: {
    id: CHEMICAL_FIELDS.costType,
    [LABEL]: "Cost Type",
    [TYPE]: "dropdown",
    [VALUE]: "",
    [DISABLED]: true,
    placeholder: "Select Cost Type",
    options: CHEMICAL_UNIT_TYPES.map(item => ({ [LABEL]: item, [VALUE]: item })),
    labelKey: LABEL,
  },
  [CHEMICAL_FIELDS.viewInProjects]: {
    id: CHEMICAL_FIELDS.viewInProjects,
    [LABEL]: "Show in all projects?",
    [TYPE]: "toggle",
    [VALUE]: false,
    [DISABLED]: true,
  },
  [CHEMICAL_FIELDS.displayName]: {
    id: CHEMICAL_FIELDS.displayName,
    [LABEL]: "Dropdown Label",
    [INPUT_TYPE]: "text",
    [TYPE]: "input",
    [VALUE]: "",
    [DISABLED]: true,
  },
};

export const CHEMICAL_DENSITY_RANGE = {
  "Hydrochloric Acid": { min: 0, max: 50 },
  "Sodium Chloride": { min: 0, max: 50 },
  "Sodium Hydroxide": { min: 0, max: 50 },
  "Sodium Carbonate": { min: 0, max: 50 },
  "Sodium Hypochlorite": { min: 0, max: 50 },
};

export const CHEMICAL_WITH_FIXED_DENSITY = [
  "Hydrochloric Acid",
  "Sulfuric Acid",
  "Sodium Chloride",
  "Sodium Hydroxide",
  "Sodium Carbonate",
];

export const SYSTEM_CHEMICAL_LIST = [
  "Base",
  "Acid",
  "Salt",
  "Oxidant",
  "Coagulant",
  "Surfactant",
  "Antiscalant",
  "Organic Acid",
  "Dechlorinator",
];

export const SYSTEM_CHEMICAL_DISABLE_FIELDS = [
  CHEMICAL_FIELDS.symbol,
  CHEMICAL_FIELDS.name,
  CHEMICAL_FIELDS.bulkDensity,
  CHEMICAL_FIELDS.displayName,
];

const systemChemical = [CHEMICAL_FIELDS.bulkPrice, CHEMICAL_FIELDS.costType];
const customSystemChemical = [
  ...systemChemical,
  CHEMICAL_FIELDS.bulkConcentration,
  CHEMICAL_FIELDS.displayName,
  CHEMICAL_FIELDS.viewInProjects,
];
const customChemicals = [...customSystemChemical, CHEMICAL_FIELDS.bulkDensity];

export const EDIT_DISABLED_FIELDS = { systemChemical, customSystemChemical, customChemicals };
