import { Card } from "react-bootstrap";
import styled from "styled-components";

import InfoIcon from "@common/icons/InfoIcon";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import IconWithTooltip from "@common/styles/components/headings/IconWithTooltip";
import { colors } from "@common/styles/Theme";

const StyledCard = styled(Card)`
  background-color: ${props => props.backgroundColor || colors.White};
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: ${props => props.borderRadius || "4px"};
  border: ${props => props.cardBorder || "none"};
  .card-header {
    padding: ${props => props.paddingHeader || "0px"};
    background-color: ${props => props.backgroundColor || colors.White};
    border-bottom: none;
  }
`;

const CustomCard = ({
  header,
  children,
  backgroundColor,
  borderRadius,
  cardBorder,
  paddingHeader,
  tooltipLabel,
  className,
  customHeader,
}) => (
  <StyledCard
    backgroundColor={backgroundColor}
    borderRadius={borderRadius}
    cardBorder={cardBorder}
    paddingHeader={paddingHeader}
    className={className}
  >
    <Card.Header>
      {header && <CustomHeading fontFamily='NotoSansRegular' color={colors.PrimaryDarkAquaMarine} label={header} />}
      {tooltipLabel && <IconWithTooltip label={tooltipLabel} icon={<InfoIcon />} />}
      {customHeader && customHeader}
    </Card.Header>
    {children}
  </StyledCard>
);

export default CustomCard;
