import { Container, Row } from "react-bootstrap";
import styled from "styled-components";

import { colors } from "@common/styles/Theme";

import "@common/styles/notoSansFont.css";
import "@common/styles/diodrumFont.css";

const FeedWaterHomeStyled = styled(Container)`
  background: ${colors.GreyF8};
  min-height: 100vh;
`;

export default FeedWaterHomeStyled;
