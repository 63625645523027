import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { updateMinMaxSliderValue } from "../UFSlice";

const MultiRangeSlider = ({ min, max, step }) => {
  const dispath = useDispatch();
  const { sliderMin, sliderMax } = useSelector(state => state.UFStore);
  const [minVal, setMinVal] = useState();
  const [maxVal, setMaxVal] = useState();
  const [sliderStep, setSliderStep] = useState(step);

  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  const getPercent = useCallback(value => Math.round(((value - min) / (max - min)) * 100), [min, max]);
  useEffect(() => setSliderStep(step), [step]);

  useEffect(() => {
    setMinVal(sliderMin);
    setMaxVal(sliderMax);
  }, [sliderMin, sliderMax]);

  useEffect(() => {
    if (minVal != undefined && maxVal != undefined && range?.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(maxVal);

      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, maxVal, range]);

  const handleValueChange = ({ target }) => {
    const { id, value } = target;
    let sliderVal = 0;
    if (id === "sliderMin") {
      sliderVal = Math.min(Number(event.target.value), maxVal - step);
      setMinVal(sliderVal);
      minValRef.current = value;
    } else {
      sliderVal = Math.max(Number(event.target.value), minVal + step);
      setMaxVal(sliderVal);
      maxValRef.current = sliderVal;
    }
    dispath(updateMinMaxSliderValue({ name: id, value: sliderVal }));
  };

  return (
    <>
      {min !== undefined && max !== undefined && sliderMin && (
        <div className='megaContainer'>
          <div className='limitLabel'>{min}</div>
          <div className='container'>
            <input
              id='sliderMin'
              type='range'
              min={min}
              max={max}
              value={minVal}
              step={sliderStep}
              onChange={handleValueChange}
              className='thumb thumb--left'
            />

            <input
              id='sliderMax'
              type='range'
              min={min}
              max={max}
              value={maxVal}
              onChange={handleValueChange}
              className='thumb thumb--right'
              step={sliderStep}
            />
            <div
              ref={minValRef}
              style={{
                left: `calc(${(100 * (minVal - min)) / (max - min)}%)`,
                position: "absolute",
                top: "-18px",
                fontSize: "11px",
              }}
            >
              {minVal}
            </div>
            <div
              ref={maxValRef}
              style={{
                left: `calc(${(100 * (maxVal - min)) / (max - min)}%)`,
                position: "absolute",
                top: "-18px",
                fontSize: "11px",
              }}
            >
              {maxVal}
            </div>
            <div className='slider'>
              <div className='slider__track' />
              <div ref={range} className='slider__range' />
            </div>
          </div>
          <div className='limitLabel'>{max}</div>
        </div>
      )}
    </>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.optional,
};

export default MultiRangeSlider;
