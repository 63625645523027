/* eslint-disable no-console */
import { getLoggerConfig, setLoggerConfig } from "./logger.conf";

export const configureLogger = config => setLoggerConfig(config);

const formatLog = argument => {
  try {
    return typeof argument === "string" ? argument : JSON.stringify(argument);
  } catch {
    return `${argument}`;
  }
};

const logToServer = async (message, level = "info") => {
  const { isLogToServer, logToServerURL } = getLoggerConfig();
  if (!isLogToServer || !logToServerURL) return;

  try {
    const response = await fetch(logToServerURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message, level }),
    });

    if (!response.ok) {
      throw new Error("Failed to send log to server");
    }
  } catch (error) {
    console.error("Error sending log to server:", error);
  }
};

const DupontLogger = (caller = "") => {
  const { isLogToConsole, isLogToServer } = getLoggerConfig();

  const logToServerIfNeeded = (level, ...args) => {
    if (isLogToServer) {
      const logMessage = `[${caller}] ${args.map(formatLog).join(" ")}`;
      logToServer(logMessage, level);
    }
  };

  const log = (...args) => {
    if (isLogToConsole) console.log(`[${caller}]`, ...args);
    logToServerIfNeeded("log", ...args);
  };

  const error = (...args) => {
    if (isLogToConsole) console.error(`[${caller}]`, ...args);
    logToServerIfNeeded("error", ...args);
  };

  const info = (...args) => {
    if (isLogToConsole) console.info(`[${caller}]`, ...args);
    logToServerIfNeeded("info", ...args);
  };

  const warn = (...args) => {
    if (isLogToConsole) console.warn(`[${caller}]`, ...args);
    logToServerIfNeeded("warn", ...args);
  };

  return { log, error, info, warn };
};

export default DupontLogger;
