import React from "react";

const ProgressBrokenIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='36' height='5' viewBox='0 0 36 5' fill='none'>
      <path d='M3.54492 2.25H33.999' stroke='#E1E1E1' strokeWidth='4' strokeLinecap='round' />
      <path d='M2 2.75H34' stroke='#007672' strokeWidth='4' strokeLinecap='round' strokeDasharray='7 6' />
    </svg>
  </>
);

export default ProgressBrokenIcon;
