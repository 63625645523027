import { WPReportDesignDiagram } from "@features/report-new/common/WPReportDesignDiagram";

import { WPUfSystemOverViewTable } from "../WPUfSystemOverviewTable";

export const WPUfSystemOverview = () => (
  <>
    <WPReportDesignDiagram />
    <WPUfSystemOverViewTable />
  </>
);
