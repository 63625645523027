import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { WPUfIngeCipParametersTableColumns as columns } from "./WPUfIngeCipParametersTable.constants";

export const WPUfIngeCipParametersTable = () => {
  const { uf_cip_parameters: rows } = useSelector(state => state.report.ufReportTableData);

  return (
    <WPTable
      sectionHeader={reportStrings.cipParameters}
      columns={columns}
      rows={rows}
      remarksList={[
        reportStrings.cipIngeFrequencyRemark,
        reportStrings.startCipBwRemark,
        reportStrings.ingeCipRemark3,
        reportStrings.oValueRemark,
      ]}
      footNotesList={[
        reportStrings.ingeCipFootNote1,
        reportStrings.cipStepDurationFootNote.replace("${steps}", "2-10"),
        reportStrings.ingeCipFootNote3,
        reportStrings.ingeCipFootNote4,
        reportStrings.cipDurationLongerFootNote,
        reportStrings.forwardFlushOptional,
      ]}
    />
  );
};
