import { UNITS } from "@constants/units.constant";

const GlobalUnitConversion = (unitConversionData, eValue, eToUnit, eFromUnit) => {
  const ToUnit = eToUnit;
  const FromUnit = eFromUnit;
  const Inputvalue = parseFloat(eValue);
  let finalUnitValue = 0;

  if (ToUnit === undefined || FromUnit === undefined) {
    return Inputvalue;
  }

  const findFactor = (ToUnit, FromUnit) => {
    const conversion = unitConversionData.find(
      ({ toUomName, fromUomName }) => toUomName === ToUnit && fromUomName === FromUnit,
    );
    return conversion ? conversion.convfactor : null;
  };

  if (ToUnit == FromUnit) {
    finalUnitValue = Inputvalue;
  } else {
    if (ToUnit === UNITS.celsius) {
      finalUnitValue = ((Inputvalue - 32) * 5) / 9;
    } else if (ToUnit === UNITS.farhenite) {
      finalUnitValue = (Inputvalue * 9) / 5 + 32;
    } else {
      let convfactor = 0;
      if (ToUnit === UNITS.millimeter && FromUnit === UNITS.meter) {
        convfactor = 1000;
      } else if (ToUnit === UNITS.centimeter && FromUnit === UNITS.meter) {
        convfactor = 100;
      } else if (ToUnit === UNITS.inch && FromUnit === UNITS.meter) {
        convfactor = 39.37;
      } else if (ToUnit === UNITS.meter && FromUnit === UNITS.millimeter) {
        convfactor = 0.001;
      } else if (ToUnit === UNITS.meter && FromUnit === UNITS.inch) {
        convfactor = 0.0254;
      } else if (ToUnit === UNITS.meter && FromUnit === UNITS.centimeter) {
        convfactor = 0.01;
      } else if (ToUnit === UNITS.kilowatt) {
        convfactor = 0.7457;
      } else if (FromUnit === UNITS.kilowatt) {
        convfactor = 1.341;
      } else if (ToUnit === UNITS.ppmv) {
        convfactor = 1.257;
      } else if (FromUnit === UNITS.ppmv) {
        convfactor = 0.7967;
      } else if (ToUnit === UNITS.gcm3) {
        convfactor = 0.1198264; //pound per gallon (U.S.) to gram per cubic centimeter
      } else if (FromUnit === UNITS.gcm3) {
        convfactor = 8.3454063545262; // gram per cubic centimeter to pound per gallon
      } else {
        convfactor = findFactor(ToUnit, FromUnit);
      }
      finalUnitValue = Inputvalue * convfactor;
    }
  }

  return finalUnitValue;
};

export default GlobalUnitConversion;
