import { useReportUtils } from "@features/report-new/useReportUtils";

import { WPUfConfiguringationOptionsTableLabels as TableLabels } from "./WPUfConfiguringationOptionsTable.constants";

export const useWPUfConfigurationOptionsTableData = () => {
  const { ufReportRespData, isSpecialFeatureVisible } = useReportUtils();

  const convertToUfConfigurationOptionsTableData = () => {
    const data = Object.entries(TableLabels).reduce((acc, [apiKey, labelValue]) => {
      let display = true;
      if (apiKey === "CEB_water_source") {
        display = isSpecialFeatureVisible("CEB");
      } else if (apiKey === "mini_CIP_water_source") {
        display = isSpecialFeatureVisible("mCIP");
      }
      if (!display) return acc;

      acc.push({
        col1: labelValue,
        col2: ufReportRespData?.uf_configuration_options?.[apiKey] || "",
      });
      return acc;
    }, []);

    return data;
  };

  return { convertToUfConfigurationOptionsTableData };
};
