import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

import CloseCircleIcon from "@common/icons/CloseCircleIcon";
import StandardPrimaryButton from "@common/styles/components/buttons/standard/StandardPrimaryButton";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import StyledModal from "@common/styles/components/modals/CustomModal";

const ProjectErrorPopup = ({ show, close, message }) => {
  // Function to handle keydown events
  const handleKeyDown = event => {
    if (event.key === "Enter") {
      close();
    }
  };
  useEffect(() => {
    // Added event listener when the modal is shown
    if (show) {
      document.addEventListener("keydown", handleKeyDown);
    }
    // Clean up the event listener on unmount or when show changes
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [show]);

  return (
    <StyledModal
      show={show}
      onHide={close}
      centered
      backdrop='static'
      keyboard={false}
      isWarningPopUp={true}
      maxWidth='416px'
    >
      <Modal.Body>
        <div className='warning-pop-up'>
          <div>
            <CloseCircleIcon />
          </div>
          <div>
            <CustomHeading fontFamily='DiodrumSemiBold' fontSize='16px' fontWeight='600' label={message} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <StandardPrimaryButton label='Ok' className='ok-btn' onClick={close} />
      </Modal.Footer>
    </StyledModal>
  );
};

export default ProjectErrorPopup;
