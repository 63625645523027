import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import useUnitConversion from "@hooks/useUnitConversion";

import BoldTextElement from "../UFDiagramsUtils/BoldTextElement";
import TextElement from "../UFDiagramsUtils/TextElement";
import { getPumpValues } from "../UFDiagramsUtils/UFDiagramHelper";

const PRESSURE_METRIC_UNIT = "bar";
const pressureValue = 3.3;

const UFDiagramFeedPumpDetails = ({ isInge, isCIP }) => {
  const { unitConversionByName } = useUnitConversion();
  const { calcEngineData } = useSelector(state => state.UFStore);
  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig);
  const [volumData, setVolumData] = useState({});

  useEffect(() => {
    let value = pressureValue;
    if (value && unitConfig.selectedUnits[3] !== PRESSURE_METRIC_UNIT) {
      value = unitConversionByName(value, unitConfig.selectedUnits[3], PRESSURE_METRIC_UNIT);
    }
    const data = getPumpValues(unitConfig, calcEngineData, "flow_Feed_Pump_max", value, unitConversionByName);
    setVolumData(data);
  }, [unitConfig.selectedUnits, calcEngineData, getPumpValues]);

  const xyPosition = useMemo(() => isInge ? (
    isCIP ? { x: 550, y: 2815 } : { x: 550, y: 2400 }
  ) : { x: 600, y: 2825 }, [isInge]);

  return (
    <>
      <BoldTextElement {...xyPosition}>
        Feed Pump
      </BoldTextElement>
      <TextElement fill='#454545'>
        <tspan x={xyPosition.x} y={xyPosition.y + 140}>
          Max {volumData.value}
        </tspan>
        <tspan x={xyPosition.x} y={xyPosition.y + 280}>
          {volumData.pressure}
        </tspan>
      </TextElement>
    </>
  );
};

export default UFDiagramFeedPumpDetails;
