import React from "react";

const DragButtonIcon = () => (
  <>
    <svg width='4' height='9' viewBox='0 0 4 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <ellipse cx='0.666667' cy='0.642857' rx='0.666667' ry='0.642857' fill='black' />
      <ellipse cx='3.33366' cy='0.642857' rx='0.666667' ry='0.642857' fill='black' />
      <ellipse cx='0.666667' cy='4.50028' rx='0.666667' ry='0.642857' fill='black' />
      <ellipse cx='0.666667' cy='8.35672' rx='0.666667' ry='0.642857' fill='black' />
      <ellipse cx='3.33366' cy='4.50028' rx='0.666667' ry='0.642857' fill='black' />
      <ellipse cx='3.33366' cy='8.35672' rx='0.666667' ry='0.642857' fill='black' />
    </svg>
  </>
);

export default DragButtonIcon;
