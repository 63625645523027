import React from "react";
import styled from "styled-components";

import MandatoryFieldIcon from "../../../icons/MandatoryFieldIcon";

import "../../diodrumFont.css";

const StyledHeading = styled.h3`
  font-family: ${props => props.fontFamily || "DiodrumRegular"};
  font-style: normal;
  line-height: ${props => props.lineHeight || "18px"};
  font-size: ${props => props.fontSize || "14px"};
  font-weight: ${props => props.fontWeight || "normal"};
  color: ${props => props.color || "#000"};
  margin-bottom: 0;
  display: flex;
`;
const CustomHeading = ({ label, children, mandatoryIcon, ...props }) => (
  <StyledHeading {...props}>
    {mandatoryIcon && <MandatoryFieldIcon />}
    {label}
    {children}
  </StyledHeading>
);

export default CustomHeading;
