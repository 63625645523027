import { createSlice } from "@reduxjs/toolkit";
const localData = {
  userID: 0,
  projectID: 0,
  caseID: 0,
  treatmentObjID: 0,
  flux_Filter_actual: 0,
  ufReport: {
    method: "default",
    exportReport: 0,
    reportType: 0,
    WaterTypeID: 0,
    WaterSubTypeID: 0,
    TechnologyId: 0,
    Flow_Design: 0,
    Flag_Design_Flow: 0,
    Guideline_number: 0,
    Temp_min: 0,
    Temp_design: 0,
    Temp_max: 0,
    Recovery_Pretreat: 0.0,
    Strainer_Size: 0,
    Recovery_RO: 0,
    Feed_acid_name: 0,
    Feed_acid_conc: 0,
    Feed_acid_pH: 7,
    Feed_coag_name: 0,
    Feed_coag_conc: 0,
    Feed_ox_name: 0,
    Feed_ox_conc: 0,
    N_Part_number: "0",
    N_Part_number_long: "0",
    Company: "DuPont",
    Drinking_water_part_names: "0",
    IntegraPac: "0",
    T_Rack: "0",
    Mem_Rack: "0",
    IntegraFlo: "0",
    Flux_Filter_target: 0,
    Flux_BW: 0,
    Flux_CEB: 0,
    Flow_FF: 0,
    Flow_FF2: 0,
    Flow_FF3: 0,
    Flow_FF4: 0,
    Flow_AS: 0,
    Flow_AS2: 0,
    Flow_mCIP_recycle: 0,
    Flow_CIP_recycle: 0,
    Flag_cycle_input: 0,
    t_filtration_cycle: 0,
    t_interval_AS: 0,
    t_interval_CEB_acid: 0,
    t_interval_CEB_caustic: 0,
    t_interval_CEB_Oxidant: 0,
    t_interval_mCIP: 0,
    t_interval_CIP: 0,
    t_MIT_module_day: 0,
    TMP_slope_BW: 0,
    TMP_slope_CEB1: 0,
    TMP_slope_CEB2: 0,
    TMP_slope_mCIP: 0,
    TMP_slope_CIP: 0,
    Standby_Option: "0",
    Flag_CIP_standby: 0,
    Flag_Storage_Tank: 1,
    N_Trains_online: 1,
    N_Trains_standby: 0,
    N_Trains_Redundant: 0,
    N_Modules_per_Train: 0,
    IP_Skids_train: 0,
    IP_Mod_skid: 0,
    Flag_BW: 0,
    Flag_FF: 0,
    Flag_BW_Protocol: 0,
    Temp_BW: 0,
    t_AS: 0,
    t_Drain: 0.0,
    t_BW1: 0.0,
    t_BW2: 0.0,
    t_FF: 0.0,
    t_LF: 0,
    t_FTL: 0,
    BW_ox_name: 0,
    BW_ox_conc: 0,
    Temp_CEB: 0,
    Flag_CEB: 0,
    t_AS_CEB: 0.0,
    t_Drain_CEB: 0.0,
    t_BW1_CEB: 0.0,
    t_BW2_CEB: 0.0,
    t_FF_CEB: 0.0,
    t_CEB_soak: 0,
    t_BW1_CEBrinse: 0,
    t_BW2_CEBrinse: 0,
    N_CEB_RScycles: 0,
    CEB1_acid_name: "0",
    CEB1_acid_conc: 0,
    CEB1_acid_pH: 0,
    CEB1_org_acid_name: 0,
    CEB1_org_acid_conc: 0,
    Flag_CEB1_Chem: false,
    CEB2_base_name: "0",
    CEB2_base_conc: 0,
    CEB2_base_pH: 0,
    CEB2_ox_name: "0",
    CEB2_ox_conc: 0,
    Flag_CEB2_Chem: false,
    CEB3_ox_name: 0,
    CEB3_ox_conc: 0,
    Temp_mCIP: 0,
    N_BW_mCIP: 0,
    N_BW_Rinse_mCIP: 0,
    Flag_mCIP: 0,
    t_mCIP_heat: 0,
    t_mCIP_recycle: 0,
    t_mCIP_soak: 0,
    N_mCIP_RScycles: 0,
    mCIP_acid_name: 0,
    mCIP_acid_conc: 0,
    mCIP_acid_pH: 0,
    mCIP_org_acid_name: 0,
    mCIP_org_acid_conc: 0,
    Flag_mCIP1_Chem: false,
    mCIP_base_name: 0,
    mCIP_base_conc: 0,
    mCIP_base_pH: 0,
    mCIP_ox_name: 0,
    mCIP_ox_conc: 0,
    mCIP_SLS_name: 0,
    mCIP_SLS_conc: 0,
    Flag_mCIP2_Chem: 0,
    Temp_CIP: 0,
    N_BW_CIP: 0,
    N_BW_Rinse_CIP: 0,
    Flag_CIP: 0,
    t_CIP_heat: 0,
    t_CIP_recycle: 0,
    t_CIP_soak: 0,
    N_CIP_RScycles: 0,
    CIP_acid_name: "HCl",
    CIP_acid_conc: 0,
    CIP_acid_pH: 0,
    CIP_org_acid_name: 0,
    CIP_org_acid_conc: 0,
    CIP_N_Chem1_Flag: false,
    CIP_base_name: "NaOH",
    CIP_base_conc: 0,
    CIP_base_pH: 0,
    CIP_ox_name: "NaOCl",
    CIP_ox_conc: 0,
    CIP_SLS_name: 0,
    CIP_SLS_conc: 0,
    CIP_N_Chem2_Flag: false,
    AdditionalSettingsScreen: false,
    P_air_max: 0.0,
    P_ADBW_max: 0,
    P_Filtrate: 0.0,
    Delta_P_piping_filtration: 0.0,
    Delta_P_strainer_filtration: 0.0,
    Delta_P_piping_BW: 0.0,
    Delta_P_piping_mCIP: 0,
    Delta_P_piping_CIP: 0.0,
    StorageTankParameters: 0,
    f_Chem_storage_days: 0,
    f_BW_tank_feed: 0,
    f_Filtrate_tank_safety_margin: 0,
    f_BW_tank_safety_margin: 0,
    f_mCIP_tank: 0,
    f_CIP_tank: 0,
    f_ADBW: 0,
    f_FTL: 0,
    N_valves_per_skid: 6,
    t_wait: 0,
    t_valve: 0.0,
    t_ramp: 0,
    Power_PLC: 0.0,
    Power_valve: 0,
    ProjectLevelUserEntries: 0,
    Eff_motor_feed: 0.0,
    Eff_motor_BW: 0.0,
    Eff_motor_mCIP: 0,
    Eff_motor_CIP: 0.0,
    Eff_motor_metering: 0.0,
    Eff_motor_compressor: 0.0,
    Eff_pump_feed: 0.0,
    Eff_pump_BW: 0.0,
    Eff_pump_mCIP: 0,
    Eff_pump_CIP: 0.0,
    Eff_pump_metering: 0.0,
    Eff_compressor: 0.0,
    OperatingCostPrices: 0,
    Price_Elec: 0.0,
    Price_Water: 0.0,
    Price_Waste: 0.0,
    N_BW_per_AS: 0,
    N_F_per_CEB1: 0,
    N_F_per_CEB2: 0,
    N_F_per_CEB3: 0,
    N_F_per_mCIP: 0,
    N_F_per_CIP: 0,
    t_normal_module_cycle: 0,
    N_Chem_CEB1: 0,
    N_Chem_CEB2: 0,
    N_Chem_CEB3: 0,
    N_Chem_CIP: 0,
    N_Chem_mCIP: 0,
    ModuleProperties: 0,
    Area_Module: 0,
    Vol_module: 0,
    Length_module: 0.0,
    Length_fibers: 0.0,
    N_capillary: 1,
    N_Capillary_Ends: 1,
    D_ID: 0.0,
    D_OD: 0.0,
    Av: 0.0,
    P0: 0.0,
    S0: 0,
    S10: 0,
    S20: 0.0,
    S30: 0.0,
    S40: 0.0,
    Feed_acid_bulk_conc: 0,
    Feed_acid_density: 0,
    Feed_acid_price: 0,
    Feed_coag_bulk_conc: 0,
    Feed_coag_density: 0,
    Feed_coag_price: 0,
    Feed_ox_bulk_conc: 0,
    Feed_ox_density: 0,
    Feed_ox_price: 0,
    BW_ox_bulk_conc: 0,
    BW_ox_density: 0,
    BW_ox_price: 0,
    CEB1_acid_bulk_conc: 0.0,
    CEB1_acid_density: 0.0,
    CEB1_acid_price: 0.0,
    CEB1_org_acid_bulk_conc: 0,
    CEB1_org_acid_density: 0,
    CEB1_org_acid_price: 0,
    CEB2_base_bulk_conc: 0.0,
    CEB2_base_density: 0.0,
    CEB2_base_price: 0.0,
    CEB2_ox_bulk_conc: 0.0,
    CEB2_ox_density: 0.0,
    CEB2_ox_price: 0.0,
    CEB3_ox_bulk_conc: 0,
    CEB3_ox_density: 0,
    CEB3_ox_price: 0,
    mCIP_acid_bulk_conc: 0,
    mCIP_acid_density: 0,
    mCIP_acid_price: 0,
    mCIP_org_acid_bulk_conc: 0,
    mCIP_org_acid_density: 0,
    mCIP_org_acid_price: 0,
    mCIP_base_bulk_conc: 0,
    mCIP_base_density: 0,
    mCIP_base_price: 0,
    mCIP_ox_bulk_conc: 0,
    mCIP_ox_density: 0,
    mCIP_ox_price: 0,
    mCIP_SLS_bulk_conc: 0,
    mCIP_SLS_density: 0,
    mCIP_SLS_price: 0,
    CIP_acid_bulk_conc: 0.0,
    CIP_acid_density: 0.0,
    CIP_acid_price: 0.0,
    CIP_org_acid_bulk_conc: 0,
    CIP_org_acid_density: 0,
    CIP_org_acid_price: 0,
    CIP_base_bulk_conc: 0.0,
    CIP_base_density: 0.0,
    CIP_base_price: 0.0,
    CIP_ox_bulk_conc: 0.0,
    CIP_ox_density: 0.0,
    CIP_ox_price: 0.0,
    CIP_SLS_bulk_conc: 0,
    CIP_SLS_density: 0,
    CIP_SLS_price: 0,
    Citric_Acid_bulk_conc: 0,
    FeCl3_bulk_conc: 0,
    H2SO4_bulk_conc: 0.0,
    HCl_bulk_conc: 0.0,
    NaOCl_bulk_conc: 0.0,
    NaOH_bulk_conc: 0.0,
    Oxalic_Acid_bulk_conc: 1,
    PACl_bulk_conc: 0,
    SLS_bulk_conc: 0,
    Citric_Acid_density: 0.0,
    FeCl3_density: 0.0,
    H2SO4_density: 0.0,
    HCl_density: 0.0,
    NaOCl_density: 0.0,
    NaOH_density: 0.0,
    Oxalic_Acid_density: 0.0,
    PACl_density: 0,
    SLS_density: 0,
    Citric_Acid_price: 0.0,
    FeCl3_price: 0.0,
    H2SO4_price: 0.0,
    HCl_price: 0.0,
    NaOCl_price: 0.0,
    NaOH_price: 0.0,
    Oxalic_Acid_price: 0.0,
    PACl_price: 0,
    SLS_price: 0,
    feed_water: {
      designTemp: 0,
      methodname: "normal",
      ph: 7,
      Degas: 0,
      percentage_of_initial_total_CO2_remaining: 100,
      Equilibrate_with: 0,
      Adjustment_Type: 0,
      Add_Reagent: 0,
      Total_CO2: 0,
      turbidity: 0,
      organicToc: 0,
      tss: 0,
      tds: 0,
      cations: [
        {
          name: "Ba",
          mgL: 0,
          molCharge: 0,
          soluteID: 0,
          ppm: 0,
          meqL: 0,
        },
        {
          name: "Ca",
          mgL: 0,
          molCharge: 2,
          soluteID: 5,
          ppm: 0,
          meqL: 0,
        },
        {
          name: "K",
          mgL: 0,
          molCharge: 1,
          soluteID: 2,
          ppm: 0,
          meqL: 0,
        },
        {
          name: "Mg",
          mgL: 0,
          molCharge: 2,
          soluteID: 4,
          ppm: 0,
          meqL: 0,
        },
        {
          name: "Na",
          mgL: 0,
          molCharge: 1,
          soluteID: 3,
          ppm: 0,
          meqL: 0,
        },
        {
          name: "NH₄",
          mgL: 0,
          molCharge: 1,
          soluteID: 1,
          ppm: 0,
          meqL: 0,
        },
        {
          name: "Sr",
          mgL: 0,
          molCharge: 2,
          soluteID: 6,
          ppm: 0,
          meqL: 0,
        },
      ],
      anions: [
        {
          name: "Br",
          mgL: 0,
          molCharge: -1,
          soluteID: 28,
          ppm: 0,
          meqL: 0,
        },
        {
          name: "Cl",
          mgL: 0,
          molCharge: -1,
          soluteID: 11,
          ppm: 0,
          meqL: 0,
        },
        {
          name: "CO₃",
          mgL: 0,
          molCharge: -2,
          soluteID: 8,
          ppm: 0,
          meqL: 0,
        },
        {
          name: "F",
          mgL: 0,
          molCharge: -1,
          soluteID: 12,
          ppm: 0,
          meqL: 0,
        },
        {
          name: "HCO₃",
          mgL: 0,
          molCharge: -1,
          soluteID: 9,
          ppm: 0,
          meqL: 0,
        },
        {
          name: "NO₃",
          mgL: 0,
          molCharge: -1,
          soluteID: 10,
          ppm: 0,
          meqL: 0,
        },
        {
          name: "PO₄",
          mgL: 0,
          molCharge: -3,
          soluteID: 23,
          ppm: 0,
          meqL: 0,
        },
        {
          name: "SO₄",
          mgL: 0,
          molCharge: -2,
          soluteID: 13,
          ppm: 0,
          meqL: 0,
        },
      ],
      neutrals: [
        {
          name: "B",
          mgL: 0,
          molCharge: 0,
          soluteID: 29,
          ppm: 0,
          meqL: 0,
        },
        {
          name: "CO₂",
          mgL: 0,
          molCharge: 0,
          soluteID: 30,
          ppm: 0,
          meqL: 0,
        },
        {
          name: "SiO₂",
          mgL: 0,
          molCharge: 0,
          soluteID: 14,
          ppm: 0,
          meqL: 0,
        },
      ],
      LSI_targ: 0,
      SDI_targ: 0,
      ChemicalAdjustment: [
        {
          CaSO4_per: 0,
          BaSO4_per: 0,
          SrSO4_per: 0,
          CaF2_per: 0,
          SiO2_per: 0,
          MgOH2_per: 0,
          LSI: 0,
          SDI: 0,
        },
      ],
    },
  },
  lstdefaultProjectUnits: [
    {
      uomId: 2,
      uomTypeID: 1,
      uomTypeName: "Flow",
      uomName: "m³/h",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 4,
      uomTypeID: 2,
      uomTypeName: "Temperature",
      uomName: "°C",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 6,
      uomTypeID: 3,
      uomTypeName: "Pressure",
      uomName: "bar",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 8,
      uomTypeID: 4,
      uomTypeName: "Flux",
      uomName: "LMH",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 10,
      uomTypeID: 5,
      uomTypeName: "Area",
      uomName: "m²",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 12,
      uomTypeID: 6,
      uomTypeName: "Concentration (gases)",
      uomName: "µatm",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 14,
      uomTypeID: 7,
      uomTypeName: "Density",
      uomName: "g/cm³",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 40,
      uomTypeID: 8,
      uomTypeName: "Length",
      uomName: "mm",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 18,
      uomTypeID: 9,
      uomTypeName: "Power",
      uomName: "kW",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 20,
      uomTypeID: 10,
      uomTypeName: "Specific Velocity",
      uomName: "BV/h",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 24,
      uomTypeID: 11,
      uomTypeName: "Volume (Solution)",
      uomName: "m³",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 27,
      uomTypeID: 12,
      uomTypeName: "Volume (Resin)",
      uomName: "m³",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 45,
      uomTypeID: 13,
      uomTypeName: "Volume (Common)",
      uomName: "BV",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 29,
      uomTypeID: 14,
      uomTypeName: "Regeneration Dose",
      uomName: "g/L",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 31,
      uomTypeID: 15,
      uomTypeName: "Linear Velocity",
      uomName: "m/h",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 33,
      uomTypeID: 16,
      uomTypeName: "Weight",
      uomName: "kg",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 34,
      uomTypeID: 17,
      uomTypeName: "Conductivity",
      uomName: "µS/cm",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 37,
      uomTypeID: 18,
      uomTypeName: "Gas Flow",
      uomName: "Nm³/h",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 38,
      uomTypeID: 19,
      uomTypeName: "Organics",
      uomName: "mg/L TOC",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
  ],
  reportProjectInfoVM: {
    projectNumber: "TP-6658",
    projectName: "Untitled-Project-ufuf",
    createDate: "2024-03-18T07:37:55.27",
    lastModified: "2024-03-18T11:37:12.21",
    caseName: "Case 1",
    preparedBy: "PriyankaRani",
    designerCompany: "TCS",
    customer: "",
    countryName: "",
    segmentName: "Food & Beverage",
    projectNotes: "",
    exchangeRate: 1.0,
    currencySymbol: "$",
    appVersion: "1.0.448",
  },
  lstAllUOMs: [
    {
      uomId: 1,
      uomTypeID: 1,
      uomTypeName: "Flow",
      uomName: "gpm",
      uomsystemId: 1,
      uomsystemName: "US",
      isSelected: false,
    },
    {
      uomId: 2,
      uomTypeID: 1,
      uomTypeName: "Flow",
      uomName: "m³/h",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 41,
      uomTypeID: 1,
      uomTypeName: "Flow",
      uomName: "gpd",
      uomsystemId: 1,
      uomsystemName: "US",
      isSelected: false,
    },
    {
      uomId: 42,
      uomTypeID: 1,
      uomTypeName: "Flow",
      uomName: "m³/d",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: false,
    },
    {
      uomId: 43,
      uomTypeID: 1,
      uomTypeName: "Flow",
      uomName: "L/s",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: false,
    },
    {
      uomId: 3,
      uomTypeID: 2,
      uomTypeName: "Temperature",
      uomName: "°F",
      uomsystemId: 1,
      uomsystemName: "US",
      isSelected: false,
    },
    {
      uomId: 4,
      uomTypeID: 2,
      uomTypeName: "Temperature",
      uomName: "°C",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 5,
      uomTypeID: 3,
      uomTypeName: "Pressure",
      uomName: "psi",
      uomsystemId: 1,
      uomsystemName: "US",
      isSelected: false,
    },
    {
      uomId: 6,
      uomTypeID: 3,
      uomTypeName: "Pressure",
      uomName: "bar",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 44,
      uomTypeID: 3,
      uomTypeName: "Pressure",
      uomName: "kPa",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: false,
    },
    {
      uomId: 7,
      uomTypeID: 4,
      uomTypeName: "Flux",
      uomName: "gfd",
      uomsystemId: 1,
      uomsystemName: "US",
      isSelected: false,
    },
    {
      uomId: 8,
      uomTypeID: 4,
      uomTypeName: "Flux",
      uomName: "LMH",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 9,
      uomTypeID: 5,
      uomTypeName: "Area",
      uomName: "ft²",
      uomsystemId: 1,
      uomsystemName: "US",
      isSelected: false,
    },
    {
      uomId: 10,
      uomTypeID: 5,
      uomTypeName: "Area",
      uomName: "m²",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 11,
      uomTypeID: 6,
      uomTypeName: "Concentration (gases)",
      uomName: "ppmv",
      uomsystemId: 1,
      uomsystemName: "US",
      isSelected: false,
    },
    {
      uomId: 12,
      uomTypeID: 6,
      uomTypeName: "Concentration (gases)",
      uomName: "µatm",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 13,
      uomTypeID: 7,
      uomTypeName: "Density",
      uomName: "lb/gal",
      uomsystemId: 1,
      uomsystemName: "US",
      isSelected: false,
    },
    {
      uomId: 14,
      uomTypeID: 7,
      uomTypeName: "Density",
      uomName: "g/cm³",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 15,
      uomTypeID: 8,
      uomTypeName: "Length",
      uomName: "in",
      uomsystemId: 1,
      uomsystemName: "US",
      isSelected: false,
    },
    {
      uomId: 16,
      uomTypeID: 8,
      uomTypeName: "Length",
      uomName: "cm",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: false,
    },
    {
      uomId: 40,
      uomTypeID: 8,
      uomTypeName: "Length",
      uomName: "mm",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 17,
      uomTypeID: 9,
      uomTypeName: "Power",
      uomName: "hp",
      uomsystemId: 1,
      uomsystemName: "US",
      isSelected: false,
    },
    {
      uomId: 18,
      uomTypeID: 9,
      uomTypeName: "Power",
      uomName: "kW",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 19,
      uomTypeID: 10,
      uomTypeName: "Specific Velocity",
      uomName: "gpm/ft³",
      uomsystemId: 1,
      uomsystemName: "US",
      isSelected: false,
    },
    {
      uomId: 20,
      uomTypeID: 10,
      uomTypeName: "Specific Velocity",
      uomName: "BV/h",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 21,
      uomTypeID: 11,
      uomTypeName: "Volume (Solution)",
      uomName: "gal",
      uomsystemId: 1,
      uomsystemName: "US",
      isSelected: false,
    },
    {
      uomId: 22,
      uomTypeID: 11,
      uomTypeName: "Volume (Solution)",
      uomName: "L",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: false,
    },
    {
      uomId: 24,
      uomTypeID: 11,
      uomTypeName: "Volume (Solution)",
      uomName: "m³",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 25,
      uomTypeID: 12,
      uomTypeName: "Volume (Resin)",
      uomName: "ft³",
      uomsystemId: 1,
      uomsystemName: "US",
      isSelected: false,
    },
    {
      uomId: 26,
      uomTypeID: 12,
      uomTypeName: "Volume (Resin)",
      uomName: "L",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: false,
    },
    {
      uomId: 27,
      uomTypeID: 12,
      uomTypeName: "Volume (Resin)",
      uomName: "m³",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 45,
      uomTypeID: 13,
      uomTypeName: "Volume (Common)",
      uomName: "BV",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 28,
      uomTypeID: 14,
      uomTypeName: "Regeneration Dose",
      uomName: "lb/ft³",
      uomsystemId: 1,
      uomsystemName: "US",
      isSelected: false,
    },
    {
      uomId: 29,
      uomTypeID: 14,
      uomTypeName: "Regeneration Dose",
      uomName: "g/L",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 30,
      uomTypeID: 15,
      uomTypeName: "Linear Velocity",
      uomName: "gpm/ft²",
      uomsystemId: 1,
      uomsystemName: "US",
      isSelected: false,
    },
    {
      uomId: 31,
      uomTypeID: 15,
      uomTypeName: "Linear Velocity",
      uomName: "m/h",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 32,
      uomTypeID: 16,
      uomTypeName: "Weight",
      uomName: "lb",
      uomsystemId: 1,
      uomsystemName: "US",
      isSelected: false,
    },
    {
      uomId: 33,
      uomTypeID: 16,
      uomTypeName: "Weight",
      uomName: "kg",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 34,
      uomTypeID: 17,
      uomTypeName: "Conductivity",
      uomName: "µS/cm",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 35,
      uomTypeID: 17,
      uomTypeName: "Conductivity",
      uomName: "mS/cm",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: false,
    },
    {
      uomId: 36,
      uomTypeID: 18,
      uomTypeName: "Gas Flow",
      uomName: "scfm",
      uomsystemId: 1,
      uomsystemName: "US",
      isSelected: false,
    },
    {
      uomId: 37,
      uomTypeID: 18,
      uomTypeName: "Gas Flow",
      uomName: "Nm³/h",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 38,
      uomTypeID: 19,
      uomTypeName: "Organics",
      uomName: "mg/L TOC",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: true,
    },
    {
      uomId: 39,
      uomTypeID: 19,
      uomTypeName: "Organics",
      uomName: "mg/L KMnO₄",
      uomsystemId: 2,
      uomsystemName: "Metric",
      isSelected: false,
    },
  ],
  lstreportConversionFactors: [
    {
      fromUomName: "gpm",
      fromUomID: 1,
      toUomName: "m³/h",
      toUomID: 2,
      convfactor: 0.2271247,
    },
    {
      fromUomName: "gpm",
      fromUomID: 1,
      toUomName: "gpd",
      toUomID: 41,
      convfactor: 1440,
    },
    {
      fromUomName: "gpm",
      fromUomID: 1,
      toUomName: "m³/d",
      toUomID: 42,
      convfactor: 5.450993,
    },
    {
      fromUomName: "gpm",
      fromUomID: 1,
      toUomName: "L/s",
      toUomID: 43,
      convfactor: 0.0630902,
    },
    {
      fromUomName: "m³/h",
      fromUomID: 2,
      toUomName: "gpm",
      toUomID: 1,
      convfactor: 4.402867,
    },
    {
      fromUomName: "m³/h",
      fromUomID: 2,
      toUomName: "gpd",
      toUomID: 41,
      convfactor: 6340.618,
    },
    {
      fromUomName: "m³/h",
      fromUomID: 2,
      toUomName: "m³/d",
      toUomID: 42,
      convfactor: 24,
    },
    {
      fromUomName: "m³/h",
      fromUomID: 2,
      toUomName: "L/s",
      toUomID: 43,
      convfactor: 0.277778,
    },
    {
      fromUomName: "psi",
      fromUomID: 5,
      toUomName: "bar",
      toUomID: 6,
      convfactor: 0.06894921,
    },
    {
      fromUomName: "psi",
      fromUomID: 5,
      toUomName: "kPa",
      toUomID: 44,
      convfactor: 6.894921,
    },
    {
      fromUomName: "bar",
      fromUomID: 6,
      toUomName: "psi",
      toUomID: 5,
      convfactor: 14.50342941,
    },
    {
      fromUomName: "bar",
      fromUomID: 6,
      toUomName: "kPa",
      toUomID: 44,
      convfactor: 100,
    },
    {
      fromUomName: "gfd",
      fromUomID: 7,
      toUomName: "LMH",
      toUomID: 8,
      convfactor: 1.697743,
    },
    {
      fromUomName: "LMH",
      fromUomID: 8,
      toUomName: "gfd",
      toUomID: 7,
      convfactor: 0.589017301,
    },
    {
      fromUomName: "ft²",
      fromUomID: 9,
      toUomName: "m²",
      toUomID: 10,
      convfactor: 0.09290304,
    },
    {
      fromUomName: "m²",
      fromUomID: 10,
      toUomName: "ft²",
      toUomID: 9,
      convfactor: 10.76391042,
    },
    {
      fromUomName: "in",
      fromUomID: 15,
      toUomName: "cm",
      toUomID: 16,
      convfactor: 2.54,
    },
    {
      fromUomName: "in",
      fromUomID: 15,
      toUomName: "mm",
      toUomID: 40,
      convfactor: 25.4,
    },
    {
      fromUomName: "cm",
      fromUomID: 16,
      toUomName: "in",
      toUomID: 15,
      convfactor: 0.393700787,
    },
    {
      fromUomName: "cm",
      fromUomID: 16,
      toUomName: "mm",
      toUomID: 40,
      convfactor: 10,
    },
    {
      fromUomName: "hp",
      fromUomID: 17,
      toUomName: "kW",
      toUomID: 18,
      convfactor: 0.7457,
    },
    {
      fromUomName: "kW",
      fromUomID: 18,
      toUomName: "hp",
      toUomID: 17,
      convfactor: 1.341021859,
    },
    {
      fromUomName: "gpm/ft³",
      fromUomID: 19,
      toUomName: "BV/h",
      toUomID: 20,
      convfactor: 8.0208337,
    },
    {
      fromUomName: "BV/h",
      fromUomID: 20,
      toUomName: "gpm/ft³",
      toUomID: 19,
      convfactor: 0.124675319,
    },
    {
      fromUomName: "gal",
      fromUomID: 21,
      toUomName: "L",
      toUomID: 22,
      convfactor: 3.785412,
    },
    {
      fromUomName: "gal",
      fromUomID: 21,
      toUomName: "cm³",
      toUomID: 23,
      convfactor: 3785.412,
    },
    {
      fromUomName: "gal",
      fromUomID: 21,
      toUomName: "m³",
      toUomID: 24,
      convfactor: 0.003785412,
    },
    {
      fromUomName: "gal",
      fromUomID: 21,
      toUomName: "m³",
      toUomID: 27,
      convfactor: 0.00378541,
    },
    {
      fromUomName: "L",
      fromUomID: 22,
      toUomName: "gal",
      toUomID: 21,
      convfactor: 0.26417203,
    },
    {
      fromUomName: "L",
      fromUomID: 22,
      toUomName: "cm³",
      toUomID: 23,
      convfactor: 1000,
    },
    {
      fromUomName: "L",
      fromUomID: 22,
      toUomName: "m³",
      toUomID: 24,
      convfactor: 0.001,
    },
    {
      fromUomName: "cm³",
      fromUomID: 23,
      toUomName: "gal",
      toUomID: 21,
      convfactor: 0.0002641721,
    },
    {
      fromUomName: "cm³",
      fromUomID: 23,
      toUomName: "L",
      toUomID: 22,
      convfactor: 0.001,
    },
    {
      fromUomName: "cm³",
      fromUomID: 23,
      toUomName: "m³",
      toUomID: 24,
      convfactor: 0.000001,
    },
    {
      fromUomName: "m³",
      fromUomID: 24,
      toUomName: "gal",
      toUomID: 21,
      convfactor: 264.1720372,
    },
    {
      fromUomName: "m³",
      fromUomID: 24,
      toUomName: "L",
      toUomID: 22,
      convfactor: 1000,
    },
    {
      fromUomName: "m³",
      fromUomID: 24,
      toUomName: "cm³",
      toUomID: 23,
      convfactor: 1000000,
    },
    {
      fromUomName: "ft³",
      fromUomID: 25,
      toUomName: "L",
      toUomID: 26,
      convfactor: 28.31685,
    },
    {
      fromUomName: "ft³",
      fromUomID: 25,
      toUomName: "m³",
      toUomID: 27,
      convfactor: 0.02831685,
    },
    {
      fromUomName: "L",
      fromUomID: 26,
      toUomName: "m³",
      toUomID: 24,
      convfactor: 0.000001,
    },
    {
      fromUomName: "L",
      fromUomID: 26,
      toUomName: "ft³",
      toUomID: 25,
      convfactor: 0.0353146625,
    },
    {
      fromUomName: "L",
      fromUomID: 26,
      toUomName: "m³",
      toUomID: 27,
      convfactor: 0.001,
    },
    {
      fromUomName: "m³",
      fromUomID: 27,
      toUomName: "gal",
      toUomID: 21,
      convfactor: 264.172,
    },
    {
      fromUomName: "m³",
      fromUomID: 27,
      toUomName: "ft³",
      toUomID: 25,
      convfactor: 35.3146624713,
    },
    {
      fromUomName: "m³",
      fromUomID: 27,
      toUomName: "L",
      toUomID: 26,
      convfactor: 1000,
    },
    {
      fromUomName: "lb/ft³",
      fromUomID: 28,
      toUomName: "g/L",
      toUomID: 29,
      convfactor: 16.01846352,
    },
    {
      fromUomName: "g/L",
      fromUomID: 29,
      toUomName: "lb/ft³",
      toUomID: 28,
      convfactor: 0.06242796,
    },
    {
      fromUomName: "gpm/ft²",
      fromUomID: 30,
      toUomName: "m/h",
      toUomID: 31,
      convfactor: 2.4447501,
    },
    {
      fromUomName: "m/h",
      fromUomID: 31,
      toUomName: "gpm/ft²",
      toUomID: 30,
      convfactor: 0.409039762,
    },
    {
      fromUomName: "lb",
      fromUomID: 32,
      toUomName: "kg",
      toUomID: 33,
      convfactor: 0.453592,
    },
    {
      fromUomName: "kg",
      fromUomID: 33,
      toUomName: "lb",
      toUomID: 32,
      convfactor: 2.20462,
    },
    {
      fromUomName: "µS/cm",
      fromUomID: 34,
      toUomName: "mS/cm",
      toUomID: 35,
      convfactor: 0.001,
    },
    {
      fromUomName: "mS/cm",
      fromUomID: 35,
      toUomName: "µS/cm",
      toUomID: 34,
      convfactor: 1000,
    },
    {
      fromUomName: "scfm",
      fromUomID: 36,
      toUomName: "Nm³/h",
      toUomID: 37,
      convfactor: 1.699010795,
    },
    {
      fromUomName: "Nm³/h",
      fromUomID: 37,
      toUomName: "scfm",
      toUomID: 36,
      convfactor: 0.588577779,
    },
    {
      fromUomName: "mg/L TOC",
      fromUomID: 38,
      toUomName: "mg/L KMnO₄",
      toUomID: 39,
      convfactor: 0.2,
    },
    {
      fromUomName: "mg/L KMnO₄",
      fromUomID: 39,
      toUomName: "mg/L TOC",
      toUomID: 38,
      convfactor: 5,
    },
    {
      fromUomName: "mm",
      fromUomID: 40,
      toUomName: "in",
      toUomID: 15,
      convfactor: 0.0393700787,
    },
    {
      fromUomName: "mm",
      fromUomID: 40,
      toUomName: "cm",
      toUomID: 16,
      convfactor: 0.1,
    },
    {
      fromUomName: "gpd",
      fromUomID: 41,
      toUomName: "gpm",
      toUomID: 1,
      convfactor: 0.0006944444,
    },
    {
      fromUomName: "gpd",
      fromUomID: 41,
      toUomName: "m³/h",
      toUomID: 2,
      convfactor: 0.0001577133,
    },
    {
      fromUomName: "gpd",
      fromUomID: 41,
      toUomName: "m³/d",
      toUomID: 42,
      convfactor: 0.00378512,
    },
    {
      fromUomName: "gpd",
      fromUomID: 41,
      toUomName: "L/s",
      toUomID: 43,
      convfactor: 0.0000526168,
    },
    {
      fromUomName: "m³/d",
      fromUomID: 42,
      toUomName: "gpm",
      toUomID: 1,
      convfactor: 0.1834528,
    },
    {
      fromUomName: "m³/d",
      fromUomID: 42,
      toUomName: "m³/h",
      toUomID: 2,
      convfactor: 0.04166667,
    },
    {
      fromUomName: "m³/d",
      fromUomID: 42,
      toUomName: "gpd",
      toUomID: 41,
      convfactor: 264.1924,
    },
    {
      fromUomName: "m³/d",
      fromUomID: 42,
      toUomName: "L/s",
      toUomID: 43,
      convfactor: 0.011574074,
    },
    {
      fromUomName: "L/s",
      fromUomID: 43,
      toUomName: "gpm",
      toUomID: 1,
      convfactor: 15.85032224,
    },
    {
      fromUomName: "L/s",
      fromUomID: 43,
      toUomName: "m³/h",
      toUomID: 2,
      convfactor: 3.59999712,
    },
    {
      fromUomName: "L/s",
      fromUomID: 43,
      toUomName: "gpd",
      toUomID: 41,
      convfactor: 19005.3432,
    },
    {
      fromUomName: "L/s",
      fromUomID: 43,
      toUomName: "m³/d",
      toUomID: 42,
      convfactor: 86.39980646,
    },
    {
      fromUomName: "kPa",
      fromUomID: 44,
      toUomName: "psi",
      toUomID: 5,
      convfactor: 0.1450342941,
    },
    {
      fromUomName: "kPa",
      fromUomID: 44,
      toUomName: "bar",
      toUomID: 6,
      convfactor: 0.01,
    },
  ],
};
const initialState = {
  data: localData,
  tabAvailableForUF: {
    FeedSetup: false,
    UF: false,
  },
  ufReportLoading: true,
  isReportAvailable: false,
};

export const ReportUFSlice = createSlice({
  name: "ReportUF",
  initialState,
  reducers: {
    UpdateUFReport: (state, action) => {
      state.data = action.payload;
      state.ufReportLoading = false;
    },
    updateTabAvailableForUF: (state, action) => {
      state.tabAvailableForUF = action.payload;
    },
    resetReportUfSlice: (state, action) => {
      state.data = localData;
      state.tabAvailableForUF = {
        FeedSetup: false,
        UF: false,
      };
      state.ufReportLoading = true;
    },
    updateUfReportLoading: (state, action) => {
      state.ufReportLoading = action.payload;
    },
    updateIsReportAvailable: (state, action) => {
      state.isReportAvailable = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  UpdateUFReport,
  updateUfReportLoading,
  updateTabAvailableForUF,
  updateIsReportAvailable,
  resetReportUfSlice,
} = ReportUFSlice.actions;

export default ReportUFSlice.reducer;
