/* eslint-disable max-len */
import React from "react";

const InfoPictonBlueIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15' fill='none'>
      <g clipPath='url(#clip0_968_46334)'>
        <path
          d='M7.9375 0.9375C4.07187 0.9375 0.9375 4.07187 0.9375 7.9375C0.9375 11.8031 4.07187 14.9375 7.9375 14.9375C11.8031 14.9375 14.9375 11.8031 14.9375 7.9375C14.9375 4.07187 11.8031 0.9375 7.9375 0.9375ZM7.9375 13.75C4.72813 13.75 2.125 11.1469 2.125 7.9375C2.125 4.72813 4.72813 2.125 7.9375 2.125C11.1469 2.125 13.75 4.72813 13.75 7.9375C13.75 11.1469 11.1469 13.75 7.9375 13.75Z'
          fill='#00A9E0'
        />
        <path
          d='M7.18726 5.1875C7.18726 5.38641 7.26627 5.57718 7.40693 5.71783C7.54758 5.85848 7.73834 5.9375 7.93726 5.9375C8.13617 5.9375 8.32693 5.85848 8.46759 5.71783C8.60824 5.57718 8.68726 5.38641 8.68726 5.1875C8.68726 4.98859 8.60824 4.79782 8.46759 4.65717C8.32693 4.51652 8.13617 4.4375 7.93726 4.4375C7.73834 4.4375 7.54758 4.51652 7.40693 4.65717C7.26627 4.79782 7.18726 4.98859 7.18726 5.1875ZM8.31226 6.9375H7.56226C7.49351 6.9375 7.43726 6.99375 7.43726 7.0625V11.3125C7.43726 11.3813 7.49351 11.4375 7.56226 11.4375H8.31226C8.38101 11.4375 8.43726 11.3813 8.43726 11.3125V7.0625C8.43726 6.99375 8.38101 6.9375 8.31226 6.9375Z'
          fill='#00A9E0'
        />
      </g>
      <defs>
        <clipPath id='clip0_968_46334'>
          <rect width='14' height='14' fill='white' transform='translate(0.9375 0.9375)' />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default InfoPictonBlueIcon;
