export const LSI_MAX_TDS = 10000;
export const ChemicalTypesMappig = {
  "Hydrochloric Acid": 1,
  "Sulfuric Acid": 2,
};

export const CHEMICAL_CONCENTRATION = { HCl: 36.4606, "H₂SO₄": 98.0795, NAOH: 39.9971, NA2CO3: 105.9888 };

export const ChemAdjustmentDBFieldsMapping = {
  pH: 1,
  SDI: 2,
  LSI: 3,
  CO2Removal: 4,
  CO2PartialPressure: 5,
  CO2Concerntration: 6,
};

export const ChemicalMeasurementTableHeaders = [
  { field: "measurement", label: "Measurement", colWidth: 200 },
  { field: "beforeAdjustment", label: "Before Adjustment", colWidth: 150 },
];

export const ChemicalAdjustmentDefaultValues = {
  phDown: 6,
  oxidant: 0.5,
  coagulant: 0,
};

export const CHEMICAL_MEASUREMENT_TABLE_ROWS = [
  {
    id: "pH",
    measurement: "pH",
  },
  {
    id: "LSI",
    measurement: "LSI*",
  },
  {
    id: "SDI",
    measurement: "Stiff & Devis Index*",
  },
  {
    id: "TDS",
    measurement: "TDS (mg/L)",
  },
  {
    id: "ionicStrength",
    measurement: "Ionic Strength (molal)",
  },
  {
    id: "HCO3",
    measurement: "HCO₃⁻ (mg/L)",
  },
  {
    id: "CO2",
    measurement: "CO₂ (mg/L)",
  },
  {
    id: "CO3_2",
    measurement: "CO₃²⁻ (mg/L)",
  },
  {
    id: "CaSO4",
    measurement: "CaSO₄ (%)",
  },
  {
    id: "BaSO4",
    measurement: "BaSO₄ (%)",
  },
  {
    id: "SrSO4",
    measurement: "SrSO₄ (%)",
  },
  {
    id: "CaF2",
    measurement: "CaF₂ (%)",
  },
  {
    id: "SiO2",
    measurement: "SiO₂ (%)",
  },
  {
    id: "MgOH2",
    measurement: "Mg(OH)₂ (%)",
  },
];

export const UFChemcicalFieldsMapping = {
  addReagent: "Add_Reagent",
  adjustmentType: "Adjustment_Type",
  chemicalAdjustment: "ChemicalAdjustment",
  degas: "Degas",
  equilibrateWith: "Equilibrate_with",
  Total_CO2: "Total_CO2",
  anions: "anions",
  cations: "cations",
  neutrals: "neutrals",
  totalDissolvedSolids: "tds",
  ph: "ph",
};

export const CHEMICAL_ADJUSTMENT_MAPPING = {
  CaSO4: "CaSO4_per",
  BaSO4: "BaSO4_per",
  SrSO4: "SrSO4_per",
  CaF2: "CaF2_per",
  SiO2: "SiO2_per",
  MgOH2: "MgOH2_per",
  LSI: "LSI",
  SDI: "SDI",
};
