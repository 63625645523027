import { colors } from "@common/styles/Theme";

export const WPHeadingSize = {
  xxxLarge: "h1",
  xxLarge: "h1",
  xLarge: "h2",
  large: "h3",
  medium: "h4",
  small: "h5",
  xSmall: "h6",
  xxSmall: "h6",
};

export const WPHeadingTypes = {
  default: colors.Black,
  primary: colors.PrimaryDarkAquaMarine,
};

export const WPHeadingSizeFontMapping = {
  xxSmall: "fs-10",
  xSmall: "fs-12",
  small: "fs-14",
  medium: "fs-16",
  large: "fs-20",
  xLarge: "fs-24",
  xxLarge: "fs-32",
  xxxLarge: "fs-64",
};
