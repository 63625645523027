import { useEffect } from "react";
import { Container } from "react-bootstrap";

import FeedTechnology from "./FeedTechnology";
import SystemDesignStyled from "./SystemDesignStyled";

const SystemDesign = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SystemDesignStyled>
        <Container fluid className='g-0'>
          <FeedTechnology />
        </Container>
      </SystemDesignStyled>
    </>
  );
};

export default SystemDesign;
