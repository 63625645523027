import { TEMPERARTURE_RANGES } from "@constants/global.constants";

export const LSIValidations = {
  softLimit: { minValue: -2, maxValue: 2 },
  hardLimit: { minValue: -10, maxValue: 10 },
};

export const PhValidations = {
  softLimit: { minValue: 2, maxValue: 7 },
  hardLimit: { minValue: 2, maxValue: 11 },
};

export const OxidantValidations = {
  DUPONT: {
    softLimit: { minValue: 0, maxValue: 10 },
    hardLimit: { minValue: 0, maxValue: 15 },
  },
  INGE: {
    softLimit: { minValue: 0, maxValue: 0.2 },
    hardLimit: { minValue: 0, maxValue: 0.7 },
  },
};

export const CogulantValidations = {
  softLimit: { minValue: 0.1, maxValue: 30 },
  hardLimit: { minValue: 0.1, maxValue: 100 },
};

export const getTempRanges = tempUnit => ({
  softLimit: {
    minValue: TEMPERARTURE_RANGES[tempUnit].soft.min,
    maxValue: TEMPERARTURE_RANGES[tempUnit].soft.max.UF,
  },
  hardLimit: {
    minValue: TEMPERARTURE_RANGES[tempUnit].hard.min,
    maxValue: TEMPERARTURE_RANGES[tempUnit].hard.max.UF,
  },
});
