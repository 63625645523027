/**
 * NOTE:
 * This is a seperate constants file for the mapping of the parameters tables for each UF type
 * This File is made seperate to avoid circular dependency issues
 **/
import {
  WPUfDupontBwParametersTableColumns,
  WPUfDupontBwParametersTableRows,
  WPUfDupontCebParametersTableColumns,
  WPUfDupontCebParametersTableRows,
  WPUfDupontCipParametersTableColumns,
  WPUfDupontCipParametersTableRows,
  WPUfDupontMiniCipParametersTableColumns,
  WPUfDupontMiniCipParametersTableRows,
} from "./WPUfDupontParametersTables";
import {
  WPUfDupontUxaBwParametersTableColumns,
  WPUfDupontUxaBwParametersTableRows,
  WPUfDupontUxaCebParametersTableColumns,
  WPUfDupontUxaCebParametersTableRows,
  WPUfDupontUxaCipParametersTableColumns,
  WPUfDupontUxaCipParametersTableRows,
  WPUfDupontUxaMiniCipParametersTableColumns,
  WPUfDupontUxaMiniCipParametersTableRows,
} from "./WPUfDupontUxaParametersTables";
import {
  WPUfIngeBwParametersTableColumns,
  WPUfIngeBwParametersTableRows,
  WPUfIngeCebParametersTableColumns,
  WPUfIngeCebParametersTableRows,
  WPUfIngeCipParametersTableColumns,
  WPUfIngeCipParametersTableRows,
} from "./WPUfIngeParametersTables";

const dupontParametersMapping = {
  bw: {
    rows: WPUfDupontBwParametersTableRows,
    columns: WPUfDupontBwParametersTableColumns,
  },
  ceb: {
    rows: WPUfDupontCebParametersTableRows,
    columns: WPUfDupontCebParametersTableColumns,
  },
  cip: {
    rows: WPUfDupontCipParametersTableRows,
    columns: WPUfDupontCipParametersTableColumns,
  },
  mcip: {
    rows: WPUfDupontMiniCipParametersTableRows,
    columns: WPUfDupontMiniCipParametersTableColumns,
  },
};

const dupontUxaParametersMapping = {
  bw: {
    rows: WPUfDupontUxaBwParametersTableRows,
    columns: WPUfDupontUxaBwParametersTableColumns,
  },
  ceb: {
    rows: WPUfDupontUxaCebParametersTableRows,
    columns: WPUfDupontUxaCebParametersTableColumns,
  },
  cip: {
    rows: WPUfDupontUxaCipParametersTableRows,
    columns: WPUfDupontUxaCipParametersTableColumns,
  },
  mcip: {
    rows: WPUfDupontUxaMiniCipParametersTableRows,
    columns: WPUfDupontUxaMiniCipParametersTableColumns,
  },
};

const ingeParametersMapping = {
  bw: {
    rows: WPUfIngeBwParametersTableRows,
    columns: WPUfIngeBwParametersTableColumns,
  },
  ceb: {
    rows: WPUfIngeCebParametersTableRows,
    columns: WPUfIngeCebParametersTableColumns,
  },
  cip: {
    rows: WPUfIngeCipParametersTableRows,
    columns: WPUfIngeCipParametersTableColumns,
  },
};

export const WPUfParametersTablesMapping = {
  dupont: dupontParametersMapping,
  dupontUxa: dupontUxaParametersMapping,
  inge: ingeParametersMapping,
};
