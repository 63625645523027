import styled from "styled-components";

import { colors, fontStyles } from "@common/styles/Theme";

const StyledWaterLibrary = styled("div")`
  height: 550px;
  .main-div {
    .form-name {
      width: 495px;
      height: 54px;
      top: 10px;
    }
    .water-type-open {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      .input-field-container {
        width: 155px;
        height: 54px;
      }
    }
    .water-type-save {
      margin-top: 35px;
      display: flex;
      justify-content: space-between;
      .input-field-container {
        width: 155px;
        height: 54px;
      }
    }
    .preview {
      margin-top: 20px;
      color: ${colors.PrimaryDarkAquaMarine};
      .inline-hr {
        display: inline-block;
        width: 740px;
        margin: 0 10px;
        vertical-align: middle;
        border: 1px solid ${colors.GreyE1};
      }
    }

    .ph-temperature {
      margin-top: 36px;
      display: flex;
      justify-content: space-between;
      .temperature-card {
        height: 71px;
        width: 672px;
        background-color: ${colors.White};
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        border: 1px;
        padding: 7px 7px 7px 40px;
      }
      .input-field-container {
        width: 155px;
        height: 54px;
      }
    }
    .table-container {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      .table-card {
        height: 380px;
        .cations {
          table {
            thead {
              background-color: ${colors.LightGrayishCyan};
            }
            tbody {
              background-color: ${colors.LightGrayishCyan030};
              overflow-y: hidden;
            }
            tfoot {
              background-color: ${colors.LightGrayishCyan};
            }
          }
        }
        .anions {
          table {
            thead {
              background-color: ${colors.anionsHeaderFooterColor};
            }
            tbody {
              background-color: ${colors.LightPink010};
              overflow-y: hidden;
            }
            tfoot {
              background-color: ${colors.anionsHeaderFooterColor};
            }
          }
        }
        .neutrals {
          table {
            thead {
              background-color: ${colors.Pale20PaleYellow};
            }
            tbody {
              background-color: ${colors.neutralHeaderFooterColor};
              overflow-y: hidden;
            }
            tfoot {
              background-color: ${colors.Pale20PaleYellow};
            }
          }
        }
      }
    }
    .disolved-solutes {
      font-family: ${fontStyles.diodrum14Bold};
      display: flex;
      justify-content: space-between;
      height: 50px;
      margin-top: 20px;
      margin-bottom: 20px;
      background-color: ${colors.White};
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      border: 1px;
      padding: 0px 20px;
      align-items: center;
    }
    .additional-information {
      margin-top: 25px;
      .additional_feed_setup_info {
        width: 100%;
      }
    }
  }
`;
export default StyledWaterLibrary;
