import React from "react";

import useProjectDetails from "@hooks/useProjectDetails";

import { CaseManagementStrings } from "@utils/StringConstants";

import IconButton from "@components/IconButton";
import { DeleteIcon, EditIcon } from "@components/SVGs";

const CaseManagementActionRender = data => {
  const { activeCaseId } = useProjectDetails();

  return (
    <div className='d-flex'>
      <IconButton
        id='caseEdit'
        name='caseEdit'
        tooltip={CaseManagementStrings.editCase}
        aria-label='edit'
        onClick={() => {
          data.context.handleEdit(data.data);
        }}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        id='caseDelete'
        name='caseDelete'
        tooltip={CaseManagementStrings.deleteCase}
        aria-label='delete'
        onClick={() => {
          data.context.onDelete(data.data.caseID);
        }}
        disabled={data.data.caseID === activeCaseId}
      >
        <DeleteIcon disabled={data.data.caseID === activeCaseId} />
      </IconButton>
    </div>
  );
};

export default CaseManagementActionRender;
