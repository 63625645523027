import { useEffect, useRef, useState } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import styled from "styled-components";

import useProjectDetails from "@hooks/useProjectDetails";

import { CaseManagementStrings } from "@utils/StringConstants";

import IconButton from "@components/IconButton";
import { CasesIcon } from "@components/SVGs";
import MoreIcon from "@components/SVGs/MoreIcon";
import WPHeading from "@components/WPHeading";
import { WPTypogrpahy } from "@components/WPTypography";

import CircularLoader from "@common/styles/components/circularLoader";
import { colors } from "@common/styles/Theme";

import CustomToggle from "../../components/WPToggle";

import CaseCustomMenu from "./CaseCustomMenu";
import CaseOptionsMenu from "./caseOptionMenu";

import "./CaseManager.scss";

const IconBtnWithLabel = styled.div`
  display: flex;
  flex-flow: column;
  padding: 5px;
  align-items: center;
  color: ${colors.whiteOpacity};

  .span-case {
    font-size: 14px;
    color: ${colors.whiteOpacity};
  }

  &:hover {
    color: var(--du-color-hover);
    background: var(--du-color-primary-light);
    border: 0px solid var(--du-color-primary-light);
    border-radius: 5px;

    svg,
    path {
      fill: var(--du-color-hover);
    }

    .span-case {
      color: var(--du-color-hover);
    }
  }
`;

const DropdownContainer = styled.div`
  min-width: 150px;
  max-width: 150px;
  .btn-group {
    padding: 0px;
    width: 100%;
    border-radius: 2px;
    height: 40px;
  }
  .dropdown-menu.show {
    display: block;
  }
`;

const DropdownMenuCustom = styled.div`
  display: flex;
  min-width: 300px;
  padding: 5px 8px;
  align-items: center;
  background-color: ${({ isActive }) => (isActive ? "var(--du-color-primary-pale)" : "transparent")};
  box-shadow: ${({ isSelected }) => (isSelected ? " 0px 0px 8px 0px rgba(0, 0, 0, 0.25)" : "transparent")};
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  }
`;

const StyledWPTypography = styled(WPHeading)`
  max-width: 250px;
  font-size: 14px;
  line-height: 22px;
`;

const CaseManagementDropdown = ({
  cases,
  setCases,
  handleManageCases,
  deleteCaseId,
  show,
  isLoading,
  handleOpenCase,
}) => {
  const { activeCaseId } = useProjectDetails();

  const [showSubmenu, setShowSubmenu] = useState(null);
  const [showDropdown, setShowDropdown] = useState(show);
  const dropdownRef = useRef(null);

  const handleToggleDropdown = () => setShowDropdown(prevState => !prevState);
  const handleMoreSettings = ({ target }) => handleToggleSubmenu(target.id);
  const handleToggleSubmenu = index => setShowSubmenu(showSubmenu === index ? null : index);

  const handleOpenCaseClick = item => {
    if (item.caseID === activeCaseId) {
      return;
    }
    setShowDropdown(false);
    handleOpenCase(item);
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false); // Close dropdown if click is outside
    }
  };

  useEffect(() => {
    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove event listener when component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer>
      <Dropdown autoClose={false} show={showDropdown}>
        <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
          <IconButton className='dropdown-icon' onClick={handleToggleDropdown}>
            <IconBtnWithLabel>
              <CasesIcon />
              <WPTypogrpahy asElement='span' text={CaseManagementStrings.cases} className='span-case' />
            </IconBtnWithLabel>
          </IconButton>
        </Dropdown.Toggle>

        <Dropdown.Menu
          as={CaseCustomMenu}
          handleManageCases={handleManageCases}
          handleClose={handleToggleDropdown}
          ref={dropdownRef}
          className='case-dropdown-menu'
        >
          {(!isLoading &&
            cases?.map((item, index) => (
              <Dropdown
                as={ButtonGroup}
                drop='end'
                show={showSubmenu === index}
                onToggle={() => handleToggleSubmenu(index)}
                key={index}
              >
                <DropdownMenuCustom
                  isActive={activeCaseId === item.caseID}
                  tabIndex={0}
                  isSelected={showSubmenu === index}
                  onClick={() => handleOpenCaseClick(item)}
                >
                  <StyledWPTypography
                    className='text-overflow-ellipsis'
                    tooltip={item.caseName}
                    secondary
                    color='var(--du-color-grey-900)'
                  >
                    {item.caseName}
                  </StyledWPTypography>
                  <div className='submenu-toggle'>
                    <Dropdown.Toggle
                      as={CustomToggle}
                      variant='transparent'
                      id={`dropdown-more-${index}`}
                      name='dropdown-more'
                      className='submenu-toggle'
                    >
                      <IconButton
                        id={index}
                        name={`case_${index}`}
                        onClick={handleMoreSettings}
                        tooltip={CaseManagementStrings.more}
                        disabled={cases?.length === 1}
                      >
                        <MoreIcon />
                      </IconButton>
                    </Dropdown.Toggle>
                  </div>
                </DropdownMenuCustom>
                <Dropdown.Menu className='submenu'>
                  <CaseOptionsMenu
                    cases={cases}
                    setCases={setCases}
                    currentActiveCase={item}
                    deleteCaseId={deleteCaseId}
                    selectedCaseIndex={index}
                    onClose={handleToggleDropdown}
                    handleOpenCase={handleOpenCase}
                  />
                </Dropdown.Menu>
              </Dropdown>
            ))) || <CircularLoader />}
        </Dropdown.Menu>
      </Dropdown>
    </DropdownContainer>
  );
};

export default CaseManagementDropdown;
