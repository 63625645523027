import React from "react";
import styled from "styled-components";

import { colors, fontStyles } from "../../../Theme";

const StyledPrimaryButton = styled.button`
  height: 32px;
  display: inline-flex;
  padding: 10px 24px 7.5px 24px;
  justify-content: center;
  align-items: center;
  ${fontStyles.diodrum14}
  color: ${props => props.color || colors.White};
  border-radius: 25px;
  border: 1px solid ${props => props.borderColor || colors.PrimaryDarkAquaMarine};
  background-color: ${props => props.bgColor || colors.PrimaryDarkAquaMarine};
  :hover {
    background-color: ${props => props.bgColor || colors.SecondaryElfGreen};
    border: 1px solid ${colors.SecondaryElfGreen};
    color: ${props => props.hoverFontColor || colors.White};
  }
  :disabled {
    background-color: ${colors.GreyF8};
    border: 1px solid ${colors.GreyD9};
    color: ${colors.blackTransparency025};
  }
`;
const StandardPrimaryButton = ({
  id,
  label,
  onClick,
  disabled,
  type,
  className,
  bgColor,
  borderColor,
  color,
  hoverFontColor,
  tabIndex,
}) => (
  <>
    <StyledPrimaryButton
      className={className}
      id={id}
      type={type}
      onClick={onClick}
      disabled={disabled}
      bgColor={bgColor}
      borderColor={borderColor}
      color={color}
      hoverFontColor={hoverFontColor}
      tabIndex={tabIndex}
    >
      {label}
    </StyledPrimaryButton>
  </>
);

export default StandardPrimaryButton;
