import { reportStrings } from "@constants/report.strings";

import { cloneArrayAndOverride } from "@utils/appUtils";

import { WPUfParametersDataMapping } from "../../WPUfParametersTables.constants";
import { WPUfDupontCipParametersTableColumns, WPUfDupontCipParametersTableRows } from "../WPUfDupontCipParametersTable";

export const WPUfDupontMiniCipParametersTableColumns = cloneArrayAndOverride(
  WPUfDupontCipParametersTableColumns,
  {
    col7: {
      headerName: reportStrings.miniCip + "<sup>c</sup>",
    },
  },
  "field",
);

export const WPUfDupontMiniCipParametersTableRows = cloneArrayAndOverride(
  WPUfDupontCipParametersTableRows,
  {
    stepLabel: {
      col8: reportStrings.miniCipRecycle + "<sup>d</sup>",
      col10: reportStrings.miniCipRecycle + "<sup>d</sup>",
    },
    duration: {
      col8: WPUfParametersDataMapping.t_mCIP_recycle,
      col9: WPUfParametersDataMapping.t_mCIP_soak,
      col10: WPUfParametersDataMapping.t_mCIP_recycle,
    },
    flowRate: {
      col8: WPUfParametersDataMapping.Flow_mCIP_recycle,
      col10: WPUfParametersDataMapping.Flow_mCIP_recycle,
    },
  },
  "rowKey",
);
