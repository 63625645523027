import { createSlice } from "@reduxjs/toolkit";

const initialdata = {
  userID: 0,
  projectID: 0,
  caseID: 0,
  treatmentObjID: 0,
  resinID1: 0,
  resinID2: 0,
  resinID3: 0,
  resinID4: 0,
  inertID1: 0,
  inertID2: 0,
  inertID3: 0,
  validDesignID: 0,
  chemID_Regenerant1: 0,
  chemID_Regenerant2: 0,
  flag_waterblock: false,
  ixReport: {
    maiN_INPUT: {
      Export_to_Excel: 0,
      Name_1: "",
      Name_2: "",
      Name_3: "",
      Name_4: "",
      Name_Inert1: "",
      Name_Inert2: "",
      Name_Inert3: "",
      Flag_Evaluate_Type: true,
      Flag_Degas: false,
      Flag_Custom_Resin_Volume_1: false,
      Flag_Vessel_1_Custom: false,
      Flag_Custom_Inert_Height: false,
      Flag_Overrun_Computation: false,
      Flag_neutral_Effluent: false,
      Flag_Optimise_Dose: false,
      Flag_Overall_Process: 7,
      Flag_CP_Sub_Process: 0,
      Flag_Layout: 0,
      Bypass_Fraction: 0,
      N_trains_online: 1,
      N_trains_Standby: 0,
      Flag_Evaluation_Mode: 2,
      Flag_Design_Criteria: 0,
      t_load_target: 10,
      SV_Load_target: 20,
      Flag_Design_Scavenger: 0,
      Flag_Design_Cation: null,
      Flag_Design_Anion: null,
      Flag_Design_Polish: 0,
      Flag_Design_CPP: 0,
      Flag_Degas_Target_Type: 0,
      Degas_Target: 0,
      Flag_Degas_Location: 0,
      Flag_Ionic_Form_1: 5,
      Flag_Ionic_Form_2: 5,
      Flag_Ionic_Form_3: 5,
      Flag_Ionic_Form_4: 5,
      Resin_Packaging_Size_1: 1,
      Resin_Packaging_Size_2: 1,
      Resin_Packaging_Size_3: 1,
      Resin_Packaging_Size_4: 1,
      Custom_Resin_Volume_1: 0,
      Custom_Resin_Volume_2: 0,
      Custom_Resin_Volume_3: 0,
      Custom_Resin_Volume_4: 0,
      Capacity_Safety_Factor_1: 0,
      Capacity_Safety_Factor_2: 0,
      Capacity_Safety_Factor_3: 0,
      Capacity_Safety_Factor_4: 0,
      Flag_Vessel_1_Regen_Sys: 0,
      Flag_Vessel_2_Regen_Sys: 0,
      Flag_Vessel_3_Regen_Sys: 0,
      Flag_Vessel_4_Regen_Sys: 0,
      Flag_Vessel_1_Inert: 0,
      Flag_Vessel_2_Inert: 0,
      Flag_Vessel_3_Inert: 0,
      Flag_Vessel_4_Inert: 0,
      Vessel_1_Custom_Diameter: 0,
      Vessel_2_Custom_Diameter: 0,
      Vessel_3_Custom_Diameter: 0,
      Vessel_4_Custom_Diameter: 0,
      Vessel_1_Custom_Wall: 0,
      Vessel_2_Custom_Wall: 0,
      Vessel_3_Custom_Wall: 0,
      Vessel_4_Custom_Wall: 0,
      Vessel_1_Custom_Height: 0,
      Vessel_2_Custom_Height: 0,
      Vessel_3_Custom_Height: 0,
      Vessel_4_Custom_Height: 0,
      Vessel_1_Comp_1_Custom_Height: 0,
      Vessel_2_Comp_1_Custom_Height: 0,
      Vessel_3_Comp_1_Custom_Height: 0,
      Vessel_4_Comp_1_Custom_Height: 0,
      Vessel_1_Comp_2_Custom_Height: 0,
      Vessel_2_Comp_2_Custom_Height: 0,
      Vessel_3_Comp_2_Custom_Height: 0,
      Vessel_4_Comp_2_Custom_Height: 0,
      Vessel_1_Custom_Inert_Height: 0,
      Vessel_2_Custom_Inert_Height: 0,
      Vessel_3_Custom_Inert_Height: 0,
      Vessel_4_Custom_Inert_Height: 0,
      Vessel_1_Comp_1_Custom_Inert_Height: 0,
      Vessel_2_Comp_1_Custom_Inert_Height: 0,
      Vessel_3_Comp_1_Custom_Inert_Height: 0,
      Vessel_4_Comp_1_Custom_Inert_Height: 0,
      Vessel_1_Comp_2_Custom_Inert_Height: 0,
      Vessel_2_Comp_2_Custom_Inert_Height: 0,
      Vessel_3_Comp_2_Custom_Inert_Height: 0,
      Vessel_4_Comp_2_Custom_Inert_Height: 0,
      Flag_Regenerant_1: false,
      Flag_Regenerant_2: false,
      Regenerant_1_Stock_Conc_Percent: 0,
      Regenerant_2_Stock_Conc_Percent: 0,
      Regenerant_1_Price_Bulk: 0,
      Regenerant_2_Price_Bulk: 0,
      Temp_bulk_regenerant: 25,
      RegenProt_1_Temperature: false,
      RegenProt_2_Temperature: false,
      RegenProt_3_Temperature: 0,
      RegenProt_3_Steps: 0,
      RegenProt_1_Steps: 1,
      RegenProt_1_Conc_Percent_n0: false,
      RegenProt_1_Conc_Percent_n1: 0,
      RegenProt_1_Conc_Percent_n2: 0,
      RegenProt_1_Conc_Percent_n3: 0,
      RegenProt_1_fraction_n1: false,
      RegenProt_1_fraction_n2: 0,
      RegenProt_1_fraction_n3: 0,
      RegenProt_2_Steps: 1,
      RegenProt_2_Conc_Percent_n0: false,
      RegenProt_2_Conc_Percent_n1: 0,
      RegenProt_2_Conc_Percent_n2: 0,
      RegenProt_2_Conc_Percent_n3: 0,
      RegenProt_2_fraction_n1: false,
      RegenProt_2_fraction_n2: 0,
      RegenProt_2_fraction_n3: 0,
      RegenProt_3_Conc_Percent_n0: 0,
      RegenProt_3_Conc_Percent_n1: 0,
      RegenProt_3_Conc_Percent_n2: 0,
      RegenProt_3_Conc_Percent_n3: 0,
      RegenProt_3_fraction_n1: 0,
      RegenProt_3_fraction_n2: 0,
      RegenProt_3_fraction_n3: 0,
      RegenProt_1_Dose_Target: null,
      RegenProt_2_Dose_Target: null,
      RegenProt_3_Dose_Target: 0,
      RegenProt_1_Ratio_Target: 0,
      RegenProt_2_Ratio_Target: 0,
      RegenProt_3_Ratio_Target: 0,
      RegenProt_1_Manual_Overrun_Factor: 0,
      RegenProt_2_Manual_Overrun_Factor: 0,
      RegenProt_3_Manual_Overrun_Factor: 0,
      Flag_RegenProt_1_BW_Source: 0,
      Flag_RegenProt_2_BW_Source: 0,
      Flag_RegenProt_3_BW_Source: 0,
      Flag_RegenProt_1_Service_Source: 0,
      Flag_RegenProt_2_Service_Source: 0,
      Flag_RegenProt_3_Service_Source: 0,
      RegenProt_1_BW_interval: false,
      RegenProt_2_BW_interval: false,
      RegenProt_3_BW_interval: 0,
      RegenProt_1_BW_expansion: false,
      RegenProt_2_BW_expansion: false,
      RegenProt_3_BW_expansion: 0,
      RegenProt_1_BW_duration: 0,
      RegenProt_2_BW_duration: 0,
      RegenProt_3_BW_duration: 0,
      RegenProt_1_Compact_t: 0,
      RegenProt_2_Compact_t: 0,
      RegenProt_3_Compact_t: 0,
      RegenProt_1_Regeneration_SV: false,
      RegenProt_2_Regeneration_SV: false,
      RegenProt_3_Regeneration_SV: 0,
      RegenProt_1_Water_Block_factor: false,
      RegenProt_2_Water_Block_factor: false,
      RegenProt_3_Water_Block_factor: 0,
      Flag_RegenProt_1_Displacement_Rinse: false,
      Flag_RegenProt_2_Displacement_Rinse: false,
      Flag_RegenProt_3_Displacement_Rinse: 0,
      RegenProt_1_Displ_Rinse_BV: false,
      RegenProt_2_Displ_Rinse_BV: false,
      RegenProt_3_Displ_Rinse_BV: 0,
      Flag_RegenProt_1_Rinse_Recycle: false,
      Flag_RegenProt_2_Rinse_Recycle: false,
      RegenProt_1_Fast_Rinse_BV: false,
      RegenProt_2_Fast_Rinse_BV: false,
      RegenProt_3_Fast_Rinse_BV: 0,
      RegenProt_1_Settling_t: false,
      RegenProt_2_Settling_t: false,
      RegenProt_3_Settling_t: 0,
      Typical_Org_Ads_Demin: 0.7,
      Typical_Org_Ads_Scav: 0.75,
      PD_Distributors: 0.05,
      PD_Piping: 0.1,
      Product_Pressure: 1,
      Crud_Input: 0.01,
      Price_Electricity: 0.09,
      Price_Raw_Water: 0.14,
      Price_Waste_Water: 0.69,
      Efficiency_Pump_Feed: 0.8,
      Efficiency_Motor_Feed: 0.92,
      Efficiency_Pump_BW: 0.8,
      Efficiency_Motor_BW: 0.92,
      Efficiency_Pump_Regen: 0.8,
      Efficiency_Motor_Regen: 0.92,
      Efficiency_Compressor: 0.5,
      Efficiency_Compressor_Motor: 0.92,
      Flow_System_Design: 100,
      RegenProt_1_Ion_Conc_Leakage: 0.000869951021757475,
      RegenProt_2_Ion_Conc_Leakage: 0.0003328654908858932,
      RegenProt_3_Ion_Conc_Leakage: 0,
      RegenProt_1_Conductivity_Leakage: 0.22,
      RegenProt_2_Conductivity_Leakage: 0,
      RegenProt_3_Conductivity_Leakage: 0,
      Organic_Adsorption_Efficiency: 0,
      Organic_Adsorption_Efficiency_2: 0,
      Organic_Adsorption_Efficiency_3: 0,
      RegenProt_1_Ion_Conc_Endpoint: 0.00173990204351495,
      RegenProt_2_Ion_Conc_Endpoint: 0.0006657309817717864,
      RegenProt_3_Ion_Conc_Endpoint: 0,
      RegenProt_1_Conductivity_Endpoint: 0.22,
      RegenProt_2_Conductivity_Endpoint: 0,
      RegenProt_3_Conductivity_Endpoint: 0,
      Diaminoethane_dose: 0,
      Amino_2_methyl_1_propanol_dose: 0,
      Methoxypropylamine_dose: 0,
      Aminopentanol_dose: 0,
      Cyclohexylamine_dose: 0,
      Ethanolamine_dose: 0,
      Morpholine_dose: 0,
      Hydrazine_dose: 0,
      Flow_net_product: 0,
      TSS_IX_Input: 0,
      flag_Vessel_Dimension_Units: 0,
    },
    feeD_WATER: {
      designTemp: 25,
      methodname: "normal",
      TOC_System_Feed: 1,
      ph: 7.600591,
      TotalDissolvedSolutes: 414.623155,
      ChargeBalance: 0,
      EstimatedConductivity: 702.413766,
      Degas: 0,
      percentage_of_initial_total_CO2_remaining: 100,
      Equilibrate_with: 0,
      Adjustment_Type: 0,
      Add_Reagent: 0,
      Total_CO2: 0,
      cations: [
        {
          name: "Ba",
          mgL: 0.69,
          molCharge: 2,
          soluteID: 7,
          ppm: 0.502888,
          meqL: 0.010049,
        },
        {
          name: "Ca",
          mgL: 60.12,
          molCharge: 2,
          soluteID: 5,
          ppm: 150.138292,
          meqL: 3.00015,
        },
        {
          name: "K",
          mgL: 0.78,
          molCharge: 1,
          soluteID: 2,
          ppm: 0.998356,
          meqL: 0.01995,
        },
        {
          name: "Mg",
          mgL: 24.31,
          molCharge: 2,
          soluteID: 4,
          ppm: 100.10779,
          meqL: 2.000411,
        },
        {
          name: "Na",
          mgL: 22.978,
          molCharge: 1,
          soluteID: 3,
          ppm: 50.017914,
          meqL: 0.999487,
        },
        {
          name: "NH₄",
          mgL: 0.18,
          molCharge: 1,
          soluteID: 1,
          ppm: 0.499373,
          meqL: 0.009979,
        },
        {
          name: "Sr",
          mgL: 0.44,
          molCharge: 2,
          soluteID: 6,
          ppm: 0.502606,
          meqL: 0.010046,
        },
      ],
      anions: [
        {
          name: "Br",
          mgL: 0,
          molCharge: -1,
          soluteID: 28,
          ppm: 0,
          meqL: 0,
        },
        {
          name: "Cl",
          mgL: 141.81,
          molCharge: -1,
          soluteID: 11,
          ppm: 200.173271,
          meqL: 3.999977,
        },
        {
          name: "CO₃",
          mgL: 0.218264,
          molCharge: -2,
          soluteID: 8,
          ppm: 0.364035,
          meqL: 0.007274,
        },
        {
          name: "F",
          mgL: 0.19,
          molCharge: -1,
          soluteID: 12,
          ppm: 0.500478,
          meqL: 0.010001,
        },
        {
          name: "HCO₃",
          mgL: 62.008519,
          molCharge: -1,
          soluteID: 9,
          ppm: 50.85672,
          meqL: 1.016248,
        },
        {
          name: "NO₃",
          mgL: 0.62,
          molCharge: -1,
          soluteID: 10,
          ppm: 0.500396,
          meqL: 0.009999,
        },
        {
          name: "PO₄",
          mgL: 0,
          molCharge: -3,
          soluteID: 23,
          ppm: 0,
          meqL: 0,
        },
        {
          name: "SO₄",
          mgL: 48.03,
          molCharge: -2,
          soluteID: 13,
          ppm: 50.041725,
          meqL: 0.999963,
        },
      ],
      neutrals: [
        {
          name: "B",
          mgL: 0,
          molCharge: 0,
          soluteID: 29,
          ppm: 0,
          meqL: 0,
        },
        {
          name: "CO₂",
          mgL: 2.032738,
          molCharge: 0,
          soluteID: 30,
          ppm: 0,
          meqL: 0,
        },
        {
          name: "SiO₂",
          mgL: 50,
          molCharge: 0,
          soluteID: 14,
          ppm: 0,
          meqL: 0,
        },
      ],
    },
  },
};
const initialState = {
  data: initialdata,
  loadder: true,
  tabAvailable: {
    FeedSetup: false,
    IXD: false,
  },
};

export const ReportIXDSlice = createSlice({
  name: "ReportIXD",
  initialState,
  reducers: {
    updateIXDJson: (state, action) => {
      state.data = action.payload;
      state.loadder = false;
    },
    updateTabAvailable: (state, action) => {
      state.tabAvailable = action.payload;
    },
    updateIXDLoadder: (state, action) => {
      state.loadder = action.payload;
    },
    resetTheIXDReport: (state, action) => {
      state.data = initialdata;
      state.loadder = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateIXDJson, updateTabAvailable, updateIXDLoadder, resetTheIXDReport } = ReportIXDSlice.actions;

export default ReportIXDSlice.reducer;
