import { useSelector } from "react-redux";

import { WPTable } from "@components/WPTable";

import { WPUfDesignWarningsTableColumns as columns } from "./WPUfDesignWarningsTable.constants";

export const WPUfDesignWarningsTable = () => {
  const { uf_design_quality: rows } = useSelector(state => state.report.ufReportTableData);

  return <WPTable sectionHeader='UF Design Warnings' columns={columns} rows={rows} />;
};
