import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  &:hover {
    line {
      fill: #007672;
      stroke: #007672;
      stroke-width: 2;
    }
  }
`;

const PlusIcon = () => (
  <>
    <StyledSvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Home-Page-Approved' clipPath='url(#clip0_179570_170394)'>
        <rect width='32' height='32' fill='#F8F8F8' />
        <g id='Left Panel'>
          <g id='Left Panel Bkg'>
            <mask
              id='path-1-outside-1_179570_170394'
              maskUnits='userSpaceOnUse'
              x='0'
              y='0'
              width='32'
              height='32'
              fill='black'
            >
              <rect fill='white' x='0' y='0' width='32' height='32' />
              <path d='M0 0H32V32H0V0Z' />
            </mask>
            <path d='M0 0H32V32H0V0Z' fill='white' />
            <path
              className='plus-icon-path'
              d='M32 32V32.5H32.5V32H32ZM0 32H-0.5V32.5H0V32ZM31.5 0V32H32.5V0H31.5ZM32 31.5H0V32.5H32V31.5ZM0.5 32V0H-0.5V32H0.5Z'
              fill='#E1E1E1'
              mask='url(#path-1-outside-1_179570_170394)'
            />
          </g>
          <g id='Folders Title'>
            <g id='Add-more'>
              <g id='Group 6'>
                <line id='Line 6' x1='16' y1='9' x2='16' y2='23' stroke='black' />
                <line id='Line 7' x1='23' y1='16' x2='9' y2='16' stroke='black' />
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_179570_170394'>
          <rect width='32' height='32' fill='white' />
        </clipPath>
      </defs>
    </StyledSvg>
  </>
);

export default PlusIcon;
