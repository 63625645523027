import React from "react";

const FolderOpenIcon = () => (
  <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M32.625 15.6094H28.8281V11.6156C28.8281 10.9934 28.3254 10.4906 27.7031 10.4906H16.6289L12.5051 6.54609C12.4526 6.49697 12.3836 6.46937 12.3117 6.46875H3.375C2.75273 6.46875 2.25 6.97148 2.25 7.59375V28.4062C2.25 29.0285 2.75273 29.5312 3.375 29.5312H27.9141C28.3711 29.5312 28.7859 29.2535 28.9582 28.8281L33.6691 17.1562C33.7219 17.0227 33.75 16.8785 33.75 16.7344C33.75 16.1121 33.2473 15.6094 32.625 15.6094ZM26.2969 15.6094H8.36719C7.91016 15.6094 7.49531 15.8871 7.32305 16.3125L4.78125 22.6125V9H11.4082L15.6129 13.0219H26.2969V15.6094Z'
      fill='black'
      fillOpacity='0.85'
    />
  </svg>
);

export default FolderOpenIcon;
