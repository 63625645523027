import { useMemo } from "react";
import { useSelector } from "react-redux";

import { calculateSum } from "@utils/appUtils";

import { isIngeSelected, isUxaSelected } from "@features/feedwater/uf/ufUtils";
import { useReportUtils } from "@features/report-new/useReportUtils";

import { WPUfParametersTablesMapping } from "./WPUfParametersTableMapping.constants";
import { VALVE_OPENED, WPUfParametersDataMapping } from "./WPUfParametersTables.constants";

const unitCoversionRows = ["duration", "flowRate", "flowRateUxa"];

export const useWPUfParametersTableData = () => {
  const { formatConvertValueWithUnit } = useReportUtils();
  const { calcEngineData } = useSelector(state => state.UFStore);
  const { data: ufData, activeUFModule, bwProtocol, ufChemicalAdjustment } = useSelector(state => state.UFStore);
  const { pUFTechnologyID, uFBWProtocolID } = ufData;

  const parametersTableType = useMemo(() => {
    if (isIngeSelected(pUFTechnologyID)) {
      return "inge";
    } else {
      return isUxaSelected({ activeUFModule, uFBWProtocolID, bwProtocol }) ? "dupontUxa" : "dupont";
    }
  }, [activeUFModule, bwProtocol, pUFTechnologyID, uFBWProtocolID]);

  // returns true if atleast of the chemical in degas is enabled
  const hasChemicalAdjustment = useMemo(() => {
    const { data } = ufChemicalAdjustment || {};
    const { phDown, oxidant, coagulant } = data || {};

    if (!phDown && !oxidant && !coagulant) {
      return false;
    }
    return true;
  }, [ufChemicalAdjustment]);

  const hasBwOxidant = useMemo(() => ufData.oxidantEnabled_Ind_BW && Number(ufData.oxidantValue_BW) > 0, [ufData]);

  const customKeyMapping = () => ({
    backwashBeforeCeb: () =>
      calculateSum([getValue(WPUfParametersDataMapping.t_BW1), getValue(WPUfParametersDataMapping.t_BW2)]),
  });

  const getValue = colValue => {
    const { calEngineDataKey, ufDataKey, customKey } = colValue || {};

    if (calEngineDataKey) {
      return calcEngineData[calEngineDataKey];
    } else if (ufDataKey) {
      return ufData[ufDataKey];
    } else if (customKey) {
      const keyMapping = customKeyMapping();
      const dataFunc = keyMapping[customKey];
      return dataFunc?.();
    } else {
      return colValue?.value ?? colValue;
    }
  };

  // this is a common converter function for all the parameters tables in UF report
  const convertToUfParametersTableData = type => {
    const parameterTableData = WPUfParametersTablesMapping?.[parametersTableType]?.[type];
    if (!parameterTableData) {
      return [];
    }
    const { rows, columns } = parameterTableData;
    return rows?.reduce((acc, row) => {
      const singleRowData = columns?.reduce((colAcc, column) => {
        const colKey = column.field;
        const colValue = row[colKey];
        const { precision, unit } = colValue || {};
        let value = colValue?.value ?? colValue;
        if (unitCoversionRows.includes(row.rowKey)) {
          value = getValue(colValue);
          colAcc[colKey] = formatConvertValueWithUnit(value, unit, precision);
        } else {
          //refer bug-145459 for more details
          if (row.rowKey === "chemDosingPump" && type === "bw") {
            if (
              ((parametersTableType === "dupont" || parametersTableType === "dupontUxa") && !hasBwOxidant) ||
              (parametersTableType === "inge" && !hasChemicalAdjustment)
            ) {
              value = undefined;
            }
          }
          colAcc[colKey] = value === true ? VALVE_OPENED : colValue;
        }
        return colAcc;
      }, {});

      acc.push(singleRowData);
      return acc;
    }, []);
  };

  return { convertToUfParametersTableData, parametersTableType };
};
