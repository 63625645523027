import { DEFAULT_PRODUCT_TYPE_FILTERS } from "../UFConfiguration/UFProductGuidelines/UFProductGuidelines.constant";

import { UF_MODULE_PER_RACK_RANGE, UFTechnologies } from "./UFConstants";
import ufDefaultData from "./UFDefaultValues.json";

const UFStoreInitialState = {
  ufCompany: "",
  isUFDetailsFetched: false,
  isUFDesignDataFetched: false,
  isUFBWDataFetched: false,
  isSaveReportJson: false,
  isUFConfigLoading: false,
  activeUFModule: {},
  headerMenuIcon: "",
  tabletMenuIcon: "",
  tabletMenuIconStatus: false,
  ufTechnologies: UFTechnologies,
  ufModules: [],
  ufModulesByCompany: {},
  ufInputRangeConfig: [],
  ufInputRangeConfigByWaterType: [],
  ufPHGuidline: [],
  ufModuleFlowVM: [],
  ufFluxGuideline: [],
  ufModulePressureRatingVM: [],
  ufStandByOptions: [],
  ufStorageTankOptions: [],
  bwWaterSource: [],
  forwardFlushWaterSource: [],
  bwProtocol: [],
  ufDoseGuidline: [],
  oxidantChemicals: [],
  defaultInputRangeConfig: ufDefaultData,
  data: ufDefaultData,
  isForDrinkingWater: false,
  sliderValue: UF_MODULE_PER_RACK_RANGE.max,
  sliderMin: UF_MODULE_PER_RACK_RANGE.min,
  sliderMax: UF_MODULE_PER_RACK_RANGE.max,
  calcEngineData: {},
  recommended_configs: [],
  defaultUFConfig: {},
  waterSubTypeFlag: false,
  callCalcEngineUFConfig: false,
  isCustomConfigAvail: false,
  isOfflineTimeChanged: false,
  activeTab: "Design",
  isUfDataUpdated: false,
  calcEngineDataRefreshCount: -1,
  isSysDesignVisible: false,
  isRecommendationCalucalting: false,
  ufChemicalAdjustment: { data: null, chemicalAdjustment: null, phConcentration: 0, adjustment: null },
  ufProductFilter: {
    selectedModuleCategory: DEFAULT_PRODUCT_TYPE_FILTERS,
    filteredModules: [],
    selectedGuidelinesData: [],
    activeSortOrder: "",
    activeSearch: "",
    isValidForFiltering: false,
    flagForModuleFilter: false,
    localFilterdModules: [],
    localSelectedModuleCategory: DEFAULT_PRODUCT_TYPE_FILTERS,
  },
};

export default UFStoreInitialState;
