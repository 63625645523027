import { reportStrings } from "@constants/report.strings";

import { WPUfParametersDataMapping } from "../../WPUfParametersTables.constants";
import { generateStepRowData } from "../../WPUfParametersTablesUtils";

export const WPUfDupontBwParametersTableColumns = [
  {
    field: "col1",
    rowHeader: true,
    headerName: reportStrings.pumpAndValveConditions,
    rowSpan: 3,
    width: "145px",
  },
  {
    field: "col2",
    headerName: reportStrings.operation,
    colSpan: 2,
  },
  {
    field: "col3",
    colSpan: 0,
  },
  {
    field: "col4",
    headerName: reportStrings.backwashBW,
    colSpan: 5,
  },
  {
    field: "col5",
    colSpan: 0,
  },
  {
    field: "col6",
    colSpan: 0,
  },
  {
    field: "col7",
    colSpan: 0,
  },
  {
    field: "col8",
    colSpan: 0,
  },
  {
    field: "col9",
    headerName: reportStrings.returnToOpertaion + "<sup>d</sup>",
    width: "85px",
  },
  {
    field: "col10",
    headerName: reportStrings.stop + "<sup>e</sup>",
    width: "60px",
  },
];

export const WPUfDupontBwParametersTableRows = [
  {
    rowKey: "stepNumber",
    ...generateStepRowData(2, 7),
    col9: 2,
    col10: "-",
  },
  {
    rowKey: "stepLabel",
    col2: reportStrings.forwardFlushAtStartUp + "<sup>b</sup>",
    col3: reportStrings.filtrationMode,
    col4: reportStrings.airInlet + "<sup>c</sup>",
    col5: reportStrings.draining,
    col6: reportStrings.backwash1,
    col7: reportStrings.backwash2,
    col8: reportStrings.forwardFlush,
    col9: reportStrings.filtrationMode,
    col10: reportStrings.stop,
  },
  {
    rowKey: "feedPump",
    col1: reportStrings.feedPump,
    col2: true,
    col3: true,
    col8: true,
    col9: true,
  },
  {
    rowKey: "backwashPump",
    col1: reportStrings.backwashPump,
    col6: true,
    col7: true,
  },
  //NOTE: Chemical dosing pump values are ON by default but are hidden based on conditions in useUfParametersTableData.js
  {
    rowKey: "chemDosingPump",
    col1: reportStrings.chemDosingPump + "<sup>a</sup>",
    col6: true,
    col7: true,
  },
  {
    rowKey: "cebDosingPump",
    col1: reportStrings.cebDosingPump,
  },
  {
    rowKey: "cipRecyclePump",
    col1: reportStrings.cipRecyclePump,
  },
  {
    rowKey: "feedValve",
    col1: reportStrings.feedValve,
    col2: true,
    col3: true,
    col8: true,
    col9: true,
  },
  {
    rowKey: "filtrateValve",
    col1: reportStrings.filtrateValve,
    col3: true,
    col9: true,
  },
  {
    rowKey: "concentrateValve",
    col1: reportStrings.concentrateValve,
    col2: true,
    col4: true,
    col5: true,
    col6: true,
    col8: true,
  },
  {
    rowKey: "backwashInletValve",
    col1: reportStrings.backwashInletValve,
    col6: true,
    col7: true,
  },
  {
    rowKey: "drainValve",
    col1: reportStrings.drainValve,
    col5: true,
    col7: true,
  },
  {
    rowKey: "airInletValve",
    col1: reportStrings.airInletValve,
    col4: true,
  },
  {
    rowKey: "duration",
    col1: reportStrings.duration,
    col2: WPUfParametersDataMapping.forwardFlushDuration,
    col3: WPUfParametersDataMapping.t_filtration_cycle,
    col4: WPUfParametersDataMapping.t_AS,
    col5: WPUfParametersDataMapping.t_Drain,
    col6: WPUfParametersDataMapping.t_BW1,
    col7: WPUfParametersDataMapping.t_BW2,
    col8: WPUfParametersDataMapping.t_FF,
    col9: WPUfParametersDataMapping.t_filtration_cycle,
  },
  {
    rowKey: "flowRate",
    col1: reportStrings.flowRate,
    col2: WPUfParametersDataMapping.Flow_FF,
    col3: WPUfParametersDataMapping.Flux_Filter_actual,
    col4: WPUfParametersDataMapping.Flow_AS,
    col5: WPUfParametersDataMapping.drainFlow,
    col6: WPUfParametersDataMapping.Flux_BW,
    col7: WPUfParametersDataMapping.Flux_BW,
    col8: WPUfParametersDataMapping.Flow_FF,
    col9: WPUfParametersDataMapping.Flux_Filter_actual,
  },
];
