import React from "react";
import styled from "styled-components";

import DuPont_logo from "@common/assets/images/DuPont_Logo_SVG.svg";
import Wave_Pro_Logo from "@common/assets/images/WAVE_Pro_Logo_Text.svg";

const LogoContainer = styled.div`
  display: flex;
  gap: 30px;

  a,
  img {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

const WaveProBrandLogo = ({ handleNavigate }) => (
  <LogoContainer>
    <a href='https://www.dupont.com/water.html' target='__blank'>
      <img src={DuPont_logo} alt='Dupont Logo' />
    </a>
    <img src={Wave_Pro_Logo} alt='WavePro Logo' onClick={handleNavigate} />
  </LogoContainer>
);

export default WaveProBrandLogo;
