import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";
import {
  ChemicalActionBtnRenderer,
  ChemicalCategroyRenderer,
  ChemicalConentrationRenderer,
  ChemicalDisplayNameRenderer,
  ChemicalToggleRenderer,
} from "../ChemicalTableRenderer";

export const CHEMICAL_LIB_TABLE_CONFIG = [
  {
    id: 1,
    headerName: CHEMICALS_LIBRARY_STRINGS.displayName,
    field: "displayName",
    cellRenderer: ChemicalDisplayNameRenderer,
    width: 140,
    tooltip: true,
  },
  { id: 2, headerName: CHEMICALS_LIBRARY_STRINGS.name, field: "name", width: 180, tooltip: true },
  {
    id: 3,
    headerName: CHEMICALS_LIBRARY_STRINGS.bulkConcentration,
    field: "bulkConcentration",
    width: 110,
    valueFormatter: params => `${params.value}%`,
    cellRenderer: ChemicalConentrationRenderer,
  },
  {
    id: 4,
    headerName: CHEMICALS_LIBRARY_STRINGS.category,
    field: "category",
    width: 100,
    cellRenderer: ChemicalCategroyRenderer,
  },
  {
    id: 5,
    headerName: CHEMICALS_LIBRARY_STRINGS.symbol,
    field: "symbol",
    width: 100,
    tooltip: true,
  },
  {
    id: 6,
    headerName: CHEMICALS_LIBRARY_STRINGS.bulkDensity,
    field: "bulkDensity",
    width: 125,
  },
  {
    id: 7,
    headerName: CHEMICALS_LIBRARY_STRINGS.bulkPrice,
    field: "bulkPrice",
    width: 100,
  },
  { id: 8, headerName: CHEMICALS_LIBRARY_STRINGS.costType, field: "costType", width: 65 },
  {
    id: 9,
    headerName: CHEMICALS_LIBRARY_STRINGS.showDropDown,
    field: "viewInProjects",
    cellRenderer: ChemicalToggleRenderer,
    width: 150,
  },
  {
    id: 10,
    headerName: "Actions",
    field: "actions",
    cellRenderer: ChemicalActionBtnRenderer,
    width: 106,
  },
];
