import { Modal } from "react-bootstrap";
import styled from "styled-components";

import { colors, modalStyles } from "@common/styles/Theme";

const StyledCustomModal = styled(Modal)`
  background-color: ${colors.blackTransparency045};

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .loading-text {
    color: var(--du-color-primary);
    background: linear-gradient(to right, #006d68 0%, #006d68 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 600;
  }

  .modal-body {
    padding: 0 4px 0 0;
    overflow-y: auto;
    margin-right: 2px;
  }

  .modal-body-container {
    padding: 16px 16px 16px 32px;
  }

  .modal-dialog {
    max-width: 1188px;
  }

  .modal-content {
    ${modalStyles.normalModalStyle};
    width: ${props => props.width || "1188px"} !important;
    margin: 0 auto;
    border-radius: 8px;

    .modal-header {
      border: none;
      background: ${colors.GreyF8};
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: space-between;
      padding: 16px 16px 8px 32px;

      .main-title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .subHeading {
        margin-top: 2px;
      }
    }
  }

  .modal-footer {
    border-top: 0.5px solid ${colors.GreyE1};
    background: ${colors.White};
    padding: 12px 32px;
  }
`;

export default StyledCustomModal;
