import { reportStrings } from "@constants/report.strings";

import { WPUfParametersDataMapping } from "../../WPUfParametersTables.constants";
import { generateStepRowData } from "../../WPUfParametersTablesUtils";

export const WPUfDupontUxaCebParametersTableColumns = [
  {
    field: "col1",
    rowHeader: true,
    headerName: reportStrings.pumpAndValveConditions,
    rowSpan: 3,
  },
  {
    field: "col2",
    headerName: reportStrings.operation,
    colSpan: 1,
  },
  {
    field: "col3",
    headerName: reportStrings.cebFullForm,
    colSpan: 4,
  },
  {
    field: "col4",
    colSpan: 0,
  },
  {
    field: "col5",
    colSpan: 0,
  },
  {
    field: "col6",
    colSpan: 0,
  },
  {
    field: "col7",
    headerName: reportStrings.returnToOpertaion + "<sup>d</sup>",
  },
];

export const WPUfDupontUxaCebParametersTableRows = [
  {
    rowKey: "stepNumber",
    ...generateStepRowData(2, 5),
    col7: 1,
  },
  {
    rowKey: "stepLabel",
    col2: reportStrings.filtrationMode,
    col3: reportStrings.backwashWithAirScrub + "<sup>b</sup>",
    col4: reportStrings.soak,
    col5: reportStrings.backwashWithAirScrub + "<sup>b</sup>",
    col6: reportStrings.forwardFlush + "<sup>c</sup>",
    col7: reportStrings.filtrationMode,
  },
  {
    rowKey: "feedPump",
    col1: reportStrings.feedPump,
    col2: true,
    col6: true,
    col7: true,
  },
  {
    rowKey: "backwashPump",
    col1: reportStrings.backwashPump,
    col3: true,
    col5: true,
  },
  {
    rowKey: "chemDosingPump",
    col1: reportStrings.chemDosingPump,
  },
  {
    rowKey: "cebDosingPump",
    col1: reportStrings.cebDosingPump + "<sup>a</sup>",
    col3: true,
  },
  {
    rowKey: "cipRecyclePump",
    col1: reportStrings.cipRecyclePump,
  },
  {
    rowKey: "feedValve",
    col1: reportStrings.feedValve,
    col2: true,
    col6: true,
    col7: true,
  },
  {
    rowKey: "filtrateValve",
    col1: reportStrings.filtrateValve,
    col2: true,
    col7: true,
  },
  {
    rowKey: "concentrateValve",
    col1: reportStrings.concentrateValve,
    col3: true,
    col5: true,
    col6: true,
  },
  {
    rowKey: "backwashInletValve",
    col1: reportStrings.backwashInletValve,
    col3: true,
    col5: true,
  },
  {
    rowKey: "drainValve",
    col1: reportStrings.drainValve,
  },
  {
    rowKey: "airInletValve",
    col1: reportStrings.airInletValve,
    col3: true,
    col5: true,
  },
  {
    rowKey: "duration",
    col1: reportStrings.duration,
    col2: WPUfParametersDataMapping.t_filtration_cycle,
    col3: WPUfParametersDataMapping.t_BW1_CEB,
    col4: WPUfParametersDataMapping.t_CEB_soak,
    col5: WPUfParametersDataMapping.t_BW1_CEB,
    col6: WPUfParametersDataMapping.t_FF,
    col7: WPUfParametersDataMapping.t_filtration_cycle,
  },
  {
    rowKey: "flowRate",
    col1: reportStrings.flowRate,
    col2: WPUfParametersDataMapping.Flux_Filter_actual,
    col3: WPUfParametersDataMapping.Flux_CEB,
    col4: WPUfParametersDataMapping.flowZero,
    col5: WPUfParametersDataMapping.Flux_BW,
    col6: WPUfParametersDataMapping.Flow_FF,
    col7: WPUfParametersDataMapping.Flux_Filter_actual,
  },
  {
    rowKey: "flowRateUxa",
    col3: WPUfParametersDataMapping.Flow_AS,
  },
];
