import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { APP_TOAST_NOTIFICATION } from "@constants/message.constants";

import useUpdateCase from "@hooks/useUpdateCase";

import WPAgGrid from "@components/WPAgGrid/WPAgGrid";
import { useToastNotification } from "@components/WPToastNotification";

import { updateCaseConfig } from "@common/ProjectInfoSlice";

import { CASE_TABLE_CONFIG } from "./CaseManagementConfig";

import "./CaseManager.scss";

const CaseManagementTable = ({
  cases,
  setCases,
  handleDelete,
  onEditCase,
  selectedCase,
  currentActiveCase,
  setSelectedCase,
  handleOpenCase,
}) => {
  const dispatch = useDispatch();
  const { updateCase, loading, caseUpdateError } = useUpdateCase();
  const { showErrorNotification } = useToastNotification();

  const gridRef = React.useRef(null);

  useEffect(() => {
    setSelectedCase(null);
  }, []);

  const moveCase = async newCases => {
    const res = await updateCase(newCases);
    if (!loading) {
      if (caseUpdateError) {
        showErrorNotification(APP_TOAST_NOTIFICATION.CASE_MOVE_FAILED);
      } else {
        dispatch(updateCaseConfig([...newCases]));
        setCases([...newCases]);
      }
    }
  };

  const handleEdit = editCase => {
    onEditCase(editCase);
  };

  const onDelete = selectedCaseIndex => {
    handleDelete(selectedCaseIndex);
  };

  const onRowDragEnd = async event => {
    const updatedData = [];
    event.api.forEachNode((node, index) => {
      updatedData.push({ ...node.data, displayOrder: index + 1 });
    });

    moveCase(updatedData);
  };

  const onSelectionChanged = () => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    if (selectedNodes.length > 0) {
      const selectedNode = selectedNodes[0];
      setSelectedCase(selectedNode.data);
    }
  };

  const onRowDoubleClicked = event => {
    const openCase = event.node.data;
    handleOpenCase(openCase);
  };

  const onGridReady = params => params.api.sizeColumnsToFit();

  const getRowClass = params => (params.data.caseID === currentActiveCase ? "highlighted-row" : "");

  const getRowDragText = params => "";

  const gridOptions = {
    animateRows: true,
    rowDragEntireRow: true,
    rowDragManaged: true,
    rowDragText: getRowDragText,
    onRowDragEnd,
    icons: {
      rowDrag: "<span></span>",
    },
  };

  return (
    <div className='case-management'>
      <WPAgGrid
        rowData={cases}
        columnDefs={CASE_TABLE_CONFIG}
        tableHeight='300px'
        rowDragEntireRow={true}
        context={{ handleEdit, onDelete }}
        rowSelection={"single"}
        onSelectionChanged={onSelectionChanged}
        onRowDoubleClicked={onRowDoubleClicked}
        gridRef={gridRef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        getRowClass={getRowClass}
      />
    </div>
  );
};

export default CaseManagementTable;
