import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { WPUfAdditionalFeedWaterInfoColumns as columns } from "./WPUfAdditionalFeedWaterInfo.constants";

export const WPUfAdditionalFeedWaterInfo = () => {
  const { uf_additional_water_info: rows } = useSelector(state => state.report.ufReportTableData);
  if (rows?.length === 0) {
    return null;
  }

  return <WPTable showHeader={false} sectionHeader={reportStrings.additionalFeedInfo} columns={columns} rows={rows} />;
};
