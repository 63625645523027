import { UNIT_TYPES, UNITS } from "@constants/units.constant";

export const WPUfDesignWarningsTableColumns = [
  {
    field: "col1",
    rowHeader: true,
    headerName: "Design Warning",
  },
  {
    field: "col2",
    rowHeader: true,
    headerName: "",
    width: "100px",
  },
  {
    field: "col3",
    headerName: "Limit",
    width: "20%",
  },
  {
    field: "col4",
    headerName: "Estimate",
    width: "20%",
  },
];

export const WPUfDesignWarningsRowsData = {
  Minimum_feed_temp_lt_min: {
    label: "Minimum feed temperature < min",
    unit: UNIT_TYPES.TEMPERATURE,
  },
  Minimum_feed_temperature_gt_max: {
    label: "Maximum feed temperature > max",
    unit: UNIT_TYPES.TEMPERATURE,
  },
  Design_feed_temperature_lt_min: {
    label: "Design feed temperature < min",
    unit: UNIT_TYPES.TEMPERATURE,
  },
  Design_feed_temperature_gt_max: {
    label: "Design feed temperature > max",
    unit: UNIT_TYPES.TEMPERATURE,
  },
  Filtration_cycle_Filtration_flux_lt_min: {
    label: "Filtration cycle: Filtration flux < min",
    unit: UNIT_TYPES.FLUX,
  },
  Filtration_cycle_Filtration_flux_gt_max: {
    label: "Filtration cycle: Filtration flux > max",
    unit: UNIT_TYPES.FLUX,
  },
  Filtration_cycle_Duration_lt_min: {
    label: "Filtration cycle: Duration < min",
    unit: UNITS.min,
  },
  Filtration_cycle_Duration_gt_max: {
    label: "Filtration cycle: Duration > max",
    unit: UNITS.min,
  },
  Filtration_cycle_Operating_pH_lt_min: {
    label: "Filtration cycle: Operating pH < min",
  },
  Filtration_cycle_Operating_pH_gt_max: {
    label: "Filtration cycle: Operating pH > max",
  },
  BW_cycle_Backwash_flux_lt_min: {
    label: "BW cycle: Backwash flux < min",
    unit: UNIT_TYPES.FLUX,
  },
  BW_cycle_Backwash_flux_gt_max: {
    label: "BW cycle: Backwash flux > max",
    unit: UNIT_TYPES.FLUX,
  },
  BW_cycle_Temperature_lt_min: {
    label: "BW cycle: Temperature < min",
    unit: UNIT_TYPES.TEMPERATURE,
  },
  BW_cycle_Temperature_gt_max: {
    label: "BW cycle: Temperature > max",
    unit: UNIT_TYPES.TEMPERATURE,
  },
  BW_cycle_Air_scour_duration_lt_min: {
    label: "BW cycle: Air scour duration < min",
    unit: UNITS.sec,
  },
  BW_cycle_Air_scour_duration_gt_max: {
    label: "BW cycle: Air scour duration > max",
    unit: UNITS.sec,
  },
  BW_cycle_Top_BW_duration_lt_min: {
    label: "BW cycle: Top BW duration < min",
    unit: UNITS.sec,
  },
  BW_cycle_Top_BW_duration_gt_max: {
    label: "BW cycle: Top BW duration > max",
    unit: UNITS.sec,
  },
  BW_cycle_Bottom_BW_duration_lt_min: {
    label: "BW cycle: Bottom BW duration < min",
    unit: UNITS.sec,
  },
  BW_cycle_Bottom_BW_duration_gt_max: {
    label: "BW cycle: Bottom BW duration > max",
    unit: UNITS.sec,
  },
  BW_cycle_Drain_BW_duration_lt_min: {
    label: "BW cycle: Drain duration < min",
    unit: UNITS.sec,
  },
  BW_cycle_Drain_BW_duration_gt_max: {
    label: "BW cycle: Drain duration > max",
    unit: UNITS.sec,
  },
  BW_cycle_Forward_Flush_duration_lt_min: {
    label: "BW cycle: Forward flush duration < min",
    unit: UNITS.sec,
  },
  BW_cycle_Forward_Flush_duration_gt_max: {
    label: "BW cycle: Forward flush duration > max",
    unit: UNITS.sec,
  },
  Acid_CEB_cycle_Interval_lt_min: {
    label: "Acid CEB cycle: Interval < min",
    unit: UNITS.hour,
  },
  Acid_CEB_cycle_Interval_gt_max: {
    label: "Acid CEB cycle: Interval > max",
    unit: UNITS.hour,
  },
  Alkali_CEB_cycle_Interval_lt_min: {
    label: "Alkali CEB cycle: Interval < min",
    unit: UNITS.hour,
  },
  Alkali_CEB_cycle_Interval_gt_max: {
    label: "Alkali CEB cycle: Interval > max",
    unit: UNITS.hour,
  },
  Disinfection_CEB_cycle_Interval_lt_min: {
    label: "Disinfection CEB cycle: Interval < min",
    unit: UNITS.hour,
  },
  Disinfection_CEB_cycle_Interval_gt_max: {
    label: "Disinfection CEB cycle: Interval > max",
    unit: UNITS.hour,
  },
  CEB_cycle_CEB_flux_lt_min: {
    label: "CEB cycle: CEB flux < min",
    unit: UNIT_TYPES.FLUX,
  },
  CEB_cycle_CEB_flux_gt_max: {
    label: "CEB cycle: CEB flux > max",
    unit: UNIT_TYPES.FLUX,
  },
  CEB_cycle_Temperature_lt_min: {
    label: "CEB cycle: Temperature < min",
    unit: UNIT_TYPES.TEMPERATURE,
  },
  CEB_cycle_Temperature_gt_max: {
    label: "CEB cycle: Temperature > max",
    unit: UNIT_TYPES.TEMPERATURE,
  },
  CEB_cycle_Air_scour_duration_lt_min: {
    label: "CEB cycle: Air scour duration < min",
    unit: UNITS.sec,
  },
  CEB_cycle_Air_scour_duration_gt_max: {
    label: "CEB cycle: Air scour duration > max",
    unit: UNITS.sec,
  },
  CEB_cycle_Top_BW_duration_lt_min: {
    label: "CEB cycle: Top BW duration < min",
    unit: UNITS.sec,
  },
  CEB_cycle_Top_BW_duration_gt_max: {
    label: "CEB cycle: Top BW duration > max",
    unit: UNITS.sec,
  },
  CEB_cycle_Bottom_BW_duration_lt_min: {
    label: "CEB cycle: Bottom BW duration < min",
    unit: UNITS.sec,
  },
  CEB_cycle_Bottom_BW_duration_gt_max: {
    label: "CEB cycle: Bottom BW duration > max",
    unit: UNITS.sec,
  },
  CEB_cycle_Drain_duration_lt_min: {
    label: "CEB cycle: Drain duration < min",
    unit: UNITS.sec,
  },
  CEB_cycle_Drain_duration_gt_max: {
    label: "CEB cycle: Drain duration > max",
    unit: UNITS.sec,
  },
  CEB_cycle_Forward_Flush_duration_lt_min: {
    label: "CEB cycle: Forward flush duration < min",
    unit: UNITS.sec,
  },
  CEB_cycle_Forward_Flush_duration_gt_max: {
    label: "CEB cycle: Forward flush duration > max",
    unit: UNITS.sec,
  },
  CEB_cycle_Chemical_soak_duration_lt_min: {
    label: "CEB cycle: Chemical soak duration < min",
    unit: UNITS.min,
  },
  CEB_cycle_Chemical_soak_duration_gt_max: {
    label: "CEB cycle: Chemical soak duration > max",
    unit: UNITS.min,
  },
  Acid_CEB_cycle_cleaning_pH_lt_min: {
    label: "Acid CEB cycle: cleaning pH < min",
  },
  Acid_CEB_cycle_cleaning_pH_gt_max: {
    label: "Acid CEB cycle: cleaning pH > max",
  },
  Alkali_CEB_cycle_cleaning_pH_lt_min: {
    label: "Alkali CEB cycle: cleaning pH < min",
  },
  Alkali_CEB_cycle_cleaning_pH_gt_max: {
    label: "Alkali CEB cycle: cleaning pH > max",
  },
  Alkali_CEB_cycle_NaOCl_Conc_gt_max: {
    label: "Alkali CEB cycle: NaOCl Conc. > max",
  },
  Disinf_CEB_cycle_NaOCl_Conc_gt_max: {
    label: "Disinfection CEB cycle: NaOCl Conc. > max",
  },
  CIP_cycle_Interval_lt_min: {
    label: "CIP cycle: Interval < min",
    unit: UNITS.day,
  },
  CIP_cycle_Interval_gt_max: {
    label: "CIP cycle: Interval > max",
    unit: UNITS.day,
  },
  CIP_cycle_Temperature_lt_min: {
    label: "CIP cycle: Temperature < min",
    unit: UNIT_TYPES.TEMPERATURE,
  },
  CIP_cycle_Temperature_gt_max: {
    label: "CIP cycle: Temperature > max",
    unit: UNIT_TYPES.TEMPERATURE,
  },
  CIP_cycle_Recycle_flow_lt_min: {
    label: "CIP cycle: Recycle flow < min",
    unit: UNIT_TYPES.FLOW,
    unitSubText: "/module",
  },
  CIP_cycle_Recycle_flow_gt_max: {
    label: "CIP cycle: Recycle flow > max",
    unit: UNIT_TYPES.FLOW,
    unitSubText: "/module",
  },
  CIP_cycle_Recycle_duration_lt_min: {
    label: "CIP cycle: Recycle duration < min",
    unit: UNITS.min,
  },
  CIP_cycle_Recycle_duration_gt_max: {
    label: "CIP cycle: Recycle duration > max",
    unit: UNITS.min,
  },
  CIP_cycle_Soak_duration_lt_min: {
    label: "CIP cycle: Soak duration < min",
    unit: UNITS.min,
  },
  CIP_cycle_Soak_duration_gt_max: {
    label: "CIP cycle: Soak duration > max",
    unit: UNITS.min,
  },
  Acid_CIP_cycle_cleaning_pH_lt_min: {
    label: "Acid CIP cycle: Cleaning pH < min",
  },
  Acid_CIP_cycle_cleaning_pH_gt_max: {
    label: "Acid CIP cycle: Cleaning pH > max",
  },
  Alkali_CIP_cycle_cleaning_pH_lt_min: {
    label: "Alkali CIP cycle: Cleaning pH < min",
  },
  Alkali_CIP_cycle_cleaning_pH_gt_max: {
    label: "Alkali CIP cycle: Cleaning pH > max",
  },
  Alkali_CIP_cycle_NaOCl_Conc_gt_max: {
    label: "Alkali CIP cycle: NaOCl Conc. > max",
  },
  Forward_flush_flow_lt_min: {
    label: "Forward flush flow < min",
    unit: UNIT_TYPES.FLOW,
  },
  Forward_flush_flow_gt_max: {
    label: "Forward flush flow > max",
    unit: UNIT_TYPES.FLOW,
  },
  Air_scour_flow_lt_min: {
    label: "Air scour flow < min",
    unit: UNIT_TYPES.GAS_FLOW,
  },
  Air_scour_flow_gt_max: {
    label: "Air scour flow > max",
    unit: UNIT_TYPES.GAS_FLOW,
  },
  Air_scour_pressure_gt_max: {
    label: "Air scour pressure > max",
    unit: UNIT_TYPES.PRESSURE,
    unitSubText: "g",
  },
  Inlet_Pressure_at_TMin_gt_max: {
    label: "Inlet Pressure @ TMin > max",
    unit: UNIT_TYPES.PRESSURE,
  },
  Inlet_Pressure_at_TDesign_gt_max: {
    label: "Inlet Pressure @ TDesign > max",
    unit: UNIT_TYPES.PRESSURE,
  },
  Inlet_Pressure_at_TMax_gt_max: {
    label: "Inlet Pressure @ TMax > max",
    unit: UNIT_TYPES.PRESSURE,
  },
  Clean_membrane_TMP_at_TMin_gt_max: {
    label: "Clean membrane TMP @ TMin > max",
    unit: UNIT_TYPES.PRESSURE,
  },
  Clean_membrane_TMP_at_TDesign_gt_max: {
    label: "Clean membrane TMP @ TDesign > max",
    unit: UNIT_TYPES.PRESSURE,
  },
  Clean_membrane_TMP_at_TMax_gt_max: {
    label: "Clean membrane TMP @ TMax > max",
    unit: UNIT_TYPES.PRESSURE,
  },
  Fouled_membrane_TMP_at_TMin_gt_max: {
    label: "Fouled membrane TMP @ TMin > max",
    unit: UNIT_TYPES.PRESSURE,
  },
  Fouled_membrane_TMP_at_TDesign_gt_max: {
    label: "Fouled membrane TMP @ TDesign > max",
    unit: UNIT_TYPES.PRESSURE,
  },
  Fouled_membrane_TMP_at_TMax_gt_max: {
    label: "Fouled membrane TMP @ TMax > max",
    unit: UNIT_TYPES.PRESSURE,
  },
  Fouled_membrane_TMP_at_TBW_gt_max: {
    label: "Fouled membrane TMP @ TBW > max",
    unit: UNIT_TYPES.PRESSURE,
  },
  Fouled_membrane_TMP_at_TCEB_gt_max: {
    label: "Fouled membrane TMP @ TCEB > max",
    unit: UNIT_TYPES.PRESSURE,
  },
};
