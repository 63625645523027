import { reportStrings } from "@constants/report.strings";

import { useReportUtils } from "@features/report-new/useReportUtils";

import {
  WPUfPumpHydraulicsTableColumns as columns,
  WPUfPumpHydraulicsTableRowsData as rowsData,
  WPUfPumpHydraulicsUnitMapping as unitMapping,
} from "./WPUfPumpHydraulicsTable.constants";

export const useWPUfPumpHydraulicsTableData = () => {
  const { ufReportRespData, formatLabel, getUnitLabelValue, formatChemicalNameConc, convertReportDataValue } =
    useReportUtils();
  const { pump_electrical } = ufReportRespData || {};

  const getLabelValue = (rowKey, isChemicalPump, name, concentration, label) => {
    const disInfectionLabel = rowKey === "CEB_NaOCl_disinf" ? ` (${reportStrings.disinfection})` : "";
    const labelValue = isChemicalPump
      ? formatChemicalNameConc(name, concentration) + " " + reportStrings.meteringPump + disInfectionLabel
      : label;

    const indentLevel = isChemicalPump ? 1 : 0;
    return formatLabel(labelValue, indentLevel);
  };

  const convertToUfPumpHydraulicsTableData = () => {
    const firstRow = columns.reduce((acc, curr) => {
      acc[curr.field] = getUnitLabelValue({ unit: unitMapping[curr.field], isCost: curr.field === "cost" });
      return acc;
    }, {});

    const data = Object.entries(rowsData).reduce((acc, curr) => {
      const [rowKey, row] = curr;
      const { label, isChemicalPump } = row;
      const apiSingleRowData = pump_electrical?.[rowKey] || {};
      const { display = true, name, concentration } = apiSingleRowData;

      if (!display) return acc;

      const singleRow = columns.reduce((acc, curr) => {
        const colKey = curr.field;
        if (colKey === "label") {
          acc[colKey] = getLabelValue(rowKey, isChemicalPump, name, concentration, label);
        } else {
          // precision is always 2 for this table overriding units precision
          acc[colKey] = convertReportDataValue({
            value: apiSingleRowData?.[colKey],
            unit: unitMapping[colKey],
            precision: 2,
            isCost: colKey === "cost",
          });
        }
        return acc;
      }, {});

      acc.push(singleRow);
      return acc;
    }, []);

    // adding unit row at the top
    data.unshift(firstRow);

    return data;
  };

  return { convertToUfPumpHydraulicsTableData };
};
