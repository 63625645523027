import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import CloseCircleGreenIcon from "@common/icons/CloseCircleGreenIcon";
import CloseCircleRedIcon from "@common/icons/CloseCircleRedIcon";
import CloseIcon from "@common/icons/CloseIcon";
import ErrorSearchIcon from "@common/icons/ErrorSearchIcon";
import PlusIcon from "@common/icons/PlusIcon";
import SearchIcon from "@common/icons/SearchIcon";
import AlertPopUp from "@common/notifications/AlertPopUp";
import StandardPrimaryButton from "@common/styles/components/buttons/standard/StandardPrimaryButton";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import ErrorMessage from "@common/styles/components/headings/ErrorMessage";
import SearchInputBox from "@common/styles/components/inputs/SearchInputBox";
import CustomRadio from "@common/styles/components/radios/CustomRadio";
import { colors } from "@common/styles/Theme";
import CustomeMessagePopup from "@common/utils/CustomeMessagePopup";
import { MyError } from "@common/utils/ErrorCreator";

import { useLazyGetAllDataQuery, useUpdateDataMutation } from "@services/apiConfig";

import FolderUpdatePopup from "./folder/FolderUpdatePopup";
import AddToFolderStyled from "./AddToFolderStyled";
import { btnlist } from "./CardListSlice";

const AddToFolder = ({ show, handleAddFolderClose, projectInfo }) => {
  const [alertData, setAlert] = useState({ type: "", message: "" });
  const [showAlert, setAlertVisibility] = useState(false);

  const [FoldersName, setFoldersName] = useState(null);
  const [AllFolders, setAllFolders] = useState(null);

  const UserInfoStore = useSelector(state => state.userInfo.data);
  const userID = UserInfoStore ? UserInfoStore.UserId : 1;
  const [getData, response] = useLazyGetAllDataQuery();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isFolderCreated, setIsFolderCreated] = useState(false);
  const [MovefoldeprojectData] = useUpdateDataMutation();
  const [isError, setIsError] = useState(false);
  const [txtvalue, setTxtvalue] = useState("");
  const StoreSidLeftpanel = useSelector(state => state.leftpanel.data);
  const [getDataupdate, updateresponsedata] = useLazyGetAllDataQuery();
  const dispatch = useDispatch();

  const [popupOperator, setPopupOperator] = useState({
    type: "",
    message: "",
    show: false,
  });

  const handleOpenCreateNewFolder = () => {
    setIsOpen(true);
    setIsFolderCreated(false);
    document.body.classList.add("disable");
  };

  useEffect(() => {
    if (updateresponsedata.status == "fulfilled") {
      dispatch(btnlist(updateresponsedata));
    }
  }, [updateresponsedata]);

  useEffect(() => {
    if (show || isFolderCreated) {
      getData(`masterdata/api/v1/FolderList?userID=${userID}`);
      setTxtvalue("");
    }
  }, [show, isFolderCreated]);

  useEffect(() => {
    if (response.isSuccess) {
      setFoldersName(response);
      setAllFolders(response);
    }
    if (response.isError) {
      throw new MyError("Get FolderList Api Error", response.error.status, "ApiError");
    }
  }, [response]);

  const handleCLoseOperator = () => {
    setPopupOperator({
      show: false,
      message: "",
      type: "",
    });
  };
  const handleFolderChange = folder => {
    setSelectedFolder(folder);
  };

  const handleShowAlert = (type, message) => {
    setAlert({ type, message });
    setAlertVisibility(true);
  };

  const handleHideAlert = () => {
    setAlert({ type: "", message: "" });
    setAlertVisibility(false);
    handleAddFolderClose();
  };

  const handleAddToFolder = async () => {
    const apiMethod = "masterdata/api/v1/ProjectFolderIDMapping";
    const data = {
      Method: apiMethod,
      userID: userID,
      projectID: projectInfo?.ProjectId,
    };
    data["folderID"] = selectedFolder.folderID;
    const responseValues = await MovefoldeprojectData(data);

    if (responseValues?.data?.responseMessage == "Success") {
      const message = `Project '${projectInfo?.title}' has been added to '${selectedFolder.folderName}'.`;
      handleShowAlert("success", message);
      updateDataBasedOnStore();
    } else if (responseValues?.error?.data?.responseMessage == "Failure") {
      const errorMessage = `${responseValues?.error?.data?.responseMessage}`;
      handleShowAlert("error", errorMessage);
    } else {
      const warningMessage = `${responseValues?.data?.responseMessage}`;
      handleShowAlert("warning", warningMessage);
    }
  };

  const updateDataBasedOnStore = () => {
    const apiPaths = {
      "masterdata/api/v1/ProjectRecent": `masterdata/api/v1/ProjectRecent?userID=${userID}`,
      "masterdata/api/v1/ProjectAll": `masterdata/api/v1/ProjectAll?userID=${userID}`,
      "masterdata/api/v1/ProjectFavorite": `masterdata/api/v1/ProjectFavorite?userID=${userID}`,
    };
    if (apiPaths[StoreSidLeftpanel]) {
      getDataupdate(apiPaths[StoreSidLeftpanel]);
    }
  };
  const txtchange = e => {
    const inputValue = e.target.value;

    const regex = /^[a-zA-Z0-9-_\s]+$/;

    if (inputValue === "") {
      setTxtvalue("");
      setIsError(false);
      setFoldersName(AllFolders);
    } else if (regex.test(inputValue)) {
      setTxtvalue(inputValue);
      setIsError(false);
    } else {
      setIsError(true);
      setTxtvalue(inputValue);
    }
  };

  const filterFolder = () => {
    const filteredFolders = FoldersName?.data?.filter(item =>
      item.folderName.toUpperCase().includes(txtvalue.toUpperCase()),
    );
    setFoldersName({ ...FoldersName, data: filteredFolders });
  };

  const SearchTxt = () => {
    if (txtvalue === "") {
      setTxtvalue("");
    }
    filterFolder();
  };

  const handleKeyDown = e => {
    switch (e.key) {
      case "Enter": {
        if (txtvalue === "") {
          setTxtvalue("");
        }
        filterFolder();
        break;
      }
    }
  };

  return (
    <div>
      <AddToFolderStyled
        size='lg'
        centered
        show={show}
        onHide={handleAddFolderClose}
        dialogClassName='primary-modal'
        backdropClassName='dark-backdrop'
        backdrop='static'
        keyboard={false}
        aria-labelledby='contained-modal-title-vcenter'
      >
        <CustomeMessagePopup operator={popupOperator} close={handleCLoseOperator} />
        <Modal.Header>
          <div className='header-create-project'>
            <div className='heading'>
              <CustomHeading
                label='Add To Folder'
                fontFamily='DiodrumSemiBold'
                fontSize='16px'
                fontWeight='600'
                color={colors.PrimaryDarkAquaMarine}
              />
              <CustomHeading
                className='add-folder-sub-heading'
                label={`Add Project ${projectInfo?.title} to any folder listed 
                                below or you can create a new folder also based on your prefrences.`}
                fontFamily='DiodrumRegular'
                fontSize='12px'
                fontWeight='400'
                color={colors.blackTransparency045}
              />
            </div>
            <div className='close-icon'>
              <Button id='btnClose' onClick={handleAddFolderClose}>
                <CloseIcon />
              </Button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='add-folder-container'>
            <div className='displayCenter'>
              <div className='search-input-container'>
                <SearchInputBox
                  type='text'
                  placeholder={!isError ? "Search Folder" : "Wrong text"}
                  onChange={e => txtchange(e)}
                  icon={!isError ? <SearchIcon /> : <ErrorSearchIcon />}
                  unitBgColor='transparent'
                  onSearch={() => SearchTxt()}
                  inputText={isError ? <CloseCircleRedIcon /> : txtvalue != "" ? <CloseCircleGreenIcon /> : ""}
                  isError={isError}
                  disabled={false}
                  value={txtvalue}
                  onKeyDown={handleKeyDown}
                />
                <ErrorMessage
                  errorIcon={true}
                  style={{ visibility: isError ? "visible" : "hidden" }}
                  texMsg={"Please check your input or clear this field."}
                />
              </div>
              <div className='create-folder' onClick={handleOpenCreateNewFolder}>
                <div>Create New Folder</div>
                <div className='text-right'>
                  <PlusIcon className='create-folder-icon' />
                </div>
              </div>
            </div>
            <div className='folders-container'>
              {AllFolders && AllFolders?.data?.length != 0 ? (
                FoldersName.data.map((folder, index) => (
                  <div key={index} className='folder-radio'>
                    <CustomRadio
                      type='radio'
                      label={folder.folderName}
                      name={folder.folderName}
                      value={folder.folderID}
                      onChange={() => handleFolderChange(folder)}
                      checked={folder.folderID == selectedFolder?.folderID}
                    />
                  </div>
                ))
              ) : (
                <div className='no-folder-text'>No folder has been created. Create one to add your Project.</div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <StandardPrimaryButton
            variant='light'
            type='submit'
            id=''
            disabled={!selectedFolder}
            onClick={handleAddToFolder}
            label='Add To Folder'
          />
        </Modal.Footer>
        {showAlert && <AlertPopUp type={alertData?.type} message={alertData?.message} close={handleHideAlert} />}
      </AddToFolderStyled>
      {FoldersName && isOpen && (
        <FolderUpdatePopup
          isOpen={isOpen}
          userID={userID}
          setIsOpen={setIsOpen}
          folders={AllFolders?.data}
          isFolderCreated={isFolderCreated}
          setIsFolderCreated={setIsFolderCreated}
          mode={"create"}
          handleCloseRenameProject={handleCLoseOperator}
          popupOperator={popupOperator}
          setPopupOperator={setPopupOperator}
        />
      )}
    </div>
  );
};

export default AddToFolder;
