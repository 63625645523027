import React from "react";
import { Modal } from "react-bootstrap";

import { PROJECT_NAME_MAX_LENGTH } from "@constants/global.constants";

import { ImportProjectStrings } from "@utils/StringConstants";

import WarningIconRound from "@components/SVGs/WarningIconRound";

import StandardLinkButton from "@common/styles/components/buttons/standard/StandardLinkButton";
import StandardPrimaryButton from "@common/styles/components/buttons/standard/StandardPrimaryButton";
import StandardSecondaryButton from "@common/styles/components/buttons/standard/StandardSecondaryButton";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import StyledModal, { StyledModalFooter } from "@common/styles/components/modals/CustomModal";

import "./index.css";

const ReplaceRenameProjectPopup = ({ projectName, handleReplace, handleRename, handleClose }) => {
  const truncatedProjectName =
    projectName.length > PROJECT_NAME_MAX_LENGTH
      ? `${projectName.substring(0, PROJECT_NAME_MAX_LENGTH)}...`
      : projectName;

  return (
    <>
      <StyledModal show centered backdrop='static' isWarningPopUp maxWidth='416px'>
        <Modal.Body>
          <div className='warning-pop-up'>
            <div>
              <WarningIconRound />
            </div>
            <div>
              <CustomHeading
                fontFamily='DiodrumSemiBold'
                fontSize='16px'
                label={ImportProjectStrings.projectWithNameAlreadyExists(truncatedProjectName)}
              />
              <div className='descrption-margin'>
                <CustomHeading
                  fontFamily='NotoSansRegular'
                  fontSize='16px'
                  label='You can either rename this project or replace the existing one.'
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <StyledModalFooter isWarningPopUp={true}>
          <StandardLinkButton id='btn-cancel' onClick={handleClose} label={ImportProjectStrings.cancel} />
          <StandardSecondaryButton id='btn-replace' onClick={handleReplace} label={ImportProjectStrings.replace} />
          <StandardPrimaryButton id='btn-rename' onClick={handleRename} label={ImportProjectStrings.rename} />
        </StyledModalFooter>
      </StyledModal>
    </>
  );
};
export default ReplaceRenameProjectPopup;
