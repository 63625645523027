import React from "react";
import styled from "styled-components";

import { InputErrorIcon } from "@components/SVGs";

import { colors, fontStyles } from "../../Theme";

const StyledWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 4px;
`;

const ErrorMessageStyled = styled.label`
  display: flex;
  color: ${colors.DupontBrandRed};
  margin-bottom: 0;
  ${fontStyles.diodrum10};
`;

const ErrorMessage = ({ texMsg = "", className = "", style = {} }) => (
  <StyledWrapper style={style}>
    <InputErrorIcon />
    <ErrorMessageStyled className={className}>{texMsg}</ErrorMessageStyled>
  </StyledWrapper>
);

export default React.memo(ErrorMessage);
