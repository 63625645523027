import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCaseData, editCaseData, getCaseData, updateCaseData } from "src/api/caseApi";

import { APP_TOAST_NOTIFICATION } from "@constants/message.constants";

import DupontLogger from "@utils/DupontLogger";
import { CaseManagementStrings } from "@utils/StringConstants";

import { updateCaseConfig } from "@common/ProjectInfoSlice";

import { setCaseName } from "@features/feedwater/systemdesign/processDiagramSlice";

import useProjectDetails from "./useProjectDetails";

const useUpdateCase = () => {
  const Logger = DupontLogger("useUpdateCase");
  const dispatch = useDispatch();
  const { activeCaseId } = useProjectDetails();

  const [loadingText, setLoadingText] = useState(false);
  const [caseUpdateError, setCaseUpdateError] = useState(null);
  const { caseList } = useSelector(state => state.projectInfo.projectConfig.caseConfig);
  const ProjectInfoStore = useSelector(state => state.projectInfo.data);
  const UserInfoStore = useSelector(state => state.userInfo.data);
  const userID = UserInfoStore ? UserInfoStore.UserId : 1;

  const addNewCases = async params => {
    setLoadingText(CaseManagementStrings.addCaseLoader);
    try {
      const { data } = await addCaseData(params);
      const newCaseIdList = [...caseList, data];
      dispatch(updateCaseConfig(newCaseIdList));
      return { isSuccess: true, message: APP_TOAST_NOTIFICATION.CASE_ADDED };
    } catch (error) {
      Logger.error("Error during adding cases:", error);
      return { isSuccess: false, message: APP_TOAST_NOTIFICATION.CASE_ADD_FAILED };
    } finally {
      setLoadingText(null);
    }
  };

  const editCase = async params => {
    setLoadingText(CaseManagementStrings.updateCaseLoader);
    setCaseUpdateError(null);
    try {
      const { data } = await editCaseData(params);
      const caseIdx = caseList.findIndex(item => item.caseID === data.caseID);
      const newCaseIdList = [...caseList];
      newCaseIdList[caseIdx] = data;
      dispatch(updateCaseConfig(newCaseIdList));
      if (activeCaseId === data.caseID) {
        dispatch(setCaseName(data.caseName));
      }
      return { isSuccess: true, message: APP_TOAST_NOTIFICATION.CASE_UPDATED };
    } catch (error) {
      Logger.error("Error during updating cases:", error);
      setCaseUpdateError(error.message);
      return { isSuccess: false, message: APP_TOAST_NOTIFICATION.CASE_UPDATE_FAILED };
    } finally {
      setLoadingText(null);
    }
  };

  const updateCase = async cases => {
    setLoadingText(true);
    setCaseUpdateError(null);
    try {
      const params = {
        userID,
        projectID: ProjectInfoStore.projectID,
        responseCases: cases.map(({ caseID, projectID, caseName, caseNote, displayOrder, isDeletedCase }) => ({
          caseID,
          projectID,
          caseName,
          caseNote,
          displayOrder,
          isDeletedCase,
        })),
        currentCaseID: ProjectInfoStore.caseId,
      };
      const response = await updateCaseData(params, {});

      if (response.status == 200) {
        setCaseUpdateError(null);
      } else {
        setCaseUpdateError(response.error);
      }
    } catch (e) {
      setCaseUpdateError(e.message);
    } finally {
      setLoadingText(false);
    }
  };

  const fetchCases = async projectID => {
    try {
      setLoadingText(true);
      const caseData = await getCaseData({ projectID });
      const sortedCaseData = caseData?.data?.sort((a, b) => a.displayOrder - b.displayOrder);
      const filteredCases = sortedCaseData?.filter(caseItem => !caseItem.isDeletedCase);
      dispatch(updateCaseConfig(filteredCases));
    } catch (error) {
      Logger.error("Error in fetching cases:", error);
    } finally {
      setLoadingText(null);
    }
  };

  return { updateCase, caseUpdateError, loading: loadingText, addNewCases, editCase, fetchCases, caseList };
};

export default useUpdateCase;
