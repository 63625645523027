export const APP_TOAST_NOTIFICATION = {
  //=================== SUCCESS MESSAGES ==================================
  PROJECT_SAVED: "Changes saved",
  CHEMICAL_SAVED: "Chemical saved successfully",
  FOLDER_CREATED: "Folder created successfully",
  FOLDER_DELETED: "Folder deleted successfully",
  FOLDER_RESTORED: "Folder restored successfully",
  PROJECT_DELETED: "Project deleted successfully",
  CHEMICAL_UPDATED: "Chemical updated successfully",
  CHEMICAL_DELETED: "Chemical deleted successfully",
  CASE_DELETED: "Case deleted successfully",
  CASE_ADDED: "Case added successfully",
  CASE_UPDATED: "Case updated successfully",
  PROJECT_RESTORED: "Project restored successfully",
  PROJECT_EXPORTED: "Project exported successfully",
  REPORT_DOWNLOADED: "Report downloaded successfully",
  WATER_LIB_SAVED: "Water library saved successfully",
  PROJECT_DUPLICATED: "Project duplicated successfully",
  WATER_LIB_REPLACED: "Water library replaced successfully",
  OPERATION_COSTS_UPDATED: "Operation costs updated successfully",
  //=================== FAILED MESSAGES ==================================
  PROJECT_SAVED_FAILED: "Unable to save changes. Please try again",
  CHEMICAL_SAVE_FAILED: "Unable to save chemical. Please try again",
  PROJECT_EXPORT_FAILED: "Unable to export project. Please try again",
  WATER_LIB_GET_FAILED: "Unable to get library data. Please try again",
  FOLDER_DELETE_FAILED: "Unable to delete the folder. Please try again",
  CHEMICAL_DELETE_FAILED: "Unable to delete chemical. Please try again",
  CHEMICAL_UPDATE_FAILED: "Unable to update chemical. Please try again",
  WATER_LIB_SAVE_FAILED: "Unable to save library data. Please try again",
  PROJECT_DELETE_FAILED: "Unable to delete the project. Please try again",
  PROJECT_DUPLICATE_FAILED: "Unable to duplicate project. Please try again",
  REPORT_DOWNLOAD_FAILED: "Unable to download PDF report. Please try again",
  WATER_LIB_DELETE_FAILED: "Unable to delete library data. Please try again",
  COPY_TO_FEED_WATER_FAILED: "Unable to copy to feed water. Please try again",
  WATER_LIB_REPLACE_FAILED: "Unable to replace library data. Please try again",
  CASE_UPDATE_FAILED: "The case could not be updated due to an error. Please try again",
  CASE_DELETE_FAILED: "The case could not be deleted due to an error. Please try again",
  OPERATION_COSTS_UPDATE_FAILED: "Unable to update project operation costs. Please try again",
  CASE_MOVE_FAILED: "Reordering failed. Please try again",
  CASE_ADD_FAILED: "Case addition failed. Please try again",
};
