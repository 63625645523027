import { useMemo } from "react";

import useProjectDetails from "@hooks/useProjectDetails";

import WPButton from "@components/WPButton";

import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";

const ChemicalLibraryChemicalUsageActions = ({ data, context }) => {
    const { projectDetails } = useProjectDetails();

    const handleBtnClick = ({ target: { name } }) => {
        if (context.onActionBtnClick) {
            context.onActionBtnClick({ type: name, data });
        }
    };

    const isCurrentProject = useMemo(
        () => projectDetails?.projectID?.toString() === data.projectId?.toString(),
        [projectDetails],
    );


    const isDeletedProject = useMemo(() => data.isDeleted, []);

    if (isCurrentProject) return;

    return (
        <div>
            {!isDeletedProject && (
                <WPButton
                    id={`open_project_${data.id}_btn`}
                    name='open-project-btn'
                    size='small'
                    variant='text'
                    aria-label='open'
                    onClick={handleBtnClick}
                >
                    {CHEMICALS_LIBRARY_STRINGS.open}
                </WPButton>
            )}
            <WPButton
                id={`delete_project_${data.id}_btn`}
                name='delete-project-btn'
                size='small'
                variant='text'
                type='danger'
                disabled={data.isSystem}
                aria-label='delete'
                onClick={handleBtnClick}
            >
                {CHEMICALS_LIBRARY_STRINGS.delete}
            </WPButton>
        </div>
    );
};

export default ChemicalLibraryChemicalUsageActions;
