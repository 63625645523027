import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { SELECTED_UNIT, UNITS } from "@constants/units.constant";

import useUnitConversion from "@hooks/useUnitConversion";

import { convertUptoDigits } from "@utils/appUtils";

import { colors, fontStyles } from "@common/styles/Theme";

import InputFieldWrapper from "./InputFieldWrapper";

const TemperatureSectionStyled = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 16px;

  h4 {
    color: ${colors.Grey96};
    ${fontStyles.notoSans14};
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
`;

const TemperatureSection = ({ waterLibData }) => {
  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig);
  const { convertUnit } = useUnitConversion();

  const temperatureFields = [
    {
      label: "Minimum",
      type: "number",
      id: "tempMin",
      name: "tempMin",
      value: convertUptoDigits(convertUnit(waterLibData.tempMin, SELECTED_UNIT.TEMPERATURE, UNITS.celsius), 1),
      inputText: unitConfig.selectedUnits[SELECTED_UNIT.TEMPERATURE],
      disabled: true,
    },
    {
      label: "Design",
      type: "number",
      id: "tempDesign",
      name: "tempDesign",
      value: convertUptoDigits(convertUnit(waterLibData.tempDesign, SELECTED_UNIT.TEMPERATURE, UNITS.celsius), 1),
      inputText: unitConfig.selectedUnits[SELECTED_UNIT.TEMPERATURE],
      disabled: true,
    },
    {
      label: "Maximum",
      type: "number",
      id: "tempMax",
      name: "tempMax",
      value: convertUptoDigits(convertUnit(waterLibData.tempMax, SELECTED_UNIT.TEMPERATURE, UNITS.celsius), 1),
      inputText: unitConfig.selectedUnits[SELECTED_UNIT.TEMPERATURE],
      disabled: true,
    },
  ];
  return (
    <TemperatureSectionStyled>
      <h4>Temperature</h4>
      {temperatureFields.map((field, index) => (
        <InputFieldWrapper key={index} {...field} />
      ))}
    </TemperatureSectionStyled>
  );
};

export default TemperatureSection;
