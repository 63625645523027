import React from "react";
import styled from "styled-components";

import MandatoryFieldIcon from "../../../icons/MandatoryFieldIcon";

import "../../diodrumFont.css";

const StyledHeading = styled.label`
  font-family: "DiodrumRegular", sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${({ disabled }) => (disabled ? "#969696" : "#000")};
  margin-bottom: 4px;
  display: flex;
`;
const CustomLabel = ({ label, mandatoryIcon, children, ...props }) => (
  <>
    <StyledHeading {...props}>
      {mandatoryIcon ? <MandatoryFieldIcon /> : ""}
      {children}
      {label}
    </StyledHeading>
  </>
);

export default CustomLabel;
