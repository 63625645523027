import { useEffect, useMemo, useState } from "react";

import DupontLogger from "@utils/DupontLogger";

import CustomModal from "@components/CustomModal";
import WPButton from "@components/WPButton";
import { useToastNotification } from "@components/WPToastNotification";

import { PROJECT_COST_FOOTER_BUTTONS } from "../chemicalLibrary.constants";
import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";
import useChemicalLibrary from "../ChemicalLibraryHooks/useChemicalLibrary";
import ProjectCostTable from "../ProjectCostTable";

import ProjectOperationCost from "./ProjectOperationCost";

import "./ProjectCostDialog.scss";

const ProjectCostDialog = ({ onClose }) => {
    const Logger = DupontLogger("ProjectCostDialog");
    const { showSuccessNotification, showErrorNotification } = useToastNotification();
    const { loaderText, projectChemicalCosts, fetchProjectCosts, updateProjectCosts } = useChemicalLibrary();
    const [updatedChemicals, setUpdatedChemicals] = useState([]);
    const [updatedCost, setUpdatedCost] = useState(null);

    const { operationCost, projectChemicals } = projectChemicalCosts;

    useEffect(() => {
        fetchProjectCosts();
    }, []);

    const updateOperationCosts = async () => {
        const paylaod = {
            operationCost: updatedCost || operationCost, projectChemicals: updatedChemicals
        };
        const { isSuccess, message } = await updateProjectCosts(paylaod);
        if (isSuccess) {
            setUpdatedChemicals([]);
            setUpdatedCost(null);
            showSuccessNotification(message);
        } else {
            showErrorNotification(message);
        }
    };

    const handleBtnClick = ({ target }) => {
        switch (target.id) {
            case "makeDefaultPriceBtn":
                Logger.log("Making current price the default");
                break;
            case "addEditChemicalBtn":
                Logger.log("Adding or editing chemicals");
                onClose({ openChemicalLib: true });
                break;
            case "saveUpdatedChemicals":
                updateOperationCosts();
                break;
            default:
                Logger.error("Unknown button clicked", target.id);
        }
    };

    const modalFooter = useMemo(() => PROJECT_COST_FOOTER_BUTTONS.map(btnConfig => {
        const isDisabled = btnConfig.id === "saveUpdatedChemicals" && (!updatedCost && !updatedChemicals.length);
        return (
            <WPButton
                key={btnConfig.id}
                variant={btnConfig.variant}
                id={btnConfig.id}
                onClick={handleBtnClick}
                disabled={isDisabled}
            >
                {btnConfig.label}
            </WPButton>
        );
    }), [handleBtnClick, updatedChemicals, updatedCost]);

    const onChemicalUpdate = updatedChemical => {
        setUpdatedChemicals(prevChemicals => {
            const index = prevChemicals.findIndex(chem => +chem.id === +updatedChemical.id);
            if (index !== -1) {
                const newChemicals = [...prevChemicals];
                newChemicals[index] = updatedChemical;
                return newChemicals;
            }
            return [...prevChemicals, updatedChemical];
        });
    };

    const handleCostChange = updatedOperationCost => setUpdatedCost(updatedOperationCost);

    return (
        <CustomModal
            header={CHEMICALS_LIBRARY_STRINGS.projectCostDlgHeading}
            headerHelper={CHEMICALS_LIBRARY_STRINGS.projectCostDlgSubHeading}
            onModalClose={onClose}
            width="930px"
            customclass="chemical-dialog--container"
            isLoading={Boolean(loaderText)}
            loadingText={loaderText}
            modalFooter={modalFooter}
        >
            {operationCost && <ProjectOperationCost operationCost={operationCost} onCostChange={handleCostChange} />}
            <ProjectCostTable chemicals={projectChemicals} onChemicalUpdate={onChemicalUpdate} />
        </CustomModal>
    );
};

export default ProjectCostDialog;