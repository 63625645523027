import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { WPUfFlowDetailsTableColumns as columns } from "./WPUfFlowDetailsTable.constants";

export const WPUfFlowDetailsTable = () => {
  const { flow_details: rows } = useSelector(state => state.report.ufReportTableData);

  return (
    <WPTable
      sectionHeader={reportStrings.ufFlowDetails}
      columns={columns}
      rows={rows}
      footNotesList={[reportStrings.maxPossibleFlowRate]}
    />
  );
};
