import React, { useEffect, useState } from "react";

import InputWithText from "@common/styles/components/inputs/InputWithText";

const FormInputBox = ({ value, onChange, isCalculating, onCancelClick, onKeyPress, disabled, inputRef, ...props }) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    value = Number.parseFloat(value).toFixed(3);
    setInputValue(value);
  }, [value]);

  const handleChange = event => {
    setInputValue(event.target.value);
    if (onChange) onChange(event);
  };

  const handleOnWheel = e => e.target.blur();
  const handleKeyDown = evt => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault();

  return (
    <InputWithText
      type='number'
      onWheel={handleOnWheel}
      onKeyDown={handleKeyDown}
      value={inputValue}
      onChange={handleChange}
      isLoading={isCalculating}
      ref={inputRef}
      disabled={disabled}
      showCancelIcon={!isCalculating}
      onCancelIconClick={onCancelClick}
      onKeyPress={onKeyPress}
      readOnly={isCalculating}
      {...props}
    />
  );
};

export default FormInputBox;
