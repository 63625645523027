/* eslint-disable max-len */
import React from "react";

const ArrowRightIcon = () => (
  <>
    <svg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        id='Vector'
        d='M12.0781 5.62163L6.175 0.498193C6.12969 0.459131 6.07188 0.437256 6.01094 0.437256H4.62813C4.5125 0.437256 4.45938 0.581006 4.54688 0.656006L10.0188 5.40601H0.875C0.80625 5.40601 0.75 5.46226 0.75 5.53101V6.46851C0.75 6.53726 0.80625 6.59351 0.875 6.59351H10.0172L4.54531 11.3435C4.45781 11.4201 4.51094 11.5623 4.62656 11.5623H6.05625C6.08594 11.5623 6.11563 11.5513 6.1375 11.531L12.0781 6.37788C12.1322 6.33084 12.1756 6.27275 12.2053 6.20752C12.235 6.14229 12.2504 6.07144 12.2504 5.99976C12.2504 5.92807 12.235 5.85723 12.2053 5.79199C12.1756 5.72676 12.1322 5.66867 12.0781 5.62163Z'
        fill='black'
        fillOpacity='0.85'
      />
    </svg>
  </>
);

export default ArrowRightIcon;
