/* eslint-disable max-len */
import React from "react";

const FavoriteIcon = () => (
  <>
    <svg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.1887 4.51702L9.22154 3.94046L7.4481 0.345147C7.39966 0.24671 7.31998 0.167022 7.22154 0.118585C6.97466 -0.00329011 6.67466 0.0982724 6.55123 0.345147L4.77779 3.94046L0.810601 4.51702C0.701226 4.53265 0.601226 4.58421 0.524663 4.66233C0.432103 4.75747 0.381099 4.88546 0.382856 5.01818C0.384614 5.1509 0.438991 5.2775 0.534038 5.37015L3.40435 8.16858L2.72623 12.1201C2.71032 12.2121 2.7205 12.3066 2.75559 12.393C2.79068 12.4795 2.84929 12.5544 2.92477 12.6092C3.00025 12.664 3.08958 12.6966 3.18263 12.7032C3.27568 12.7098 3.36873 12.6903 3.45123 12.6467L6.99966 10.7811L10.5481 12.6467C10.645 12.6983 10.7575 12.7155 10.8653 12.6967C11.1372 12.6498 11.32 12.392 11.2731 12.1201L10.595 8.16858L13.4653 5.37015C13.5434 5.29358 13.595 5.19358 13.6106 5.08421C13.6528 4.81077 13.4622 4.55765 13.1887 4.51702Z'
        fill='#E2F7F5'
      />
    </svg>
  </>
);

export default FavoriteIcon;
