import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { WPUfElectricityTableColumns as columns } from "./WPUfElectricityTable.constants";

export const WPUfElectricityTable = () => {
  const { Electricity: rows } = useSelector(state => state.report.ufReportTableData);

  return <WPTable showHeader={false} sectionHeader={reportStrings.electricity} columns={columns} rows={rows} />;
};
