import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CustomModalButtons } from "@constants/global.constants";
import { UNITS } from "@constants/units.constant";

import useUFChemicalsHandler from "@hooks/useUFChemicalsHandler";

import { convertUptoDigits } from "@utils/appUtils";

import ConfirmationPopup, { DIALOG_TYPES } from "@components/ConfirmationPopup";
import InputRanges from "@components/InputRanges";
import WPDropdown from "@components/WPDropdown";

import CustomHeading from "@common/styles/components/headings/CustomHeading";
import InputWithText from "@common/styles/components/inputs/InputWithText";
import ToggleSwitch from "@common/styles/components/switches/CustomSwitch";

import ProjectErrorPopup from "@features/modals/ProjectErrorPopup";

import { CHEMICAL_TYPES, UF_CHEMICAL_FIELDS } from "../constants/UFConstants";
import { updateUFStoreData } from "../UFSlice";

import "./index.scss";

const CHEMICALS_TYPES_PH = [CHEMICAL_TYPES.mineral, CHEMICAL_TYPES.alkali];
const UFChemicalFields = ({ type, disabled, chemicalRanges, screen, tabIndex }) => {
  const dispatch = useDispatch();

  const {
    isValueInPh,
    chemicalListByCategory,
    getChemicalKeys,
    getUFChemicalData,
    getChemicalListByCategory,
    updateChemicalWithSameConentration,
  } = useUFChemicalsHandler();

  const { data: UFData } = useSelector(state => state.UFStore);

  const [errorMsg, setErrorMsg] = useState();
  const [isFocused, setIsFocused] = useState(null);
  const [confirmDlgData, setConfirmDlgData] = useState(null);
  const [selectedChemical, setSelectedChemical] = useState(null);

  const { enabledKey, chemIdKey, valueKey } = useMemo(() => getChemicalKeys(type, screen), [type, screen]);
  const isEnabled = useMemo(() => (disabled ? false : UFData[enabledKey]), [UFData, enabledKey, disabled]);
  const chemicalList = useMemo(() => getChemicalListByCategory(type) || [], [type, chemicalListByCategory]);
  const isChemicalDropdownDisabled = useMemo(() => !chemicalList.length, [chemicalList]);
  const chemicalUnit = useMemo(
    () => (isValueInPh && CHEMICALS_TYPES_PH.includes(type) ? UNITS.pH : UNITS.MiligramPerLiter),
    [isValueInPh, type],
  );

  const handleFocus = id => setIsFocused(id);
  const handleCloseErrorPopup = () => setErrorMsg();
  const updateStoreData = newData => dispatch(updateUFStoreData(newData));
  const getValue = field => (isFocused ? UFData[field] : convertUptoDigits(UFData[field]));

  const checkError = id => {
    const range = chemicalRanges[id];
    return !(range?.minValue <= UFData[id] && range?.maxValue >= UFData[id]);
  };

  const handleDropdownChange = data => {
    const value = data.id;
    setSelectedChemical(value);
    const chemicalValue = getUFChemicalData(type, value, screen);
    const eventData = { target: { id: type, value: chemicalValue, name: `${type}Value_${screen}` } };
    handleInputChange(eventData);
    const updateData = {
      data: { [chemIdKey]: value.toString() },
      calcEngineDataRefreshCount: 1,
      isUfDataUpdated: true,
    };
    dispatch(updateUFStoreData(updateData));
  };

  const handleConfirmDlgClose = actionType => {
    if (actionType === CustomModalButtons.CONFIRM) {
      updateChemicalWithSameConentration(type, selectedChemical);
    }
    setConfirmDlgData(null);
  };

  const handleToggleChange = event => {
    const { name, checked } = event.target;
    const id = checked ? chemicalList[0].id : 0;
    handleDropdownChange({ id });
    updateStoreData({ data: { [name]: checked }, calcEngineDataRefreshCount: 1 });
  };

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    if (!isNaN(value)) {
      updateStoreData({ data: { [name]: value }, isUfDataUpdated: true });
    }
  };

  const handleBlur = event => {
    const { name, value } = event.target;
    const { minValue, maxValue } = chemicalRanges[name] || {};
    if (value < minValue || value > maxValue) {
      const error = `The ${UF_CHEMICAL_FIELDS[type]} value entered is outside the allowed range 
                        (${convertUptoDigits(minValue)} to ${convertUptoDigits(maxValue)}). Please revise your input.`;
      setErrorMsg(error);
      setTimeout(() => event.target.focus(), 0);
    } else {
      updateStoreData({ data: { [name]: convertUptoDigits(value) }, calcEngineDataRefreshCount: 1 });
      setIsFocused(null);
      setErrorMsg();
    }
  };

  const getOptionLabel = option => option.displayName;

  const value = useMemo(() => UFData[chemIdKey]
    ? chemicalList.find(item => item.id.toString() === UFData[chemIdKey]?.toString())
    : "", [UFData[chemIdKey], chemicalList]);

  return (
    <>
      <div className='uf-chemical-fields-container'>
        <div className='label-switch'>
          <CustomHeading label={UF_CHEMICAL_FIELDS[type]} />
          <ToggleSwitch
            small
            id={`${type}_toggle`}
            name={enabledKey}
            disabled={disabled || isChemicalDropdownDisabled}
            checked={isEnabled}
            onChange={handleToggleChange}
          />
        </div>
        <div className='input-select'>
          <div className='select'>
            <WPDropdown
              id={`${type}_dropdown`}
              name={valueKey}
              value={value}
              disabled={!isEnabled}
              options={chemicalList}
              onChange={handleDropdownChange}
              placeholder="Select chemical"
              getOptionLabel={getOptionLabel}
            />
          </div>
          <div className='input'>
            <InputWithText
              type='number'
              id={`${type}_input`}
              name={valueKey}
              disabled={!isEnabled}
              value={getValue(valueKey)}
              onChange={handleInputChange}
              isError={checkError(valueKey)}
              inputText={chemicalUnit}
              isFocused={isFocused}
              onBlur={handleBlur}
              onFocus={handleFocus}
              tabIndex={tabIndex}
            />
            <div className={isEnabled ? "visible" : "invisible"}>
              <InputRanges id={`${type}_input_ranges`} ranges={chemicalRanges[valueKey]} />
            </div>
          </div>
        </div>
      </div>
      {confirmDlgData && (
        <ConfirmationPopup
          type={DIALOG_TYPES.ERROR}
          header={confirmDlgData.header}
          description={confirmDlgData.description}
          onClose={handleConfirmDlgClose}
          confirmBtn='Confirm'
          cancelBtn='No'
        />
      )}
      {errorMsg && <ProjectErrorPopup show close={handleCloseErrorPopup} message={errorMsg} />}
    </>
  );
};

export default UFChemicalFields;
