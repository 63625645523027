export const handleTabAndEnter = event => {
  const currentElement = document.activeElement;
  const tabIndexAttr = currentElement.getAttribute("tabIndex");

  if (tabIndexAttr !== null) {
    const tabIndex = parseInt(tabIndexAttr);
    if (!isNaN(tabIndex)) {
      let nextElement = document.querySelector(`[tabIndex="${tabIndex + 1}"]`);
      let nextTabIndex = tabIndex + 1;

      while (nextElement && nextElement.disabled) {
        nextTabIndex++;
        nextElement = document.querySelector(`[tabIndex="${nextTabIndex}"]`);
      }

      if (nextElement) {
        event.preventDefault(); // Prevent default Tab behavior
        nextElement.focus();
      }
    }
  }
};
