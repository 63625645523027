import { reportStrings } from "@constants/report.strings";

// The order of the keys in the object will be the order of the sections in the report
export const ufSectionsLabels = {
  sytem_diagram: reportStrings.processFlowDiagram,
  uf_system_overview: reportStrings.ufSystemOverview,
  uf_operating_conditions: reportStrings.ufOperatingConditions,
  uf_water_quality: reportStrings.UfWaterQuality,
  uf_design_quality: reportStrings.ufDesignWarnings,
  uf_configuration_options: reportStrings.ufConfigurationOptions,
  uf_system_module: reportStrings.ufSystemSizeModuleDetails,
  flow_details: reportStrings.ufFlowDetails,
  pump_electrical: reportStrings.ufPumpHydraulicElectricalCost,
  uf_Power_ratings: reportStrings.ufPressureRatings,
  uf_Storage_tanks: reportStrings.ufStorageTanks,
  uf_utility_chemicals_costs: reportStrings.ufUtilityChemicalCosts,
  uf_backwash_parameters: reportStrings.backwashParameters,
  uf_ceb_parameters: reportStrings.cebParameters,
  uf_mcip_parameters: reportStrings.mCipParameters,
  uf_cip_parameters: reportStrings.cipParameters,
};
