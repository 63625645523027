import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DupontString, IngeString } from "@utils/StringConstants";

import CustomCard from "@components/CustomCard";

import CustomHeading from "@common/styles/components/headings/CustomHeading";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import CalcEngineInputBox from "@common/styles/components/inputs/CalcEngineInputBox";
import CustomInput from "@common/styles/components/inputs/CustomInput";
import { handleTabAndEnter } from "@common/styles/components/inputs/InputFocusUtililty";
import CustomRadio from "@common/styles/components/radios/CustomRadio";

import ProjectErrorPopup from "@features/modals/ProjectErrorPopup";

import { INGE_MODULE_TR_RANGES } from "../constants/UFConstants";
import { calculateUFConfigFields, setCustomAvail, setUfDataUpdate, updateUFStore, updateUFStoreData } from "../UFSlice";

const MesageMap = {
  modulesPerSkid: "Modules/Rack",
  onlineTrains: "Online Units",
  redundantStandbyTrains: "Standby Units",
  redundantTrains: "Redundant Units",
  modulesPerTrain: "Modules/Unit",
  skidsPerTrain: "Racks/Unit",
};
const INGE_TR_OPTION_WITH_ODD_VALUE = "TR4";
const SelectedConfiguration = () => {
  const dispatch = useDispatch();
  const updateStoreData = newData => dispatch(updateUFStoreData(newData));

  const { activeUFModule, defaultInputRangeConfig, calcEngineData, data, ufCompany } = useSelector(
    state => state.UFStore,
  );
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const { skids, skidsPerTrain, modulesPerSkid } = data;
  const [isFieldValid, setIsFieldValid] = useState(false);
  const [flux_Filter_actual, setFlux_Filter_actual] = useState(0);
  const [message, setMessage] = useState("");
  const [ingeModuleRackOptions, setIngeModuleRackOptions] = useState();
  const [radionBtnCount, setRadionBtnCount] = useState(-1);
  const [selectedTROption, setSelectedTROption] = useState(null);
  const labelStrings = ufCompany === "INGE" ? IngeString : DupontString;

  const fieldOnlineTrains = defaultInputRangeConfig["onlineTrains"];
  const redundantStandbyTrains1 = defaultInputRangeConfig["redundantStandbyTrains"];

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      //  setIsFieldValid(false);
      handleTabAndEnter(event);
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => document.body.removeEventListener("keydown", handleKeyDown);
  }, []);

  useEffect(() => {
    const { ufmoduleId, moduleType } = activeUFModule;
    setSelectedTROption(null);
    setRadionBtnCount(-1);
    //Timout is added to make sure, selected TR option is reset to null, before setting new
    setTimeout(() => {
      if (moduleType === "Inge") {
        const TROptions = INGE_MODULE_TR_RANGES[ufmoduleId];
        const trOption = activeUFModule.tRack ? `TR${skidsPerTrain}` : null;
        setIngeModuleRackOptions(TROptions);
        setSelectedTROption(trOption);
      } else {
        setIngeModuleRackOptions(null);
      }
    }, 200);
  }, [activeUFModule]);

  useEffect(() => {
    if (selectedTROption && activeUFModule?.tRack) {
      const ufmoduleId = activeUFModule?.ufmoduleId;
      const skidsPerTrain = selectedTROption.replace("TR", "");
      let modulesPerTrain = modulesPerSkid;
      let sliderData = {};
      if (activeUFModule?.tRack) {
        const { min: sliderMin, max: sliderMax } = INGE_MODULE_TR_RANGES[ufmoduleId]?.[`TR${skidsPerTrain}`] || {};
        sliderData = { sliderMin, sliderMax };
        modulesPerTrain = modulesPerSkid * skidsPerTrain;
      }
      const updatedData = {
        data: { skidsPerTrain, modulesPerTrain },
        ...sliderData,
        calcEngineDataRefreshCount: 1,
        isRecommendationCalucalting: false,
      };

      if (radionBtnCount > -1) {
        updatedData.isCustomConfigAvail = true;
      }
      updateStoreData(updatedData);
    } else if (!activeUFModule?.tRack) {
      updateStoreData({ calcEngineDataRefreshCount: 1 });
    }
  }, [selectedTROption]);

  useEffect(() => {
    if (activeUFModule.integraPacInd || activeUFModule.tRack) {
      dispatch(calculateUFConfigFields());
    }
  }, [skidsPerTrain]);

  useEffect(() => {
    const value = Number(Math.round(calcEngineData?.flux_Filter_actual) ?? 0).toFixed(2);
    setFlux_Filter_actual(value);
  }, [calcEngineData?.flux_Filter_actual]);

  const validations = {
    modulesPerSkid: defaultInputRangeConfig["modulesPerSkid"],
    onlineTrains: defaultInputRangeConfig["onlineTrains"],
    redundantStandbyTrains: defaultInputRangeConfig["redundantStandbyTrains"],
    redundantTrains: { minValue: 0, maxValue: 200 },
    modulesPerTrain: defaultInputRangeConfig["modulesPerTrain"],
    skidsPerTrain: defaultInputRangeConfig["skidsPerTrain"],
  };

  const getFieldRange = id => {
    const { minValue, maxValue } = validations[id] || {};
    let range = { minValue, maxValue };
    if (id === "modulesPerSkid" && activeUFModule?.tRack) {
      const ufmoduleId = activeUFModule?.ufmoduleId;
      const ingeTROption = `TR${skidsPerTrain}`;
      const { min, max } = INGE_MODULE_TR_RANGES[ufmoduleId][ingeTROption];
      range = { minValue: min, maxValue: max };
    }
    return range;
  };

  const checkModulesPerSkidError = id => {
    const { minValue, maxValue } = getFieldRange(id);
    const isModulesPerSkidInvalid = id === "modulesPerSkid" && isModulePerRackInvalid();
    const isError = minValue > modulesPerSkid || maxValue < modulesPerSkid || isModulesPerSkidInvalid;
    return isError;
  };

  const checkError = id => {
    const { minValue, maxValue } = validations[id] || {};
    return !(minValue <= skidsPerTrain && maxValue >= skidsPerTrain);
  };

  const checkWarning = name => {
    if ("modulesPerSkid" === name && activeUFModule?.tRack) return false;
    const value = data[name];
    if (validations[name]) {
      const { minValue, softLowerLimit, softUpperLimit, maxValue } = validations[name];
      return value >= minValue && value < maxValue && (value < softLowerLimit || value > softUpperLimit);
    }
  };

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    const numericValue = value ? +value : "";

    if (!isNaN(numericValue)) {
      dispatch(updateUFStore({ ...data, [name]: numericValue }));
      dispatch(calculateUFConfigFields());
      dispatch(setUfDataUpdate(true));
    }
    dispatch(setCustomAvail(false));
  };

  const isNumberEven = number => number % 2 === 0;
  const isModulePerRackInvalid = () =>
    activeUFModule?.tRack && !isNumberEven(modulesPerSkid) && selectedTROption !== INGE_TR_OPTION_WITH_ODD_VALUE;

  const handleBlur = ({ target }) => {
    const { id, name, value } = target;
    const { modulesPerSkid, modulesPerTrain, skidsPerTrain } = defaultInputRangeConfig;
    const configMap = {
      modulesPerSkid,
      onlineTrains: fieldOnlineTrains,
      redundantStandbyTrains: redundantStandbyTrains1,
      redundantTrains: { minValue: 0, maxValue: 200 },
      modulesPerTrain,
      skidsPerTrain,
    };

    const config = configMap[name];
    const { minValue, maxValue } = id === "modulesPerSkid" ? getFieldRange(id) : config;
    const isValueInvalid = isNaN(value) || value < minValue || value > maxValue;
    const isModulesPerSkidInvalid = id === "modulesPerSkid" && isModulePerRackInvalid();

    if (isValueInvalid || isModulesPerSkidInvalid) {
      setIsFieldValid(true);
      const field = MesageMap[name];
      const revisitText = "Please revise your input.";
      const errorMessage = isValueInvalid
        ? `${field} value entered is outside the allowed range (${minValue} to ${maxValue}). ${revisitText}`
        : `${field} value entered is not an even number. ${revisitText}`;
      setMessage(errorMessage);
      setTimeout(() => target.focus(), 0);
    } else {
      setIsFieldValid(false);
      updateStoreData({ calcEngineDataRefreshCount: 1, isRecommendationCalucalting: true });
    }
  };

  const handleRadioChange = ({ target }) => {
    setRadionBtnCount(count => count + 1);
    setSelectedTROption(target.value);
  };

  return (
    <>
      <div className='selected-configuration'>
        <CustomCard
          header='Selected Configuration'
          className='selected-configuration-card'
          tooltipLabel='Provide the arrangement and number of units and modules. Redundant units ignored in calculation.'
        >
          {(activeUFModule?.integraPacInd || activeUFModule?.tRack) && (
            <div className='rack-wrapper'>
              <CustomHeading className='track-design' fontSize='10px' label={labelStrings.selectedConfRackLabel} />
              <div className='rack-module'>
                <div className='racks'>
                  <CustomLabel label='Racks/Units' />
                  <CustomInput
                    type='text'
                    isError={checkError("skidsPerTrain")}
                    name='skidsPerTrain'
                    id='skidsPerTrain'
                    value={skidsPerTrain}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    isWarning={checkWarning("skidsPerTrain")}
                    disabled={ingeModuleRackOptions}
                    tabIndex={1}
                  />
                </div>
                <div className='racks'>
                  <CustomLabel label='Modules/Rack' lineHeight='18px' />
                  <CustomInput
                    type='text'
                    isWarning={checkWarning("modulesPerSkid")}
                    isError={checkModulesPerSkidError("modulesPerSkid")}
                    name='modulesPerSkid'
                    id='modulesPerSkid'
                    value={modulesPerSkid}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    tabIndex={2}
                  />
                </div>
                <div className='racks'>
                  <CustomLabel label='Total No. of Racks' />
                  <CalcEngineInputBox isAutoPopulated name='skids' id='skids' value={skids} disabled />
                </div>
                {ingeModuleRackOptions && (
                  <div className='tr-option-container'>
                    <>
                      {Object.keys(ingeModuleRackOptions).map(trOption => (
                        <div key={`${trOption}`} className='radio'>
                          <CustomRadio
                            name='TrRadio'
                            id={trOption}
                            value={trOption}
                            checked={selectedTROption === trOption}
                            label={ingeModuleRackOptions[trOption].label}
                            disabled={!ingeModuleRackOptions[trOption].min}
                            onChange={handleRadioChange}
                          />
                        </div>
                      ))}
                    </>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className='unit-wrapper'>
            <CustomHeading className='unit-header' fontSize='10px' label='Number of Units' />
            <div className='unit-group'>
              <div>
                <CustomLabel label='Online Units' />
                <CustomInput
                  type='text'
                  name='onlineTrains'
                  id='onlineTrains'
                  placeholder='0'
                  value={data.onlineTrains}
                  isError={checkError("onlineTrains")}
                  isWarning={checkWarning("onlineTrains")}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  tabIndex={3}
                />
              </div>
              <div>
                <CustomLabel label='Standby Units' />
                <CustomInput
                  type='text'
                  name='redundantStandbyTrains'
                  disabled={data.uFBWCEBStandbyOptionID == 1}
                  id='redundantStandbyTrains'
                  value={Number(data.redundantStandbyTrains)}
                  isError={checkError("redundantStandbyTrains")}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  isWarning={checkWarning("redundantStandbyTrains")}
                  tabIndex={4}
                />
              </div>
              <div>
                <CustomLabel label='Redundant Unit' />
                <CustomInput
                  type='text'
                  name='redundantTrains'
                  id='redundantTrains'
                  value={data.redundantTrains}
                  isError={checkError("redundantTrains")}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  isWarning={checkWarning("redundantTrains")}
                  tabIndex={5}
                />
              </div>
              <div>
                <CustomLabel label='Total Units' />
                <CustomInput
                  type='text'
                  name='totalTrains'
                  disabled
                  id='totalTrains'
                  value={data.totalTrains}
                  isError={false}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  tabIndex={6}
                />
              </div>

              <div>
                <CustomLabel label='Modules/Unit' />
                <CustomInput
                  type='text'
                  name='modulesPerTrain'
                  disabled={activeUFModule?.integraPacInd || activeUFModule?.tRack}
                  id='modulesPerTrain'
                  value={data.modulesPerTrain}
                  isError={checkError("modulesPerTrain")}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  isWarning={checkWarning("modulesPerTrain")}
                  tabIndex={7}
                />
              </div>
              <div>
                <CustomLabel label='Total Modules' />
                <CustomInput
                  type='text'
                  name='totalModules'
                  disabled
                  id='totalModules'
                  value={isNaN(data.totalModules) ? 0 : data.totalModules}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  tabIndex={8}
                />
              </div>
              <div>
                <CustomLabel label='Operating Flux' />
                <CalcEngineInputBox
                  type='number'
                  name='flux_Filter_actual'
                  inputText={unit?.selectedUnits[4]}
                  disabled
                  id='flux_Filter_actual'
                  value={flux_Filter_actual}
                />
              </div>
            </div>
          </div>
        </CustomCard>
      </div>
      {isFieldValid && <ProjectErrorPopup show message={message} close={() => setIsFieldValid(false)} />}
    </>
  );
};

export default SelectedConfiguration;
