import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_PATH,
  prepareHeaders: (headers, { getState }) => {
    const idToken = getState().Auth?.idToken;
    if (idToken) {
      headers.set("Authorization", `Bearer ${idToken}`);
    }
    return headers;
  },
});

const headers = { "Content-type": "application/json; charset=UTF-8" };

// Utility function to simplify mutation configurations
const createMutation = method => data => {
  const { Method, ...payload } = data;
  return {
    method,
    url: `/${Method}`,
    headers,
    body: payload,
  };
};

export const postApi = createApi({
  reducerPath: "postApi",
  baseQuery,
  endpoints: builder => ({
    getAllData: builder.query({
      query: urlname => ({
        url: urlname,
        method: "GET",
      }),
    }),
    deleteData: builder.mutation({ query: createMutation("DELETE") }),
    deleteDataRecord: builder.mutation({ query: createMutation("PUT") }),
    createData: builder.mutation({ query: createMutation("POST") }),
    updateData: builder.mutation({ query: createMutation("PUT") }),
    calcEngineData: builder.mutation({ query: createMutation("POST") }),
    chemicalAdjustmentData: builder.mutation({ query: createMutation("POST") }),
  }),
});

export const {
  useLazyGetAllDataQuery,
  useCreateDataMutation,
  useCalcEngineDataMutation,
  useDeleteDataRecordMutation,
  useDeleteDataMutation,
  useUpdateDataMutation,
  useChemicalAdjustmentDataMutation,
} = postApi;
