import React from "react";

const SortUpandDownArrowIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='10' height='14' viewBox='0 0 10 14' fill='none'>
      <g clipPath='url(#clip0_184490_368424)'>
        <path d='M1.06752 5.5L5 0.781025L8.93248 5.5H1.06752Z' stroke='black' />
        <path d='M8.93248 8.5L5 13.219L1.06752 8.5L8.93248 8.5Z' stroke='black' />
      </g>
      <defs>
        <clipPath id='clip0_184490_368424'>
          <rect width='10' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default SortUpandDownArrowIcon;
