import React from "react";

const ProjectTickIcon = () => (
  <svg width='36' height='37' viewBox='0 0 36 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_88_34694)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.7997 5.26272L27.2145 0.69933C27.0866 0.572098 26.9141 0.5 26.733 0.5H17.6818C17.3047 0.5 17 0.803237 17 1.17857V8.5L18.5341 8.5L18.5341 2.02679H24.9687V6.60714C24.9687 6.84335 25.063 7.06988 25.2309 7.23691C25.3987 7.40393 25.6263 7.49777 25.8636 7.49777H30.4659V17.9732H27.5V19.5H31.3182C31.6953 19.5 32 19.1968 32 18.8214V5.74408C32 5.56384 31.9276 5.38996 31.7997 5.26272ZM26.5 2V5.99085H30.5099L28.505 3.99542L26.5 2Z'
        fill='black'
        fillOpacity='0.7'
      />
      <path
        d='M18 9.5C13.0299 9.5 9 13.5299 9 18.5C9 23.4701 13.0299 27.5 18 27.5C22.9701 27.5 27 23.4701 27 18.5C27 13.5299 22.9701 9.5 18 9.5ZM21.8873 15.5609L17.6565 21.427C17.5973 21.5095 17.5194 21.5768 17.4291 21.6232C17.3388 21.6696 17.2387 21.6938 17.1372 21.6938C17.0356 21.6938 16.9356 21.6696 16.8453 21.6232C16.7549 21.5768 16.677 21.5095 16.6179 21.427L14.1127 17.9556C14.0364 17.8491 14.1127 17.7004 14.2433 17.7004H15.1855C15.3904 17.7004 15.5853 17.7989 15.7058 17.9676L17.1362 19.9525L20.2942 15.573C20.4147 15.4062 20.6076 15.3058 20.8145 15.3058H21.7567C21.8873 15.3058 21.9636 15.4545 21.8873 15.5609Z'
        fill='#128370'
      />
    </g>
    <defs>
      <clipPath id='clip0_88_34694'>
        <rect width='36' height='36' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>
);

export default ProjectTickIcon;
