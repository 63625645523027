import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { WPUfServiceWaterTableColumns as columns } from "./WPUfServiceWaterTable.constants";

export const WPUfServiceWaterTable = () => {
  const { service_Water: rows } = useSelector(state => state.report.ufReportTableData);

  return <WPTable sectionHeader={reportStrings.serviceWater} columns={columns} rows={rows} hasUnitsRow />;
};
