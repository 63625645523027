import { useSelector } from "react-redux";

import { WPTable } from "@components/WPTable";

import { WPUfSystemOverviewTableColumns } from "./WPUfSystemOverViewTable.constants";

export const WPUfSystemOverViewTable = () => {
  const { uf_system_overview: rows } = useSelector(state => state.report.ufReportTableData);
  return <WPTable showHeader={false} columns={WPUfSystemOverviewTableColumns} rows={rows} />;
};
