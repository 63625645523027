import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTypogrpahy } from "@components/WPTypography";

import { UF_SPECIAL_FEATURE } from "@features/feedwater/uf/constants/UFConstants";
import { isIngeSelected } from "@features/feedwater/uf/ufUtils";

import { WPReportSideNavFilter } from "../WPReportSideNavFilter/WPReportSideNavFilter";
import { ufSectionsLabels } from "../WPReportSideNavFilter/WPReportSideNavFilter.constants";

export const WPReportSideNav = () => {
  const { data: ufData } = useSelector(state => state.UFStore);
  const { pUFTechnologyID, ufSpecialFeatureID } = ufData;

  const ufLabels = useMemo(() => {
    const ufLabelsClone = structuredClone(ufSectionsLabels);
    if (isIngeSelected(pUFTechnologyID) || +ufSpecialFeatureID === +UF_SPECIAL_FEATURE.isCebOnly) {
      delete ufLabelsClone.uf_mcip_parameters;
    } else if (+ufSpecialFeatureID === +UF_SPECIAL_FEATURE.isMiniCipOnly) {
      delete ufLabelsClone.uf_ceb_parameters;
    }
    return ufLabelsClone;
  }, [pUFTechnologyID, ufSpecialFeatureID]);

  return (
    <>
      <WPTypogrpahy className='fs-12' fontFamily='Diodrum' text={reportStrings.sideNavSelectMsg} />
      <WPReportSideNavFilter id='ufSectionData' sectionLabels={ufLabels} sectionName={reportStrings.ultraFiltration} />
    </>
  );
};
