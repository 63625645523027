import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteRequest, getRequest } from "src/api/baseApi";

import API_URLS from "@constants/api.urls";
import { CustomModalButtons, STATUS_CODE, TECHNOLOGIES } from "@constants/global.constants";
import { APP_TOAST_NOTIFICATION } from "@constants/message.constants";
import { UNITS } from "@constants/units.constant";

import useCalculateFeedWaterData from "@hooks/useCalculateFeedWaterData";
import { useCheckProjectExistence } from "@hooks/useCheckProjectExistence";
import useUFDataHandler from "@hooks/useUFDataHandler";

import DupontLogger from "@utils/DupontLogger";

import ConfirmationPopup, { DIALOG_TYPES } from "@components/ConfirmationPopup";
import CustomModal from "@components/CustomModal";
import { useToastNotification } from "@components/WPToastNotification";

import { updateGlobalData } from "@common/GlobalDataSlice";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import CustomTextArea from "@common/styles/components/inputs/CustomTextArea";
import InputTextWithNameValidation from "@common/styles/components/inputs/inputTextWithNameValidation";
import CustomSelect from "@common/styles/components/selects/CustomSelect";

import {
  ANIONS_IONS_ORDER,
  CATIONS_IONS_ORDER,
  DEFAULT_IONS_PROPS,
  DEFAULT_WATER_LIBRARY_DATA,
  FEED_STRINGS,
  FEED_WATER_IONS_LABEL,
  getWaterTypeInputFields,
  NEUTRALS_IONS_ORDER,
} from "../constants/FeedWaterConstants";
import { parseAndFormatFloat, sortByOrder } from "../FeedSetupConfiguration/FeedSetupHelper";

import DissolvedSolutesSection from "./DissolvedSolutesSection";
import InputFieldWrapper from "./InputFieldWrapper";
import StyledWaterLibrary from "./styled";
import TemperatureSection from "./TemperatureSection";
import WaterLibraryIonsTable from "./WaterLibraryIonsTable";

const WaterLibraryModal = ({ close, actionType }) => {
  const Logger = DupontLogger("WaterLibraryModal");
  const dispatch = useDispatch();
  const { showSuccessNotification, showErrorNotification } = useToastNotification();

  const { streamData, data } = useSelector(state => state.Feedsetupdetailsdatapanel);
  const FeedData = streamData?.lstrequestsavefeedwater?.[0].streams?.[0] || {};
  const { waterLibraryList } = useSelector(state => state.globalAppData || {});
  const { predefinedLibrary, userLibrary } = waterLibraryList;
  const waterLibraryListSorted = [...userLibrary, DEFAULT_WATER_LIBRARY_DATA, ...predefinedLibrary];
  const [waterLibraryData, setWaterLibraryData] = useState(FeedData);
  const [editedName, setEditedName] = useState(waterLibraryData.streamName);
  const [selectedLibrary, setSelectedLibrary] = useState(1);
  const [doesProjectExist, setDoesProjectExist] = useState(false);
  const [hasDataBeenFetched, setHasDataBeenFetched] = useState(false);
  const [confirmDlgData, setConfirmDlgData] = useState(null);
  const [checkProjectExistence, isCheckingProjectName, entityData] = useCheckProjectExistence();
  const { calculateFeedWaterData, isCalculating, saveWaterLibraryData } = useCalculateFeedWaterData();
  const { projectTechnology } = useUFDataHandler()?.projectDetails || {};
  const isUF = projectTechnology === TECHNOLOGIES.UF;
  const [sortedData, setSortedData] = useState({
    cations: [],
    anions: [],
    neutrals: [],
  });
  const [loadingData, setLoadingData] = useState({});

  useEffect(() => {
    if (actionType === FEED_STRINGS.SAVE) {
      setWaterLibraryData(FeedData);
      performCalculationsAndSorting(FeedData);
    }
  }, [actionType, FeedData]);

  useEffect(() => {
    if (actionType === FEED_STRINGS.OPEN) {
      const validWaterLibraryList = waterLibraryListSorted.filter(item => item.waterLibraryId !== "separator");
      const firstValidWaterLibraryId = validWaterLibraryList[0].waterLibraryId;
      handleLibraryList({ target: { value: firstValidWaterLibraryId } });
    }
  }, [actionType, userLibrary]);

  useEffect(() => {
    if (hasDataBeenFetched && !isCalculating) {
      setHasDataBeenFetched(false);
      close();
    }
    if (isCalculating) {
      setLoadingData({
        isLoading: true,
        loadingText: FEED_STRINGS.copyDataToFeedSetup,
      });
    } else {
      setLoadingData(null);
    }
  }, [isCalculating]);

  const applyDefaultsAndSort = (items, order, defaults) =>
    sortByOrder(
      items.map(item => ({ ...item, ...defaults[item.soluteID] })),
      order,
    );

  const performCalculationsAndSorting = data => {
    const cations = applyDefaultsAndSort(data.cations, CATIONS_IONS_ORDER, DEFAULT_IONS_PROPS);
    const anions = applyDefaultsAndSort(data.anions, ANIONS_IONS_ORDER, DEFAULT_IONS_PROPS);
    const neutrals = applyDefaultsAndSort(data.neutrals, NEUTRALS_IONS_ORDER, DEFAULT_IONS_PROPS);
    setSortedData({ cations, anions, neutrals });
  };

  // Array defining the input fields for water type information
  const waterTypeInputFields = getWaterTypeInputFields(waterLibraryData, data, isUF);

  const showErrorMsag = entityData => {
    const { WATER_LIB_REPLACE_FAILED, WATER_LIB_SAVE_FAILED } = APP_TOAST_NOTIFICATION;
    const errorMsg = entityData ? WATER_LIB_REPLACE_FAILED : WATER_LIB_SAVE_FAILED;
    showErrorNotification(errorMsg);
  };

  const saveData = async entityData => {
    try {
      const updatingTxt = entityData ? FEED_STRINGS.replaceWaterLibrary : FEED_STRINGS.savingWaterLibrary;
      setLoadingData({
        isLoading: true,
        loadingText: updatingTxt,
      });
      const result = await saveWaterLibraryData(waterLibraryData, editedName, entityData);
      if (result === FEED_STRINGS.success) {
        close();
        const message = entityData ? APP_TOAST_NOTIFICATION.WATER_LIB_REPLACED : APP_TOAST_NOTIFICATION.WATER_LIB_SAVED;
        showSuccessNotification(message);
      } else {
        showErrorMsag(entityData);
      }
    } catch (error) {
      Logger.error("Error saving library data:", error);
      showErrorMsag(entityData);
    } finally {
      setLoadingData(null);
    }
  };

  const handleModalClose = async event => {
    const { id } = event.target;
    if (id === CustomModalButtons.CONFIRM && actionType === FEED_STRINGS.OPEN) {
      calculateFeedWaterData(waterLibraryData, setHasDataBeenFetched);
      setHasDataBeenFetched(true);
    } else if (id === CustomModalButtons.CANCEL && actionType === FEED_STRINGS.OPEN) {
      setConfirmDlgData({
        type: DIALOG_TYPES.ERROR,
        header: FEED_STRINGS.deleteLibraryHeader,
        description: `Are you sure you want to delete ${waterLibraryData.waterLibraryName} library?`,
      });
    } else if (id === CustomModalButtons.CONFIRM && actionType === FEED_STRINGS.SAVE) {
      saveData();
    } else if (id === CustomModalButtons.CLOSE) {
      close();
    }
  };

  const handleReplace = () => {
    setConfirmDlgData({
      type: DIALOG_TYPES.WARNING,
      header: FEED_STRINGS.replaceLibraryHeader,
      description: FEED_STRINGS.waterLibraryConfirmation,
    });
  };

  const handleDeleteWaterLib = async () => {
    try {
      setConfirmDlgData(null);
      setLoadingData({
        isLoading: true,
        loadingText: FEED_STRINGS.deletingWaterLibMsg,
      });
      const response = await deleteRequest(`${API_URLS.waterLibrary}/${selectedLibrary}`);
      if (response.status === STATUS_CODE.NO_CONTENT) {
        const updatedUserLibrary = userLibrary.filter(lib => lib.waterLibraryId !== selectedLibrary);
        const updatedWaterLibraryList = {
          ...waterLibraryList,
          userLibrary: updatedUserLibrary,
        };
        dispatch(updateGlobalData({ type: "waterLibraryList", value: updatedWaterLibraryList }));
      }
    } catch (error) {
      Logger.error("Error deleting waterLibraryData: ", error);
      showErrorMsag(APP_TOAST_NOTIFICATION.WATER_LIB_DELETE_FAILED);
    } finally {
      setLoadingData(null);
    }
  };

  const handleConfirmDlgClose = id => {
    if (id === CustomModalButtons.CONFIRM && actionType === FEED_STRINGS.OPEN) {
      handleDeleteWaterLib();
    } else if (id === CustomModalButtons.CONFIRM && actionType === FEED_STRINGS.SAVE) {
      setConfirmDlgData(null);
      saveData(entityData);
    } else if (id === CustomModalButtons.CANCEL) {
      setConfirmDlgData(null);
    }
  };

  const handleLibraryList = async event => {
    setLoadingData({
      isLoading: true,
      loadingText: FEED_STRINGS.fethingWaterLibraryData,
    });
    const selectedId = Number(event.target.value);
    setSelectedLibrary(selectedId);
    try {
      const { data } = await getRequest(`${API_URLS.waterLibrary}/${selectedId}`);
      setWaterLibraryData(data);
      performCalculationsAndSorting(data);
    } catch (error) {
      Logger.error("Error fetching waterLibraryData: ", error);
    } finally {
      setLoadingData(null);
    }
  };
  const isDeleteButtonDisabled = waterLibraryListSorted?.some(
    library => library.waterLibraryId === selectedLibrary && library.isSystem,
  );
  return (
    <CustomModal
      header={actionType === FEED_STRINGS.OPEN ? FEED_STRINGS.OPEN_WATER_LIBRARY : FEED_STRINGS.SAVE_WATER_LIBRARY}
      confirmBtn={actionType === FEED_STRINGS.OPEN ? FEED_STRINGS.COPY_TO_FEED_WATER : FEED_STRINGS.saveButton}
      cancelBtn={actionType === FEED_STRINGS.OPEN && FEED_STRINGS.DELETE}
      onModalClose={handleModalClose}
      width={"900px"}
      cancelBtnDisabled={isDeleteButtonDisabled}
      isLoading={loadingData?.isLoading}
      loadingText={loadingData?.loadingText}
      cancelBtnType={!isDeleteButtonDisabled && actionType === FEED_STRINGS.OPEN ? "danger" : null}
      confirmBtnDisabled={
        actionType === "save" &&
        (!doesProjectExist || isCheckingProjectName || editedName?.trim() === "" || editedName === null)
      }
    >
      <StyledWaterLibrary>
        <div className='main-div'>
          <div className='form-name'>
            <CustomLabel
              label={actionType === FEED_STRINGS.OPEN ? FEED_STRINGS.DROP_DOWN_LABEL : FEED_STRINGS.NAME}
              mandatoryIcon={actionType === FEED_STRINGS.SAVE}
            />
            {actionType === FEED_STRINGS.OPEN ? (
              <CustomSelect name='waterlibrary' onChange={handleLibraryList} value={selectedLibrary}>
                {waterLibraryListSorted.map(library =>
                  library.waterLibraryId === "separator" ? (
                    <option key={library.waterLibraryId} disabled>
                      {library.waterLibraryName}
                    </option>
                  ) : (
                    <option key={library.waterLibraryId} value={library.waterLibraryId}>
                      {library.waterLibraryName}
                    </option>
                  ),
                )}
              </CustomSelect>
            ) : (
              <InputTextWithNameValidation
                value={waterLibraryData.streamName || FEED_STRINGS.feedSetup}
                doesProjectExist={doesProjectExist}
                onValidationChange={setDoesProjectExist}
                validateInput={name => checkProjectExistence(name, "WaterLibrary")}
                setEditedName={setEditedName}
                errorMessage={
                  entityData?.isSystem
                    ? FEED_STRINGS.predefinedWaterLibrary
                    : FEED_STRINGS.waterLibraryNameExistDoYouWantToReplace
                }
                handleReplace={handleReplace}
                replaceBtnLabel={entityData?.isSystem ? "" : FEED_STRINGS.replaceLibrary}
              />
            )}
          </div>
          {actionType === FEED_STRINGS.OPEN && (
            <div className='preview'>
              Preview <hr className='inline-hr' />
            </div>
          )}
          <div className={`water-type-${actionType}`}>
            {waterTypeInputFields.map((field, index) => (
              <InputFieldWrapper key={index} {...field} />
            ))}
          </div>
          <div className='ph-temperature'>
            <InputFieldWrapper
              label='pH'
              type='number'
              id='pH'
              name='pH'
              value={parseAndFormatFloat(
                actionType === FEED_STRINGS.OPEN ? waterLibraryData.ph : waterLibraryData.pH,
                2,
              )}
              disabled
            />
            <div className='temperature-card'>
              <TemperatureSection waterLibData={waterLibraryData} />
            </div>
          </div>
          <div className='table-container'>
            {Object.keys(FEED_WATER_IONS_LABEL).map(ionType => (
              <WaterLibraryIonsTable key={ionType} type={ionType} data={sortedData[ionType]} />
            ))}
          </div>
          <div className='disolved-solutes'>
            <DissolvedSolutesSection
              label={`Total Dissolved ${isUF ? "Solids" : "Solutes"}`}
              value={`${parseAndFormatFloat(
                isUF ? waterLibraryData.totalDissolvedSolids : waterLibraryData.totalDissolvedSolutes,
                3,
              )} ${UNITS.MiligramPerLiter}`}
            />
            <DissolvedSolutesSection
              label='Charge Balance'
              value={`${parseAndFormatFloat(waterLibraryData.chargeBalance, 6)} ${UNITS.MiliEquivalentPerLiter}`}
            />
          </div>
          <div className='additional-information'>
            <CustomLabel label={FEED_STRINGS.additionalFeedWaterInfo} />
            <CustomTextArea
              rows={4}
              type='textarea'
              id='additionalInfoTextArea'
              className='additional_feed_setup_info'
              disabled
              value={waterLibraryData.additionalFeedWaterInfo || ""}
            />
          </div>
        </div>
      </StyledWaterLibrary>

      {confirmDlgData && (
        <ConfirmationPopup
          {...confirmDlgData}
          onClose={handleConfirmDlgClose}
          confirmBtn={actionType === FEED_STRINGS.OPEN ? FEED_STRINGS.deleteButton : FEED_STRINGS.replaceButton}
          cancelBtn={FEED_STRINGS.cancelButton}
        />
      )}
    </CustomModal>
  );
};

export default WaterLibraryModal;
