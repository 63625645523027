import styled from "styled-components";

const StyledUFProductFilters = styled("span")`
  display: inline-block;
  margin-left: 10px;
  .dropdown {
    position: static !important;
    .dropdown-toggle {
      background-color: transparent;
      border-color: transparent;
      padding: 0px;
      margin-bottom: -3px;

      ::after {
        display: none !important;
      }
    }
    .dropdown-menu {
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      width: 250px;
      border: none;
      padding: 11px 15px;
      margin-left: 10px;
      margin-top: -5px;

      .sort-wrapper {
        margin-bottom: 15px;

        .sort-option {
          cursor: pointer;
          display: inline-block;

          &:hover {
            color: #128370;
          }

          svg {
            margin-right: 16px;
          }
        }
      }

      .label {
        margin-bottom: 4px;
      }

      .filter-heading {
        color: #128370;
        font-family: "DiodrumRegular";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
      }
      .filter-group {
        margin-bottom: 15px;
        .filter-sub-dropdown {
          color: var(--character-mark, #000);
          font-family: "DiodrumRegular";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          border-radius: 2px;
          border: 1px solid var(--neutrals-3, #e1e1e1);
          background-color: var(--neutrals-1, #fff);
        }
      }
      .radio-group {
        display: flex;
        .form-check-label {
          color: var(--character-title-85, rgba(0, 0, 0, 0.85));
          font-family: "DiodrumRegular";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          margin-right: 5px;
        }
        .form-check-input:checked {
          background-color: #007672;
          border-color: #007672;
        }
      }
      #filter-inside-input {
        border-radius: 2px;
        border: 1px solid var(--neutrals-3, #e1e1e1);
        background: var(--neutrals-1, #fff);
        height: 32px;
        box-shadow: none;
        margin-bottom: 15px;
        color: var(--character-disabled-placeholder-25, #969696);
        font-family: "DiodrumRegular";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
      /* .form-control{
                              border-radius: 2px;
                              border: 1px solid var(--neutrals-3, #E1E1E1);
                              background: var(--neutrals-1, #FFF);
                              height: 32px;
                          } */
      #btnClear {
        color: var(--character-mark, #000);
        text-align: left;
        font-family: "NotoSansRegular";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 183.333% */
        letter-spacing: 0.06px;
        background-color: transparent;
        border-color: transparent;
        padding-left: 0;
        svg {
          width: 12px;
          height: 12px;
          path {
            stroke: rgba(0, 0, 0, 0.45);
            fill: rgba(0, 0, 0, 0.45);
          }
        }
      }
      .check-group {
        display: flex;
        .form-check-label {
          color: var(--character-title-85, rgba(0, 0, 0, 0.85));
          font-family: "DiodrumRegular";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
          margin-right: 5px;
        }
        .form-check-input:checked {
          background-color: #007672;
          border-color: #007672;
          box-shadow: none;
        }
      }
      .date-wrapper {
        display: flex;
        gap: 2px;
      }
    }
  }
`;

export const StyledDiv = styled.div`
  margin-top: 16px;
`;

export default StyledUFProductFilters;
