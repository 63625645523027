import React from "react";
import PropTypes from "prop-types";

import { combineClassNames } from "@utils/appUtils";

import { ErrorFileIcon } from "@components/SVGs";
import { WPTypogrpahy } from "@components/WPTypography";

import "./WPEmptyStates.scss";
const WPEmptyStates = ({ descriptionMsg, titleMsg, className }) => (
  <div className={combineClassNames("flex-center", className)}>
    <div className='flex-center empty-states-wrapper'>
      <div className='flex-center icon-wrapper'>
        <ErrorFileIcon />
      </div>
      <WPTypogrpahy fontFamily='Diodrum' asElement='h2' fontSize={16} fontWeight={600} text={titleMsg} />
      <WPTypogrpahy className='empty-states-description' fontSize={14} text={descriptionMsg} />
    </div>
  </div>
);

WPEmptyStates.propTypes = {
  descriptionMsg: PropTypes.string,
  titleMsg: PropTypes.string,
};

export default WPEmptyStates;
