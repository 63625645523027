/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { APP_TOAST_NOTIFICATION } from "@constants/message.constants";

import { useToastNotification } from "@components/WPToastNotification";

import FolderIcon from "@common/icons/FolderIcon";
import MoreVertIcon from "@common/icons/MoreVertIcon";
import ShortUpArrow from "@common/icons/ShortUpArrow";
import SortDownArrow from "@common/icons/SortDownArrow";
import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import { colors } from "@common/styles/Theme";
import CustomeMessagePopup from "@common/utils/CustomeMessagePopup";

import { useDeleteDataMutation, useLazyGetAllDataQuery } from "@services/apiConfig";

import {
  Folderbtnlist,
  FolderTempbtnlist,
  FolderupdateLoader,
  sortData,
  sortFolderData,
} from "../home/ViewAllFolderSlice";
import { FolderProjectID, updatetitle } from "../menu/FolderProjectSlice";
import { updateLeftpanel } from "../menu/SideMenuSlice";

import FolderUpdatePopup from "./folder/FolderUpdatePopup";
import { sortData2, updateFlag, updateOrder } from "./CardListSlice";
import DeletePopup from "./DeletePopup";
import { ViewAllFolderListViewStyled } from "./ViewAllFolderListViewStyled";

const ViewAllFolderListView = () => {
  const UserInfoStore = useSelector(state => state.userInfo.data);
  const userID = UserInfoStore ? UserInfoStore.UserId : 1;
  const dispatch = useDispatch();
  const { showSuccessNotification, showErrorNotification } = useToastNotification();
  dispatch(updateLeftpanel("masterdata/api/v1/FolderList"));
  const [Cardstate, setCard] = useState([]);
  const StoreSidLeftpanel = useSelector(state => state.leftpanel.data);
  const FolderStoreData = useSelector(state => state.Folderview.data);
  const { order, pTitle } = useSelector(state => state.cardlist);
  const [selectedfolderID, setselectedfolderID] = useState();
  const [activeMenuItem, setActiveMenuItem] = useState("recent");
  const [getData, responsedata] = useLazyGetAllDataQuery();
  const ProjectInfoStore = useSelector(state => state.projectInfo.data);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [deletePost, response] = useDeleteDataMutation();
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const StoreData = useSelector(state => state.cardlist.data);
  const [popupOperator, setPopupOperator] = useState({
    message: "",
    show: false,
    type: "",
    subtext: "",
  });

  useEffect(() => {
    if (responsedata.status !== "fulfilled") {
      getData(`${StoreSidLeftpanel}?userID=${userID}`);
    } else {
      dispatch(Folderbtnlist(responsedata.data));
      dispatch(FolderTempbtnlist(responsedata.data));
      dispatch(FolderupdateLoader(false));
      dispatch(sortFolderData({ flag: pTitle, data: responsedata.data }));
    }
  }, [responsedata, StoreSidLeftpanel]);

  useEffect(() => {
    LoadRecord();
  }, [FolderStoreData]);
  const LoadRecord = () => {
    setCard([]);
    if (FolderStoreData === 0) {
      <div>Loading....</div>;
    } else {
      setCard([]);
      FolderStoreData.forEach(element => {
        setCard(current => [
          ...current,
          {
            folderID: element.folderID,
            folderName: element.folderName,
            createdDate: element.createdDate,
            updatedDate: element.updatedDate,
            modifiedDuration: element.modifiedDuration,
            createdDateDuration: element.createdDateDuration,
          },
        ]);
      });
    }
  };
  const btnFolderProject = (listName, e, Folder) => {
    dispatch(updateLeftpanel("masterdata/api/v1/FolderProject"));
    dispatch(FolderProjectID(e));
    dispatch(updatetitle(Folder));
    setselectedfolderID(e);
    setActiveMenuItem(listName);
    dispatch(FolderupdateLoader(false));
  };

  const handleSort1 = () => {
    dispatch(sortData("PN"));
    if (order === "D") {
      dispatch(updateOrder("A"));
      dispatch(updateFlag("Folder Name (ascending)"));
      dispatch(sortData2({ flag: "PN", data: StoreData }));
    } else {
      dispatch(updateOrder("D"));
      dispatch(updateFlag("Folder Name (descending)"));
      dispatch(sortData2({ flag: "PN", data: StoreData }));
    }
  };

  const handleSort2 = () => {
    dispatch(sortData("CD"));
    dispatch(updateFlag("Date Created"));
    dispatch(sortData2({ flag: "CD", data: StoreData }));
  };

  const handleSort3 = () => {
    dispatch(sortData("MD"));
    dispatch(updateFlag("Last Modified"));
    dispatch(sortData2({ flag: "MD", data: StoreData }));
  };

  const handleRenameFolder = folder => {
    setSelectedFolder(folder);
    setIsOpen(true);
    document.body.classList.add("disable");
  };

  const handleCloseRenameProject = () => {
    setIsOpen(false);
  };

  const handleCloseOperator = () => {
    setPopupOperator({
      message: "",
      show: false,
      type: "",
      subtext: "",
    });
  };

  const handleDeleteClose = () => {
    setShowDeleteWarning(false);
  };

  const handleDeleteFolder = folderID => {
    setselectedfolderID(folderID);
    setShowDeleteWarning(true);
  };

  const deleteFolder = () => {
    setShowDeleteWarning(false);
    const data = {
      Method: "masterdata/api/v1/DeleteFolder",
      userID: userID,
      folderID: selectedfolderID,
    };

    deletePost(data)
      .then(responsedata => {
        if (responsedata.data?.responseCode === 200) {
          getData(`${StoreSidLeftpanel}?userID=${userID}`);
          showSuccessNotification(APP_TOAST_NOTIFICATION.FOLDER_DELETED);
        } else {
          showErrorNotification(APP_TOAST_NOTIFICATION.FOLDER_DELETE_FAILED);
        }
      })
      .catch(error => {});
  };

  return (
    <>
      <ViewAllFolderListViewStyled>
        {!FolderStoreData.length ? (
          <h6 className='error-msg'>Records not found</h6>
        ) : (
          <>
            <div className='folder_header_section'>
              <div className='folder_name_title'>
                <CustomHeading fontSize={"18px"} color={colors.blackTransparency045} onClick={handleSort1}>
                  Folder Name
                </CustomHeading>
                {pTitle === "PN" ? (
                  <div>
                    {order === "D" ? (
                      <div className='arrow-updown-icon' onClick={handleSort1}>
                        <ShortUpArrow />
                      </div>
                    ) : (
                      <div className='arrow-updown-icon' onClick={handleSort1}>
                        <SortDownArrow />
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className='folder_created_title'>
                <CustomHeading fontSize={"18px"} color={colors.blackTransparency045} onClick={handleSort2}>
                  Date Created
                </CustomHeading>
                {pTitle === "CD" ? (
                  <div>
                    {order === "D" ? (
                      <div className='arrow-updown-icon' onClick={handleSort2}>
                        <ShortUpArrow />
                      </div>
                    ) : (
                      <div className='arrow-updown-icon' onClick={handleSort2}>
                        <SortDownArrow />
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className='folder_created_title'>
                <CustomHeading fontSize={"18px"} color={colors.blackTransparency045} onClick={handleSort3}>
                  Last Modified
                </CustomHeading>
                {pTitle === "MD" ? (
                  <div>
                    {order === "D" ? (
                      <div className='arrow-updown-icon' onClick={handleSort3}>
                        <ShortUpArrow />
                      </div>
                    ) : (
                      <div className='arrow-updown-icon' onClick={handleSort3}>
                        <SortDownArrow />
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className='folder_cards_wrapper'>
              {Cardstate?.map((u, i) => (
                <StyledCard className='folder_list_view_card' key={i}>
                  <div
                    className='folder_name'
                    onClick={() => btnFolderProject("FolderProject", u.folderID, u.folderName)}
                  >
                    <FolderIcon />
                    <CustomHeading fontFamily='NotoSansRegular' fontSize={"16px"}>
                      {u.folderName}
                    </CustomHeading>
                  </div>
                  <div
                    className='folder_created'
                    onClick={() => btnFolderProject("FolderProject", u.folderID, u.folderName)}
                  >
                    <CustomHeading fontFamily='NotoSansRegular' fontSize={"16px"}>
                      {u.createdDateDuration}
                    </CustomHeading>
                  </div>

                  <div
                    className='folder_created'
                    onClick={() => btnFolderProject("FolderProject", u.folderID, u.folderName)}
                  >
                    <CustomHeading fontFamily='NotoSansRegular' fontSize={"16px"}>
                      {u.modifiedDuration}
                    </CustomHeading>
                  </div>
                  <div className='hidden'></div>
                  <div className='folder_option'>
                    <Dropdown className='project-option-dropdown'>
                      <Dropdown.Toggle variant=''>
                        <MoreVertIcon />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href='#/action-1'>
                          <a href='#' onClick={() => btnFolderProject("FolderProject", u.folderID, u.folderName)}>
                            Open Folder
                          </a>
                        </Dropdown.Item>
                        {/* <Dropdown.Item href="#/action-3">
                          <a href="#">Duplicate this Folder</a>
                        </Dropdown.Item> */}
                        <Dropdown.Item href='#/action-3'>
                          <a href='#' onClick={() => handleDeleteFolder(u.folderID)}>
                            Delete this Folder
                          </a>
                        </Dropdown.Item>
                        <Dropdown.Item href='#/action-3'>
                          <a href='#' onClick={() => handleRenameFolder(u)}>
                            Rename this Folder
                          </a>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </StyledCard>
              ))}
              <CustomeMessagePopup operator={popupOperator} close={handleCloseOperator} />
            </div>
          </>
        )}
      </ViewAllFolderListViewStyled>
      {isOpen && (
        <FolderUpdatePopup
          mode={"rename"}
          folder={selectedFolder}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          userID={userID}
          folders={Cardstate}
          handleCloseRenameProject={handleCloseRenameProject}
          setPopupOperator={setPopupOperator}
        />
      )}
      <DeletePopup
        show={showDeleteWarning}
        message='Are you sure you want to delete this Folder?'
        deleteRecord={deleteFolder}
        handleClose={handleDeleteClose}
      />
    </>
  );
};

export default ViewAllFolderListView;
