import { Card } from "react-bootstrap";

import { IXMB, IXMBStrings } from "@utils/StringConstants";

import InfoIcon from "@common/icons/InfoIcon";
import MandatoryFieldIcon from "@common/icons/MandatoryFieldIcon";
import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import IconWithTooltip from "@common/styles/components/headings/IconWithTooltip";
import { colors } from "@common/styles/Theme";

const WaterChemistryAdjustment = () => {
  const handleMouseLeave = () => {};
  return (
    <div style={{ display: "none" }}>
      <StyledCard className='modeling-card'>
        <Card.Header>
          <CustomHeading
            fontFamily='NotoSansRegular'
            fontSize='14px'
            fontWeight='400'
            color={colors.PrimaryDarkAquaMarine}
            label={IXMBStrings.WaterChemistryAdjustments}
            mandatoryIcon={<MandatoryFieldIcon />}
          />
          <Card.Title onMouseLeave={handleMouseLeave}>
            <IconWithTooltip label='' icon={<InfoIcon />} />
          </Card.Title>
        </Card.Header>
        <Card.Body className='modeling_objective_radio_group'>
          <div>Add Chemical Degas</div>
        </Card.Body>
      </StyledCard>
    </div>
  );
};

export default WaterChemistryAdjustment;
