import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { useReport } from "@hooks/useReport";

import IconButton from "@components/IconButton";
import DownloadIcon from "@components/SVGs/DownloadIcon";
import PrintIcon from "@components/SVGs/PrintIcon";
import WPEmptyStates from "@components/WPEmptyStates";
import { typographyVariants, WPTypogrpahy } from "@components/WPTypography";

import DuPont_logo from "@common/assets/images/DuPont_Logo_SVG.svg";
import Wave_Pro_Logo from "@common/assets/images/WAVE_Pro_Logo_Text.svg";
import { colors } from "@common/styles/Theme";

import { useWPUfParametersTableData } from "@features/report-new/uf/WPUfParametersTables";

import { WPReportProjectInfoTable } from "../WPReportProjectInfoTable";
import { ufSectionsLabels } from "../WPReportSideNavFilter/WPReportSideNavFilter.constants";

import { ufParametersTableMapping, ufReportTableMapping } from "./WPReport.constant";

import "./WPReportContent.scss";

export const WPReportContentHeader = () => {
  const { generatePDF, isUfReportErr } = useReport();

  return (
    <div className='report-content-header'>
      <WPTypogrpahy asElement='h2' color={colors.PrimaryDarkAquaMarine} fontSize={14}>
        {reportStrings.ufSummaryReport}
      </WPTypogrpahy>
      <div className='d-flex'>
        <IconButton
          className='mx-3'
          id='download-report'
          tooltip={reportStrings.downloadReport}
          onClick={generatePDF}
          disabled={isUfReportErr}
        >
          <DownloadIcon disabled={isUfReportErr} />
        </IconButton>
        <IconButton
          id='print-report'
          tooltip={reportStrings.printReport}
          onClick={() => generatePDF({ autoPrint: true })}
          disabled={isUfReportErr}
        >
          <PrintIcon disabled={isUfReportErr} />
        </IconButton>
      </div>
    </div>
  );
};

export const ReportPdfHeader = () => (
  <div>
    <div id='reportPdfHeader' className='report-pdf-header'>
      <img src={DuPont_logo} alt='Dupont Logo' />
      <WPTypogrpahy text='UF Detailed Report' className='report-pdf-header-title' />
      <img src={Wave_Pro_Logo} alt='WavePro Logo' />
    </div>
  </div>
);

export const WPReportContent = () => {
  const { reportSectionData } = useSelector(state => state.report);
  const { parametersTableType } = useWPUfParametersTableData();

  const { isUfReportErr } = useReport();

  const { ufSectionData } = reportSectionData;

  const ufComponentMapping = useMemo(
    () => ({ ...ufReportTableMapping, ...ufParametersTableMapping[parametersTableType] }),
    [parametersTableType],
  );

  if (isUfReportErr) {
    return (
      <>
        <WPReportContentHeader />
        <WPEmptyStates
          className='empty-states-content'
          titleMsg={reportStrings.reportNotGenerated}
          descriptionMsg={reportStrings.reportGenerationErrMsg}
        />
      </>
    );
  }

  return (
    <>
      <WPReportContentHeader />
      <div id='content-to-print' className='report-content-main'>
        <ReportPdfHeader />
        <WPReportProjectInfoTable />
        {Object.keys(ufSectionsLabels).map(key => {
          if (!ufSectionData[key]) {
            return null;
          }
          const Component = ufComponentMapping[key] || React.Fragment;
          return <Component key={key} />;
        })}
        <WPTypogrpahy
          text={reportStrings.reportDisclaimer}
          className='marginTop24 no-page-break'
          variant={typographyVariants.xsText}
        />
      </div>
    </>
  );
};
