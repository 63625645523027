import React from "react";

const SaveIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='24' viewBox='0 0 22 24' fill='none'>
      <path d='M1 23V2.53488V1H16L21 6.11628V23H1Z' stroke='white' />
      <path
        d='M6 1.5H5.5V2V5C5.5 5.82843 6.17157 6.5 7 6.5H14C14.8284 6.5 15.5 5.82843 15.5 5V2V1.5H15H6Z'
        stroke='white'
      />
      <circle cx='11' cy='15' r='3.5' stroke='white' />
    </svg>
  </>
);

export default SaveIcon;
