import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { WPUfDupontMiniCipParametersTableColumns as columns } from "./WPUfDupontMiniCipParametersTable.constants";

export const WPUfDupontMiniCipParametersTable = () => {
  const { uf_mcip_parameters: rows } = useSelector(state => state.report.ufReportTableData);

  return (
    <WPTable
      tableLayout='fixed'
      sectionHeader={reportStrings.mCipParameters}
      columns={columns}
      rows={rows}
      remarksList={[
        reportStrings.mCipHighFrequencyRemark,
        reportStrings.mCipAuxillaryRemark,
        reportStrings.mCipDurationRemark,
        reportStrings.mCipSubstituteRemark,
      ]}
      footNotesList={[
        reportStrings.airFlowFootNote,
        reportStrings.repeatStepRemark.replace("${steps}", "2"),
        reportStrings.cipStepDurationFootNote.replace("${steps}", "6-13"),
        reportStrings.mCipFootNote4,
        reportStrings.cipDurationLongerFootNote,
        reportStrings.forwardFlushFlowFootNote,
        reportStrings.mayWastePermeateRemark,
      ]}
    />
  );
};
