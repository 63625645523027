import React from "react";

import "./index.css";
import "./index.css";

const ProjectImportIcon = ({ isActiveFocused }) => {
  const rootStyles = getComputedStyle(document.documentElement);
  const tealColor = rootStyles.getPropertyValue("--du-color-primary").trim();
  const blackColor = rootStyles.getPropertyValue("--du-color-black").trim();

  return (
    <svg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.12031 9.83242H1.96094C2.02656 9.83242 2.08125 9.88711 2.08125 9.95274V10.9184H11.9203V1.07773H2.07969V2.04336C2.07969 2.10898 2.025 2.16367 1.95937 2.16367H1.11875C1.05312 2.16367 0.998435 2.11055 0.998435 2.04336V0.477734C0.998435 0.212109 1.2125 -0.00195312 1.47812 -0.00195312H12.5203C12.7859 -0.00195312 13 0.212109 13 0.477734V11.5184C13 11.784 12.7859 11.998 12.5203 11.998H1.47969C1.21406 11.998 0.999998 11.784 0.999998 11.5184V9.95274C0.999998 9.88555 1.05469 9.83242 1.12031 9.83242ZM0.906248 5.43555H5.8125V4.24805C5.8125 4.14336 5.93437 4.08399 6.01562 4.14961L8.23281 5.89961C8.24775 5.9113 8.25983 5.92624 8.26814 5.9433C8.27645 5.96035 8.28077 5.97908 8.28077 5.99805C8.28077 6.01702 8.27645 6.03574 8.26814 6.0528C8.25983 6.06985 8.24775 6.08479 8.23281 6.09649L6.01562 7.84649C5.93281 7.91211 5.8125 7.85274 5.8125 7.74805V6.56055H0.906248C0.837498 6.56055 0.781247 6.5043 0.781247 6.43555V5.56055C0.781247 5.4918 0.837498 5.43555 0.906248 5.43555Z'
        fill={isActiveFocused ? tealColor : blackColor}
        fillOpacity='0.85'
      />
    </svg>
  );
};

export default ProjectImportIcon;
