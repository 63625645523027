/* eslint-disable max-len */
import React from "react";

const CloseCircleRedIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_1719_29664)'>
        <path
          d='M11.0962 5.17606C11.0962 5.09749 11.0319 5.0332 10.9533 5.0332L9.77475 5.03856L7.99975 7.15463L6.22653 5.04035L5.04618 5.03499C4.96761 5.03499 4.90332 5.09749 4.90332 5.17785C4.90332 5.21178 4.91582 5.24392 4.93725 5.2707L7.26046 8.03856L4.93725 10.8046C4.91567 10.8308 4.9037 10.8636 4.90332 10.8975C4.90332 10.9761 4.96761 11.0403 5.04618 11.0403L6.22653 11.035L7.99975 8.91892L9.77296 11.0332L10.9515 11.0386C11.0301 11.0386 11.0944 10.9761 11.0944 10.8957C11.0944 10.8618 11.0819 10.8296 11.0605 10.8028L8.74082 8.03677L11.064 5.26892C11.0855 5.24392 11.0962 5.20999 11.0962 5.17606Z'
          fill='#E4001C'
        />
        <path
          d='M8 0C3.58214 0 0 3.58214 0 8C0 12.4179 3.58214 16 8 16C12.4179 16 16 12.4179 16 8C16 3.58214 12.4179 0 8 0ZM8 14.6429C4.33214 14.6429 1.35714 11.6679 1.35714 8C1.35714 4.33214 4.33214 1.35714 8 1.35714C11.6679 1.35714 14.6429 4.33214 14.6429 8C14.6429 11.6679 11.6679 14.6429 8 14.6429Z'
          fill='#E4001C'
        />
      </g>
      <defs>
        <clipPath id='clip0_1719_29664'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default CloseCircleRedIcon;
