import { reportStrings } from "@constants/report.strings";

import { WPTypogrpahy } from "@components/WPTypography";

import "./WPReportTableNotes.scss";

export const WPReportTableNotes = ({ type, list }) => (
  <div className='table-notes'>
    <WPTypogrpahy
      text={type === "footnotes" ? reportStrings.footNotes : reportStrings.remarks}
      className='notes fs-12'
      fontWeight={700}
    />
    {list?.map((note, index) => (
      <WPTypogrpahy
        key={index}
        fontFamily='Diodrum'
        text={note}
        className={`fs-12 list-item-${type === "footnotes" ? "footnotes" : "remarks"}`}
      />
    ))}
  </div>
);

WPReportTableNotes.defaultProps = {
  type: "footnotes",
};
