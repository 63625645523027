import React from "react";

const FolderIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='83' height='66' viewBox='0 0 83 66' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.5 0.5H29.1376L40.31 11.6304H82.5V65.5H0.5V0.5ZM1.5 1.5V64.5H81.5V12.6304H39.8969L28.7245 1.5H1.5Z'
        fill='#007672'
      />
      <path d='M2.39648 62.2173V12.1304L10.7758 53.8695L69.431 62.2173H2.39648Z' fill='#E2F7F5' />
    </svg>
  </>
);

export default FolderIcon;
