export const getEvenNumber = value => {
  const ceilValue = Math.ceil(value);
  return ceilValue % 2 === 0 ? ceilValue : ceilValue + 1;
};

export const getStandByUnits = (withStandBy, N_Online_Trains, N_BW_per_filter_max) =>
  withStandBy ? Math.floor(Math.max(1, N_Online_Trains / (N_BW_per_filter_max + 1) + 0.99)) : 0;

export const getNModulesTrainTarget = (
  withStandBy,
  N_Modules_Target,
  T_Filter_Module_Day,
  T_MIT_module_day,
  N_Online_Trains,
) => {
  if (withStandBy) {
    return Math.ceil((N_Modules_Target * T_Filter_Module_Day) / (1440 - T_MIT_module_day) / N_Online_Trains);
  } else {
    return Math.ceil(N_Modules_Target / N_Online_Trains);
  }
};
