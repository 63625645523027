import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateFeedSetupStoreData } from "@features/feedwater/feedsetup/FeedsetupSlice";
import { COMPANY_CODE, UFTechnologies } from "@features/feedwater/uf/constants/UFConstants";
import { getModules } from "@features/feedwater/uf/UFConfiguration/UFHelper";
import { updateUFStoreData } from "@features/feedwater/uf/UFSlice";
import { filterModules, getUFModulesByCompany as getUFModulesByCompanyUtil } from "@features/feedwater/uf/ufUtils";

import useUFConfig from "./useUFConfig";
import { useUFInputRanges } from "./useUFInputRanges";

export const useUFModuleFilters = () => {
  const dispatch = useDispatch();
  const { updateActiveModule } = useUFConfig();
  const { getModuleBasedInputRangeConfigs } = useUFInputRanges();

  const {
    data: UFStoreData,
    ufModulesByCompany,
    activeUFModule,
    ufProductFilter,
  } = useSelector(state => state.UFStore);
  const {
    waterSubTypesByCompany,
    streamData,
    data: feedStreamData,
    waterTypes,
    selectedWaterSubType,
    isUpdateActiveModule,
  } = useSelector(state => state.Feedsetupdetailsdatapanel);

  const feedWaterData = streamData.lstrequestsavefeedwater[0]?.streams[0] || {};
  const { waterTypeID, waterSubTypeID, turbidity, tss, toc } = feedWaterData;
  const [existingModule, setExistingModule] = useState(null);
  const [activeUFTech, setActiveUFTech] = useState(0);

  useEffect(() => {
    if (waterSubTypeID) {
      const { defaultInputRangeConfig, updatedDataFields } = getModuleBasedInputRangeConfigs(activeUFModule);
      dispatch(updateUFStoreData({ data: { ...updatedDataFields }, defaultInputRangeConfig }));
    }
  }, [waterSubTypeID]);

  const getWaterSourceName = id => waterTypes.find(({ waterTypeId }) => id?.toString() === waterTypeId?.toString());

  useEffect(() => {
    if (selectedWaterSubType?.waterSubTypeId && waterTypes?.length) {
      const waterSource = getWaterSourceName(feedWaterData.waterTypeID)?.waterSource || "";
      const feedData = { ...feedWaterData, waterSubTypeID: selectedWaterSubType.waterSubTypeId };
      const streamData = { lstrequestsavefeedwater: [{ streams: [feedData] }] };
      const { streamName, feedTitle, waterSubTypeID } = feedData;
      const waterSubTypeName = selectedWaterSubType.description || "";
      const data = [{ ...feedStreamData[0], streamName, feedTitle, waterSubTypeID, waterSource, waterSubTypeName }];
      dispatch(updateFeedSetupStoreData({ streamData, data }));
    }
  }, [selectedWaterSubType, waterTypes]);

  /**
   * This useEffect hook is utilized within the `useUFModuleFilters.js` file to dynamically update the feed setup
   * store data based on the currently active UF module's company name.
   * It performs the following steps:
   *
   * - Extracts the `companyName` from the `activeUFModule` object.
   * - Initializes a `data` object with default structures for `waterSubTypes` (an empty array) and `selectedWaterSubType` (null).
   * - If a `companyName` is present, it proceeds with the following steps:
   *   - Attempts to retrieve a list of water subtypes associated with the `companyName` from the `waterSubTypesByCompany` mapping.
   *     If no such mapping exists, it defaults to an empty array.
   *   - Assigns the retrieved list (or empty array) to `data.waterSubTypes`.
   *   - Determines the `selectedWaterSubType` based on specific criteria:
   *     - If the turbidity, TSS (Total Suspended Solids), and TOC (Total Organic Carbon) values are all non-positive
   *       and there are items in the list, it selects either the first item marked as default or the last item in the list.
   *     - Otherwise, it calculates the appropriate water subtype ID using the `getWaterSubTypeId` function, based on the
   *       available `data.waterSubTypes`, and assigns this as the `selectedWaterSubType`.
   * - Dispatches an action to update the feed setup store with the newly constructed `data` object,
   *   which includes the updated list of water subtypes and the selected water subtype.
   */
  useEffect(() => {
    const { companyName } = activeUFModule;
    if (companyName) {
      const data = { waterSubTypes: [], selectedWaterSubType: null };
      const items = waterSubTypesByCompany[companyName] || [];
      data.waterSubTypes = waterSubTypesByCompany[companyName] || [];
      if (turbidity <= 0 && tss <= 0 && toc <= 0 && items.length > 0) {
        data.selectedWaterSubType = items.find(({ isDefault }) => isDefault) || items[items.length - 1];
      } else {
        const waterSubTypeData = getWaterSubTypeId(data.waterSubTypes);
        data.selectedWaterSubType = waterSubTypeData;
      }
      dispatch(updateFeedSetupStoreData(data));
      setTimeout(() => dispatch(updateUFStoreData({ calcEngineDataRefreshCount: 1 })), 200); // Timout needed to make sure if water subtype has been updated
    }
  }, [activeUFModule, waterSubTypesByCompany]);

  /**
   * This useEffect hook is triggered whenever `reportUFData` or `ufModulesByCompany` changes.
   * It performs the following actions:
   *
   * - Extracts the active module ID and company name from `reportUFData`.
   * - Checks if there is no active module ID or if `ufModulesByCompany` is empty, and if so, exits early.
   * - Retrieves the UF modules associated with the company name from `ufModulesByCompany`.
   * - Sets the pUFTechnologyID based on the company name (1 for DuPont, otherwise 2).
   * - Calls `setFeedStreamData` to update the feed stream data based on the current report data.
   * - Updates the active UF technology and the existing module with the new data.
   * - Calls `updateActiveModule` to update the active module in the context of the UF modules.
   * - Dispatches an action to update the UFStoreData with the new UF modules, UF company code, and pUFTechnologyID.
   */

  /**
   * This function `setUFModules` is responsible for:
   * - Filtering the provided module list by company using the `filterModules` function.
   * - Dispatching an action to update the UFStoreData with the filtered ufModulesByCompany.
   *
   * @param {Array} moduleList - The list of UF modules to be filtered and set in the store.
   */
  const setUFModules = moduleList => {
    const ufModulesByCompany = filterModules(moduleList);
    dispatch(updateUFStoreData({ ufModulesByCompany }));
  };

  /**
   * This useEffect hook triggers whenever the pUFTechnologyID property within the UFStoreData object changes.
   * This useEffect hook is responsible for:
   *
   * - Resetting the existing module to null.
   * - Updating the active UF technology based on the pUFTechnologyID property from UFStoreData.
   * It triggers whenever the pUFTechnologyID property changes.
   */
  useEffect(() => {
    setExistingModule(null);
    setActiveUFTech(UFStoreData.pUFTechnologyID);
  }, [UFStoreData.pUFTechnologyID]);

  /**
   * This useEffect hook performs the following actions when the waterTypeID changes:
   * - Filters out 'DuPont' from the ufTechnologies array if waterTypeID is "8".
   * - Sets pUFTechnologyID to 2 if waterTypeID is "8".
   * - Dispatches an action to update the UFStoreData with the new ufTechnologies array and pUFTechnologyID.
   */
  useEffect(() => {
    let ufTechnologies = [...UFTechnologies];
    let { pUFTechnologyID } = UFStoreData;
    if (waterTypeID?.toString() === "8") {
      ufTechnologies = ufTechnologies.filter(({ companyName }) => companyName !== "DuPont");
      pUFTechnologyID = 2;
    }
    dispatch(updateUFStoreData({ ufTechnologies, data: { pUFTechnologyID } }));
  }, [waterTypeID]);

  const getWaterSubTypeId = (waterSubTypesList, concentration) => {
    if (waterSubTypesList.length === 0) return {};
    const feedwaterData = concentration || { NTU: turbidity, TSS: tss, TOC: toc };
    for (let i = 0; i < waterSubTypesList.length; i++) {
      let str = waterSubTypesList[i].description;
      str = str.replaceAll("≥", ">=");
      str = str.replaceAll("≤", "<=");
      str = str.replaceAll("<", "<");
      str = str.replaceAll(",", " &&");
      str = str.replaceAll("NTU", Number(feedwaterData.NTU));
      str = str.replaceAll("TSS", Number(feedwaterData.TSS));
      str = str.replaceAll("TOC", Number(feedwaterData.TOC));
      if (eval(str)) {
        return {
          waterSubTypeId: waterSubTypesList[i].waterSubTypeId,
          description: waterSubTypesList[i].description,
          ...feedwaterData,
        };
      }
    }
    return {
      waterSubTypeId: waterSubTypesList[waterSubTypesList.length - 1]?.waterSubTypeId,
      description: waterSubTypesList[waterSubTypesList.length - 1]?.description,
      ...feedwaterData,
      TSS: feedwaterData.TSS == 0 ? feedwaterData.NTU * 2 : feedwaterData.TSS,
    };
  };

  /*
   * Below code filters and populates the UF module list based on selected criteria:
   * - Water Type selection update the available UF Technology options:
   *   - "Backwash Water" automatically selects "PES In-Out" technology. It will be only option available for UF Technolgy selection.
   *   - Other Water Types allow selection between "PVDF Out-In" (default) and "PES In-Out".
   *
   * - The UF module list is populated based on the combination of UF Technology, Water Type, and feed water characteristics (turbidity(NTU), TSS, TOC):
   *   - For "Backwash Water", only Inge MB 1.5 modules are populated.
   *   - For "PVDF Out-In" technology, the list is populated with DuPont modules.
   *   - For "PES In-Out" technology:
   *     - "Waste Water" with NTU <= 50, TSS <= 100, and TOC <= 30 populates with both Inge MB 0.9 and MB 1.5 modules. Otherwise populate with Inge MB 1.5 modules only.
   *     - "Sea Water" with NTU <= 20, TSS <= 40, and TOC <= 9 populates with both Inge MB 0.7 and MB 0.9 modules. Otherwise populate with Inge MB 0.9 modules only.
   *     - "Surface Water" with NTU <= 20, TSS <= 40, and TOC <= 8 populates with both Inge MB 0.7 and MB 0.9 modules. Otherwise populate with Inge MB 0.9 modules only.
   *     - "Softened", "Municipal", or "Well" waters with NTU <= 20, TSS <= 40, and TOC <= 5 populates with both Inge MB 0.7 and MB 0.9 modules.
   *     - In other cases, populate with Inge MB 0.9 modules only.
   *
   * - Additional filters may be applied as needed (e.g., obsolete modules, public vs. restricted access).
   * - After module selection, use Water Type, NTU, TSS, and TOC values, along with the module list name, to determine the correct design guideline.
   * - If NTU, TSS, and TOC are all 0, assume unknown values and choose the most conservative design guideline based on Water Type and list.
   * - Use the selected design guideline to populate input fields with default values.
   */

  const getUFModulesByCompany = (ufCompany, waterID) =>
    getUFModulesByCompanyUtil({ ufCompany, waterID, ufModulesByCompany, feedWaterData });

  const updateActiveModuleForImported = (activeUFTech, ufModuleId) => {
    const ufCompany = COMPANY_CODE[activeUFTech];
    const ufModules = getUFModulesByCompany(ufCompany);
    setActiveUFTech(activeUFTech);

    updateActiveModule(ufModuleId, ufModules);
    dispatch(updateUFStoreData({ ufModules, data: { activeUFTech } }));
  };

  useEffect(() => {
    if (ufModulesByCompany && Object.keys(ufModulesByCompany).length > 0 && activeUFTech > 0 && waterTypeID) {
      const ufCompany = COMPANY_CODE[activeUFTech];
      const ufModules = getUFModulesByCompany(ufCompany, waterTypeID);
      if (isUpdateActiveModule) {
        const filteredModules = getModules(ufProductFilter.selectedModuleCategory, ufModules);
        const ufModuleId = existingModule?.ufModuleId || filteredModules[0].ufmoduleId;
        updateActiveModule(ufModuleId, filteredModules);
      }
      dispatch(updateUFStoreData({ ufModules, ufCompany }));
    }
  }, [activeUFTech, waterTypeID, isUpdateActiveModule]);

  return { setUFModules, getWaterSubTypeId, getWaterSourceName, updateActiveModuleForImported };
};
