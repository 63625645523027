import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import API_URLS from "@constants/api.urls";
import { UNITS } from "@constants/units.constant";

import DupontLogger from "@utils/DupontLogger";

import { useLazyGetAllDataQuery } from "@services/apiConfig";

import { Feedsetupdetailsdata, updateFeedSetupStoreData } from "@features/feedwater/feedsetup/FeedsetupSlice";

import useUnitConversion from "./useUnitConversion";

const useFeedSetupDataHandler = () => {
  const Logger = DupontLogger("useFeedSetupDataHandler");
  const [getDataAPI] = useLazyGetAllDataQuery();

  const dispatch = useDispatch();
  const { unitConversionByName } = useUnitConversion();

  const { projectInfo, userInfo } = useSelector(state => state);
  const addedTechnology = useSelector(state => state.processDiagramSlice.addedTechnology);
  const { waterTypes } = useSelector(state => state.Feedsetupdetailsdatapanel);

  const { unitConfig } = projectInfo?.projectConfig || {};

  const projectDetails = useMemo(() => {
    const validTechnologies = addedTechnology.filter(item => item.id > 0);
    const validTechCounts = validTechnologies.length;

    const projectTechnology =
      validTechCounts === 1 ? validTechnologies[0].heading : validTechCounts > 1 ? "Multiple" : "null";
    const { UserId: userID } = userInfo.data || {};
    const { caseId: caseID, projectID: projectid } = projectInfo.data || {};

    return {
      langid: 1,
      technologyid: 1,
      userID,
      caseID,
      projectid,
      projectTechnology,
    };
  }, [projectInfo, userInfo, addedTechnology]);

  const fetchWaterTypes = async (waterTypeid, waterSubTypeID) => {
    if (waterTypes.length > 0) return;
    const { userID, projectid, langid, technologyid } = projectDetails;
    const queryParams = new URLSearchParams({ userID, projectid, langid, technologyid }).toString();
    if (waterTypeid) fetchWaterSubTypes(waterTypeid, waterSubTypeID);
    const { data } = await getDataAPI(`masterdata/api/v1/WaterType?${queryParams}`);
    //const isIXD = projectTechnology === "IXD";
    const waterTypesData = data.filter(({ waterSource }) => waterSource !== "RO/NF Permeate");
    dispatch(updateFeedSetupStoreData({ waterTypes: waterTypesData }));
  };

  const fetchWaterSubTypes = async (waterTypeid, waterSubTypeID) => {
    console.log("fetchWaterSubTypes: ", waterTypeid, waterSubTypeID);
    if (waterTypeid) {
      const { userID, projectid, langid, technologyid } = projectDetails;
      const params = { userID, projectid, langid, technologyid, waterTypeid };
      const queryParams = new URLSearchParams(params).toString();
      try {
        const response = await getDataAPI(`masterdata/api/v1/WaterSubType?${queryParams}`);
        const waterSubTypes = response.data || [];
        const waterSubTypesByCompany = waterSubTypes.reduce((acc, item) => {
          if (!acc[item.companyName]) acc[item.companyName] = [];
          acc[item.companyName].push(item);
          return acc;
        }, {});
        dispatch(updateFeedSetupStoreData({ waterSubTypesByCompany }));
      } catch (error) {
        Logger.error("Error fetching water sub types: ", error);
      }
    }
  };

  const convertTempToFahrenheit = temp => unitConversionByName(temp, unitConfig.selectedUnits[2], UNITS.celsius);

  const parseFeedWaterData = responseFeedWaterData => {
    //Logger.info("fetchFeedSetupData - selectedUnits ", unitConfig.selectedUnits);
    let formattedData = {};
    if (responseFeedWaterData) {
      const { data } = responseFeedWaterData;
      if (data) {
        formattedData = data.map(item => {
          const newItem = { ...item };
          if (unitConfig.selectedUnits[17] !== UNITS.uscm) {
            newItem.estimatedConductivity = unitConversionByName(
              newItem.estimatedConductivity,
              unitConfig.selectedUnits[17],
              UNITS.uscm,
            );
          }
          if (unitConfig.selectedUnits[2] !== UNITS.celsius) {
            newItem.tempDesign = convertTempToFahrenheit(newItem.tempDesign);
            newItem.tempMax = convertTempToFahrenheit(newItem.tempMax);
            newItem.tempMin = convertTempToFahrenheit(newItem.tempMin);
          }
          newItem.waterTypeId = newItem.waterTypeId || 5;
          return newItem;
        });
      }
    }
    return formattedData;
  };

  const fetchFeedSetupData = async () => {
    let feedWaterData = [];
    try {
      const { userID, projectid, caseID } = projectDetails;
      const params = { userID, projectID: projectid, caseID, methodName: "normal" };
      const queryParams = new URLSearchParams(params).toString();
      const reponse = await getDataAPI(`${API_URLS.feedWater}?${queryParams}`);
      feedWaterData = parseFeedWaterData(reponse);
      dispatch(Feedsetupdetailsdata(feedWaterData));
    } catch (error) {
      Logger.error("Error while fetching feed water data..", error);
    }
    return feedWaterData;
  };

  return { projectDetails, fetchWaterTypes, fetchWaterSubTypes, fetchFeedSetupData };
};

export default useFeedSetupDataHandler;
