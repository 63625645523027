import { useMemo } from "react";
import { useSelector } from "react-redux";

import { IXDStrings, IXMBStrings, IXStrings } from "@utils/StringConstants";

export const useIXTechnologyStrings = () => {
  const ixTreatment = useSelector(state => state.IXStore.data.treatmentName);

  const techStrings = useMemo(() => {
    if (ixTreatment === "IXD") {
      return { ...IXStrings, ...IXDStrings };
    } else {
      return { ...IXStrings, ...IXMBStrings };
    }
  }, [ixTreatment]);

  return techStrings;
};

export default useIXTechnologyStrings;
