import React from "react";

import loader from "../../../assets/images/loader.gif";

import "./index.css";

const CircularLoader = ({ size = "40px" }) => (
  <div className='loader'>
    <img src={loader} alt='Loading...' style={{ width: size, height: size }} />
  </div>
);

export default CircularLoader;
