import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useUFInputRanges } from "@hooks/useUFInputRanges";

import CustomRadio from "@common/styles/components/radios/CustomRadio";

import { updateUFStoreData } from "../../uf/UFSlice";

import TableWrapper from "./TableStyled";

export default function TableComponent() {
  const dispatch = useDispatch();
  const { getDefaultUFConfiguration } = useUFInputRanges();

  const { recommended_configs, sliderMin, sliderMax, data } = useSelector(state => state.UFStore);
  const [selectedConfig, setSelectedConfig] = useState();

  const handleConfigSelection = rowSelected => {
    const selectedRowOption = rowSelected.options;
    setSelectedConfig(selectedRowOption);
    const defaultConfig = getDefaultUFConfiguration(rowSelected);
    dispatch(
      updateUFStoreData({
        ...defaultConfig,
        isUfDataUpdated: true,
        isRecommendationCalucalting: true,
        calcEngineDataRefreshCount: 1,
      }),
    );
  };

  useEffect(() => {
    const { totalModules, onlineTrains, modulesPerTrain, modulesPerSkid } = data;
    let index;
    if (recommended_configs.length > 0) {
      const selectedRow = recommended_configs.find(
        item =>
          item.onlineUnits == onlineTrains &&
          item.modulesPerUnit == modulesPerTrain &&
          item.totalModules == totalModules &&
          (item.modulesPerRack == "-" || item.modulesPerRack == modulesPerSkid),
      );
      index = selectedRow?.options;
    }
    setSelectedConfig(index);
  }, [recommended_configs, data]);

  const headers = [
    "Select Configuration",
    "Online Units",
    "Standby Units",
    "Total Units",
    "Max Offline BW/CEB",
    "Modules/Rack",
    "Racks/Unit",
    "Modules/Unit",
    "Online Modules",
    "Total Modules",
  ];

  return (
    <TableWrapper>
      <table className='table '>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index} className='th'>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {recommended_configs
            ?.filter(item =>
              item.modulesPerRack == "-" ? true : item.modulesPerRack <= sliderMax && item.modulesPerRack >= sliderMin,
            )
            .map((config, index) => (
              <tr key={`config-row-${config.options}-${index}`} onClick={() => handleConfigSelection(config)}>
                <td className='td'>
                  <CustomRadio
                    type='radio'
                    id='selectedConfig'
                    name='selectedConfig'
                    key={`recommended-configuration-${config.options}`}
                    checked={selectedConfig == config.options}
                    onChange={() => handleConfigSelection(config)}
                  />
                </td>
                <td className='td'>{config.onlineUnits}</td>
                <td className='td'>{config.standByUnits}</td>
                <td className='td'>{config.totalUnits}</td>
                <td className='td'>{config.maxOfflineBW_CEB}</td>
                <td className='td'>{config.modulesPerRack}</td>
                <td className='td'>{config.racksPerUnit}</td>
                <td className='td'>{config.modulesPerUnit}</td>
                <td className='td'>{config.onlineModules}</td>
                <td className='td'>{config.totalModules}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </TableWrapper>
  );
}
