import API_URLS from "@constants/api.urls";

import { getRequest, postRequest } from "../baseApi";

export const getProjectNameStatus = parmas => getRequest(`${API_URLS.projectStatus}`, parmas);

export const downloadExportedProject = parms => {
  const config = {
    responseType: "file",
    headers: { accept: "application/json, text/plain, */*" },
  };
  return getRequest(`${API_URLS.exportProject}`, parms, config);
};

export const uploadProjectFile = data =>
  postRequest(API_URLS.importProject, data, null, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
