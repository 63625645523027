import { useEffect, useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { APP_TOAST_NOTIFICATION } from "@constants/message.constants";

import { getFolderName } from "@utils/appUtils";
import DupontLogger from "@utils/DupontLogger";

import { useToastNotification } from "@components/WPToastNotification";

import FolderIcon from "@common/icons/FolderIcon";
import MoreVertIcon from "@common/icons/MoreVertIcon";
import StyledCard from "@common/styles/components/cards/CustomCard";

import { useDeleteDataMutation, useLazyGetAllDataQuery } from "@services/apiConfig";

import { Folderbtnlist, FolderTempbtnlist, FolderupdateLoader, sortFolderData } from "../home/ViewAllFolderSlice";
import { FolderProjectID, updatetitle } from "../menu/FolderProjectSlice";
import { updateLeftpanel } from "../menu/SideMenuSlice";

import FolderUpdatePopup from "./folder/FolderUpdatePopup";
import DeletePopup from "./DeletePopup";
import { ViewAllFolderStyled } from "./ViewAllFolderStyled";

const ViewAllFolder = () => {
  // const userID = 1;
  const Logger = DupontLogger("ViewAllFolder");
  const { showSuccessNotification, showErrorNotification } = useToastNotification();
  const dispatch = useDispatch();
  const UserInfoStore = useSelector(state => state.userInfo.data);
  const userID = UserInfoStore ? UserInfoStore.UserId : 1;
  const [Cardstate, setCard] = useState([]);
  const StoreSidLeftpanel = useSelector(state => state.leftpanel.data);
  const FolderStoreData = useSelector(state => state.Folderview.data);
  const { pTitle } = useSelector(state => state.cardlist);
  const [deletePost, response] = useDeleteDataMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);

  const [getData, responsedata] = useLazyGetAllDataQuery();
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [folderId, setfolderId] = useState(null);

  useEffect(() => {
    if (responsedata.status !== "fulfilled") {
      getData(`${StoreSidLeftpanel}?userID=${userID}`);
    } else {
      dispatch(Folderbtnlist(responsedata.data));
      dispatch(FolderTempbtnlist(responsedata.data));
      dispatch(FolderupdateLoader(false));
      dispatch(sortFolderData({ flag: pTitle, data: responsedata.data }));
    }
  }, [responsedata, StoreSidLeftpanel]);

  useEffect(() => {
    LoadRecord();
  }, [FolderStoreData]);
  const LoadRecord = () => {
    setCard([]);
    if (FolderStoreData === 0) {
      <div>Loading....</div>;
    } else {
      setCard([]);
      FolderStoreData.forEach(element => {
        setCard(current => [
          ...current,
          {
            folderID: element.folderID,
            folderName: element.folderName,
            createdDate: element.createdDate,
            updatedDate: element.updatedDate,
            modifiedDuration: element.modifiedDuration,
            createdDateDuration: element.createdDateDuration,
          },
        ]);
      });
    }
  };

  const handleDeleteFolder = folderID => {
    setfolderId(folderID);
    setShowDeleteWarning(true);
  };

  const deleteFolder = () => {
    setShowDeleteWarning(false);
    const data = {
      Method: "masterdata/api/v1/DeleteFolder",
      userID: userID,
      folderID: folderId,
    };

    deletePost(data)
      .then(responsedata => {
        if (responsedata?.data?.responseCode === 200) {
          getData(`${StoreSidLeftpanel}?userID=${userID}`);
          showSuccessNotification(APP_TOAST_NOTIFICATION.FOLDER_DELETED);
        } else {
          showErrorNotification(APP_TOAST_NOTIFICATION.FOLDER_DELETE_FAILED);
        }
      })
      .catch(error => {
        Logger.log(error);
      });
  };

  const handleRenameFolder = folder => {
    setSelectedFolder(folder);
    setIsOpen(true);
    document.body.classList.add("disable");
  };

  useEffect(() => {
    if (responsedata.status === "fulfilled") {
      dispatch(Folderbtnlist(responsedata.data));
      dispatch(FolderTempbtnlist(responsedata.data));
      dispatch(FolderupdateLoader(false));
      dispatch(sortFolderData({ flag: pTitle, data: responsedata.data }));
    }
  }, [responsedata]);

  const btnFolderProject = (listName, e, Folder) => {
    dispatch(updateLeftpanel("masterdata/api/v1/FolderProject"));
    dispatch(FolderProjectID(e));
    dispatch(updatetitle(Folder));
    dispatch(FolderupdateLoader(false));
  };

  const handleCloseRenameProject = () => {
    setIsOpen(false);
  };

  const handleDeleteClose = () => {
    setShowDeleteWarning(false);
  };

  return (
    <div>
      <ViewAllFolderStyled>
        {!FolderStoreData.length ? (
          <h6 className='error-msg'>Records not found</h6>
        ) : (
          Cardstate?.map((u, i) => (
            <>
              <StyledCard className='folder_card' key={i}>
                <Card.Body>
                  <Card.Title className='d-flex justify-content-between'>
                    <h6 onClick={() => btnFolderProject("FolderProject", u.folderID, u.folderName)}>
                      {getFolderName(u.folderName)}
                    </h6>
                    <Dropdown className='project-option-dropdown'>
                      <Dropdown.Toggle variant=''>
                        <MoreVertIcon />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href='#/action-1'>
                          <a href='#' onClick={() => btnFolderProject("FolderProject", u.folderID, u.folderName)}>
                            Open Folder
                          </a>
                        </Dropdown.Item>
                        <Dropdown.Item href='#/action-3'>
                          <a href='#' onClick={() => handleDeleteFolder(u.folderID)}>
                            Delete this Folder
                          </a>
                        </Dropdown.Item>
                        <Dropdown.Item href='#/action-3' onClick={() => handleRenameFolder(u)}>
                          <a href='#'>Rename this Folder</a>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Card.Title>
                  <Card.Text>
                    <p>{u.modifiedDuration}</p>
                  </Card.Text>
                </Card.Body>
                <Card.Body
                  className='folder-card-body'
                  onClick={() => btnFolderProject("FolderProject", u.folderID, u.folderName)}
                >
                  <FolderIcon />
                </Card.Body>
              </StyledCard>
            </>
          ))
        )}
      </ViewAllFolderStyled>
      {isOpen && (
        <FolderUpdatePopup
          mode={"rename"}
          folder={selectedFolder}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          userID={userID}
          folders={Cardstate}
          handleCloseRenameProject={handleCloseRenameProject}
        />
      )}

      <DeletePopup
        show={showDeleteWarning}
        message='Are you sure you want to delete this Folder?'
        deleteRecord={deleteFolder}
        handleClose={handleDeleteClose}
      />
    </div>
  );
};

export default ViewAllFolder;
