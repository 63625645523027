import { WPUfConfiguringationOptionsTable } from "@features/report-new/uf/WPUfConfiguringationOptionsTable";
import { WPUfDesignWarningsTable } from "@features/report-new/uf/WPUfDesignWarningsTable";
import { WPUfFlowDetailsTable } from "@features/report-new/uf/WPUfFlowDetailsTable";
import { WPUfOperatingConditionsTable } from "@features/report-new/uf/WPUfOperatingConditionsTable";
import {
  WPUfDupontBwParametersTable,
  WPUfDupontCebParametersTable,
  WPUfDupontCipParametersTable,
  WPUfDupontMiniCipParametersTable,
} from "@features/report-new/uf/WPUfParametersTables/WPUfDupontParametersTables";
import {
  WPUfDupontUxaBwParametersTable,
  WPUfDupontUxaCebParametersTable,
  WPUfDupontUxaCipParametersTable,
  WPUfDupontUxaMiniCipParametersTable,
} from "@features/report-new/uf/WPUfParametersTables/WPUfDupontUxaParametersTables";
import {
  WPUfIngeBwParametersTable,
  WPUfIngeCebParametersTable,
  WPUfIngeCipParametersTable,
} from "@features/report-new/uf/WPUfParametersTables/WPUfIngeParametersTables";
import { WPUfPressureRatingsTable } from "@features/report-new/uf/WPUfPressureRatingsTable";
import { WPUfPumpHydraulicsTable } from "@features/report-new/uf/WPUfPumpHydraulicsTable";
import { WPUfStorageTanksTable } from "@features/report-new/uf/WPUfStorageTanksTable";
import { WPUfSystemOverview } from "@features/report-new/uf/WPUfSystemOverview";
import { WPUfSystemSizeModuleDetailsTable } from "@features/report-new/uf/WPUfSystemSizeModuleDetailsTable";
import { WPUfUtilityAndChemicalCost } from "@features/report-new/uf/WPUfUtilityAndChemicalCost";
import { WPUfWaterQualityTable } from "@features/report-new/uf/WPUfWaterQualityTable";

import { WPReportSystemDiagram } from "../WPReportSystemDiagram";

export const ufReportTableMapping = {
  sytem_diagram: WPReportSystemDiagram,
  uf_system_overview: WPUfSystemOverview,
  uf_operating_conditions: WPUfOperatingConditionsTable,
  uf_configuration_options: WPUfConfiguringationOptionsTable,
  uf_design_quality: WPUfDesignWarningsTable,
  flow_details: WPUfFlowDetailsTable,
  uf_water_quality: WPUfWaterQualityTable,
  uf_Power_ratings: WPUfPressureRatingsTable,
  pump_electrical: WPUfPumpHydraulicsTable,
  uf_Storage_tanks: WPUfStorageTanksTable,
  uf_system_module: WPUfSystemSizeModuleDetailsTable,
  uf_utility_chemicals_costs: WPUfUtilityAndChemicalCost,
};

// The Mapping of the UF parameters table based on the parameters table type
export const ufParametersTableMapping = {
  dupont: {
    uf_backwash_parameters: WPUfDupontBwParametersTable,
    uf_ceb_parameters: WPUfDupontCebParametersTable,
    uf_cip_parameters: WPUfDupontCipParametersTable,
    uf_mcip_parameters: WPUfDupontMiniCipParametersTable,
  },
  dupontUxa: {
    uf_backwash_parameters: WPUfDupontUxaBwParametersTable,
    uf_ceb_parameters: WPUfDupontUxaCebParametersTable,
    uf_cip_parameters: WPUfDupontUxaCipParametersTable,
    uf_mcip_parameters: WPUfDupontUxaMiniCipParametersTable,
  },
  inge: {
    uf_backwash_parameters: WPUfIngeBwParametersTable,
    uf_ceb_parameters: WPUfIngeCebParametersTable,
    uf_cip_parameters: WPUfIngeCipParametersTable,
  },
};
