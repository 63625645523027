/* eslint-disable max-len */
import React from "react";

const RecentProjectIcon = () => (
  <>
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_181191_35026)'>
        <path
          d='M7.37642 3.26468H6.62486C6.55611 3.26468 6.49986 3.32093 6.49986 3.38968V7.69124C6.49986 7.73187 6.51861 7.76937 6.55142 7.79281L9.13423 9.67874C9.19048 9.71937 9.26861 9.70843 9.30923 9.65218L9.75611 9.04281C9.7983 8.98499 9.7858 8.90687 9.72955 8.86781L7.50142 7.25687V3.38968C7.50142 3.32093 7.44517 3.26468 7.37642 3.26468ZM10.8108 4.43968L13.2608 5.03812C13.3389 5.05687 13.4155 4.99749 13.4155 4.91781L13.428 2.39437C13.428 2.28968 13.3077 2.23031 13.2264 2.29593L10.7639 4.21937C10.7453 4.23377 10.7311 4.25312 10.723 4.27522C10.7149 4.29731 10.7132 4.32124 10.7181 4.34425C10.723 4.36727 10.7343 4.38843 10.7507 4.40531C10.7671 4.4222 10.7879 4.43411 10.8108 4.43968V4.43968ZM13.4311 9.14437L12.5452 8.83968C12.5143 8.82909 12.4805 8.83101 12.451 8.84503C12.4215 8.85904 12.3987 8.88404 12.3874 8.91468C12.3577 8.99437 12.3264 9.07249 12.2936 9.15062C12.0155 9.80843 11.617 10.4006 11.1077 10.9084C10.604 11.4137 10.007 11.8165 9.34986 12.0944C8.66912 12.3822 7.93738 12.5299 7.1983 12.5287C6.45142 12.5287 5.72798 12.3834 5.04673 12.0944C4.38961 11.8165 3.79264 11.4137 3.28892 10.9084C2.78111 10.4006 2.38267 9.80843 2.10298 9.15062C1.81676 8.4695 1.67012 7.73787 1.67173 6.99906C1.67173 6.25218 1.81705 5.52718 2.10611 4.84593C2.38423 4.18812 2.78267 3.59593 3.29205 3.08812C3.79577 2.58284 4.39273 2.18008 5.04986 1.90218C5.72798 1.61312 6.45298 1.46781 7.19986 1.46781C7.94673 1.46781 8.67017 1.61312 9.35142 1.90218C10.0085 2.18008 10.6055 2.58284 11.1092 3.08812C11.2686 3.24906 11.4186 3.41624 11.5561 3.59281L12.4905 2.86156C11.2608 1.28968 9.34673 0.278743 7.19673 0.280306C3.45298 0.281868 0.446734 3.32249 0.484234 7.06781C0.521734 10.7475 3.51392 13.7178 7.19986 13.7178C10.0983 13.7178 12.567 11.8803 13.5077 9.30687C13.5311 9.24124 13.4967 9.16781 13.4311 9.14437V9.14437Z'
          fill='black'
        />
      </g>
      <defs>
        <clipPath id='clip0_181191_35026'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default RecentProjectIcon;
