import React from "react";

const SortDownArrow = () => (
  <>
    <svg width='10' height='14' viewBox='0 0 10 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1.06752 5.5L5 0.781025L8.93248 5.5H1.06752Z' stroke='#969696' />
      <path d='M8.93248 8.5L5 13.219L1.06752 8.5L8.93248 8.5Z' fill='#128370' stroke='#128370' />
    </svg>
  </>
);

export default SortDownArrow;
