import React from "react";
import PropTypes from "prop-types";

const WPAgGridTooltip = props => (
    <div className="ag-tooltip-container fs-12">
        <div>{props.value}</div>
    </div>
);

WPAgGridTooltip.propTypes = {
    color: PropTypes.string,
    value: PropTypes.any,
};

export default WPAgGridTooltip;