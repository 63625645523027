/* eslint-disable max-len */
import { Component, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import WaveProBrandLogo from "@components/WaveProBrandLogo";

import error404Image from "@common/assets/images/bubble-gum-error-404.gif";

import { setErrorTab } from "@features/feedwater/activitymonitor/tabChangeSlice";

import HeaderStyled from "../header/HeaderStyled";
import CustomHeading from "../styles/components/headings/CustomHeading";
import CustomLabel from "../styles/components/headings/CustomLabel";
import { colors, fontStyles } from "../styles/Theme";

import ErrorImg from "./ErrorImg";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, type: "", status: 420, message: "" };
  }

  static getDerivedStateFromError(error) {
    const { status, message, type } = error;
    return {
      hasError: true,
      type: status === "FETCH_ERROR" ? "No Internet" : type,
      status: status,
      message: message,
      error,
    };
  }

  /**
   * We need to check this functionlaity with the backend team. For now we are commenting this code
   * as it is failing always.
   */
  // componentDidCatch(error, errorInfo) {
  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //       logMessage: error.message,
  //       logType: `${error.status}_${error.type}`,
  //       methodName: errorInfo.componentStack,
  //     }),
  //   };
  //   fetch(`${process.env.REACT_APP_API_PATH}/masterdata/api/v1/UILogs`, requestOptions);
  // }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.state.type === "ApiError") {
        return <ErrorPage status={this.state.status} message={this.state.message} />;
      }

      if (this.state.type === "AuthenticationError") {
        return <ErrorPage status={this.state.status} message={this.state.message} />;
      }
      if (this.state.type === "No Internet") {
        return <ErrorPage label={"Sorry this page is not available due to no internet connectivity."} />;
      }
      return <ErrorPage status={"Oops!"} message={"Looks like something went wrong"} />;
    }

    return this.props.children;
  }
}

export const ErrorPage = ({ status, message, label }) => {
  const dispatch = useDispatch();
  const handleNavigate = () => {
    window.location.href = "/home";
  };
  useEffect(() => {
    dispatch(setErrorTab());
  }, []);
  return (
    <>
      <HeaderStyled>
        <div className='global_header'>
          <WaveProBrandLogo handleNavigate={handleNavigate} />
          <div className='global_header_selected_project_name'>
            <h4 className='selected-project-title'>
              {location.state?.title.length > 30
                ? location.state?.title
                  ? `${location.state.title.substring(0, 30)}...}`
                  : ""
                : location.state?.title
                  ? `${location.state.title}}`
                  : ""}
            </h4>
          </div>
        </div>
      </HeaderStyled>
      <ErrorPageStyle>
        <section className='page_404'>
          <div className='error-display'>
            <div
              className='four_zero_four_bg'
              style={{
                background: `url(${status ? error404Image : ""}) 50% no-repeat`,
              }}
            >
              {status ? (
                <CustomHeading
                  fontFamily='DiodrumSemiBold'
                  fontSize='64px'
                  fontWeight='600'
                  color={colors.Black}
                  className='text-center margin-top-50'
                  label={status}
                />
              ) : (
                <ErrorImg />
              )}
              {/* <img src="./errorPage.gif" alt="loading..." /> */}
            </div>
            <div className='contant_box_404'>
              <CustomHeading
                className='error_common_message text-center'
                fontFamily='DiodrumRegular'
                fontSize='64px'
                fontWeight='400'
                lineHeight='64px'
                color={colors.Black}
                label={label || "Sorry, this page isn’t available"}
              />

              <CustomLabel className='error_common_message_sub_heading' label={message} />
              {status != 403 ? (
                <div className='navigate_to_home'>
                  <a href='/home' className='link_404'>
                    Go to Home
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        </section>
      </ErrorPageStyle>
    </>
  );
};

const ErrorPageStyle = styled.section`
  min-height: 85vh;
  .page_404 {
    min-height: 75vh;
    padding: 0px 0px;
  }

  .error-display {
  }
  .four_zero_four_bg {
    height: 400px;
    background-position: center;
    display: flex;
    justify-content: center;
  }

  .four_zero_four_bg h1 {
    font-size: 80px;
  }

  .four_zero_four_bg h3 {
    font-size: 80px;
  }

  .link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #007672;
    border-radius: 20px;
    margin: 20px 0;
    display: inline-block;
    ${fontStyles.diodrum14SemiBold}
    text-decoration: none;
  }
  .margin-top-50 {
    margin-top: 50px;
  }
  .contant_box_404 {
    margin-top: -50px;
    .error_common_message,
    .error_common_message_sub_heading {
      display: flex;
      justify-content: center;
    }
    .navigate_to_home {
      display: flex;
      justify-content: center;
    }
    .text-center {
      text-align: center;
    }
  }
`;
