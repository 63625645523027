import { reportStrings } from "@constants/report.strings";
import { UNIT_TYPES } from "@constants/units.constant";

import { useReportUtils } from "@features/report-new/useReportUtils";

import { WPUfSystemOverviewRows } from "./WPUfSystemOverViewTable.constants";

export const useWPUfSystemOverViewTableData = () => {
  const {
    ufReportRespData,
    formatLabelValue,
    formatKeyValue,
    formatUnitLabel,
    formatValue,
    formatConvertValue,
    getUnitLabelValue,
    isSpecialFeatureVisible,
  } = useReportUtils();

  const formatValueAtTemperature = (value, unit, temperatureValue) =>
    formatConvertValue(value, unit) +
    " @ " +
    formatConvertValue(temperatureValue, UNIT_TYPES.TEMPERATURE) +
    " " +
    getUnitLabelValue({ unit: UNIT_TYPES.TEMPERATURE, returnValue: true });

  const convertToSystemOverviewTableData = () => {
    const { uf_system_overview } = ufReportRespData;
    const {
      module_type,
      units_one: { online, standby, redundant } = {},
      modules: { per_unit, total } = {},
      system_flow_rate: { gross_feed: systemFlowGrossFeed, net_product: systemFlowNetProduct } = {},
      unit_flow_rate: { gross_feed: unitFlowGrossFeed, net_product: unitFlowNetProduct } = {},
      system_recovery,
      utility_water = {},
      TMP: { TMP_min, TMP_design } = {},
    } = uf_system_overview || {};

    const valueRows = [
      { col3: module_type },
      {
        col3: formatLabelValue(reportStrings.online, online, null, 0),
        col4: formatLabelValue(reportStrings.standBy, standby, null, 0),
      },
      {
        col4: formatLabelValue(reportStrings.redundant, redundant, null, 0),
      },
      {
        col3: formatLabelValue(reportStrings.perUnit, per_unit, null, 0),
        col4: formatLabelValue(reportStrings.total, total, null, 0),
      },
      {
        col3: formatLabelValue(reportStrings.grossFeed, systemFlowGrossFeed, UNIT_TYPES.FLOW),
        col4: formatLabelValue(reportStrings.netProduct, systemFlowNetProduct, UNIT_TYPES.FLOW),
      },
      {
        col3: formatLabelValue(reportStrings.grossFeed, unitFlowGrossFeed, UNIT_TYPES.FLOW),
        col4: formatLabelValue(reportStrings.netProduct, unitFlowNetProduct, UNIT_TYPES.FLOW),
      },
      {
        col3: formatValue(system_recovery * 100),
      },
      {
        col3: formatValueAtTemperature(TMP_min?.tmp_init_min, UNIT_TYPES.PRESSURE, TMP_min?.tmp_min),
        col4: formatValueAtTemperature(TMP_design?.tmp_init_design, UNIT_TYPES.PRESSURE, TMP_design?.tmp_design),
      },
      {
        col3: formatKeyValue(reportStrings.forwardFlush, utility_water?.forward_flush),
        col4: formatKeyValue(reportStrings.backwash, utility_water?.backwash),
      },
      {
        col3: isSpecialFeatureVisible("CEB") ? formatKeyValue(reportStrings.ceb, utility_water?.CEB) : "",
        col4: formatKeyValue(reportStrings.cip, utility_water?.CIP),
      },
    ];

    const data = WPUfSystemOverviewRows.map((row, index) => {
      const singleRowData = valueRows[index];
      singleRowData.col1 = reportStrings[row[0]];
      singleRowData.col2 = formatUnitLabel(row[1]);
      return singleRowData;
    });

    return data;
  };

  return { convertToSystemOverviewTableData };
};
