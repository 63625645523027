import React from "react";
import styled from "styled-components";

import WPHeading from "@components/WPHeading";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  height: inherit;
  .box {
    padding: 4px 8px 2px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--du-color-grey);
    color:var(--du-color-black_07);
  }
`;

const ChemicalCategroyRenderer = props => (
    <StyledDiv>
        <WPHeading secondary size='xSmall' className="box">
            {props.value}
        </WPHeading>
    </StyledDiv>
);

export default ChemicalCategroyRenderer;
