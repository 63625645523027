import React from "react";

import IconButton from "@components/IconButton";
import { DeleteIcon, EditIcon } from "@components/SVGs";

import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";

const ChemicalActionBtnRenderer = ({ data, context }) => {

    const handleBtnClick = ({ target: { name } }) => {
        if (context.onActionBtnClick) {
            context.onActionBtnClick({ type: name, data });
        }
    };

    return <div className="d-flex">
        <IconButton id={`edit_chemical_${data.id}_btn`} name="edit-chemical-btn" tooltip={CHEMICALS_LIBRARY_STRINGS.edit} aria-label="edit" onClick={handleBtnClick}>
            <EditIcon />
        </IconButton>
        <IconButton id={`delete_chemical_${data.id}_btn`} name="delete-chemical-btn" disabled={data.isSystem} tooltip={CHEMICALS_LIBRARY_STRINGS.delete} aria-label="delete" onClick={handleBtnClick}>
            <DeleteIcon disabled={data.isSystem} />
        </IconButton>
    </div>;
};

export default ChemicalActionBtnRenderer;