import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { WPUfDupontUxaCebParametersTableColumns as columns } from "./WPUfDupontUxaCebParametersTable.constants";

export const WPUfDupontUxaCebParametersTable = () => {
  const { uf_ceb_parameters: rows } = useSelector(state => state.report.ufReportTableData);

  return (
    <WPTable
      sectionHeader={reportStrings.cebUxaParameters}
      columns={columns}
      rows={rows}
      remarksList={[
        reportStrings.cebFrequencyRemark,
        reportStrings.cebStepRemark.replace("${steps}", "2-3-4-5-1"),
        reportStrings.oValueRemark,
      ]}
      footNotesList={[
        reportStrings.cebFootNote1,
        reportStrings.airFlowFootNote,
        reportStrings.forwardFlushFlowFootNote,
        reportStrings.mayWastePermeateRemark,
      ]}
    />
  );
};
