import React, { memo, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import PropTypes from "prop-types";

import WPAgGridTooltip from "./WPAgGridTooltip";

import "./WPAgGrid.scss";

const WPAgGrid = ({
  gridRef,
  context,
  rowData,
  getRowId,
  columnDefs,
  onGridReady,
  tableHeight,
  rowSelection,
  defaultColDef,
  onRowSelected,
  onRowDoubleClicked,
  gridOptions,
  onSelectionChanged,
  getRowClass,
}) => {
  const tableDefaultColDef = useMemo(
    () => ({
      ...defaultColDef,
      tooltipComponent: WPAgGridTooltip,
      suppressMovable: true,
      tooltipValueGetter: ({ colDef, value, valueFormatted }) => (colDef.tooltip ? (valueFormatted ?? value) : null),
      sortable: false,
    }),
    [defaultColDef, columnDefs],
  );

  return (
    <div className='ag-theme-alpine wp-ag-grid' style={{ height: tableHeight }}>
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        context={context}
        onGridReady={onGridReady}
        getRowId={getRowId}
        defaultColDef={tableDefaultColDef}
        rowSelection={rowSelection}
        onRowSelected={onRowSelected}
        onRowDoubleClicked={onRowDoubleClicked}
        onSelectionChanged={onSelectionChanged}
        gridOptions={gridOptions}
        tooltipShowDelay={500}
        tooltipMouseTrack={true}
        getRowClass={getRowClass}
      />
    </div>
  );
};

WPAgGrid.propTypes = {
  getRowId: PropTypes.func,
  rowData: PropTypes.array,
  gridRef: PropTypes.object,
  context: PropTypes.object,
  columnDefs: PropTypes.array,
  onGridReady: PropTypes.func,
  onRowDragEnd: PropTypes.func,
  onRowSelected: PropTypes.func,
  gridOptions: PropTypes.object,
  tableHeight: PropTypes.string,
  rowSelection: PropTypes.string || PropTypes.object,
  defaultColDef: PropTypes.object,
  onRowDoubleClicked: PropTypes.func,
  onSelectionChanged: PropTypes.func,
};

WPAgGrid.defaultProps = {
  rowData: [],
  columnDefs: [],
  tableHeight: "300px",
  rowSelection: "single",
};

export default memo(WPAgGrid);
