import { useSelector } from "react-redux";

export const useWPUfAdditionalFeedWaterInfoTableData = () => {
  const FeedStreamData = useSelector(state => state.Feedsetupdetailsdatapanel.streamData);

  const { additionalFeedWaterInfo, streamName } = FeedStreamData.lstrequestsavefeedwater[0]?.streams[0] || {};

  const convertToUfAdditionalWaterInfoData = () => {
    if (!additionalFeedWaterInfo) {
      return [];
    }
    return [
      {
        col1: streamName || "Stream 1",
        col2: additionalFeedWaterInfo,
      },
    ];
  };

  return { convertToUfAdditionalWaterInfoData };
};
