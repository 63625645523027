import styled from "styled-components";

import { typographyVariants, WPTypogrpahy } from "@components/WPTypography";

import { colors } from "@common/styles/Theme";

const WPReportSectionHeaderStyled = styled.div`
  background: ${colors.GreyE1};
  padding: 8px 10px 8px 6px;
  margin-top: 24px;
  page-break-inside: avoid;
`;

export const WPReportSectionHeader = ({ text }) => (
  <WPReportSectionHeaderStyled>
    <WPTypogrpahy variant={typographyVariants.reportSectionHeader} text={text} />
  </WPReportSectionHeaderStyled>
);
