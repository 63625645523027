import React from "react";

const ErrorSearchIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'>
      <path
        d='M21.6521 20.1779L14.6959 13.2217C15.7753 11.8262 16.3593 10.1199 16.3593 8.32527C16.3593 6.17705 15.5209 4.16277 14.0048 2.64402C12.4887 1.12527 10.4691 0.289551 8.32356 0.289551C6.17802 0.289551 4.15838 1.12794 2.64231 2.64402C1.12356 4.16009 0.287842 6.17705 0.287842 8.32527C0.287842 10.4708 1.12623 12.4904 2.64231 14.0065C4.15838 15.5253 6.17534 16.361 8.32356 16.361C10.1182 16.361 11.8218 15.7771 13.2173 14.7003L20.1736 21.6538C20.194 21.6742 20.2182 21.6904 20.2448 21.7015C20.2715 21.7125 20.3001 21.7182 20.3289 21.7182C20.3578 21.7182 20.3863 21.7125 20.413 21.7015C20.4397 21.6904 20.4639 21.6742 20.4843 21.6538L21.6521 20.4887C21.6725 20.4683 21.6887 20.444 21.6998 20.4174C21.7108 20.3907 21.7165 20.3622 21.7165 20.3333C21.7165 20.3044 21.7108 20.2759 21.6998 20.2492C21.6887 20.2226 21.6725 20.1983 21.6521 20.1779ZM12.5664 12.5681C11.4307 13.7012 9.92534 14.3253 8.32356 14.3253C6.72177 14.3253 5.21641 13.7012 4.0807 12.5681C2.94766 11.4324 2.32356 9.92705 2.32356 8.32527C2.32356 6.72348 2.94766 5.21544 4.0807 4.08241C5.21641 2.94937 6.72177 2.32527 8.32356 2.32527C9.92534 2.32527 11.4334 2.94669 12.5664 4.08241C13.6994 5.21812 14.3236 6.72348 14.3236 8.32527C14.3236 9.92705 13.6994 11.4351 12.5664 12.5681Z'
        fill='#E4001C'
      />
    </svg>
  </>
);

export default ErrorSearchIcon;
