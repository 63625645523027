import React from "react";
import { Dropdown } from "react-bootstrap";

import { getFolderName } from "@utils/appUtils";
import { FolderStrings } from "@utils/StringConstants";

import FolderIcon from "@common/icons/FolderIcon";
import MoreVertIcon from "@common/icons/MoreVertIcon";
import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomHeading from "@common/styles/components/headings/CustomHeading";

import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";

const DeleteFolderListItem = ({ item, handleFolderPermanentDelete, handleRestoreFolder }) => (
  <StyledCard className='list-view'>
    <div className='star-icon project_favorite folder-icon-list-item'>
      <FolderIcon />
    </div>
    <div className='project_name'>
      <CustomHeading fontFamily='NotoSansSemiBold' fontWeight='700'>
        {getFolderName(item.folderNameDeleted)}
      </CustomHeading>
    </div>
    <div className='project_created_date'>
      <CustomHeading fontFamily='NotoSansRegular'>{item.createDate}</CustomHeading>
    </div>
    <div className='project_modified_date'>
      <CustomHeading fontFamily='NotoSansRegular'>{item.folderDeletedDuration}</CustomHeading>
    </div>
    <div className='project-tags sample-project-tag'></div>
    <div className='project-option'>
      <Dropdown className='project-option-dropdown'>
        <Dropdown.Toggle variant=''>
          <MoreVertIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <>
            <Dropdown.Item onClick={() => handleRestoreFolder(item.folderIdDeleted)}>
              {FolderStrings.restoreFolder}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleFolderPermanentDelete(item.folderIdDeleted)}>
              Permanently Delete
            </Dropdown.Item>
          </>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  </StyledCard>
);

export default DeleteFolderListItem;
