import { useReportUtils } from "@features/report-new/useReportUtils";

import {
  chemicalFlowUnit,
  meteringPumpLabel,
  WPUfFlowChemicalKeys,
  WPUfFlowDetailsTableRowsData as rowsData,
  WPUfFlowProcessStepLabels,
} from "./WPUfFlowDetailsTable.constants";

export const useWPUfFlowDetailsTableData = () => {
  const { ufReportRespData, formatUnitLabel, formatConvertValue, formatLabel, formatChemicalNameConc } =
    useReportUtils();

  const { flow_details } = ufReportRespData || {};

  const convertToUfFlowDetailsTableData = () => {
    const data = Object.entries(rowsData).reduce((acc, curr) => {
      const [key, row] = curr;
      const { label, unit, indentLevel } = row;
      const { peak_flow, avg_flow, display = true } = flow_details[key] || {};
      if (!display) return acc;
      acc.push({
        col1: formatLabel(label, indentLevel),
        col2: formatUnitLabel(unit),
        col3: formatConvertValue(peak_flow, unit),
        col4: formatConvertValue(avg_flow, unit),
      });
      return acc;
    }, []);

    // chemical metering pump flow row data
    const chemicalFlowData = Object.entries(WPUfFlowProcessStepLabels).reduce((acc, curr) => {
      const [LabelKey, labelValue] = curr;
      const arr = [];

      WPUfFlowChemicalKeys[LabelKey].forEach(respKey => {
        const { peak_flow, avg_flow, name, concentration, display = true } = flow_details[respKey] || {};
        if (display) {
          arr.push({
            col1: `${labelValue} ${formatChemicalNameConc(name, concentration)} ${meteringPumpLabel}`,
            col2: formatUnitLabel(chemicalFlowUnit),
            col3: formatConvertValue(peak_flow, chemicalFlowUnit),
            col4: formatConvertValue(avg_flow, chemicalFlowUnit),
          });
        }
      });

      // concatinating all the chemical metering pump
      acc.push(...arr);
      return acc;
    }, []);

    data.push(...chemicalFlowData);
    return data;
  };

  return { convertToUfFlowDetailsTableData };
};
