import { useCallback, useState } from "react";
import styled from "styled-components";

import IconButton from "@components/IconButton";
import { ChemicalsIcon, CostsIcon } from "@components/SVGs";

import CaseManager from "@features/cases/CaseManager";
import { ChemicalLibraryDialog, ProjectCostDialog } from "@features/chemicalLibrary";

const ActionBtnContainer = styled.div`
  position: absolute;
  left: 50%;
  display: flex;
  top:5px;
`;

const IconBtnWithLabel = styled.div`
  display: flex;
  flex-flow: column;
  padding: 5px;
  align-items: center;
  color: rgba(255, 255, 255);

  span {
    font-size: 14px;
    font-family: NotoSans;
  }

  &:hover {
    color: var(--du-color-primary-aquamarine);
    background: var(--du-color-primary-light);
    border: 0px solid var(--du-color-primary-light);
    border-radius: 5px;

    svg,
    path {
      fill: var(--du-color-primary-aquamarine);
    }
  }
`;

const ButtonsConfigList = [
  { id: "chemicalsBtn", label: "Chemicals", icon: <ChemicalsIcon /> },
  { id: "costsBtn", label: "Costs", icon: <CostsIcon /> },
  { id: "caseManagement", isCustom: true, label: "" }
];

const RibbonIconButtons = () => {
  const [activeAction, setActiveAction] = useState(null);

  const handleButtonClick = useCallback(({ target }) => setActiveAction(target.id), []);

  const handleDialogClose = useCallback(event => {
    const { openChemicalLib } = event || {};
    openChemicalLib ? setActiveAction("chemicalsBtn") : setActiveAction(null);
  }, [setActiveAction]);

  return (
    <>
      <ActionBtnContainer>
        {ButtonsConfigList.map((item, index) => (
          item.isCustom ? (<CaseManager key={item.id} />) : (
            <IconButton id={item.id} key={index} onClick={handleButtonClick}>
              <IconBtnWithLabel>
                {item.icon}
                <span>{item.label}</span>
              </IconBtnWithLabel>
            </IconButton>
          )
        ))}
      </ActionBtnContainer>
      {activeAction === "chemicalsBtn" && <ChemicalLibraryDialog onClose={handleDialogClose} />}
      {activeAction === "costsBtn" && <ProjectCostDialog onClose={handleDialogClose} />}
    </>
  );
};

export default RibbonIconButtons;
