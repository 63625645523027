import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import CustomModal from "@components/CustomModal";
import IconButton from "@components/IconButton";
import { BackIcon } from "@components/SVGs";
import WPHeading from "@components/WPHeading";

import CHEMICALS_LIBRARY_STRINGS, { CHEMICAL_LIBRARY_HELP_DATA } from "../chemicalLibrary.strings";

// Styled component for the help dialog container
const ChemicalHelpDlgContainer = styled.div`
  margin: 16px 0;

  .header {
    margin-bottom: 6px;
    font-family: NotoSans;
  }

  .description {
    font-family: NotoSans;
    color: var(--Black, rgba(0, 0, 0, 0.70)) !important;
    white-space: normal;
  }
`;

/**
 * ChemicalLibraryHelp component
 * This component renders a modal with help information for the Chemical Library.
 * It includes a header with a back button and a list of help sections.
 */
const ChemicalLibraryHelp = ({ onClose }) => {
    // Memoize the header content to avoid unnecessary re-renders
    const headerContent = useMemo(() => (
        <div>
            <IconButton
                tooltip={CHEMICALS_LIBRARY_STRINGS.backBtnTitle}
                onClick={onClose}
            >
                <BackIcon />
            </IconButton>
            {CHEMICALS_LIBRARY_STRINGS.helpDialogTitle}
        </div>
    ), [onClose]);

    return (
        <CustomModal
            header={headerContent}
            confirmBtn="Okay"
            onModalClose={onClose}
            width="600px"
        >
            {CHEMICAL_LIBRARY_HELP_DATA.map((item, index) => (
                <ChemicalHelpDlgContainer id={`help-${index}`} key={`help-${index}`}>
                    <WPHeading size="xSmall" className="header">{item.section}</WPHeading>
                    <WPHeading secondary size="xSmall" className="description">{item.description}</WPHeading>
                </ChemicalHelpDlgContainer>
            ))}
        </CustomModal>
    );
};

ChemicalLibraryHelp.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default ChemicalLibraryHelp;