import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateGlobalData } from "@common/GlobalDataSlice";

export const useFullScreenLoader = () => {
  const dispatch = useDispatch();
  const { fullScreenLoaderMessage } = useSelector(state => state.globalAppData);

  const showFullScreenLoader = message => {
    dispatch(updateGlobalData({ type: "fullScreenLoaderMessage", value: message }));
  };

  const hideFullScreenLoader = () => {
    dispatch(updateGlobalData({ type: "fullScreenLoaderMessage", value: null }));
  };

  const isFullScreenLoaderVisible = useMemo(() => Boolean(fullScreenLoaderMessage), [fullScreenLoaderMessage]);

  return {
    isFullScreenLoaderVisible,
    fullScreenLoaderMessage,
    showFullScreenLoader,
    hideFullScreenLoader,
  };
};
