/* eslint-disable max-len */
import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";

import DeleteProjectSuccessfulPopup from "@features/home/DeleteProjectSuccessfulPopup";

import WarningIcon from "./icons/WarningIcon";
import StandardPrimaryButton from "./styles/components/buttons/standard/StandardPrimaryButton";
import StandardSecondaryButton from "./styles/components/buttons/standard/StandardSecondaryButton";
import CustomHeading from "./styles/components/headings/CustomHeading";
import StyledModal from "./styles/components/modals/CustomModal";
import { colors } from "./styles/Theme";
const ConfirmationPopup = props => {
  const [deleteSuccess] = useState(false);

  return (
    <>
      <StyledModal
        {...props}
        show={props.show}
        keyboard='false'
        centered
        backdrop='static'
        isWarningPopUp={true}
        maxWidth='416px'
      >
        <Modal.Body>
          <div className='warning-pop-up'>
            <div>
              <WarningIcon />
            </div>
            <div>
              <CustomHeading
                fontFamily='DiodrumSemiBold'
                fontSize='16px'
                fontWeight='600'
                color={colors.Black}
                label={props.lblMessage}
              />
              <CustomHeading fontFamily='NotoSansRegular' fontSize='16px' fontWeight='400' color={colors.Black} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <StandardSecondaryButton className='' id='canBtn' onClick={() => props.onHide("Cancel")} label='Cancel' />
          <StandardPrimaryButton label='OK' className='' onClick={() => props.onHide("OK")} />
          <DeleteProjectSuccessfulPopup show={deleteSuccess} propvalue={props} />
        </Modal.Footer>
      </StyledModal>
    </>
  );
};

export default ConfirmationPopup;
