import React, { useState } from "react";

import { CustomModalButtons } from "@constants/global.constants";

import WPButton from "@components/WPButton";

import StandardSecondarySButton from "@common/styles/components/buttons/standard/StandardSecondarySButton";

import { FEED_STRINGS } from "../constants/FeedWaterConstants";

import WaterLibraryModal from ".";

const WaterLibraryButtons = () => {
  const [showSaveToWaterLibrary, setShowSaveToWaterLibrary] = useState(false);
  const [openWaterLibrary, setOpenWaterLibrary] = useState(false);

  const handleSaveToWaterLibrary = () => {
    setShowSaveToWaterLibrary(true);
  };

  const handleOpenWaterLibrary = () => {
    setOpenWaterLibrary(true);
  };

  const handleOpenDialogClose = () => {
    setOpenWaterLibrary(false);
  };

  const handleSaveDialogClose = () => {
    setShowSaveToWaterLibrary(false);
  };

  return (
    <>
      <WPButton variant='outlined' id={CustomModalButtons.CONFIRM} onClick={handleOpenWaterLibrary} size='small'>
        {FEED_STRINGS.OPEN_WATER_LIBRARY}
      </WPButton>
      {openWaterLibrary && <WaterLibraryModal close={handleOpenDialogClose} actionType='open' />}
      <WPButton variant='outlined' id={CustomModalButtons.CONFIRM} onClick={handleSaveToWaterLibrary} size='small'>
        {FEED_STRINGS.saveToWaterLibrary}
      </WPButton>
      {showSaveToWaterLibrary && <WaterLibraryModal close={handleSaveDialogClose} actionType='save' />}
    </>
  );
};

export default WaterLibraryButtons;
