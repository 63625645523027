import { useMemo } from "react";
import PropTypes from "prop-types";

import IconButton from "@components/IconButton";
import { BackIcon } from "@components/SVGs";
import WPButton from "@components/WPButton";
import WPHeading from "@components/WPHeading";

import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";

import { CHEMICAL_USAGE_FOOTER_BTNS } from "./ChemicalLibraryChemicalUsage.const";

export const ChemicalUsageDlgHeader = ({ onClose, actionType }) => {
    const headerTitle = useMemo(() => {
        const { reviewBeforeAction, deleting, updating } = CHEMICALS_LIBRARY_STRINGS;
        const action = actionType === "delete-chemical-btn" ? deleting : updating;
        return reviewBeforeAction.replace("{%}", action);
    }, [actionType]);

    return (
        <div>
            <IconButton
                id='chemical-usage-dlg-back-btn'
                name='chemical-usage-dlg-back-btn'
                tooltip={CHEMICALS_LIBRARY_STRINGS.backBtnTitle}
                onClick={onClose}
            >
                <BackIcon />
            </IconButton>
            {headerTitle}
        </div>
    );
};

ChemicalUsageDlgHeader.propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

export const ChemicalUsageDlgFooter = ({ onDialogClose, isConfirmDisable }) => {
    const footerButtons = useMemo(
        () =>
            CHEMICAL_USAGE_FOOTER_BTNS.map(btnConfig => {
                const isDisabled = isConfirmDisable && btnConfig.id === "deleteProjects";
                return (
                    <WPButton
                        type='danger'
                        key={btnConfig.id}
                        id={btnConfig.id}
                        name={btnConfig.id}
                        variant={btnConfig.variant}
                        onClick={onDialogClose}
                        disabled={isDisabled}
                    >
                        {btnConfig.label}
                    </WPButton>
                );
            }),
        [onDialogClose, isConfirmDisable],
    );

    return footerButtons;
};

ChemicalUsageDlgFooter.propTypes = {
    onDialogClose: PropTypes.func.isRequired,
    isConfirmDisable: PropTypes.bool.isRequired,
};

export const ChemicalUsageErrorBanner = ({ chemical }) => (
    <div className='chemical-error-banner'>
        <WPHeading secondary type='default' className='chemical-name ' size='small'>
            {CHEMICALS_LIBRARY_STRINGS.chemicalAlreadyUsed.replace("{%}", chemical)}
        </WPHeading>
        <WPHeading secondary type='default' size='small'>
            {CHEMICALS_LIBRARY_STRINGS.deleteProjectWarningMsg}
        </WPHeading>
    </div>
);
