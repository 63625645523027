/* eslint-disable max-len */
import React from "react";

const ArrowDownBlackIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='12' viewBox='0 0 14 12' fill='none'>
      <path
        d='M12.4688 5.28684H11.2032C11.1313 5.28684 11.0626 5.31059 11.0141 5.35216L7.59383 8.34822V1.66141C7.59383 1.60916 7.53758 1.56641 7.46883 1.56641H6.53133C6.46258 1.56641 6.40633 1.60916 6.40633 1.66141V8.34822L2.98602 5.35216C2.93914 5.31059 2.87039 5.28684 2.79695 5.28684H1.53133C1.42508 5.28684 1.36727 5.38303 1.43758 5.44359L6.62352 9.98578C6.6704 10.0269 6.72835 10.0598 6.79345 10.0824C6.85856 10.105 6.92929 10.1167 7.00086 10.1167C7.07243 10.1167 7.14316 10.105 7.20827 10.0824C7.27337 10.0598 7.33133 10.0269 7.37821 9.98578L12.5626 5.44359C12.6329 5.38184 12.5751 5.28684 12.4688 5.28684Z'
        fill='black'
        fillOpacity='0.85'
      />
    </svg>
  </>
);

export default ArrowDownBlackIcon;
