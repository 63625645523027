import { UFDesignDiagram } from "@features/feedwater/uf/UFSystemDesignDiagram";

import { WPReportSectionHeader } from "../WPReportSectionHeader";

/*currently directly rendering UF Design Diagram
 This component will be used to render other technology Design Diagram in the report */
export const WPReportDesignDiagram = () => (
  <div className='no-page-break'>
    <WPReportSectionHeader text='UF System Overview' />
    <div className='p-3'>
      <UFDesignDiagram />
    </div>
  </div>
);
