import { CHEMICAL_SYMBOLS } from "@constants/chemical-symbol.constants";
import { reportStrings } from "@constants/report.strings";
import { UNIT_TYPES, UNITS } from "@constants/units.constant";

import { CHEMICALS_LIST } from "@features/chemicalLibrary/chemicalLibrary.strings";

export const WPUfStorageTanksTableColumns = [
  {
    field: "label",
    rowHeader: true,
    headerName: reportStrings.name,
  },
  {
    field: "bulk_conc",
    headerName: reportStrings.bulkConc,
  },
  {
    field: "min_recommended_volume",
    headerName: reportStrings.minRecommVolume,
  },
];

export const WPUfStorageTanksTableRowsData = {
  Water: {
    label: reportStrings.water + "<sup>a</sup>",
  },
  Min_CIP_Tank: {
    label: reportStrings.miniCipTank,
    specialFeature: "mCIP",
  },
  CIP_Tank: {
    label: reportStrings.cipTank,
  },
  // custom row not present in api response
  chemical_storage: {
    label: reportStrings.chemicalStorage + "<sup>b</sup>",
  },
  Ferric_chloride: {
    chemical: CHEMICALS_LIST.ferricChloride,
    [CHEMICAL_SYMBOLS.ferricIon]: CHEMICALS_LIST.ferricIon,
  },
  Citric_acid: {
    chemical: CHEMICALS_LIST.citricAcid,
  },
  Hydrochloric_acid: {
    chemical: CHEMICALS_LIST.hydrochloricAcid,
  },
  Oxalic_acid: {
    chemical: CHEMICALS_LIST.oxalicAcid,
  },
  Poly_aluminium_chloride: {
    chemical: CHEMICALS_LIST.polyaluminiumChloride,
    [CHEMICAL_SYMBOLS.aluminiumIon]: CHEMICALS_LIST.polyaluminiumIon,
  },
  Sulfuric_acid: {
    chemical: CHEMICALS_LIST.sulfuricAcid,
  },
  Sodium_hypochlorite: {
    chemical: CHEMICALS_LIST.sodiumHypochlorite,
  },
  Sodium_hydroxide: {
    chemical: CHEMICALS_LIST.sodiumHydroxide,
  },
  Sodium_lauryl_sulfate: {
    chemical: CHEMICALS_LIST.sodiumLaurylSulfate,
  },
};

export const WPUfStorageTanksTableUnitMapping = {
  bulk_conc: UNITS.percentage,
  min_recommended_volume: UNIT_TYPES.VOLUME_SOLUTION,
};
