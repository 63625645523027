import { useEffect, useMemo, useState } from "react";

import WPDropdown from "@components/WPDropdown";
import WPFormLabel from "@components/WPFormLabel";

import ErrorMessage from "@common/styles/components/headings/ErrorMessage";
import InputWithText from "@common/styles/components/inputs/InputWithText";

export const ChemicalLibInputBox = ({ control, onControlChange, onBlur }) => {
    const [inputConfig, setInputConfig] = useState({});
    const [errorMsg, setErrorMsg] = useState();

    useEffect(() => setInputConfig(control), [control]);

    const handleInputChange = ({ target }) => {
        const { value } = target;
        onControlChange({ id: control.id, data: { value } });
    };

    const handleBlur = ({ target: { id, value } }) => {
        const { min, max } = control.range || {};
        const errorMsg = !value
            ? "Required field"
            : (min !== undefined && max !== undefined && (Number(value) > max || Number(value) < min))
                ? `Range ${min} - ${max}`
                : null;
        setErrorMsg(errorMsg);
        if (onBlur) onBlur({ id, value, isInvalid: Boolean(errorMsg) });
    };

    return (
        <>
            <WPFormLabel label={control.label} isMandatory />
            <InputWithText
                id={inputConfig.id}
                name={inputConfig.id}
                value={inputConfig.value}
                inputText={inputConfig.unit}
                type={inputConfig.inputType}
                disabled={inputConfig.disabled}
                isError={Boolean(errorMsg)}
                onBlur={handleBlur}
                onChange={handleInputChange}
            />
            {errorMsg && <div className="error-container"><ErrorMessage texMsg={errorMsg} /></div>}
        </>
    );
};

export const ChemicalLibDropdown = ({ control, onControlChange }) => {

    const value = useMemo(() => {
        const key = control?.labelKey || "name";
        return control?.value
            ? control.options.find(item => item[key] === control.value)
            : "";
    }, [control?.value]);

    if (!control) return null;

    const handleDropdownChange = data => onControlChange({ id: control.id, data });
    const getOptionLabel = option => option[control.labelKey];

    return (
        <div>
            <WPFormLabel label={control.label} isMandatory />
            <WPDropdown
                id={control.id}
                value={value}
                disabled={control.disabled}
                options={control.options}
                onChange={handleDropdownChange}
                placeholder={control.placeholder}
                getOptionLabel={getOptionLabel}
            />
        </div>
    );
};
