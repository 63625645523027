import { useMemo } from "react";
import { useSelector } from "react-redux";

import { UNIT_PRECISION_MAPPING } from "@constants/units.constant";

import useUnitConversion from "@hooks/useUnitConversion";

import { isNullOrUndefined } from "@utils/appUtils";

import { UF_SPECIAL_FEATURE } from "@features/feedwater/uf/constants/UFConstants";

// default unit precision is 2 for all units
export const useReportUtils = () => {
  const {
    activeUnits,
    activeCurrency,
    convertFromMetric,
    convertCurrencyPerUnitFromMetric,
    convertUnitPerUnitFromMetric,
  } = useUnitConversion();

  const { data: UFData } = useSelector(state => state.UFStore);

  const ufSpecialFeatureID = Number(UFData?.ufSpecialFeatureID).toString();

  const { data: calcEngineData } = useSelector(state => state.report.ufCalcReportApiResp) || {};

  const ufReportRespData = useMemo(() => calcEngineData, [calcEngineData]);

  const formatLabelValue = (label, value, unit, precision) => {
    const convertedValue = convertReportDataValue({ value, unit, precision });
    return `${label} = ${convertedValue}`;
  };

  const formatValue = (value, precision) => convertReportDataValue({ value, precision });

  const formatConvertValue = (value, unit, precision) => convertReportDataValue({ value, unit, precision });

  // here the unit is always a denominator unit
  // $/kg will be converted to $/lb when unit is of type weight
  const formatConvertCostValuePerUnit = (value, unit, precision) =>
    convertReportDataValue({ value, unit, precision, isCost: true });

  const formatKeyValue = (key, value) => `${key}: ${value}`;

  const formatUnitLabel = (unitValue, unitSubText = "") => getUnitLabelValue({ unit: unitValue, unitSubText });

  const formatCostUnitLabel = (unitValue, unitSubText = "") =>
    getUnitLabelValue({ unit: unitValue, unitSubText, isCost: true });

  // base function to return unit label
  const getUnitLabelValue = ({ unit, unitSubText, isCost, unitDenominator, returnValue, unitDenominatorPreText }) => {
    let unitLabel = activeUnits[unit] || unit;
    if (!unitLabel) {
      return "";
    }
    if (isCost) {
      const preUnitTextValue = unitDenominatorPreText ? unitDenominatorPreText : "";
      unitLabel = `${activeCurrency}/${preUnitTextValue}${unitLabel}`;
    }
    if (unitDenominator) {
      const unitDenominatorLabel = activeUnits[unitDenominator] || unitDenominator;
      unitLabel = `${unitLabel}/${unitDenominatorLabel}`;
    }
    if (unitSubText) {
      unitLabel = unitLabel + unitSubText;
    }
    return returnValue ? unitLabel : `(${unitLabel})`;
  };

  const formatConvertValueWithUnit = (value, unit, precision) => {
    if (isNullOrUndefined(value)) {
      return "";
    }
    return formatConvertValue(value, unit, precision) + " " + getUnitLabelValue({ unit, returnValue: true });
  };

  /**
   * Determines if a special feature is visible based on the provided type.
   *
   * @param {string} type - The type of the special feature. Can be "CEB" or "mCIP".
   * @returns {boolean} - Returns true if the special feature is visible, otherwise false.
   */
  const isSpecialFeatureVisible = type => {
    switch (type) {
      case "CEB":
        return ufSpecialFeatureID !== UF_SPECIAL_FEATURE.isMiniCipOnly;
      case "mCIP":
        return ufSpecialFeatureID !== UF_SPECIAL_FEATURE.isCebOnly;
      default:
        return true;
    }
  };

  /* 
  base function to handle all possible scenarios
  if there is a precision 2, 0 should be rendered as 0.00 not 0 ,
  null , undefined and empty string should be rendered as empty string and should not be treated as 0 
  */
  const convertReportDataValue = ({
    value,
    unit,
    precision,
    isCost,
    unitDenominator,
    returnNumber,
    returnCheckMark,
  }) => {
    if (isNullOrUndefined(value) || value === "") {
      return "";
    }
    if (isNaN(value)) {
      return value;
    }

    const unitVal = activeUnits[unit] || unit;
    // precisionValue is the precision value mapped to the unit ,unless overriden
    const precisionValue = precision ?? UNIT_PRECISION_MAPPING[unitVal] ?? 2;

    let result;
    if (isCost) {
      result = convertCurrencyPerUnitFromMetric(value, unit, precisionValue);
    } else if (unitDenominator) {
      result = convertUnitPerUnitFromMetric(value, unit, unitDenominator, precisionValue);
    } else if (typeof unit === "number") {
      result = convertFromMetric(value, unit, precisionValue);
    } else {
      result = value;
    }

    result = Number(result).toFixed(precisionValue);
    // this would remove trailing zero's 0.00 to 0
    if (returnNumber) {
      return Number(result);
    }
    if (returnCheckMark) {
      return Number(result) ? "\u2713" : "";
    }
    return result;
  };

  const formatChemicalNameConc = (name, concentration) => `${name} (${Number(concentration * 100).toFixed(0)}%)`;

  // indentLevel is used to add indentation (white spaces nbsp;) to the label based on the level
  const formatLabel = (label, indentLevel = 0) => `${"\u00A0".repeat(indentLevel * 4)}${label}`;
  return {
    ufReportRespData,
    formatLabelValue,
    formatKeyValue,
    formatUnitLabel,
    formatValue,
    formatConvertValue,
    formatLabel,
    formatChemicalNameConc,
    formatConvertValueWithUnit,
    formatCostUnitLabel,
    formatConvertCostValuePerUnit,
    convertReportDataValue,
    getUnitLabelValue,
    isSpecialFeatureVisible,
  };
};
