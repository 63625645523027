import useProjectDetails from "@hooks/useProjectDetails";

import { WPReport } from "@features/report-new";

import ViewReport from "./ViewReport";

const Report = () => {
  const { IS_UF_PROJECT } = useProjectDetails();
  const isNewReport = process.env.REACT_APP_REPORT_NEW === "true";
  return <>{isNewReport && IS_UF_PROJECT ? <WPReport /> : <ViewReport />}</>;
};

export default Report;
