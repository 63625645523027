import { useReportUtils } from "@features/report-new/useReportUtils";

import { WPUfWaterQualityTableDataRows as rowsData } from "./WPUfWaterQualityTable.constants";

export const useWPUfWaterQualityTableData = () => {
  const { ufReportRespData, formatConvertValue, getUnitLabelValue } = useReportUtils();
  const { uf_water_quality = {} } = ufReportRespData || {};

  const convertToUfWaterQualityTableData = () => {
    const data = Object.entries(rowsData).reduce((acc, curr) => {
      const [key, row] = curr;
      const { feed, product, display = true } = uf_water_quality[key] || {};
      if (!display) return acc;

      const { unit, label } = row;
      // handling this differently as the source does not have feed and product values
      if (key === "source") {
        const { name, temp_max, temp_min } = uf_water_quality[key];
        const temperatureValue = `${formatConvertValue(temp_min, unit)} - ${formatConvertValue(temp_max, unit)}`;
        const value = `${name} (${temperatureValue} ${getUnitLabelValue({ unit, returnValue: true })})`;
        acc.push({
          col1: label,
          col3: value,
        });
        return acc;
      }
      acc.push({
        col1: label,
        col2: getUnitLabelValue({ unit }),
        col3: formatConvertValue(feed, unit),
        col4: formatConvertValue(product, unit),
      });
      return acc;
    }, []);

    return data;
  };

  return { convertToUfWaterQualityTableData };
};
