import styled from "styled-components";

const MoreIcon = () => {
  const StyledSvg = styled.svg`
    &:hover {
      line {
        fill: #007672;
        stroke: #007672;
        stroke-width: 2;
      }
    }
  `;
  return (
    <StyledSvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='more-horizontal'>
        <g id='Icon'>
          <path
            d='M10.8337 9.99984C10.8337 9.5396 10.4606 9.1665 10.0003 9.1665C9.54009 9.1665 9.16699 9.5396 9.16699 9.99984C9.16699 10.4601 9.54009 10.8332 10.0003 10.8332C10.4606 10.8332 10.8337 10.4601 10.8337 9.99984Z'
            stroke='#475467'
            strokeWidth='1.66667'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M10.8337 4.1665C10.8337 3.70627 10.4606 3.33317 10.0003 3.33317C9.54009 3.33317 9.16699 3.70627 9.16699 4.1665C9.16699 4.62674 9.54009 4.99984 10.0003 4.99984C10.4606 4.99984 10.8337 4.62674 10.8337 4.1665Z'
            stroke='#475467'
            strokeWidth='1.66667'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M10.8337 15.8332C10.8337 15.3729 10.4606 14.9998 10.0003 14.9998C9.54009 14.9998 9.16699 15.3729 9.16699 15.8332C9.16699 16.2934 9.54009 16.6665 10.0003 16.6665C10.4606 16.6665 10.8337 16.2934 10.8337 15.8332Z'
            stroke='#475467'
            strokeWidth='1.66667'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </StyledSvg>
  );
};

export default MoreIcon;
