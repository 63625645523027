import React from "react";

import IconButton from "@components/IconButton";

const WPToggle = React.forwardRef(function CustomToggle({ children, onClick }, ref) {
  const handleClick = event => {
    onClick(event);
  };
  return (
    <IconButton ref={ref} onClick={handleClick}>
      {children}
    </IconButton>
  );
});

export default WPToggle;
