import React from "react";

const ProjectIcon = () => (
  <svg width='16' height='21' viewBox='0 0 16 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15.7679 5.09858L10.8827 0.213387C10.7465 0.0771825 10.5626 0 10.3697 0H0.726423C0.32462 0 0 0.32462 0 0.726423V19.6134C0 20.0152 0.32462 20.3398 0.726423 20.3398H15.2549C15.6567 20.3398 15.9813 20.0152 15.9813 19.6134V5.61389C15.9813 5.42093 15.9041 5.23479 15.7679 5.09858ZM14.306 5.94759H10.0337V1.67531L14.306 5.94759ZM14.3469 18.7054H1.63445V1.63445H8.49007V6.53781C8.49007 6.79067 8.59052 7.03318 8.76932 7.21198C8.94813 7.39079 9.19063 7.49124 9.4435 7.49124H14.3469V18.7054Z'
      fill='#4D4D4D'
    />
  </svg>
);

export default ProjectIcon;
