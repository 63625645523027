import React from "react";

const GridViewHoveredIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <rect x='0.5' y='0.5' width='8.23077' height='8.23077' stroke='#007672' />
      <rect x='0.5' y='15.269' width='8.23077' height='8.23077' stroke='#007672' />
      <rect x='15.2695' y='0.5' width='8.23077' height='8.23077' stroke='#007672' />
      <rect x='15.2686' y='15.269' width='8.23077' height='8.23077' stroke='#007672' />
    </svg>
  </>
);

export default GridViewHoveredIcon;
