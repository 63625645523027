import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { WPUfDupontUxaBwParametersTableColumns as columns } from "./WPUfDupontUxaBwParametersTable.constants";

export const WPUfDupontUxaBwParametersTable = () => {
  const { uf_backwash_parameters: rows } = useSelector(state => state.report.ufReportTableData);

  return (
    <WPTable
      sectionHeader={reportStrings.normalWithUxaBwParameters}
      columns={columns}
      rows={rows}
      remarksList={[
        reportStrings.filtrationModeBwRemark.replace("${steps}", "2-3-4-2"),
        reportStrings.valveOpeningClosingRemark,
        reportStrings.oValueRemark,
      ]}
      footNotesList={[
        reportStrings.bwFootNote1,
        reportStrings.forwardFlushFlowFootNote,
        reportStrings.bwFootNote3,
        reportStrings.mayWastePermeateRemark,
        reportStrings.bwFootNote5,
      ]}
    />
  );
};
