import React from "react";

const InputErrorIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
        <g clipPath="url(#clip0_1604_102574)">
            <path d="M5.41103 8.05357C5.41103 8.20986 5.47311 8.35975 5.58362 8.47026C5.69414 8.58077 5.84402 8.64286 6.00031 8.64286C6.1566 8.64286 6.30649 8.58077 6.417 8.47026C6.52751 8.35975 6.5896 8.20986 6.5896 8.05357C6.5896 7.89728 6.52751 7.7474 6.417 7.63689C6.30649 7.52637 6.1566 7.46429 6.00031 7.46429C5.84402 7.46429 5.69414 7.52637 5.58362 7.63689C5.47311 7.7474 5.41103 7.89728 5.41103 8.05357ZM5.60745 4.32143V6.58036C5.60745 6.63438 5.65165 6.67857 5.70567 6.67857H6.29495C6.34897 6.67857 6.39317 6.63438 6.39317 6.58036V4.32143C6.39317 4.26741 6.34897 4.22321 6.29495 4.22321H5.70567C5.65165 4.22321 5.60745 4.26741 5.60745 4.32143ZM11.4475 9.72322L6.34038 0.883929C6.26426 0.752567 6.1329 0.6875 6.00031 0.6875C5.86772 0.6875 5.73513 0.752567 5.66024 0.883929L0.553101 9.72322C0.402097 9.98594 0.591159 10.3125 0.893168 10.3125H11.1075C11.4095 10.3125 11.5985 9.98594 11.4475 9.72322ZM1.82866 9.38069L6.00031 2.15949L10.172 9.38069H1.82866Z" fill="#E4001C" />
        </g>
    </svg>
);

export default InputErrorIcon;