import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";
import {
  ChemicalCategroyRenderer,
  ChemicalConentrationRenderer,
  ChemicalDisplayNameRenderer,
  ChemicalDropdownRenderer,
  ChemicalProjectCostRenderer,
} from "../ChemicalTableRenderer";

export const PROJECT_COST_TABLE_CONFIG = [
  {
    id: 1,
    headerName: CHEMICALS_LIBRARY_STRINGS.displayName,
    field: "displayName",
    cellRenderer: ChemicalDisplayNameRenderer,
    width: 150,
    tooltip: true,
  },
  { id: 2, headerName: CHEMICALS_LIBRARY_STRINGS.name, field: "name", width: 150, tooltip: true },
  {
    id: 3,
    headerName: CHEMICALS_LIBRARY_STRINGS.bulkConcentration,
    field: "bulkConcentration",
    width: 110,
    valueFormatter: params => `${params.value}%`,
    cellRenderer: ChemicalConentrationRenderer,
  },

  {
    id: 4,
    headerName: CHEMICALS_LIBRARY_STRINGS.category,
    field: "category",
    width: 100,
    cellRenderer: ChemicalCategroyRenderer,
  },
  {
    id: 5,
    headerName: CHEMICALS_LIBRARY_STRINGS.symbol,
    field: "symbol",
    width: 80,
    tooltip: true,
  },
  {
    id: 6,
    headerName: CHEMICALS_LIBRARY_STRINGS.bulkDensity,
    field: "bulkDensity",
    width: 120,
  },
  {
    id: 7,
    headerName: CHEMICALS_LIBRARY_STRINGS.bulkPrice,
    field: "bulkPrice",
    width: 120,
    cellRenderer: ChemicalProjectCostRenderer,
  },
  {
    id: 8,
    headerName: CHEMICALS_LIBRARY_STRINGS.costType,
    field: "costType",
    width: 90,
    cellRenderer: ChemicalDropdownRenderer,
  },
];
