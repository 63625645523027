import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  fill: rgba(255, 255, 255, 0.7);
  &:hover {
    fill: #007672;
    stroke: #007672;
    stroke-width: 1;
  }
`;

const ChemicalsIcon = () => (
  <StyledSvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 -960 960 960' width='24px'>
    <path d='M200-120q-51 0-72.5-45.5T138-250l222-270v-240h-40q-17 0-28.5-11.5T280-800q0-17 11.5-28.5T320-840h320q17 0 28.5 11.5T680-800q0 17-11.5 28.5T640-760h-40v240l222 270q32 39 10.5 84.5T760-120H200Zm80-120h400L544-400H416L280-240Zm-80 40h560L520-492v-268h-80v268L200-200Zm280-280Z' />
  </StyledSvg>
);

export default ChemicalsIcon;
