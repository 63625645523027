import React from "react";

const ListViewActiveIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='20' viewBox='0 0 24 20' fill='none'>
      <line y1='10' x2='24' y2='10' stroke='#128370' strokeWidth='2' />
      <line y1='19' x2='24' y2='19' stroke='#128370' strokeWidth='2' />
      <line y1='1' x2='24' y2='1' stroke='#128370' strokeWidth='2' />
    </svg>
  </>
);

export default ListViewActiveIcon;
