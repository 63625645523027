import React from "react";

const ListViewHoverIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='19' viewBox='0 0 24 19' fill='none'>
      <line y1='9.5' x2='24' y2='9.5' stroke='#007672' />
      <line y1='18.5' x2='24' y2='18.5' stroke='#007672' />
      <line y1='0.5' x2='24' y2='0.5' stroke='#007672' />
    </svg>
  </>
);

export default ListViewHoverIcon;
