import React from "react";

import SVGContainer from "../SVGContainer";

const CloseIcon = () => (
  <SVGContainer xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <g width='16' height='16' transform='translate(8 8)'>
      <path
        d='M9.3281 8.17773L14.3027 2.41884C14.3861 2.32313 14.316 2.17773 14.1871 2.17773H12.6748C12.5858 2.17773 12.5005 2.21638 12.4417 2.28264L8.33887 7.03295L4.236 2.28264C4.17915 2.21638 4.09387 2.17773 4.00291 2.17773H2.49063C2.36176 2.17773 2.29164 2.32313 2.37503 2.41884L7.34963 8.17773L2.37503 13.9366C2.35635 13.958 2.34436 13.9841 2.3405 14.0118C2.33663 14.0396 2.34105 14.0678 2.35322 14.0932C2.36539 14.1186 2.38481 14.14 2.40916 14.155C2.43352 14.17 2.46179 14.1779 2.49063 14.1777H4.00291C4.09198 14.1777 4.17725 14.1391 4.236 14.0728L8.33887 9.32252L12.4417 14.0728C12.4986 14.1391 12.5839 14.1777 12.6748 14.1777H14.1871C14.316 14.1777 14.3861 14.0323 14.3027 13.9366L9.3281 8.17773Z'
      />
    </g>
  </SVGContainer>
);

export default CloseIcon;
