/* Setting up callback component for receiving Authorization Code from Salesforce.*/
import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import useGlobalDataHandler from "@hooks/useGlobalDataHandler";

import { updateUserInfo } from "@common/UserInfoSlice";
import { ErrorPage } from "@common/utils/ErrorBoundary";

import { useExchangeTokenMutation } from "@services/apiConfigSSO";

import { clearAuthData, setAccessTokens, setUserAttributes } from "./AuthSlice";

const SalesforceCallback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { checkIXPermission } = useGlobalDataHandler();
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [POSTExchangeToken] = useExchangeTokenMutation();
  const userInfoStore = useSelector(state => state.userInfo.data);

  const handleErrorPage = () => {
    dispatch(clearAuthData());
    localStorage.clear();
    setShowErrorPage(true);
  };

  /* On Page Load : Extracting Authorization Code from the URL Params*/
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authorization_code = urlParams.get("code");
    if (authorization_code) {
      localStorage.setItem("authCode", authorization_code);
      tokenExchange(authorization_code);
    } else {
      handleErrorPage();
    }
  }, []);

  const stringExistsInArray = (searchString, array1) => {
    const combinedArrray = array1;
    return combinedArrray.includes(searchString[0]);
  };

  /* Making POST Request to perform Salesforce Exchanging Token. Then, store Access Tokens in application store.Decode IDToken and store user details in application store. */
  const tokenExchange = async authCode => {
    try {
      const providerData = {
        code: authCode,
        grant_type: "authorization_code",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        redirect_uri: process.env.REACT_APP_CALLBACK_URL,
      };
      const tokenResponse = await POSTExchangeToken(providerData);
      if (tokenResponse?.data?.id_token !== undefined) {
        dispatch(setAccessTokens(tokenResponse?.data));
        localStorage.setItem("accessTokens", JSON.stringify(tokenResponse.data));
        const data = sessionStorage.getItem("userLogedIn");
        if (data) {
          sessionStorage.setItem("userLogedIn", "false");
        } else {
          sessionStorage.setItem("userLogedIn", "true");
        }

        decodeIDToken(tokenResponse?.data?.id_token);
      }
    } catch (err) {
      console.log("Catch: Token Exchange Failed: ", err);
    }
  };

  /* Decode ID Token and check Permission*/
  const decodeIDToken = idToken => {
    const decodedInfo = jwt_decode(idToken);
    const userDetails = decodedInfo?.custom_attributes || {};
    const UserName = `${userDetails.First_Name} ${userDetails.Last_Name}`;

    const newUserInfo = {
      ...userInfoStore,
      UserName,
      Role: userDetails?.Role,
      EmailId: userDetails?.UserEmail,
      CompanyName: userDetails?.CompanyName,
    };
    if (newUserInfo.applicationVersion === null) {
      newUserInfo.applicationVersion = localStorage.getItem("appVersion");
    }
    const isPermissionGranted = stringExistsInArray(["Wave Pro"], userDetails.AppDetails);

    if (userDetails?.UserEmail && localStorage.getItem("accessTokens") && isPermissionGranted) {
      //updating authSlice with access tokens.
      dispatch(setUserAttributes(userDetails));
      //updating userInfoSlice with custom attributes from salesforce
      dispatch(updateUserInfo(newUserInfo));

      localStorage.setItem("loggedInData", JSON.stringify(userDetails));
      navigate("/home");
      //This will be enabled when email whitelisting is enabled.
      // checkIXPermission(userDetails?.UserEmail);
    } else {
      handleErrorPage();
    }
  };

  return (
    <>
      {showErrorPage ? (
        <ErrorPage
          status={403}
          message={"Access Forbidden: You don't have an active subscription to this application."}
        />
      ) : null}
    </>
  );
};

export default SalesforceCallback;
