const BASE_UF = "uf/api/v1";
const BASE_MASTER_DATA = "masterdata/api/v1";
const BASE_USER_PROFILE = "userprofile/api/v1";

const MASTER_DATA = {
  pumps: `${BASE_MASTER_DATA}/Pumps`,
  cases: `${BASE_MASTER_DATA}/Cases`,
  project: `${BASE_MASTER_DATA}/Projects`,
  caseMgmt: `${BASE_MASTER_DATA}/CaseMgmt`,
  feedWater: `${BASE_MASTER_DATA}/FeedWater`,
  countriesList: `${BASE_MASTER_DATA}/Country`,
  projectInfo: `${BASE_MASTER_DATA}/ProjectInfo`,
  projectState: `${BASE_MASTER_DATA}/ProjectState`,
  systemDesign: `${BASE_MASTER_DATA}/SystemDesign`,
  projectStatus: `${BASE_MASTER_DATA}/EntityCheck`,
  marketSegment: `${BASE_MASTER_DATA}/MarketSegment`,
  unitOfMeasure: `${BASE_MASTER_DATA}/UnitOfMeassure`,
  defaultCurrency: `${BASE_MASTER_DATA}/DefaultCurrency`,
  duplicateProject: `${BASE_MASTER_DATA}/DuplicateProject`,
  chemicalCategory: `${BASE_MASTER_DATA}/ChemicalCategory`,
  unreadNotifications: `${BASE_MASTER_DATA}/UnreadNotification`,
};

const USER_PROFILE = {
  ixdUsers: `${BASE_USER_PROFILE}/IXDUsers`,
  importProject: `${BASE_USER_PROFILE}/Import`,
  exportProject: `${BASE_USER_PROFILE}/Export`,
  waterLibrary: `${BASE_USER_PROFILE}/WaterLibrary`,
  chemicalLibrary: `${BASE_USER_PROFILE}/ChemicalLibrary`,
  projectCosts: `${BASE_USER_PROFILE}/ChemicalLibrary/ProjectCosts`,
  chemicalLibraryCategory: `${BASE_USER_PROFILE}/ChemicalLibrary/Categories`,
  chemicalLibraryChemicalUsage: `${BASE_USER_PROFILE}/ChemicalLibrary/ChemicalUsage`,
};

const UF = {
  ufConfig: `${BASE_UF}/UFConfig`,
  ufDetails: `${BASE_UF}/UFDetails`,
  designData: `${BASE_UF}/DesignData`,
  ufCalcReport: `${BASE_UF}/UFCalcReport`,
};

const API_URLS = { ...MASTER_DATA, ...USER_PROFILE, ...UF };

export default API_URLS;
