import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import WarningIcon from "@common/icons/WarningIcon";
import StandardPrimaryButton from "@common/styles/components/buttons/standard/StandardPrimaryButton";
import StandardSecondaryButton from "@common/styles/components/buttons/standard/StandardSecondaryButton";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import StyledModal from "@common/styles/components/modals/CustomModal";
import { colors } from "@common/styles/Theme";

const DeletePopup = ({ show, message, deleteRecord, handleClose }) => {
  const [openModal, setOpenModal] = useState(true);

  useEffect(() => {
    if (show) {
      setOpenModal(true);
      close(false);
    }
  }, [openModal]);

  return (
    <>
      <StyledModal
        show={show && openModal}
        onHide={() => close(false)}
        keyboard='false'
        centered
        backdrop='static'
        isWarningPopUp={true}
        maxWidth='416px'
      >
        <Modal.Body>
          <div className='warning-pop-up'>
            <div>
              <WarningIcon />
            </div>
            <div>
              <CustomHeading
                fontFamily='DiodrumSemiBold'
                fontSize='16px'
                fontWeight='600'
                color={colors.Black}
                label={message}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <StandardSecondaryButton className='' id='canBtn' onClick={handleClose} label='Cancel' />
          <StandardPrimaryButton label='OK' className='' onClick={deleteRecord} />
        </Modal.Footer>
      </StyledModal>
    </>
  );
};

export default DeletePopup;
