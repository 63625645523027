/* eslint-disable max-len */
import React from "react";

const ArrowRightBlackIcon = ({ changeIcon, disabled }) => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
      <path
        d='M12.5781 6.62163L6.675 1.49819C6.62969 1.45913 6.57188 1.43726 6.51094 1.43726H5.12813C5.0125 1.43726 4.95938 1.58101 5.04688 1.65601L10.5188 6.40601H1.375C1.30625 6.40601 1.25 6.46226 1.25 6.53101V7.46851C1.25 7.53726 1.30625 7.59351 1.375 7.59351H10.5172L5.04531 12.3435C4.95781 12.4201 5.01094 12.5623 5.12656 12.5623H6.55625C6.58594 12.5623 6.61563 12.5513 6.6375 12.531L12.5781 7.37788C12.6322 7.33084 12.6756 7.27275 12.7053 7.20752C12.735 7.14229 12.7504 7.07144 12.7504 6.99976C12.7504 6.92807 12.735 6.85723 12.7053 6.79199C12.6756 6.72676 12.6322 6.66867 12.5781 6.62163Z'
        fill={changeIcon ? "#007672" : disabled ? "#e1e1e1" : "Black"}
        fillOpacity='0.85'
      />
    </svg>
  </>
);

export default ArrowRightBlackIcon;
