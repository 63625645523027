const WarningIcon = () => (
    <>
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='warning'>
                <path
                    id='Vector'
                    d='M22.3993 20.0625L12.6493 3.1875C12.5039 2.93672 12.2532 2.8125 12 2.8125C11.7469 2.8125 11.4938 2.93672 11.3508 3.1875L1.60083 20.0625C1.31254 20.5641 1.67348 21.1875 2.25004 21.1875H21.75C22.3266 21.1875 22.6875 20.5641 22.3993 20.0625ZM11.25 9.75C11.25 9.64687 11.3344 9.5625 11.4375 9.5625H12.5625C12.6657 9.5625 12.75 9.64687 12.75 9.75V14.0625C12.75 14.1656 12.6657 14.25 12.5625 14.25H11.4375C11.3344 14.25 11.25 14.1656 11.25 14.0625V9.75ZM12 18C11.7057 17.994 11.4253 17.8728 11.2193 17.6625C11.0132 17.4522 10.8978 17.1695 10.8978 16.875C10.8978 16.5805 11.0132 16.2978 11.2193 16.0875C11.4253 15.8772 11.7057 15.756 12 15.75C12.2944 15.756 12.5747 15.8772 12.7808 16.0875C12.9869 16.2978 13.1023 16.5805 13.1023 16.875C13.1023 17.1695 12.9869 17.4522 12.7808 17.6625C12.5747 17.8728 12.2944 17.994 12 18Z'
                    fill='#FAAD14'
                />
            </g>
        </svg>
    </>
);

export default WarningIcon;
