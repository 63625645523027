import { useCallback, useEffect, useState } from "react";

import { UNIT_TYPES } from "@constants/units.constant";

import ConfirmationPopup, { DIALOG_TYPES } from "@components/ConfirmationPopup";
import CustomModal from "@components/CustomModal";
import IconButton from "@components/IconButton";
import { QuestionIcon } from "@components/SVGs";
import WPButton from "@components/WPButton";

import ChemicalLibraryHelp from "../ChemcialLibraryHelp";
import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";
import ChemicalLibraryAddEditDialog from "../ChemicalLibraryAddEditDialog";
import { formatChemicalFormData } from "../ChemicalLibraryAddEditDialog/ChemicalLibraryAddEditDialog.util";
import ChemicalLibraryChemicalUsage from "../ChemicalLibraryChemicalUsage";
import useChemicalLibrary from "../ChemicalLibraryHooks/useChemicalLibrary";
import useChemicalUnitConversion from "../ChemicalLibraryHooks/useChemicalUnitConversion";
import ChemicalLibraryTable from "../ChemicalLibraryTable/ChemicalLibraryTable";

import "./ChemicalLibraryDialog.scss";

const HeaderContent = ({ onHelpBtnClick }) => (
    <div>
        {CHEMICALS_LIBRARY_STRINGS.chemicalLibDlgHeading}
        <IconButton
            id='help-dialog-btn'
            className='question-icon'
            onClick={onHelpBtnClick}
            tooltip={CHEMICALS_LIBRARY_STRINGS.questionIconTooltip}
        >
            <QuestionIcon />
        </IconButton>
    </div>
);

const ModalFooter = ({ handleBtnClick }) => (
    <WPButton id='addNewChemicalBtn' onClick={handleBtnClick}>
        {CHEMICALS_LIBRARY_STRINGS.addChemicalBtnText}
    </WPButton>
);

const ChemicalLibraryDialog = ({ onClose }) => {
    const {
        loaderText,
        chemicalLibraryList,
        chemicalCategoryData,
        deleteChemical,
        addEditChemical,
        checkChemicalUsage,
        fetchChemicalCategory,
        fetchChemicalLibraryList,
    } = useChemicalLibrary();
    const { activeUnits, activeCurrency, convertChemicalDensity, convertChemicalCurrency } = useChemicalUnitConversion();

    const [currentAction, setCurrentAction] = useState(null);
    const [chemicalFormData, setChemicalFormData] = useState(null);
    const [chemicalUsageData, setChemicalUsageData] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [chemicalRowData, setChemicalRowData] = useState({});

    useEffect(() => {
        fetchChemicalLibraryList();
        fetchChemicalCategory();
    }, []);

    const handleHelpBtnClick = useCallback(() => setCurrentAction("showHelpDialog"), []);
    const handleBtnClick = useCallback(
        ({ target: { id } }) => (id === "addNewChemicalBtn" ? onActionBtnClick() : onClose()),
        [],
    );

    const handleDialogClose = useCallback(event => {
        const { isCloseChemDlg } = event || {};
        setCurrentAction(null);
        setChemicalFormData(null);
        setChemicalUsageData(null);
        if (isCloseChemDlg) {
            onClose();
        }
    }, []);

    const formatControlsUnitData = defaultFormControls => {
        const clonedControls = structuredClone(defaultFormControls);

        const bulkDensityControl = defaultFormControls.bulkDensity;
        bulkDensityControl.unit = activeUnits[UNIT_TYPES.DENSITY];
        bulkDensityControl.value = convertChemicalDensity(bulkDensityControl.value);

        const bulkPriceControl = defaultFormControls.bulkPrice;
        bulkPriceControl.unit = activeCurrency;
        bulkPriceControl.value = convertChemicalCurrency(bulkPriceControl.value);

        clonedControls.bulkPrice = bulkPriceControl;
        clonedControls.bulkDensity = bulkDensityControl;
        return clonedControls;
    };

    const openAddEditDialog = chemicalData => {
        const formControls = formatChemicalFormData(chemicalData, chemicalCategoryData);
        const defaultFormControls = formatControlsUnitData(formControls);
        setChemicalFormData({ isEdit: Boolean(chemicalData), defaultFormControls });
        setCurrentAction("addEditChemical");
    };

    const handleDeleteChemical = async chemicalData => deleteChemical(chemicalData);
    const onChemicalAction = (type, chemicalData) => {
        if (type === "edit-chemical-btn") {
            openAddEditDialog(chemicalData);
        } else {
            handleDeleteChemical(chemicalData);
        }
    };

    const validateChemicalUsage = async (type, data) => {
        const projectList = await checkChemicalUsage(data.id);
        if (projectList && projectList.length) {
            setChemicalUsageData({ chemical: data, actionType: type, projectList });
            setCurrentAction("chemicalUsageDialog");
        } else {
            onChemicalAction(type, data);
        }
    };

    const onActionBtnClick = async chemicalData => {
        if (!chemicalData) {
            openAddEditDialog();
            return;
        }
        const { type, data } = chemicalData;
        if (!data.isSystem) {
            if (type === "viewInProjects") {
                addEditChemical(data);
            } else if (type === "delete-chemical-btn") {
                setChemicalRowData(data);
                setDeleteConfirmation(true);
            } else {
                validateChemicalUsage(type, data);
            }
        } else {
            onChemicalAction(type, data);
        }
    };

    const handleDeleteConfirmClose = action => {
        setDeleteConfirmation(false);
        if (action === "modal-confirm-btn") {
            validateChemicalUsage("delete-chemical-btn", chemicalRowData);
        }
    };

    const handleChemUsageDialogClose = data => {
        handleDialogClose(data);
        if (data) {
            const { actionType, chemical } = data;
            if (actionType === "delete-chemical-btn") {
                handleDeleteChemical(chemical);
            } else {
                openAddEditDialog(chemical);
            }
        }
    };

    return (
        <>
            <CustomModal
                header={<HeaderContent onHelpBtnClick={handleHelpBtnClick} />}
                headerHelper={CHEMICALS_LIBRARY_STRINGS.chemicalLibDlgSubHeading}
                onModalClose={onClose}
                size='lg'
                customclass='chemical-dialog--container'
                isLoading={Boolean(loaderText)}
                loadingText={loaderText}
                invisible={currentAction}
                modalFooter={<ModalFooter handleBtnClick={handleBtnClick} />}
            >
                <ChemicalLibraryTable chemicals={chemicalLibraryList} context={{ onActionBtnClick }} />
            </CustomModal>
            {currentAction === "showHelpDialog" && <ChemicalLibraryHelp onClose={handleDialogClose} />}
            {chemicalFormData && <ChemicalLibraryAddEditDialog onClose={handleDialogClose} formData={chemicalFormData} />}
            {chemicalUsageData && (
                <ChemicalLibraryChemicalUsage {...chemicalUsageData} onClose={handleChemUsageDialogClose} />
            )}
            {deleteConfirmation && (
                <ConfirmationPopup
                    type={DIALOG_TYPES.ERROR}
                    header={CHEMICALS_LIBRARY_STRINGS.deleteChemicalDlgTitle}
                    description={CHEMICALS_LIBRARY_STRINGS.deleteChemicalDlgDescription}
                    onClose={handleDeleteConfirmClose}
                    confirmBtn={CHEMICALS_LIBRARY_STRINGS.deletChemicalBtn}
                    cancelBtn={CHEMICALS_LIBRARY_STRINGS.cancelBtn}
                />
            )}
        </>
    );
};

export default ChemicalLibraryDialog;
