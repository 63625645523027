import axios from "axios";

import { setApiData } from "@common/ApiSlice";

import { store } from "../app/store"; // Adjust the import path as necessary

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_PATH,
});

const redirectToLogin = () => {
  localStorage.clear();
  window.location.href = process.env.REACT_APP_TOKEN_SFDCURL + "auth/idp/oidc/logout";
};

//The config must be as per axios request-config, refer https://www.npmjs.com/package/axios#request-config
const getRequestConfig = (method, endpoint, reqParams, reqBody, additionalConfig) => {
  const { headers: additionalHeaders } = additionalConfig;

  return {
    method,
    url: endpoint,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store?.getState().Auth?.idToken}`,
      ...additionalHeaders,
    },
    params: reqParams,
    data: reqBody,
  };
};

/**
 * Makes an HTTP request using the Axios instance.
 *
 * @param {string} endpoint - The API endpoint to call.
 * @param {string} method - The HTTP method to use (GET, POST, PUT, DELETE, PATCH).
 * @param {object} data - The request payload or query parameters.
 * @param {object} additionalConfig - Additional configuration options, such as headers and cacheKey.
 * @returns {Promise} - A promise that resolves to the response data.
 */
// base request function do not use this function directly
const request = async (endpoint, method, reqBody, reqParams, additionalConfig = {}) => {
  const { cacheKey, preferCache } = additionalConfig;
  try {
    if (cacheKey && preferCache) {
      const cachedData = store.getState().apiData[cacheKey];
      if (cachedData) {
        return Promise.resolve({ response: { data: cachedData } });
      }
    }
    const config = getRequestConfig(method, endpoint, reqParams, reqBody, additionalConfig);
    const response = await axiosInstance(config);
    if (cacheKey) {
      store.dispatch(setApiData({ key: cacheKey, data: response.data }));
    }
    return response;
  } catch (error) {
    if (error?.response?.status === 401) {
      // currently redirecting to logout page, need to figure to extend session without logging user out
      redirectToLogin();
      // Handle unauthorized error
      // invoke refresh token
      // request(error.config;);
    }
    throw error;
  }
};

export const getRequest = (endpoint, reqParams = {}, additionalConfig) =>
  request(endpoint, "GET", undefined, reqParams, additionalConfig);

export const postRequest = (endpoint, reqBody, reqParams = {}, additionalConfig) =>
  request(endpoint, "POST", reqBody, reqParams, additionalConfig);

export const putRequest = (endpoint, reqBody, reqParams = {}, additionalConfig) =>
  request(endpoint, "PUT", reqBody, reqParams, additionalConfig);

export const deleteRequest = (endpoint, reqBody, reqParams = {}, additionalConfig) =>
  request(endpoint, "DELETE", reqBody, reqParams, additionalConfig);

export const patchRequest = (endpoint, reqBody, reqParams = {}, additionalConfig) =>
  request(endpoint, "PATCH", reqBody, reqParams, additionalConfig);
