import React, { useEffect, useState } from "react";

import CustomTable from "@components/CustomTable";

import CustomLabel from "@common/styles/components/headings/CustomLabel";

import { FEED_STRINGS, FEED_WATER_IONS_LABEL, IONS_TABLE_HEADER } from "../constants/FeedWaterConstants";
import { parseAndFormatFloat } from "../FeedSetupConfiguration/FeedSetupHelper";

const WaterLibraryIonsTable = ({ type, data }) => {
  const [ionsTotal, setIonsTotal] = useState({ name: FEED_STRINGS.TOTAL, mgL: 0 });
  useEffect(() => {
    if (data) {
      const sumOfIons = data.reduce((total, item) => total + Number(item.mgL), 0);
      setIonsTotal({ name: FEED_STRINGS.TOTAL, mgL: parseAndFormatFloat(sumOfIons, 3) });
    }
  }, [type, data]);

  return (
    <div className='table-card'>
      <CustomLabel label={FEED_WATER_IONS_LABEL[type]} />
      <CustomTable
        headers={IONS_TABLE_HEADER}
        data={data}
        maxHeight={281}
        tableCustomeClass={type}
        footer={ionsTotal}
      />
    </div>
  );
};

export default WaterLibraryIonsTable;
