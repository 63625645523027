/* eslint-disable max-len */
import React from "react";
import styled from "styled-components";

const StyledFilterIcon = styled.div`
  .svg-container {
    .fill-color {
      fill: #000;
    }
    &:hover {
      .fill-color {
        fill: #128370;
      }
    }
  }
`;

const FilterIconOpenProject = () => (
  <StyledFilterIcon>
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' className='svg-container'>
      <g transform='translate(5, 5)'>
        <path
          d='M12.7516 1.4082H1.2485C0.865687 1.4082 0.626625 1.82539 0.818812 2.1582L4.45319 8.33633V12.0957C4.45319 12.3723 4.67506 12.5957 4.95006 12.5957H9.05006C9.32506 12.5957 9.54694 12.3723 9.54694 12.0957V8.33633L13.1829 2.1582C13.3735 1.82539 13.1344 1.4082 12.7516 1.4082ZM8.42819 11.4707H5.57194V9.0332H8.42975V11.4707H8.42819ZM8.57819 7.77383L8.42975 8.0332H5.57037L5.42194 7.77383L2.3235 2.5332H11.6766L8.57819 7.77383Z'
          className='fill-color'
        />
      </g>
    </svg>
  </StyledFilterIcon>
);

export default FilterIconOpenProject;
