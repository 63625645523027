import { useMemo } from "react";

import useProjectDetails from "@hooks/useProjectDetails";

import { formatDate } from "@utils/appUtils";

import { WPTable } from "@components/WPTable";

import { reportProjectInfoColumns, reportProjectInfoRows, reportProjectInfoTableLabels } from "./constants";

export const WPReportProjectInfoTable = () => {
  const { projectDetails } = useProjectDetails();

  const renderValue = key => {
    let value = projectDetails[key];
    if (key === "createdDate" || key === "updatedDate") {
      value = formatDate(value);
    }
    return `${reportProjectInfoTableLabels[key]}: ${value}`;
  };

  const rows = useMemo(
    () =>
      reportProjectInfoRows.map(row =>
        row.reduce((acc, key, index) => {
          acc[`col${index + 1}`] = renderValue(key);
          return acc;
        }, {}),
      ),
    [projectDetails],
  );

  return <WPTable showHeader={false} fullBorder columns={reportProjectInfoColumns} rows={rows} />;
};
