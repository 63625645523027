import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CHEMICAL_ADJUSTMENT_MAPPING,
  CHEMICAL_CONCENTRATION,
  CHEMICAL_MEASUREMENT_TABLE_ROWS,
  ChemicalTypesMappig,
  LSI_MAX_TDS,
  UFChemcicalFieldsMapping,
} from "@constants/chemicalAdjustment.constant";

import { convertUpto2Digits } from "@utils/appUtils";

import { useChemicalAdjustmentDataMutation, useLazyGetAllDataQuery } from "@services/apiConfig";

import { setUFChemicalAdjustment } from "@features/feedwater/uf/UFSlice";

export const useChemicalAdjustment = () => {
  const dispatch = useDispatch();
  const [adjustmentDataAPI] = useChemicalAdjustmentDataMutation();
  const [getAllData] = useLazyGetAllDataQuery();

  const feedWaterData =
    useSelector(state => state.Feedsetupdetailsdatapanel.streamData.lstrequestsavefeedwater[0]?.streams[0]) || {};
  const { chemicalListByCategory, chemicalListById } = useSelector(state => state.projectInfo.projectChemicalCosts);
  const { lstTechnologyLists } = useSelector(state => state.processDiagramSlice) || [];
  const { data: ChemicalData } = useSelector(state => state.UFStore.ufChemicalAdjustment) || null;

  const [chemicalAdjustmentBefore, setChemicalAdjustmentBefore] = useState({});
  const [isSDI, setIsDSI] = useState(feedWaterData.totalDissolvedSolids >= LSI_MAX_TDS);

  const findItemByName = (list, name) => {
    const item = list?.find(item => item.name === name);
    return (item && item.mgL) || "0.00";
  };

  const hco3 = useMemo(() => findItemByName(feedWaterData.anions, "HCO₃"), [feedWaterData.anions]);
  const co3 = useMemo(() => findItemByName(feedWaterData.anions, "CO₃"), [feedWaterData.anions]);
  const co2 = useMemo(() => findItemByName(feedWaterData.neutrals, "CO₂"), [feedWaterData.neutrals]);
  const ca = useMemo(() => findItemByName(feedWaterData.cations, "Ca"), [feedWaterData.cations]);

  const { caseTreatmentID: caseTreatmentId } = useMemo(
    () => lstTechnologyLists.find(({ technologyID, isDeleted }) => technologyID == 1 && !isDeleted) || {},
    [lstTechnologyLists],
  );

  const isPhDownEnabled = useMemo(
    () =>
      feedWaterData &&
      (feedWaterData.estimatedConductivity > 0 ||
        feedWaterData.totalDissolvedSolids > 0 ||
        feedWaterData.chargeBalance > 0),
    [feedWaterData.estimatedConductivity, feedWaterData.totalDissolvedSolids, feedWaterData.chargeBalance],
  );

  const temperatureData = useMemo(
    () => [
      { id: "minimumTemp", label: "Minimum", value: feedWaterData.tempMin },
      { id: "designTemp", label: "Design", value: feedWaterData.tempDesign },
      { id: "maximumTemp", label: "Maximum", value: feedWaterData.tempMax },
      { id: "specify", label: "Specify", value: feedWaterData.tempDesign },
    ],
    [feedWaterData],
  );

  useEffect(() => {
    if (isPhDownEnabled) {
      const data = {
        ph: convertUpto2Digits(feedWaterData.pH),
        lsi: "NaN",
        sdi: "NaN",
        tds: convertUpto2Digits(feedWaterData.totalDissolvedSolids),
        is: "0.00",
        hco3: convertUpto2Digits(hco3),
        co2: convertUpto2Digits(co2),
        co3: convertUpto2Digits(co3),
        caso4: "0.00",
        baso4: "0.00",
        srs04: "0.00",
        caf2: "0.00",
        mgoh2: "0.00",
      };
      setChemicalAdjustmentBefore(data);
    }
    const isSDI = feedWaterData.totalDissolvedSolids >= LSI_MAX_TDS;
    setIsDSI(isSDI);
  }, [feedWaterData]);

  const getUFChemicalMeasurements = () =>
    CHEMICAL_MEASUREMENT_TABLE_ROWS.map(item => ({
      ...item,
      beforeAdjustment: chemicalAdjustmentBefore[item.id],
    }));

  const getDefaultPayload = () => {
    const {
      anions,
      cations,
      neutrals,
      chargeBalance,
      estimatedConductivity,
      ph,
      pH,
      ph25,
      totalDissolvedSolids,
      totalDissolvedSolutes,
      tempDesign,
    } = feedWaterData;
    return {
      methodName: "normal",
      anions,
      cations,
      neutrals,
      chargeBalance,
      estimatedConductivity,
      ph: ph || pH,
      ph25,
      totalDissolvedSolids,
      totalDissolvedSolutes,
      chemicalAdjustment: [
        {
          CaSO4: 0,
          BaSO4: 0,
          SrSO4: 0,
          CaF2: 0,
          SiO2: 0,
          MgOH2: 0,
          LSI: 0,
          SDI: 0,
          pH: ph || pH,
          ionicStrength: 0,
        },
      ],
      adjustmentType: 0,
      degas: 0,
      initialCO2: 0,
      totalCO2: 0,
      equilibrateWith: 0,
      addReagent: 0,
      LSITarg: 0,
      SDITarg: 0,
      designTemp: tempDesign,
    };
  };

  const calculateChemicalAdjustment = async (data = {}) => {
    const Method = "masterdata/api/v1/CalculateChemicalAdjustment";
    const payload = { Method, ...getDefaultPayload(), ...data };
    const response = (await adjustmentDataAPI(payload)) || {};
    return response;
  };

  const getUFChemicalAdjustmentData = async () => {
    if (!ChemicalData) {
      const queryParams = { caseTreatmentId };
      const queryString = new URLSearchParams(queryParams).toString();
      const url = `masterdata/api/v1/ChemicalAdjustment?${queryString}`;
      const { data } = (await getAllData(url)) || {};
      const { phDown, oxidant, coagulant } = data;
      dispatch(setUFChemicalAdjustment({ data, chemicalAdjustment: null }));
      return { phDown, oxidant, coagulant };
    } else {
      return { ...ChemicalData };
    }
  };

  const saveUFChemicalAdjustmentData = async data => {
    const Method = "masterdata/api/v1/ChemicalAdjustment";
    const payload = { Method, caseTreatmentId, ...data };
    await adjustmentDataAPI(payload);
  };

  const isLsiSdiEnabled = () => ca > 0 && co3 > 0;

  const formatChemicalsResponse = chemicalData =>
    Object.keys(CHEMICAL_ADJUSTMENT_MAPPING).reduce((acc, key) => {
      acc[CHEMICAL_ADJUSTMENT_MAPPING[key]] = Math.round(chemicalData[key]);
      return acc;
    }, {});

  const formatChemicalDataForUF = chemicalData =>
    Object.keys(UFChemcicalFieldsMapping).reduce((acc, key) => {
      acc[UFChemcicalFieldsMapping[key]] = chemicalData[key];
      if (key === "chemicalAdjustment") {
        acc[UFChemcicalFieldsMapping[key]] = [formatChemicalsResponse(chemicalData[key][0])];
      }
      return acc;
    }, {});

  const getReportAdjustmentData = (beforeAdjustmentData, afterAdjustmentData) =>
    afterAdjustmentData && beforeAdjustmentData
      ? {
          beforePh: beforeAdjustmentData?.pH?.toString() || "",
          afterPh: afterAdjustmentData?.chemicalAdjustment[0]?.pH?.toString() || "",
          beforeTds: beforeAdjustmentData?.TDS?.toString() || "",
          afterTds: afterAdjustmentData?.chemicalAdjustment[0]?.TDS?.toString() || "",
        }
      : null;

  const fetchUFChemicalAdjustmentFieldsData = async () => {
    const data = await getUFChemicalAdjustmentData();
    let phConcentration = 0;
    let chemicalAdjustment = null;
    if (data.phDown) {
      const { phDown } = data;
      const chemical = chemicalListById[phDown.chemicalId];
      if (chemical === undefined) return;
      const payload = {
        ph: phDown.ph,
        addReagent: ChemicalTypesMappig[chemical.name],
        LSITarg: (!isSDI && phDown.lsiSdi) || "0",
        SDITarg: (isSDI && phDown.lsiSdi) || "0",
        designTemp: +temperatureData[1].value,
      };
      const response = await calculateChemicalAdjustment(payload);
      if (response && response.data) {
        const { addReagent, ...respData } = response.data;
        phConcentration = CHEMICAL_CONCENTRATION[chemical.symbol] * addReagent * 1000;
        phConcentration = convertUpto2Digits(phConcentration);
        chemicalAdjustment = formatChemicalDataForUF(respData);
      }
    }
    dispatch(setUFChemicalAdjustment({ data, chemicalAdjustment, phConcentration }));
    return chemicalAdjustment;
  };

  return {
    isSDI,
    feedWaterData,
    isPhDownEnabled,
    temperatureData,
    chemicalListByCategory,
    isLsiSdiEnabled,
    getReportAdjustmentData,
    formatChemicalDataForUF,
    getUFChemicalMeasurements,
    getUFChemicalAdjustmentData,
    calculateChemicalAdjustment,
    saveUFChemicalAdjustmentData,
    fetchUFChemicalAdjustmentFieldsData,
  };
};
