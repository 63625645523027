/* eslint-disable max-len */
import React from "react";

const CameraIcon = () => (
  <>
    <svg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.57407 1.73077H8L7.625 0.732967C7.59909 0.66469 7.55161 0.605672 7.48905 0.563975C7.42649 0.522278 7.3519 0.499936 7.27546 0.5H3.72454C3.56829 0.5 3.42824 0.593407 3.37616 0.732967L3 1.73077H1.42593C0.914352 1.73077 0.5 2.12418 0.5 2.60989V7.62088C0.5 8.10659 0.914352 8.5 1.42593 8.5H9.57407C10.0856 8.5 10.5 8.10659 10.5 7.62088V2.60989C10.5 2.12418 10.0856 1.73077 9.57407 1.73077ZM9.66667 7.62088C9.66667 7.66923 9.625 7.70879 9.57407 7.70879H1.42593C1.375 7.70879 1.33333 7.66923 1.33333 7.62088V2.60989C1.33333 2.56154 1.375 2.52198 1.42593 2.52198H3.58681L3.78472 1.9967L4.04977 1.29121H6.94907L7.21412 1.9967L7.41204 2.52198H9.57407C9.625 2.52198 9.66667 2.56154 9.66667 2.60989V7.62088ZM5.5 3.22528C4.47685 3.22528 3.64815 4.01209 3.64815 4.98352C3.64815 5.95495 4.47685 6.74176 5.5 6.74176C6.52315 6.74176 7.35185 5.95495 7.35185 4.98352C7.35185 4.01209 6.52315 3.22528 5.5 3.22528ZM5.5 6.03846C4.88657 6.03846 4.38889 5.56593 4.38889 4.98352C4.38889 4.4011 4.88657 3.92857 5.5 3.92857C6.11343 3.92857 6.61111 4.4011 6.61111 4.98352C6.61111 5.56593 6.11343 6.03846 5.5 6.03846Z'
        fill='white'
      />
    </svg>
  </>
);

export default CameraIcon;
