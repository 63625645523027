import { getChemicalObject, getDisabledUFTech } from "@utils/appUtils";

import { UF_PAYLOAD_CHEMICAL_IDS } from "./useUFConfigConstants";

export const getUFConfigChemicalParams = (chemicalListById, UFData) => {
  if (!chemicalListById) return {};
  const disabledField = getDisabledUFTech(UFData.ufSpecialFeatureID);
  const validData = (data, label) => (data ? data[label] : 0);

  const getChemicalsData = (chemicals, getChemical) => {
    const result = {};
    for (const key in chemicals) {
      result[key] = getChemical(chemicals[key]);
    }
    return result;
  };

  const getChemicalsDataById = (property, transformFn) => {
    const chemicalIds = getChemicalObject(UF_PAYLOAD_CHEMICAL_IDS, property, disabledField);
    return getChemicalsData(chemicalIds, id => transformFn(chemicalListById[UFData[id]]).toString());
  };

  const getChemicalsDensityById = () => getChemicalsDataById("density", data => validData(data, "bulkDensity"));
  const getChemicalsBukPriceById = () => getChemicalsDataById("price", data => validData(data, "bulkPrice"));
  const getChemicalsBulkConcentrationById = () =>
    getChemicalsDataById("bulk_conc", data => validData(data, "bulkConcentration") / 100);

  return {
    ...getChemicalsDensityById(),
    ...getChemicalsBukPriceById(),
    ...getChemicalsBulkConcentrationById(),
  };
};

export const getFeedwaterParams = StreamStoreData => {
  const params = {};
  if (StreamStoreData) {
    const { tempDesign, pH, turbidity, toc, tss, totalDissolvedSolutes, cations, anions, neutrals } = StreamStoreData;
    params["feed_water"] = {
      methodname: "normal",
      designTemp: tempDesign,
      ph: parseFloat(pH),
      Degas: 0.0,
      percentage_of_initial_total_CO2_remaining: parseFloat(StreamStoreData?.percentContribution),
      Equilibrate_with: 0.0,
      Adjustment_Type: 0.0,
      Add_Reagent: 0.0,
      Total_CO2: 0.0,
      Turbidity: turbidity,
      organicToc: toc,
      TSS: tss,
      TDS: totalDissolvedSolutes,
      cations: cations,
      anions: anions,
      neutrals: neutrals,
      LSI_targ: 0,
      SDI_targ: 0,
      ChemicalAdjustment: [
        {
          CaSO4_per: 0,
          BaSO4_per: 0,
          SrSO4_per: 0,
          CaF2_per: 0,
          SiO2_per: 0,
          MgOH2_per: 0,
          LSI: 0,
          SDI: 0,
          adjustedpH: 0,
          adjustedTDS: 0,
        },
      ],
    };
  }
  return params;
};

/**
 * This function takes a list of selected chemicals and a list of items,
 * and returns an object containing the price, density, and bulk concentration
 * details for each item based on the corresponding chemical in the selected chemicals list.
 *
 * @param {Array} selectedChemicals - An array of chemical objects, each containing properties like symbol, bulkPrice, bulkDensity, and bulkConcentration.
 * @param {Array} list - An array of item objects, each containing properties like name and symbol.
 * @returns {Object} - An object where each key is a combination of the item name and a detail type (price, density, bulk_conc),
 *                     and the value is the corresponding detail from the selected chemical or "0" if not found.
 *
 * The function works as follows:
 * 1. It uses the reduce method to iterate over the list of items.
 * 2. For each item, it finds the corresponding chemical in the selectedChemicals array based on the symbol property.
 * 3. It then adds the price, density, and bulk concentration details of the chemical to the accumulator object,
 *    using the item name as part of the key.
 * 4. If the chemical is not found, it defaults the values to "0".
 * 5. Finally, it returns the accumulator object containing all the details.
 */
export const getChemicalDetail = (selectedChemicals, list) =>
  list.reduce((acc, { name, symbol, symbol1, symbol2 }) => {
    const chemical =
      selectedChemicals?.find(
        ({ symbol: chemSymbol }) => chemSymbol === symbol || chemSymbol === symbol1 || chemSymbol === symbol2,
      ) ?? {};
    const { bulkPrice = 0, bulkDensity = 0, bulkConcentration = 0 } = chemical;

    acc[`${name}_price`] = `${bulkPrice}`;
    acc[`${name}_density`] = `${bulkDensity}`;
    acc[`${name}_bulk_conc`] = `${bulkConcentration / 100}`;

    return acc;
  }, {});
