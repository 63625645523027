import React, { useState } from "react";
import Select, { components } from "react-select";

import AccordionDownArrowIcon from "@common/icons/AccordionDownArrowIcon";
import AccordionUpArrowIcon from "@common/icons/AccordionUpArrowIcon";

import "./WPDropdown.scss";

const CustomDropdownIndicator = props =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <AccordionUpArrowIcon /> : <AccordionDownArrowIcon />}
    </components.DropdownIndicator>
  );

const CustomOption = ({ children, ...props }) => (
  <div id={`dropdonw-option-${props.data.value}`}>
    <components.Option {...props} >
      {children}
    </components.Option>
  </div>
);

const WPDropdown = ({ id, name, options, defaultValue, placeholder, getOptionLabel, getOptionValue, onChange, value, isMulti, disabled, menuPosition = "fixed" }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleChange = selectedOption => {
    onChange({ ...selectedOption });
  };

  const formatGroupLabel = data => (
    <div className="select__group--container">
      <span>{data.group}</span>
      <span>{data.options.length}</span>
    </div>
  );

  getOptionValue = getOptionValue ? getOptionValue : option => option;

  return (
    <div className='dropdown-container'>
      <Select
        id={id || "dropdown_id"}
        name={name || id || "dropdown_name"}
        defaultValue={defaultValue}
        value={value}
        isDisabled={disabled}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        onChange={handleChange}
        options={options}
        className='basic-multi-select'
        classNamePrefix='select'
        placeholder=""
        inputId={id + "_input" || "dropdown_input"}
        components={{ DropdownIndicator: CustomDropdownIndicator, Option: CustomOption }}
        onMenuOpen={() => setMenuIsOpen(true)}
        menuPosition={menuPosition}
        onMenuClose={() => setMenuIsOpen(false)}
        menuIsOpen={menuIsOpen}
        formatGroupLabel={formatGroupLabel}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
      />
    </div>
  );
};

export default WPDropdown;