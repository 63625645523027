import React from "react";
import { Modal } from "react-bootstrap";

import { WPTypogrpahy } from "@components/WPTypography";

import CircularLoader from "@common/styles/components/circularLoader";
import StyledModal from "@common/styles/components/modals/CustomModal";

import { useFullScreenLoader } from "./useFullScreenLoader";

export const WPFullScreenLoader = () => {
  const { isFullScreenLoaderVisible, fullScreenLoaderMessage } = useFullScreenLoader();

  if (!isFullScreenLoaderVisible) {
    return null;
  }

  return (
    <StyledModal
      show
      centered
      backdrop='static'
      maxWidth='412px'
      dialogClassName='primary-modal'
      backdropClassName='dark-backdrop'
      keyboard={false}
    >
      <Modal.Body>
        <div className='modal-body'>
          <CircularLoader />
          {fullScreenLoaderMessage && (
            <WPTypogrpahy align='center' className='mt-3' text={fullScreenLoaderMessage} fontSize={14} />
          )}
        </div>
      </Modal.Body>
    </StyledModal>
  );
};
