import React from "react";

import "./index.css";

const CustomTable = ({ headers, data, maxHeight = 300, tableCustomeClass = "", footer }) => {
  const defaultColWidth = 100 / headers.length;

  const getColWidth = header => {
    let width = `${defaultColWidth}%`;
    if (header.colWidth) {
      width = `${header.colWidth}px`;
    }
    return { width };
  };
  return (
    <div className={`custom-table-container ${tableCustomeClass}`}>
      <table className='table'>
        <thead>
          <tr>
            {headers.map(header => (
              <th key={header.field} style={getColWidth(header)}>
                {header.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={{ height: maxHeight }}>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header, colIndex) => (
                <td key={colIndex} style={getColWidth(header)}>
                  {row[header.field]}
                  {/*Below component FieldRenderer can be used when we want to show dynamic input box and other inputs in table */}
                  {/* <FieldRenderer headerData={header} colIndex={colIndex} coloumnData={row} rowIndex={rowIndex} /> */}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        {footer && (
          <tfoot>
            <tr>
              {headers.map(header => (
                <th key={header.field} style={getColWidth(header)}>
                  {footer[header.field]}
                </th>
              ))}
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default CustomTable;
