import React from "react";

import SVGContainerPath from "../SVGContainerPath";

const BackIcon = () => (
    <SVGContainerPath xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M18.0353 9.15095H4.97499L12.792 2.36523C12.917 2.25586 12.8411 2.05273 12.6759 2.05273H10.7004C10.6134 2.05273 10.5308 2.08398 10.4661 2.13979L2.03079 9.45898C1.95353 9.52595 1.89157 9.60875 1.84911 9.70175C1.80664 9.79476 1.78467 9.89581 1.78467 9.99805C1.78467 10.1003 1.80664 10.2013 1.84911 10.2943C1.89157 10.3874 1.95353 10.4701 2.03079 10.5371L10.5152 17.901C10.5486 17.93 10.5888 17.9456 10.6312 17.9456H12.6736C12.8388 17.9456 12.9147 17.7402 12.7897 17.6331L4.97499 10.8474H18.0353C18.1335 10.8474 18.2138 10.767 18.2138 10.6688V9.32952C18.2138 9.23131 18.1335 9.15095 18.0353 9.15095Z" />
    </SVGContainerPath>
);

export default BackIcon;