// Public and restricted can be used in RO and other technologies
export const DEFAULT_PRODUCT_TYPE_FILTERS = [
  {
    label: "Rack",
    value: "rack",
  },
  // {
  //   label: "Public",
  //   value: "public",
  // },
  // {
  //   label: "Restricted",
  //   value: "restricted",
  // },
  {
    label: "Open Platform",
    value: "open_platform",
  },
  {
    label: "Drinking Water",
    value: "drinking_water",
  },
  {
    label: "Non-drinking Water",
    value: "non_drinking_water",
  },
];

export const PRODUCT_CATEGORIES = [
  ...DEFAULT_PRODUCT_TYPE_FILTERS,
  {
    label: "Obsolete",
    value: "obsolete",
  },
  {
    label: "China",
    value: "chinese",
  },
];
