import { reportStrings } from "@constants/report.strings";
import { UNIT_TYPES, UNITS } from "@constants/units.constant";

export const WPUfServiceWaterTableColumns = [
  {
    field: "label",
    rowHeader: true,
  },
  {
    field: "Average_Flowrate",
    headerName: reportStrings.averageFlowRate,
  },
  {
    field: "Unit_Cost",
    headerName: reportStrings.unitCost,
  },
  {
    field: "Cost",
    headerName: reportStrings.hourlyCost,
  },
  {
    field: "Cost1",
    headerName: reportStrings.dailyCost,
  },
];

export const WPUfServiceWaterRowsData = [
  {
    apiKey: "Non_product_feed_water",
    label: reportStrings.nonProductFeedWater,
  },
  {
    apiKey: "Waste_water_disposal",
    label: reportStrings.wasteWaterDisposal,
  },
  {
    apiKey: "Total_Service_Water_Cost",
    label: reportStrings.totalServiceWaterCost,
  },
];

export const WPUfServiceWaterUnitMapping = {
  Average_Flowrate: {
    unit: UNIT_TYPES.FLOW,
    precision: 2, // overriding default unit precision
  },
  Unit_Cost: {
    isCost: true,
    unit: UNIT_TYPES.VOLUME_SOLUTION,
    precision: 4,
  },
  Cost: {
    isCost: true,
    unit: UNITS.hour,
  },
  Cost1: {
    isCost: true,
    unit: UNITS.day,
  },
};
