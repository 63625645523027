/* eslint-disable max-len */
import React from "react";

const StarIcon = () => (
  <>
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_181191_35056)'>
        <path
          d='M13.5779 4.51837L9.6107 3.9418L7.83726 0.34649C7.78882 0.248053 7.70914 0.168365 7.6107 0.119928C7.36382 -0.00194734 7.06382 0.0996152 6.94039 0.34649L5.16695 3.9418L1.19976 4.51837C1.09039 4.53399 0.990386 4.58555 0.913823 4.66368C0.821263 4.75881 0.770259 4.8868 0.772017 5.01952C0.773775 5.15225 0.828151 5.27884 0.923198 5.37149L3.79351 8.16993L3.11539 12.1215C3.09948 12.2134 3.10966 12.308 3.14475 12.3944C3.17984 12.4808 3.23845 12.5557 3.31393 12.6105C3.38941 12.6653 3.47874 12.6979 3.57179 12.7045C3.66484 12.7112 3.75789 12.6916 3.84039 12.6481L7.38882 10.7824L10.9373 12.6481C11.0341 12.6996 11.1466 12.7168 11.2544 12.6981C11.5263 12.6512 11.7091 12.3934 11.6623 12.1215L10.9841 8.16993L13.8544 5.37149C13.9326 5.29493 13.9841 5.19493 13.9998 5.08555C14.0419 4.81212 13.8513 4.55899 13.5779 4.51837ZM9.77632 7.77618L10.3404 11.0621L7.38882 9.51212L4.43726 11.0637L5.00132 7.77774L2.61382 5.44962L5.91382 4.96993L7.38882 1.98087L8.86382 4.96993L12.1638 5.44962L9.77632 7.77618Z'
          fill='black'
        />
      </g>
      <defs>
        <clipPath id='clip0_181191_35056'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default StarIcon;
