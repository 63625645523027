import { reportStrings } from "@constants/report.strings";

import { cloneArrayAndOverride } from "@utils/appUtils";

import { WPUfParametersDataMapping } from "../../WPUfParametersTables.constants";
import {
  WPUfDupontUxaCipParametersTableColumns,
  WPUfDupontUxaCipParametersTableRows,
} from "../WPUfDupontUxaCipParametersTable";

export const WPUfDupontUxaMiniCipParametersTableColumns = cloneArrayAndOverride(
  WPUfDupontUxaCipParametersTableColumns,
  {
    col4: {
      headerName: reportStrings.miniCip + "<sup>c</sup>",
    },
  },
  "field",
);

export const WPUfDupontUxaMiniCipParametersTableRows = cloneArrayAndOverride(
  WPUfDupontUxaCipParametersTableRows,
  {
    stepLabel: {
      col5: reportStrings.miniCipRecycle + "<sup>d</sup>",
      col7: reportStrings.miniCipRecycle + "<sup>d</sup>",
    },
    duration: {
      col5: WPUfParametersDataMapping.t_mCIP_recycle,
      col6: WPUfParametersDataMapping.t_mCIP_soak,
      col7: WPUfParametersDataMapping.t_mCIP_recycle,
    },
    flowRate: {
      col5: WPUfParametersDataMapping.Flow_mCIP_recycle,
      col7: WPUfParametersDataMapping.Flow_mCIP_recycle,
    },
  },
  "rowKey",
);
