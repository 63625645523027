import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import CloseCircleGreenIcon from "@common/icons/CloseCircleGreenIcon";
import StandardPrimaryButton from "@common/styles/components/buttons/standard/StandardPrimaryButton";
import StandardSecondaryButton from "@common/styles/components/buttons/standard/StandardSecondaryButton";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import ErrorMessage from "@common/styles/components/headings/ErrorMessage";
import InputWithIcon from "@common/styles/components/inputs/InputWithIcon";
import CustomSelect from "@common/styles/components/selects/CustomSelect";
import { colors } from "@common/styles/Theme";
import { MyError } from "@common/utils/ErrorCreator";

import { useLazyGetAllDataQuery } from "@services/apiConfig";

import DesignerAndCustomerDetailsStyled from "./DesignerAndCustomerDetailsStyled";

import "react-phone-input-2/lib/style.css";

const DesignerAndCustomerDetails = ({ postdata, changeBack, change, handleSave, nameError }) => {
  const [getState, responseState] = useLazyGetAllDataQuery();
  const [selectedCountryId, setSelecetedCountryId] = useState(postdata.countryID);
  const [State, setState] = useState();
  const [isFocused, setIsFocused] = useState(null);

  const { countries } = useSelector(state => state.globalAppData);

  useEffect(() => {
    try {
      getState(`${"masterdata/api/v1/State"}?countryID=${selectedCountryId}`);
    } catch (error) {
      console.log(error);
    }
  }, [selectedCountryId]);

  useEffect(() => {
    if (responseState.isSuccess === true) {
      // 👇️ sort by String property ASCENDING (A - Z)
      const strAscending = [...responseState.data].sort((a, b) => (a.stateName > b.stateName ? 1 : -1));
      setState(strAscending);
    }

    if (responseState.isError) {
      throw new MyError("State Api Error", responseState.error.status, "ApiError");
    }
  }, [responseState]);

  const handleSubmit = e => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  const handleOpenSaveMessage = () => {
    handleSave();
  };

  const handleChangeInputs = e => {
    const { name, value } = e.target;
    change(name, value);
  };
  const handleCountyChange = e => {
    setSelecetedCountryId(parseInt(e.target.value));
    change(e.target.name, e.target.value);
  };
  const handleStateChange = e => {
    change(e.target.name, e.target.value);
  };

  const handleFocus = id => {
    setIsFocused(id);
  };
  const handleBlur = () => {
    setIsFocused(null);
  };

  return (
    <>
      <DesignerAndCustomerDetailsStyled onSubmit={handleSubmit}>
        <div className='second-section'>
          <Row className='designer-details'>
            <CustomHeading
              lineHeight='18px'
              color={colors.PrimaryDarkAquaMarine}
              label='Designer Details'
              fontFamily='DiodrumSemiBold'
              fontSize='16px'
              fontWeight='600'
              id='designer-details-heading'
            />
            <Col lg={4} md={4} sm={4} xs={4} className='designer-name'>
              <CustomLabel label='Designer' />
              <InputWithIcon
                disabled={false}
                type='text'
                inputText={<CloseCircleGreenIcon />}
                unitBgColor='transparent'
                id='designerName'
                onFocus={() => handleFocus(1)}
                onBlur={handleBlur}
                isFocused={isFocused === 1}
                name='designer'
                placeholder='User Profile Name'
                defaultValue={postdata.designer}
                value={postdata.designer}
                onChange={handleChangeInputs}
              />
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className='designer-company-name'>
              <CustomLabel label="Designer's Company" />
              <InputWithIcon
                disabled={false}
                type='text'
                inputText={<CloseCircleGreenIcon />}
                unitBgColor='transparent'
                id='designerCompany'
                placeholder='Users profile'
                onFocus={() => handleFocus(2)}
                onBlur={handleBlur}
                isFocused={isFocused === 2}
                defaultValue={postdata.designerCompany}
                value={postdata.designerCompany}
                onChange={handleChangeInputs}
                name='designerCompany'
              />
            </Col>
          </Row>
          <Row className='customer-details'>
            <CustomHeading
              lineHeight='18px'
              label='Project Customer Details'
              id='projectDetailsHeading'
              color={colors.PrimaryDarkAquaMarine}
              fontFamily='DiodrumSemiBold'
              fontSize='16px'
              fontWeight='600'
            />
            <Col lg={4} md={4} sm={4} xs={4} className='customer-name'>
              <CustomLabel label='Customer' />
              <InputWithIcon
                disabled={false}
                type='text'
                inputText={<CloseCircleGreenIcon />}
                unitBgColor='transparent'
                id='customerName'
                onFocus={() => handleFocus(3)}
                onBlur={handleBlur}
                isFocused={isFocused === 3}
                placeholder='xyz Refinery'
                defaultValue={postdata.customer}
                value={postdata.customer}
                onChange={handleChangeInputs}
                name='customer'
              />
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className='country-selection'>
              <CustomLabel label='Project Location' mandatoryIcon={true} />
              <CustomSelect
                id='countrySelect'
                onChange={handleCountyChange}
                name='countryID'
                value={postdata.countryID}
              >
                <option value={null}>Select Country</option>
                {countries?.map((data, i) => (
                  <option key={i} value={data.countryID}>
                    {data.countryName}
                  </option>
                ))}
              </CustomSelect>
              <ErrorMessage
                errorIcon={true}
                style={{ visibility: postdata.countryID < 1 ? "visible" : "hidden" }}
                texMsg='Please Select a country'
              />
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className='state-selection'>
              <CustomLabel label='State or Province' />
              <CustomSelect id='stateSelect' onChange={handleStateChange} name='stateID' value={postdata.stateID}>
                <option value={0}>Select State</option>
                {State?.map((data, i) => (
                  <option key={i} value={data.stateID}>
                    {data.stateName}
                  </option>
                ))}
              </CustomSelect>
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className='city-selection'>
              <CustomLabel label='City' />
              <InputWithIcon
                disabled={false}
                isError={false}
                type='text'
                inputText={<CloseCircleGreenIcon />}
                unitBgColor='transparent'
                id='cityInput'
                onFocus={() => handleFocus(4)}
                onBlur={handleBlur}
                isFocused={isFocused === 4}
                placeholder='City Name'
                onChange={handleChangeInputs}
                name='city'
                defaultValue={postdata.city}
                value={postdata.city}
              />
            </Col>
          </Row>
        </div>
        <div className='create-page-footer'>
          <StandardSecondaryButton label='Back' type='submit' id='backBtn' onClick={() => changeBack(0)} />
          <StandardPrimaryButton
            label='Save'
            type='submit'
            id='saveBtn'
            disabled={nameError.caseName || nameError.projectName}
            onClick={handleOpenSaveMessage}
          />
        </div>
      </DesignerAndCustomerDetailsStyled>
    </>
  );
};

export default DesignerAndCustomerDetails;
