import { isNullOrUndefined } from "@utils/appUtils";

import { useReportUtils } from "@features/report-new/useReportUtils";

import {
  WPUfStorageTanksTableColumns as columns,
  WPUfStorageTanksTableRowsData as rowsData,
  WPUfStorageTanksTableUnitMapping as unitMapping,
} from "./WPUfStorageTanksTable.constants";

export const useWPUfStorageTanksTableData = () => {
  const {
    ufReportRespData,
    formatConvertValue,
    formatUnitLabel,
    formatLabel,
    convertReportDataValue,
    isSpecialFeatureVisible,
  } = useReportUtils();
  const { uf_Storage_tanks } = ufReportRespData || {};

  const convertToUfStorageTanksTableData = () => {
    // unit row
    const firstRow = columns.reduce((acc, curr) => {
      acc[curr.field] = formatUnitLabel(unitMapping[curr.field]);
      return acc;
    }, {});

    const data = Object.entries(rowsData).reduce((acc, curr) => {
      const [rowKey, row] = curr;
      let { label, chemical, specialFeature } = row;
      const apiSingleRowData = uf_Storage_tanks?.[rowKey] || {};
      chemical = row?.[apiSingleRowData.symbol] || chemical;
      const { display = true, Min_recommended_volume, Bulk_conc } = apiSingleRowData;

      if (specialFeature && !isSpecialFeatureVisible(specialFeature)) {
        return acc;
      }
      if (!display) return acc;

      const labelValue = chemical ? `${chemical.name} (${chemical.symbol})` : label;
      const labelIndentLevel = chemical ? 1 : 0;

      acc.push({
        label: formatLabel(labelValue, labelIndentLevel),
        // precision is 0
        bulk_conc:
          !isNullOrUndefined(Bulk_conc) && Bulk_conc !== ""
            ? formatConvertValue(Bulk_conc * 100, unitMapping.bulk_conc, 0)
            : "",
        // No trailing zeros for min_recommended_volume, this is an exception
        min_recommended_volume: convertReportDataValue({
          value: Min_recommended_volume,
          unit: unitMapping.min_recommended_volume,
          precision: 3,
          returnNumber: true,
        }),
      });
      return acc;
    }, []);

    // adding unit row at the top
    data.unshift(firstRow);

    return data;
  };

  return { convertToUfStorageTanksTableData };
};
