import React from "react";
import { useSelector } from "react-redux";

import { CaseManagementStrings } from "@utils/StringConstants";

import CustomModal from "@components/CustomModal";

import CaseManagementTable from "./CaseManagementTable";

const CaseManagementPopup = ({
  setCases,
  customclass,
  onModalClose,
  handleDelete,
  onEditCase,
  setSelectedCase,
  selectedCase,
  currentActiveCase,
  handleOpenCase,
}) => {
  const { caseList } = useSelector(state => state.projectInfo.projectConfig.caseConfig);

  const handleBtnClick = id => {
    onModalClose(id);
  };

  return (
    <CustomModal
      className={`${customclass}`}
      header={CaseManagementStrings.caseManagement}
      headerHelper={CaseManagementStrings.caseManagementEditDelete}
      onModalClose={handleBtnClick}
      confirmBtn={CaseManagementStrings.openCase}
      confirmBtnDisabled={!selectedCase || selectedCase.caseID === currentActiveCase}
      cancelBtn={CaseManagementStrings.addCase}
      width="960px"
    >
      <CaseManagementTable
        cases={caseList}
        setCases={setCases}
        handleDelete={handleDelete}
        onEditCase={onEditCase}
        selectedCase={selectedCase}
        setSelectedCase={setSelectedCase}
        currentActiveCase={currentActiveCase}
        handleOpenCase={handleOpenCase}
      />
    </CustomModal>
  );
};

export default CaseManagementPopup;
