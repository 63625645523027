import React, { useState } from "react";
import { Carousel, Col, Row } from "react-bootstrap";

import DecentralizedSolutionsImg from "@common/assets/images/Banner=Descriptive.svg";
import WelcomeWaveProImg from "@common/assets/images/Banner=WavePro.svg";
import WorkingTogetherImg from "@common/assets/images/Banner=With CTA.svg";
import CloseIconWhite from "@common/icons/CloseIconWhite";

import ComonStyled from "./ComonStyled";
import DismissStyled from "./DismissStyled";

const carouselImages = [
  { src: WelcomeWaveProImg, alt: "Welcome to WavePro" },
  { src: WorkingTogetherImg, alt: "Working together for a clean-water future" },
  { src: DecentralizedSolutionsImg, alt: "Decentralized MBR Solutions for a Residential Complex in India" },
];

const ProjectHeadline = () => {
  const [isCarouselOpen, setCarouselOpen] = useState(true);

  const handleCloseCarousel = e => {
    e.preventDefault();
    setCarouselOpen(false);
  };

  return (
    <>
      {isCarouselOpen && (
        <>
          <ComonStyled>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Carousel>
                  {carouselImages.map((image, index) => (
                    <Carousel.Item key={index}>
                      <img className='d-block w-100' src={image.src} alt={image.alt} />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Col>
            </Row>
          </ComonStyled>
          <DismissStyled type='button' className='text-right' onClick={handleCloseCarousel}>
            Dismiss
            <CloseIconWhite />
          </DismissStyled>
        </>
      )}
    </>
  );
};

export default ProjectHeadline;
