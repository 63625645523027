import React from "react";

const TextElement = React.memo(({ id, fill = "black", children, fontWeight = "normal", fontSize = "110" }) => (
  <text id={id} fill={fill} fontSize={fontSize} xmlSpace='preserve' className={`text-style ${fontWeight}`}>
    {children}
  </text>
));
TextElement.displayName = "TextElement";

export default TextElement;
