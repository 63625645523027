import { useEffect, useMemo, useRef, useState } from "react";

import { UNIT_TYPES } from "@constants/units.constant";

import WPAgGrid from "@components/WPAgGrid/WPAgGrid";

import { CHEMICAL_FIELDS } from "../chemicalLibrary.constants";
import useChemicalUnitConversion from "../ChemicalLibraryHooks/useChemicalUnitConversion";

import { CHEMICAL_LIB_TABLE_CONFIG } from "./ChemicalLibraryTable.config";

const ChemicalLibraryTable = ({ chemicals, context }) => {
    const gridApiRef = useRef(null);

    const { activeUnits, activeCurrency, convertChemicalDensity, convertChemicalCurrency } = useChemicalUnitConversion();
    const [columnDefs, setColumnDefs] = useState(CHEMICAL_LIB_TABLE_CONFIG);

    const defaultColDef = useMemo(() => ({
        deltaRowDataMode: true,
        getRowNodeId: data => data.id,
    }), []);

    useEffect(() => {
        const densityUnit = activeUnits[UNIT_TYPES.DENSITY] ? `(${activeUnits[UNIT_TYPES.DENSITY]})` : "";
        const currancy = activeCurrency ? `(${activeCurrency})` : "";
        const colDefs = (CHEMICAL_LIB_TABLE_CONFIG).map(item => {
            const updatedItem = { ...item };
            switch (item.field) {
                case CHEMICAL_FIELDS.bulkDensity:
                    updatedItem.headerName = `${updatedItem.headerName} ${densityUnit}`;
                    updatedItem.valueFormatter = ({ value }) => convertChemicalDensity(value);
                    break;
                case CHEMICAL_FIELDS.bulkPrice:
                    updatedItem.headerName = `${updatedItem.headerName} ${currancy}`;
                    updatedItem.valueFormatter = ({ value }) => convertChemicalCurrency(value);
                    break;
                default:
                    break;
            }
            return updatedItem;
        });
        setColumnDefs(colDefs);
    }, [activeUnits, activeCurrency]);

    useEffect(() => {
        if (gridApiRef.current && chemicals.length > 0) {
            gridApiRef.current.api.ensureIndexVisible(chemicals.length - 1);
        }
    }, [chemicals]);

    const onGridReady = params => gridApiRef.current = params;

    return (
        <WPAgGrid
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={chemicals}
            tableHeight='500px'
            context={context}
            onGridReady={onGridReady}
        />
    );
};

export default ChemicalLibraryTable;